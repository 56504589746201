import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './layouts/auth/auth.module';
import {DefaultModule} from './layouts/default/default.module';
import {DipendenteModule} from './layouts/dipendente/dipendente.module';
import {AuthInterceptorService} from './services/authentication/auth-interceptor.service';
import {AuthGuard} from './services/authentication/auth.guard';
import {AuthenticationService} from './services/authentication/authentication.service';

import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {DividerModule} from 'primeng/divider';
import {ReactiveFormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {CalendarModule} from 'primeng/calendar';
import {InputMaskModule} from 'primeng/inputmask';
import {DropdownModule} from 'primeng/dropdown';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {StepsModule} from 'primeng/steps';
import {TirocinanteDefaultModule} from './layouts/tirocinante-default/tirocinante.module';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DefaultModule,
    AuthModule,
    DipendenteModule,
    HttpClientModule,
    NgxSpinnerModule,
    DividerModule,
    ReactiveFormsModule,
    InputTextModule,
    CalendarModule,
    InputMaskModule,
    DropdownModule,
    MessageModule,
    MessagesModule,
    StepsModule,
    TirocinanteDefaultModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    AuthenticationService, AuthGuard
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

}
