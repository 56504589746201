import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ruoloUtente'
})
export class RuoloUtentePipe implements PipeTransform {
  transform(ruolo: string) {
    return ruolo == 'ADMIN' ? ruolo : 'USER' ? ruolo : 'TIROCINANTE';
  }
}
