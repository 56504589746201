<input (click)="toggleSidebar()" [checked]="navToggleChecked" id="nav-toggle" type="checkbox">
<div class="sidebar">
  <div class="sidebar-brand">
    <a routerLink="/dipendente"><img [alt]="companyName" [src]="datasoundImageUrl" class="logo" height="50px"
                                     width="50px"/></a>
    <a routerLink="/dipendente"><img [alt]="companyName" [src]="fullLogoUrl" class="logo-extended" width="150px"/></a>

  </div>
  <div class="sidebar-menu">
    <ul>
      <li>
        <a #profilo="routerLinkActive" [routerLinkActive]="['active']" routerLink="/dipendente/profilo">
          <span class="las la-id-card"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Profilo</small>
          <span>Profilo</span>
        </a>
      </li>
      <li>
        <a #modificaPassword="routerLinkActive" [routerLinkActive]="['active']"
           routerLink="/dipendente/modifica-password">
          <span class="las la-key"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Modifica password</small>
          <span>Modifica password</span>
        </a>
      </li>
      <li>
        <a #bustePaga="routerLinkActive" [routerLinkActive]="['active']" routerLink="/dipendente/buste-paga">
          <span class="las la-donate"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Buste paga</small>
          <span>Buste paga</span>
        </a>
      </li>
      <li *ngIf="navToggleChecked || currentWindowWidth >= 1200" @fadeSlideFromLeft class="p-mb-2 p-mt-2">
        <small class="menu-subheader">RAPPORTINI</small>
      </li>
      <li>
        <a [ngClass]="{'active': !modificaPassword.isActive && !vistaBustaPaga.isActive && !bustePaga.isActive && !storico.isActive && !profilo.isActive && !vistaRapportino.isActive}"
           routerLink="/dipendente">
          <span class="las la-calendar"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Mese corrente</small>
          <span>Mese corrente</span>
        </a>
      </li>
      <li>
        <a #storico="routerLinkActive" [routerLinkActive]="['active']" routerLink="/dipendente/storico">
          <span class="las la-business-time"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Storico Rapportini</small>
          <span>Storico Rapportini</span>
        </a>
      </li>
      <li>
        <a #vistaRapportino="routerLinkActive" [routerLinkActive]="['active']" routerLink="/dipendente/vista-rapportino/id/"
           style="display: none;"></a>
      </li>
      <li>
        <a #vistaBustaPaga="routerLinkActive" [routerLinkActive]="['active']" routerLink="/dipendente/busta-paga/id/"
           style="display: none;"></a>
      </li>
    </ul>
  </div>
</div>
