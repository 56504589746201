<div class="main-area">
  <header>
    <h2>
      <label for="nav-toggle" id="nav-toggle">
        <span class="las la-bars"></span>
      </label>
      <!-- Dashboard-->
    </h2>

    <div class="user-wrapper">
      <div *ngIf="currentWindowWidth > 560" class="nomeProfilo">
        <h4>{{ nome }} {{ cognome }}</h4>
        <p-tag [severity]="getServerityByRole()" [style]="{'font-size':'.7rem'}" [value]="getRoleName()"></p-tag>
      </div>

      <button (click)="logout()" class="p-button-outlined p-button-secondary p-button-sm logout-button-margin" icon="pi pi-power-off" label="Logout"
              pButton type="button"></button>
    </div>
  </header>
</div>
