import {Pipe, PipeTransform} from '@angular/core';
import {Stato} from '../models/timesheet/stato.enum';

@Pipe({
  name: 'statoRapportino'
})
export class StatoRapportinoPipe implements PipeTransform {
  transform(statoId: number) {
    switch (statoId) {
      case Stato.APPROVATO:
        return 'info';
      case Stato.IN_APPROVAZIONE:
        return 'warning';
      case Stato.IN_LAVORAZIONE:
        return 'danger';
      case Stato.CONSUNTIVATO:
        return 'success';
      default:
        return 'danger';
    }
  }
}
