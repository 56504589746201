import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';

@Component({
  selector: 'app-tirocinante-default',
  templateUrl: './tirocinante-default.component.html',
  styleUrls: ['./tirocinante-default.component.scss']
})
export class TirocinanteDefaultComponent implements OnInit {

  constructor(private config: PrimeNGConfig) {
  }

  ngOnInit() {

    this.config.setTranslation({
      accept: 'Accept',
      reject: 'Cancel',
      'startsWith': 'Inizia con',
      'contains': 'Contiene',
      'notContains': 'Non contiene',
      'endsWith': 'Termina con',
      'equals': 'Uguale',
      'notEquals': 'Non uguale',
      'noFilter': 'No Filter',
      'lt': 'Less than',
      'lte': 'Less than or equal to',
      'gt': 'Greater than',
      'gte': 'Great then or equals',
      'is': 'Is',
      'isNot': 'Is not',
      'before': 'Before',
      'after': 'After',
      'clear': 'Annulla',
      'apply': 'Applica',
      'matchAll': 'Tutte le regole',
      'matchAny': 'Almeno una regola',
      'addRule': 'Aggiungi regola',
      'removeRule': 'Rimuovi regola',
      'choose': 'Choose',
      'upload': 'Upload',
      'cancel': 'Cancel',
      'dayNames': ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
      'dayNamesShort': ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      'dayNamesMin': ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      'monthNames': ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
      'monthNamesShort': ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
      'today': 'Oggi',
      'weekHeader': 'St'
    });
  }

}
