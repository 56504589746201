import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import { Constants } from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {STATUS_DIPENDENTI_MOCK} from 'src/app/mock/status-dipendenti.mock';
import {MansioneInterface} from 'src/app/models/mansione.interface';
import {ProgettoDipendente} from 'src/app/models/progetto-dipendente';
import {Role} from 'src/app/models/role';
import {StatusDipendente} from 'src/app/models/status-dipendente';
import {TipoContrattoInterface} from 'src/app/models/tipo-contratto.interface';
import {User} from 'src/app/models/user';
import {UserInterface} from 'src/app/models/user-interface';
import {MansioniService} from 'src/app/services/mansioni.service';
import {ProgettiService} from 'src/app/services/progetti.service';
import {UserService} from 'src/app/services/user.service';
import GenericUtils from 'src/app/utils/generic-utils';
import TimeUtils from 'src/app/utils/time-utils';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [MessageService, NgxSpinnerService]
})
export class UserComponent implements OnInit {


  profilo: User;
  profiloUtente: UserInterface;
  formProfilo: FormGroup;
  statusDip: StatusDipendente[] = STATUS_DIPENDENTI_MOCK;
  progetti: ProgettoDipendente[] = [];
  progettiSelezionati: ProgettoDipendente[] = [];
  switchToEditMode: boolean;
  statusSelezionato: StatusDipendente;
  pcAziendaleCheckBox: boolean;
  utils = GenericUtils;

  dipendente: User;
  dataLoaded: Promise<boolean>;

  mansioni: MansioneInterface[] = [];
  nome: string;
  ruoloSelezionato: Role;
  timeUtils = TimeUtils;
  ruoliUtente: Role[];
  tipiContratto: TipoContrattoInterface[] = [];
  phonePrefix: string;

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private progettiService: ProgettiService,
    private mansioniService: MansioniService) {

    this.messageService.clear();
    this.spinner.show();
    this.mansioniService.getMansioni().subscribe(mansioni => {
      this.mansioni = mansioni;
    }, (error: HttpErrorResponse) => {
      this.messageService.clear();
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

    this.mansioniService.getTipiContratto().subscribe(tipiContratto => {
      this.tipiContratto = tipiContratto;
    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

    this.progettiService.getProgetti().subscribe(progetti => {
      progetti.forEach(progetto => {
        this.progetti.push(new ProgettoDipendente(progetto.nome, progetto.code));
      });
    }, (errorResponse: HttpErrorResponse) => {
      this.messageService.clear();
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

    this.userService.getUserRoles().subscribe(ruoliUtente => {
      this.ruoliUtente = ruoliUtente;
    }, error => {
      this.messageService.clear();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

    this.spinner.show();
    this.userService.getUserProfile_v3().subscribe(profilo => {
      let comodatoPc = null;
      if (profilo.comodatoPc != null) {
        comodatoPc = {
          id: profilo.comodatoPc.id,
          modelloPc: profilo.comodatoPc.modello,
          serialePc: profilo.comodatoPc.seriale
        };
      }
      this.profiloUtente = {
        id: profilo.id,
        username: profilo.username,
        nome: profilo.nome,
        cognome: profilo.cognome,
        email: profilo.email,
        cellulare: profilo.cellulare,
        dataDiNascita: profilo.dataDiNascita,
        status: profilo.status,
        codiceFiscale: profilo.codiceFiscale,
        tipoContratto: profilo.tipoContratto,
        dataInizioRapporto: profilo.dataInizioRapporto,
        dataFineRapporto: profilo.dataFineRapporto,
        oreSettimanali: profilo.oreSettimanali,
        chiaviUfficio: profilo.chiaviUfficio,
        telefonoAziendale: profilo.telefonoAziendale,
        comodatoPc: comodatoPc,
        mansione: profilo.mansione,
        roles: [{
          id: profilo.roles[0].id,
          name: profilo.roles[0].name
        }],
        progetti: profilo.progetti
      };
      this.nome = profilo.nome;
      this.statusSelezionato = new StatusDipendente(profilo.status, profilo.status);
      this.pcAziendaleCheckBox = profilo.comodatoPc ? true : false;
      const serialePc = profilo.comodatoPc != null ? profilo.comodatoPc.seriale : null;
      const modelloPc = profilo.comodatoPc != null ? profilo.comodatoPc.modello : null;
      this.ruoloSelezionato = {
        id: profilo.roles[0].id,
        name: profilo.roles[0].name
      };

      const phone = profilo.cellulare.split(' ')[1];
      this.phonePrefix = profilo.cellulare.split(' ')[0];
      this.formProfilo = this.fb.group({
        nome: [profilo.nome, [
          Validators.required,
          Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
          Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
          this.utils.whitespaceValidator,
          this.utils.endWithSpaceValidator,
          Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]
        ],
        cognome: [profilo.cognome, [
          Validators.required,
          Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
          Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
          this.utils.whitespaceValidator,
          this.utils.endWithSpaceValidator,
          Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]
        ],
        codiceFiscale: [profilo.codiceFiscale, [
          Validators.required,
          Validators.maxLength(Constants.CODICE_FISCALE_MAX_LENGTH),
          Validators.pattern(Constants.CODICE_FISCALE_REGEX),
          this.utils.codiceFiscaleValidator]],
        email: [profilo.email, [Validators.required, Validators.pattern(Constants.EMAIL_REGEX)]],
        cellulare: [phone, [Validators.required, Validators.pattern(Constants.PHONE_REGEX)]],
        prefix: [this.phonePrefix, Validators.required],
        dataDiNascita: [new Date(profilo.dataDiNascita), [Validators.required, this.timeUtils.dataDiNascitaValidator]],
        statusSelezionato: [this.statusSelezionato, Validators.required],
        ruolo: [this.ruoloSelezionato],
        mansione: [profilo.mansione, Validators.required],
        dataInizioRapporto: [new Date(profilo.dataInizioRapporto), [Validators.required, RxwebValidators.minDate({value: Constants.INITIAL_DATE_DATASOUND})]],
        dataFineRapporto: [profilo.dataFineRapporto || 0],
        tipoContratto: [profilo.tipoContratto, Validators.required],
        oreSettimanali: [profilo.oreSettimanali, [Validators.required, RxwebValidators.minNumber({value: 1}), RxwebValidators.maxNumber({value: Constants.MAX_ORE_SETTIMANALI})]],
        chiaviUfficio: [profilo.chiaviUfficio],
        telefonoAziendale: [profilo.telefonoAziendale],
        pcAziendale: [this.pcAziendaleCheckBox],
        serialePc: [serialePc, this.pcAziendaleCheckBox ? [Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE), this.utils.whitespaceValidator, this.utils.endWithSpaceValidator] : null],
        modelloPc: [modelloPc, this.pcAziendaleCheckBox ? [Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE), this.utils.whitespaceValidator, this.utils.endWithSpaceValidator] : null],
        progetti: ['']
      });
      this.formProfilo.disable();
      this.dataLoaded = Promise.resolve(true);
      this.spinner.hide();
    }, (errorResponse: HttpErrorResponse) => {
      this.messageService.clear();
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }

  ngOnInit(): void {
  }

  inviaForm() {
    this.spinner.show();
    this.userService.updateUserProfile(this.dipendente).subscribe(response => {
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_OK);
        this.closeEditMode();
      },
      error => {
        this.spinner.hide();
      });
  }


  switchToEdit() {
    this.switchToEditMode = true;
    this.formProfilo.controls['email'].enable();
    this.messageService.clear();
  }

  closeEditMode() {
    this.switchToEditMode = false;
    this.formProfilo.controls['email'].disable();

  }

  getYearRange(): string {
    const currentYear = new Date();
    const finalYear = currentYear.getFullYear() - 18;
    const startingYear = currentYear.getFullYear() - 60;
    return startingYear + ':' + finalYear;
  }

}
