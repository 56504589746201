<div class="main-area">
  <main>
    <div class="titolo-sezione">
      <i class="las la-users"></i> Dipendenti
    </div>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <p-divider type="dashed"></p-divider>
    <!-- <div style="text-align: right;">
      <button pButton type="button" label="Nuovo dipendente" class=" p-button-success p-button-md" (click)="nuovoDipendente()"></button>
    </div> -->
    <p-divider type="none"></p-divider>

    <div *ngIf="!dipendenti" class="p-grid">
      <div class="p-col-12">
        <div class="msgNoDipendenti">
          <span class="las la-5x la-info-circle"></span>
          <h3>{{ msgNessunDipendente }}</h3>
        </div>
      </div>
    </div>


    <p-table
      *ngIf="dipendenti"
      [autoLayout]="true"
      [paginator]="true"
      [rowHover]="true"
      [rowsPerPageOptions]="[10,20,30]"
      [rows]="10" [showCurrentPageReport]="true" [totalRecords]="dipendenti.length"
      [value]="dipendenti"
      currentPageReportTemplate="Risultati da {first} a {last}. Totale {totalRecords} dipendente/i"
      styleClass="p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th class="p-text-center" pSortableColumn="cognome" scope="col">
            Cognome
            <p-sortIcon field="cognome"></p-sortIcon>
            <p-columnFilter display="menu" field="cognome" type="text"></p-columnFilter>
          </th>
          <th class="p-text-center" pSortableColumn="nome" scope="col">
            Nome
            <p-sortIcon field="nome"></p-sortIcon>
            <p-columnFilter display="menu" field="nome" type="text"></p-columnFilter>
          </th>
          <th class="p-text-center" scope="col">
            Email
            <p-columnFilter display="menu" field="email" type="text"></p-columnFilter>
          </th>
          <th class="p-text-center" pSortableColumn="status" scope="col">
            Stato
            <p-sortIcon field="status"></p-sortIcon>
            <p-columnFilter [showMatchModes]="false" display="menu" field="status" matchMode="equals">
              <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                <p-dropdown (onChange)="filter($event.value)" [ngModel]="value" [options]="statusDip" optionLabel="name"
                            optionValue="code" placeholder="Qualsiasi">
                  <ng-template let-option pTemplate="item">
                      <span>
                        <p-tag [severity]="utils.statoUtenteStyle(option.code)"
                               [style]="{'width': '100%'}"
                               [value]="option.name"></p-tag>
                      </span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>
          <th class="p-text-center" scope="col">
            Ruolo

            <p-columnFilter display="menu" field="nomeRuolo" matchMode="equals">

            </p-columnFilter>
          </th>
          <th class="p-text-center" scope="col">
            Azioni
          </th>

          <!--
          <th scope="col">
            Progetti
            <p-columnFilter type="text" field="progetti" display="menu"></p-columnFilter>
          </th>-->
        </tr>
      </ng-template>
      <ng-template let-dipendente pTemplate="body">
        <tr>
          <td class="p-text-center">{{dipendente.cognome}}</td>
          <td class="p-text-center">{{dipendente.nome}}</td>
          <td class="p-text-center">{{dipendente.email}}</td>
          <td class="p-text-center">
            <p-tag [severity]="utils.statoUtenteStyle(dipendente.status)" [style]="{'width': '100%'}"
                   [value]="dipendente.status"></p-tag>
          </td>

          <td class="p-text-center">{{ dipendente.nomeRuolo | ruoloUtente | titlecase}}</td>


          <td class="p-text-center">
            <button (click)="selezionaRiga(dipendente)" class="p-button p-button-text" icon="pi pi-eye" pButton
                    type="button"></button>
            <button (click)="confirmDelete_v2($event, dipendente.id)" [disabled]="deleteDisabled(dipendente)" class="p-button p-button-text p-button-danger" icon="pi pi-trash"
                    pButton type="button"></button>
            <p-confirmPopup></p-confirmPopup>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-dialog #dialogDetail (onHide)="onHideModal()" (onShow)="showDialogMaximized(dialogDetail)" *ngIf="dipendenteSelezionato"
              [(visible)]="displayModal" [draggable]="false" closeOnEscape="true" header="Dettaglio dipendente" maximizable="true"
              modal="true" position="top" resizable="true">
      <p-tag *ngIf="switchToEditMode" [value]="'ID = '+dipendenteSelezionato.id" icon="pi pi-user"
             severity="info"></p-tag>
      <p-divider type="dashed"></p-divider>
      <form (ngSubmit)="modificaUtente()" [formGroup]="formDipendente">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-6 p-md-3">
            <label for="nome">Nome</label>
            <input [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('nome').valid && (formDipendente.get('nome').dirty || formDipendente.get('nome').touched)}" formControlName="nome"
                   id="nome"
                   pInputText required type="text">
          </div>
          <div class="p-field p-col-6 p-md-3">
            <label for="cognome">Cognome</label>
            <input [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('cognome').valid && (formDipendente.get('cognome').dirty || formDipendente.get('cognome').touched)}" formControlName="cognome"
                   id="cognome"
                   pInputText required type="text">
          </div>
          <div class="p-field p-col-6 p-md-3">
            <label for="codiceFiscale">Codice fiscale</label>
            <input (input)="onInputCodiceFiscale(formDipendente.get('codiceFiscale').value)" [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('codiceFiscale').valid && (formDipendente.get('codiceFiscale').dirty || formDipendente.get('codiceFiscale').touched)}"
                   formControlName="codiceFiscale"
                   id="codiceFiscale"
                   pInputText
                   required type="text">
            <!-- <div *ngIf="switchToEditMode">
               <small @fadeInAnimation [ngClass]="{'p-error': !cfValid, 'success-text': cfValid }"
                     id="cf-help">{{ getCfValidationMessage() }}</small>
            </div> -->
          </div>
          <div class="p-field p-col-6 p-md-3">
            <label for="dataDiNascita">Data di nascita</label>
            <p-calendar [maxDate]="timeUtils.maxDateForBirthDay()"
                        [monthNavigator]="true"
                        [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('dataDiNascita').valid && (formDipendente.get('dataDiNascita').dirty || formDipendente.get('dataDiNascita').touched)}"
                        [yearNavigator]="true"
                        [yearRange]="getYearRange()"
                        dateFormat="dd/mm/yy"
                        formControlName="dataDiNascita" required></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-3 parent">
            <label for="prefix">Cellulare<label class="required-asterisk">*</label></label>

            <div class="block-div">
              <div class="first">
                <p-dropdown [options]="country" [placeholder]="this.phonePrefix"
                            formControlName="prefix"
                            id="prefix" optionLabel="phonecode" required
                            styleClass="stileRuoli"></p-dropdown>
              </div>
              <div class="last">
                <p-inputMask [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('cellulare').valid && (formDipendente.get('cellulare').dirty || formDipendente.get('cellulare').touched)}" formControlName="cellulare" id="cellulare"
                             mask="9999999999"
                             required></p-inputMask>
              </div>
            </div>
          </div>

          <div class="p-field p-col-12 p-md-3">
            <label for="state">Stato</label>
            <p-dropdown [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('statusSelezionato').valid && (formDipendente.get('statusSelezionato').dirty || formDipendente.get('statusSelezionato').touched)}" [options]="statusDip"
                        formControlName="statusSelezionato"
                        inputId="statusSelezionato"
                        optionLabel="name" placeholder="Seleziona" required></p-dropdown>
          </div>

          <div class="p-field p-col-12 p-md-3">
            <label for="ruolo">Ruolo</label>
            <p-dropdown [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('ruolo').valid && (formDipendente.get('ruolo').dirty || formDipendente.get('ruolo').touched)}" [options]="ruoliUtente"
                        formControlName="ruolo"
                        inputId="ruolo"
                        optionLabel="name"
                        placeholder="Seleziona" required styleClass="stileRuoli"></p-dropdown>
          </div>

          <div class="p-field p-col-12 p-md-3">
            <label for="state">Mansione</label>
            <p-dropdown (onChange)="detectDataChange()" [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('mansione').valid && (formDipendente.get('mansione').dirty || formDipendente.get('mansione').touched)}"
                        [options]="mansioni"
                        formControlName="mansione" inputId="mansione" optionLabel="nome" placeholder="Seleziona"
                        required></p-dropdown>
          </div>
          <div class="p-field p-col-6 p-md-4">
            <label for="email">Indirizzo email</label>

            <input (cancel)="startEmail=false" (input)="checkEmail(formDipendente.get('email').value)" [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('email').valid && (formDipendente.get('email').dirty || formDipendente.get('email').touched)}"
                   formControlName="email"
                   id="email"
                   pInputText
                   required type="email">
            <small *ngIf="existEmail" @fadeInAnimation class="p-error" id="cf-help">Attenzione! Indirizzo email già in
              uso.</small>


          </div>
          <div class="p-field p-col-6 p-md-4">
            <label for="dataDiNascita">Data inizio rapporto</label>
            <p-calendar (keypress)="onDataInizioChange()"
                        (onClose)="onDataInizioChange()"
                        (onSelect)="onDataInizioChange()"
                        [minDate]="initialDateValue"
                        [monthNavigator]="true"
                        [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('dataInizioRapporto').valid && (formDipendente.get('dataInizioRapporto').dirty || formDipendente.get('dataInizioRapporto').touched)}"
                        [yearNavigator]="true"
                        [yearRange]="timeUtils.getFutureYearRange()"
                        dateFormat="dd/mm/yy"
                        formControlName="dataInizioRapporto" required></p-calendar>
          </div>
          <div class="p-field p-col-6 p-md-4 fix-non-required-inline">
            <label for="dataFineRapporto">Data fine rapporto</label>
            <p-calendar [minDate]="timeUtils.setMinDate(formDipendente.get('dataInizioRapporto').value)"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [yearRange]="timeUtils.getFutureYearRange()"
                        dateFormat="dd/mm/yy"
                        formControlName="dataFineRapporto"></p-calendar>
          </div>
          <!-- <div class="p-field p-col-12 p-md-4">
            <label for="tipoContratto">Tipo contratto</label>
            <input id="tipoContratto" formControlName="tipoContratto" type="text" pInputText>
          </div> -->
          <div class="p-field p-col-12 p-md-3">
            <label for="tipoContratto">Tipo contratto</label>
            <p-dropdown [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('tipoContratto').valid && (formDipendente.get('tipoContratto').dirty || formDipendente.get('tipoContratto').touched)}" [options]="tipiContratto"
                        formControlName="tipoContratto"
                        inputId="tipoContratto" optionLabel="nome" placeholder="Seleziona" required></p-dropdown>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="oreSettimanali">Ore settimanali</label>
            <input (keydown)="this.utils.disablePointKeyPress($event)" [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('oreSettimanali').valid && (formDipendente.get('oreSettimanali').dirty || formDipendente.get('oreSettimanali').touched)}"
                   formControlName="oreSettimanali"
                   id="oreSettimanali"
                   pInputText required type="number">
          </div>
          <div class="p-field p-col-12 p-md-12">

            <p-divider align="left">
              <p-button label="In possesso di:" styleClass="p-button-text"></p-button>
            </p-divider>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <div class="p-field-checkbox">
              <p-checkbox binary="true" formControlName="chiaviUfficio" inputId="chiaviUfficio"></p-checkbox>
              <label for="chiaviUfficio">Chiavi ufficio</label>
            </div>
            <div class="p-field-checkbox">
              <p-checkbox binary="true" formControlName="telefonoAziendale" inputId="telefonoAziendale"></p-checkbox>
              <label for="telefonoAziendale">Telefono aziendale</label>
            </div>
            <div class="p-field-checkbox">
              <p-checkbox (onChange)="detectComodatoChange($event)" [(ngModel)]="pcAziendaleCheckBox"
                          [checked]="pcAziendaleCheckBox" binary="true" formControlName="pcAziendale"
                          inputId="pcAziendale"></p-checkbox>
              <label for="pcAziendale">Pc aziendale</label>
            </div>
          </div>
          <div *ngIf="pcAziendaleCheckBox" class="p-field p-col-6 p-md-6">
            <label for="serialePc">Seriale Pc</label>
            <input [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('serialePc').valid && (formDipendente.get('serialePc').dirty || formDipendente.get('serialePc').touched)}" [required]="pcAziendaleCheckBox || null"
                   formControlName="serialePc"
                   id="serialePc" pInputText type="text">
          </div>
          <div *ngIf="pcAziendaleCheckBox" class="p-field p-col-6 p-md-6">
            <label for="modelloPc">Modello Pc</label>
            <input [ngClass]="{'ng-invalid ng-dirty': !formDipendente.get('modelloPc').valid && (formDipendente.get('modelloPc').dirty || formDipendente.get('modelloPc').touched)}" [required]="pcAziendaleCheckBox || null"
                   formControlName="modelloPc"
                   id="modelloPc" pInputText type="text">
          </div>

          <div *ngIf="dipendenteSelezionato.progetti.length > 0" class="p-field p-col-12 p-md-12">
            <p-divider align="left">
              <p-button label="Progetti" styleClass="p-button-text"></p-button>
            </p-divider>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <div *ngIf="dipendenteSelezionato.progetti" class="p-d-flex p-ai-center">
              <p-chip *ngFor="let progetto of dipendenteSelezionato.progetti" styleClass="p-mr-2">

                <a [routerLink]="'/admin/progetto/id/'+progetto.id" rel="noopener noreferrer">{{ progetto.nome }}</a>
              </p-chip>
            </div>
          </div>
          <!-- <div class="p-field p-col-12 p-md-6">
            <label for="progetti">Assegnazione progetto/i</label>
            <p-multiSelect [options]="progetti" [(ngModel)]="progettiSelezionati" optionLabel="nome" formControlName="progetti"></p-multiSelect>
          </div> -->

          <div *ngIf="!switchToEditMode" class="p-field p-col-2 p-md-2 p-offset-10">
            <button (click)="switchToEdit()" class="p-button p-button-warning" label="Modifica" pButton
                    type="button"></button>
          </div>
          <!-- <div class="p-field p-col-2 p-md-2" *ngIf="!switchToEditMode">
            <button pButton type="button" label="Elimina" class="p-button p-button-danger" (click)="confirmDelete($event)"></button>
            <p-confirmPopup></p-confirmPopup>
          </div>-->
          <div *ngIf="switchToEditMode" class="p-field p-col-2 p-md-2 p-offset-8">
            <button (click)="closeEditMode();revertForm(dipendente);" class="p-button p-button-danger" label="Annulla" pButton
                    type="button"></button>
          </div>
          <div *ngIf="switchToEditMode" class="p-field p-col-2 p-md-2">
            <button [disabled]="!formDipendente.valid || existEmail || !formDipendente.get('cellulare').valid" class="p-button p-button-success" label="Salva" pButton
                    type="submit"></button>
          </div>
        </div>
        <p-messages [enableService]="true" key="formModifica"></p-messages>
      </form>
      <p-message *ngIf="utils.formValid(formDipendente).length > 0 && emptyFields().length == 0" @simpleFadeAnimation
                 severity="error" text="Attenzione! Campo/i non popolati correttamente"></p-message>
      <div class="p-md-12" style="padding: 0px"></div>
      <p-message *ngIf="emptyFields().length > 0" @simpleFadeAnimation
                 severity="error" text="Attenzione! Campo/i obbligatori non popolati"></p-message>
    </p-dialog>
    <p-messages [enableService]="true" key="tableDipendenti"></p-messages>
  </main>
</div>
