export class Tirocinante {

  public id: number;
  public username: string;
  public nome: string;
  public cognome: string;
  public stato?: string;
  public email: string;
  public cellulare: string;
  public dataDiNascita: Date;
  public luogoDiNascita: string;
  public codiceFiscale: string;
  public matricola: number;
  public annoImmatricolazione: string;
  public residenza: string;
  public provincia: string;
  public citta: string;
  public cap: string;


  constructor(id: number, username: string, nome: string, cognome: string, stato: string, email: string, cellulare: string, dataDiNascita: Date, luogoDiNascita: string, codiceFiscale: string, matricola: number, annoImmatricolazione: string, residenza: string, provincia: string, citta: string, cap: string) {
    this.id = id;
    this.username = username;
    this.nome = nome;
    this.cognome = cognome;
    this.email = email;
    this.cellulare = cellulare;
    this.dataDiNascita = dataDiNascita;
    this.codiceFiscale = codiceFiscale;
    this.matricola = matricola;
    this.annoImmatricolazione = annoImmatricolazione;
    this.residenza = residenza;
    this.provincia = provincia;
    this.citta = citta;
    this.cap = cap;
    this.luogoDiNascita = luogoDiNascita;
    this.stato = stato;
  }


}
