import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {MultiSelectModule} from 'primeng/multiselect';
import {TableModule} from 'primeng/table';
import {TagModule} from 'primeng/tag';
import {ToastModule} from 'primeng/toast';
import {CommonLayoutModule} from 'src/app/common-layout/common-layout.module';
import {CustomPipesModule} from 'src/app/pipes/custom-pipes.module';
import {ChipModule} from 'primeng/chip';
import {PasswordModule} from 'primeng/password';
import {MarkAsteriskDirectiveModule} from 'src/app/directives/mark-asterisk.directive';
import {TirocinanteComponent} from '../../pages/tirocinante/tirocinante.component';
import {LibrettoDiarioComponent} from '../../pages/tirocinante/libretto-diario/libretto-diario.component';
import {TirocinanteService} from '../../services/tirocinante.service';
import {ProgettoFormativoService} from '../../services/progetto-formativo.service';
import {TirocinanteDefaultComponent} from './tirocinante-default.component';
import {StepsModule} from 'primeng/steps';
import {InputMaskModule} from 'primeng/inputmask';
import {PianoFormativoComponent} from '../../pages/tirocinante/piano-formativo/piano-formativo.component';
import {LibrettoDiarioService} from '../../services/libretto-diario.service';
import {ModificaPasswordTirocinanteComponent} from '../../pages/tirocinante/modifica-password-tirocinante/modifica-password-tirocinante.component';
import {ConfirmPopupModule} from 'primeng/confirmpopup';

@NgModule({
  declarations: [
    TirocinanteDefaultComponent,
    TirocinanteComponent,
    LibrettoDiarioComponent,
    PianoFormativoComponent,
    ModificaPasswordTirocinanteComponent,
  ],
  imports: [
    CommonModule,
    CommonLayoutModule,
    RouterModule,
    DividerModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    TableModule,
    MessagesModule,
    MessageModule,
    TagModule,
    DialogModule,
    ButtonModule,
    CalendarModule,
    ConfirmPopupModule,
    MultiSelectModule,
    CustomPipesModule,
    NgxSpinnerModule,
    CheckboxModule,
    ToastModule,
    ChipModule,
    PasswordModule,
    MarkAsteriskDirectiveModule,
    StepsModule,
    InputMaskModule
  ],
  providers: [
    TirocinanteService, ProgettoFormativoService, LibrettoDiarioService
  ]
})
export class TirocinanteDefaultModule {
}
