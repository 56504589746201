<div>

  <app-light-sidebar></app-light-sidebar>

  <app-header></app-header>

  <div>
    <router-outlet></router-outlet>
  </div>

</div>
