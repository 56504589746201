import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../constants/endpoints';
import {StaticContentResponse} from '../models/response/static-content-response';

@Injectable()
export class StaticContentService {

  constructor(private http: HttpClient) {
  }

  getAsset(varEnv: string): Observable<StaticContentResponse> {
    return this.http.get<StaticContentResponse>(Endpoints.GET_ASSET + varEnv);
  }
}
