import {HttpErrorResponse} from '@angular/common/http';
import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmationService, MessageService} from 'primeng/api';
import {simpleFadeAnimation} from 'src/app/animations/simple-fade-animation';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {MESI_BUSTA_PAGA} from 'src/app/mock/mesi-busta-paga';
import {Anno} from 'src/app/models/anno';
import {MeseBustaPaga} from 'src/app/models/mese-busta-paga';
import {ModificaBustaPagaRequest} from 'src/app/models/request/modifica-busta-paga-request';
import {BustaPagaResponse} from 'src/app/models/response/busta-paga-response';
import {UserInterface} from 'src/app/models/user-interface';
import {BustePagaService} from 'src/app/services/buste-paga.service';
import {UserService} from 'src/app/services/user.service';
import GenericUtils from 'src/app/utils/generic-utils';
import TimeUtils from 'src/app/utils/time-utils';
import {RouterPaths} from '../../../constants/RouterPaths';

@Component({
  selector: 'app-modifica-busta-paga',
  templateUrl: './modifica-busta-paga.component.html',
  styleUrls: ['./modifica-busta-paga.component.scss'],
  providers: [MessageService, BustePagaService, ConfirmationService],
  animations: [simpleFadeAnimation]
})
export class ModificaBustaPagaComponent {

  bustaPagaId: string;
  bustaPaga: BustaPagaResponse;
  dipendente: UserInterface;
  alias: string;
  formBustaPaga: FormGroup;
  mesi: MeseBustaPaga[] = MESI_BUSTA_PAGA;
  anni: Anno[] = [];
  timeUtils = TimeUtils;
  dataLoaded: Promise<boolean>;
  filePdf: any;
  uploadedFiles: any[] = [];
  editMode: boolean = false;
  utils = GenericUtils;
  deleteFileIntent: boolean;


  dipendentePreviuos: string;
  mesePreviuos: string;
  annoPreviuos: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private bustaPagaService: BustePagaService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router) {


    this.activatedRoute.params.subscribe(
      (param) => {
        this.dipendentePreviuos = this.router.getCurrentNavigation().extras.state.dipendente;
        this.mesePreviuos = this.router.getCurrentNavigation().extras.state.mese;
        this.annoPreviuos = this.router.getCurrentNavigation().extras.state.anno;
      });
    this.deleteFileIntent = false;
    this.messageService.clear();
    this.spinner.show();
    this.anni = this.timeUtils.yearsForDropdown();
    this.bustaPagaId = this.activatedRoute.snapshot.params.id;
    this.loadBustaPaga();
  }

  loadBustaPaga() {
    this.spinner.show();
    this.bustaPagaService.getBustaPagaById(this.bustaPagaId).subscribe(bustaPaga => {
      this.bustaPaga = bustaPaga;
      this.userService.getOneUser(bustaPaga.userId + '').subscribe(dipendente => {
        this.dipendente = dipendente;
        this.alias = dipendente.nome + ' ' + dipendente.cognome + ' - ' + dipendente.codiceFiscale;

      }, (errorResponse: HttpErrorResponse) => {
        this.spinner.hide();
        this.dataLoaded = Promise.resolve(true);
        this.messageService.add({
          severity: FrontendMessage.SEVERITY_ERROR,
          summary: FrontendMessage.SUMMARY_ERROR,
          detail: errorResponse.error.message
        });
      });
      this.populateForm();
      this.dataLoaded = Promise.resolve(true);
      this.spinner.hide();
    }, (errorResponse: HttpErrorResponse) => {
      this.dataLoaded = Promise.resolve(true);
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_ERROR,
        detail: errorResponse.error.message
      });
    });


  }

  populateForm() {
    const annoSelezionato: Anno = this.anni.find(anno => {
      return anno.nome == this.bustaPaga.anno + '';
    });
    this.formBustaPaga = this.fb.group({
      alias: [{value: this.alias, disabled: true}],
      meseSelezionato: [{value: this.bustaPaga.mese, disabled: true}],
      annoSelezionato: [{value: annoSelezionato.codice, disabled: true}],
      importoNetto: [{value: this.bustaPaga.importo, disabled: true}, [RxwebValidators.minNumber({value: Constants.BUSTA_PAGA_MIN_VALUE})]],
      costoAzienda: [{
        value: this.bustaPaga.costoAzienda,
        disabled: true
      }, [RxwebValidators.minNumber({value: Constants.BUSTA_PAGA_MIN_VALUE})]]
    });
  }

  goBack() {
    const navigationExtras: NavigationExtras = {
      state: {
        dipendente: this.dipendentePreviuos,
        anno: this.annoPreviuos,
        mese: this.mesePreviuos
      },
    };
    this.router.navigate([RouterPaths.ADMIN_ROUTE + '/' + RouterPaths.LISTA_BUSTE_PAGA], navigationExtras);
  }


  modificaBustaPaga() {
    this.spinner.show();
    const bustaPaga = new FormData();
    const modificaBustaPagaRequest: ModificaBustaPagaRequest = {
      id: this.bustaPaga.id,
      mese: this.formBustaPaga.get('meseSelezionato').value,
      anno: this.formBustaPaga.get('annoSelezionato').value,
      userId: this.dipendente.id,
      importo: this.formBustaPaga.get('importoNetto').value,
      costoAzienda: this.formBustaPaga.get('costoAzienda').value
    };


    bustaPaga.append('file', this.filePdf);
    bustaPaga.append('bustaPagaJsonRequest', JSON.stringify(modificaBustaPagaRequest));

    this.bustaPagaService.modificaBustaPaga(bustaPaga).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.spinner.hide();
        this.loadBustaPaga();
        this.messageService.add({severity: FrontendMessage.SEVERITY_SUCCESS, summary: '', detail: response.message});
        this.closeEditMode();
        this.uploadedFiles = [];
        this.filePdf = null;
        this.deleteFileIntent = false;
        const currRoute = this.router.url;
        setTimeout(() => {
          this.messageService.clear();
          if (currRoute === this.router.url) { this.goBack(); }
        }, 2000);
      }
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({severity: FrontendMessage.SEVERITY_ERROR, summary: '', detail: errorResponse.error.message});
    });
  }


  openPdf(fileId: string) {
    this.spinner.show();
    this.bustaPagaService.getFilePdfBustaPaga(fileId).subscribe((response) => {
      let file = new Blob([response], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      // console.log(file);
      // console.log(fileURL);

      window.open(fileURL);
      this.spinner.hide();
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_ERROR,
        detail: errorResponse.error.message
      });
    });
  }

  downloadPdf(fileId: string) {
    // console.log(fileId);

  }

  deletePdf(event: Event, bustaPagaId: string) {
    this.messageService.clear();
    this.confirmationService.confirm({
      target: event.target,
      message: FrontendMessage.POPUP_CONFERMA_ELIMINAZIONE_BUSTA_PAGA,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: FrontendMessage.CONFERMA,
      rejectLabel: FrontendMessage.ANNULLA,
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.spinner.show();
        this.deleteFileIntent = true;
        this.spinner.hide();

      },
      reject: () => {

      }
    });

  }

  uploadHandler(event) {
    this.filePdf = event.files[0];
    // console.log('deleteFileIntent ' + this.deleteFileIntent);

  }

  switchToEditMode() {
    this.editMode = true;
    this.formBustaPaga.enable();
    this.formBustaPaga.get('alias').disable();
    this.formBustaPaga.get('meseSelezionato').disable();
    this.formBustaPaga.get('annoSelezionato').disable();
    // console.log(this.formBustaPaga);
    // console.log('deletefileintent ' + this.deleteFileIntent);
    // console.log('filepdf ' + this.filePdf);


  }

  changeImporto() {
    // console.log(this.formBustaPaga.get('costoAzienda'));
  }

  closeEditMode() {
    this.editMode = false;
    this.deleteFileIntent = false;
    this.filePdf = null;
    this.formBustaPaga.disable();
  }

  revertForm() {
    this.formBustaPaga.get('importoNetto').reset();
    this.formBustaPaga.get('costoAzienda').reset();
    this.formBustaPaga.patchValue({
      importoNetto: this.bustaPaga.importo,
      costoAzienda: this.bustaPaga.costoAzienda
    });
    this.deleteFileIntent = false;
    this.filePdf = null;
  }

  disableButton(): boolean {
    if (this.isFormValid()) {
      if ((this.filePdf != null || this.filePdf != undefined) && this.deleteFileIntent) {
        return false;
      } else if ((this.filePdf == null || this.filePdf == undefined) && !this.deleteFileIntent) {
        return false;
      }
      return true;
    }
    return true;
  }

  isFormValid(): boolean {
    return this.formBustaPaga.disabled ? true : this.formBustaPaga.valid;
  }

  onRemoveFile() {
    this.filePdf = null;
    this.bustaPaga.filename = null;
  }

  emptyFields() {
    const fields = [];
    const controls = this.formBustaPaga.controls;
    for (const name in controls) {
      if (this.utils.formValid(this.formBustaPaga).includes(name) && controls[name].invalid && (controls[name].value == null || controls[name].value == '')) {
        fields.push(name);
      }
    }
    return fields;
  }
}
