import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {RouterModule} from '@angular/router';
import {TagModule} from 'primeng/tag';
import {FooterComponent} from './footer/footer.component';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DividerModule} from 'primeng/divider';
import {LightSidebarComponent} from './light-sidebar/light-sidebar.component';
import {BadgeModule} from 'primeng/badge';
import {HeaderAdminComponent} from './header-admin/header-admin.component';
import {StaticContentService} from '../services/static-content.service';
import {HeaderTirocinanteComponent} from './header-tirocinante/header-tirocinante.component';
import {SidebarTirocinanteComponent} from './sidebar-tirocinante/sidebar-tirocinante.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderAdminComponent,
    SidebarComponent,
    FooterComponent,
    LightSidebarComponent,
    HeaderTirocinanteComponent,
    SidebarTirocinanteComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TagModule,
    ButtonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    DividerModule,
    BadgeModule
  ],
  exports: [
    HeaderComponent,
    HeaderAdminComponent,
    SidebarComponent,
    FooterComponent,
    LightSidebarComponent,
    HeaderTirocinanteComponent,
    SidebarTirocinanteComponent
  ],
  providers: [StaticContentService]
})
export class CommonLayoutModule {
}
