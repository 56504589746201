import {Component, OnInit} from '@angular/core';
import {ProgettoFormativoService} from '../../../services/progetto-formativo.service';
import {IProgettoFormativo} from '../../../models/tirocinio/progetto-formativo.interface';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormBuilder, FormGroup} from '@angular/forms';
import GenericUtils from '../../../utils/generic-utils';
import TimeUtils from '../../../utils/time-utils';
import {MessageService} from 'primeng/api';
import {simpleFadeAnimation} from '../../../animations/simple-fade-animation';
import {fadeInAnimation} from '../../../animations/fade-in-animation';

@Component({
  selector: 'app-piano-formativo',
  templateUrl: './piano-formativo.component.html',
  styleUrls: ['./piano-formativo.component.scss'],
  providers: [MessageService],
  animations: [simpleFadeAnimation, fadeInAnimation]
})
export class PianoFormativoComponent implements OnInit {

  progettoFormativo: IProgettoFormativo;

  formProgettoFormativo: FormGroup;

  timeUtils: any;
  utils: any;
  dataLoaded: Promise<boolean>;
  tutorPhonePrefix: string;

  constructor(private progettoFormativoService: ProgettoFormativoService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
    this.progettoFormativo = undefined;
    this.utils = GenericUtils;
    this.timeUtils = TimeUtils;
    this.loadProgetto();
  }

  ngOnInit(): void {
  }

  loadProgetto() {
    this.spinner.show();

    this.progettoFormativoService.getProgettoFormativo().subscribe(progetto => {
      this.progettoFormativo = {
        id: progetto.id,
        tirocinante: progetto.tirocinante,
        dataInizioTirocinio: progetto.dataInizioTirocinio,
        dataFineTirocinio: progetto.dataFineTirocinio,
        tutorAziendale: progetto.tutorAziendale,
        tutorUniversitario: progetto.tutorUniversitario,
        totaleOre: progetto.totaleOre,
        obiettivi: progetto.obiettivi
      };

      let phone = progetto.tutorAziendale.cellulare.split(' ')[1];
      this.tutorPhonePrefix = progetto.tutorAziendale.cellulare.split(' ')[0];
      if (phone == null) {
        phone = this.tutorPhonePrefix;
        this.tutorPhonePrefix = '+39'
      }
      // console.log(progetto.tutorAziendale.cellulare);

      this.formProgettoFormativo = this.fb.group({
        dataInizioTirocinio: [new Date(progetto.dataInizioTirocinio)],
        dataFineTirocinio: [new Date(progetto.dataFineTirocinio)],
        ore: [progetto.totaleOre],
        cognomeTutorUniversitario: [progetto.tutorUniversitario.cognome],
        nomeTutorUniversitario: [progetto.tutorUniversitario.nome],
        areaScientifica: [progetto.tutorUniversitario.areaScientifica],
        emailTutorUniversitario: [progetto.tutorUniversitario.email],
        tutorAziendale: [progetto.tutorAziendale.nome + ' ' + progetto.tutorAziendale.cognome],
        prefixTutorAziendale: [this.tutorPhonePrefix],
        cellulareTutorAziendale: [phone],
        emailTutorAziendale: [progetto.tutorAziendale.email],
        obiettivi: [progetto.obiettivi]
      });
      this.dataLoaded = Promise.resolve(true);
      this.formProgettoFormativo.disable();
    });
    this.spinner.hide();
  }

}
