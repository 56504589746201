import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../constants/endpoints';
import {Progetto} from '../models/progetto';
import {ProgettoInterface} from '../models/progetto.interface';
import {ServiceResponse} from '../models/response/service-response';
import {RiepilogoTimesheet} from '../models/timesheet/riepilogo-timesheet.interface';

@Injectable()
export class ProgettiService {

  constructor(private http: HttpClient) {
  }

  getProgetti(): Observable<Progetto[]> {
    return this.http.get<Progetto[]>(Endpoints.PROGETTI_ALL);
  }

  getProgetti_v2(): Observable<ProgettoInterface[]> {
    return this.http.get<ProgettoInterface[]>(Endpoints.PROGETTI_ALL);
  }

  getProgettiSorted(): Observable<ProgettoInterface[]> {
    return this.http.get<ProgettoInterface[]>(Endpoints.PROGETTI_ALL_SORTED_BY_NOME);
  }

  getProgettoById(progettoId: string): Observable<ProgettoInterface> {
    return this.http.get<ProgettoInterface>(Endpoints.GET_PROGETTO_BY_ID + progettoId);
  }

  nuovoProgetto(progetto: Progetto): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(Endpoints.NUOVO_PROGETTO, progetto);
  }

  nuovoProgetto_v2(progetto: ProgettoInterface): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(Endpoints.NUOVO_PROGETTO, progetto);
  }

  updateProgetto(progetto: Progetto): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.UPDATE_PROGETTO, progetto);
  }

  updateProgetto_v2(progetto: ProgettoInterface): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.UPDATE_PROGETTO, progetto);
  }

  /** DEPRECATO */
  riepilogoOreProgettiByTimesheetId(timesheetId: number, userId: string): Observable<RiepilogoTimesheet[]> {
    let params = new HttpParams().set('id', '' + timesheetId).set('userId', userId);
    return this.http.get<RiepilogoTimesheet[]>(Endpoints.PROGETTI_ORE_BY_TIMESHEET_ID, {params: params});
  }

  eliminaProgetto(progettoId: string): Observable<ServiceResponse> {
    return this.http.delete<ServiceResponse>(Endpoints.ELIMINA_PROGETTO_BY_ID + progettoId);
  }

}
