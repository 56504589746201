<input (click)="toggleSidebar()" [checked]="navToggleChecked" id="nav-toggle" type="checkbox">
<div class="sidebar">
  <div class="sidebar-brand">
    <a routerLink="/admin"> <img [alt]="companyName" [src]="datasoundImageUrl" class="logo" height="50px" width="50px"/>
    </a>
    <a routerLink="/admin"> <img [alt]="companyName" [src]="fullLogoUrl" class="logo-extended" width="150px"/> </a>

  </div>
  <div class="sidebar-menu">
    <ul>
      <li>
        <a [ngClass]="{'active': !bustePaga.isActive && !nuovaBustaPaga.isActive && !modifica_busta.isActive
          && !modificaPassword.isActive && !dipendenti.isActive && !progetti.isActive && !dipendente.isActive
          && !progetto.isActive && !timesheets.isActive && !profilo.isActive && !progettoId.isActive
          && !timesheet.isActive && !vista_rapportino.isActive && !notifiche.isActive && !modificaPassword.isActive && !tirocinanti.isActive && !vista_libretto.isActive
          && !tirocinante.isActive}" routerLink="/admin">
          <span class="las la-poll"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Dashboard</small>
          <span>Dashboard</span>
        </a>
      </li>
      <li>
        <a #notifiche="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/notifiche">
          <span class="lab la-telegram"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Notifiche</small>
          <span>Notifiche</span>
        </a>
      </li>
      <li>
        <a #profilo="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/profilo">
          <span class="las la-id-card"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Profilo</small>
          <span>Profilo</span>
        </a>
      </li>
      <li>
        <a #modificaPassword="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/modifica-password">
          <span class="las la-key"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Modifica password</small>
          <span>Modifica password</span>
        </a>
      </li>
      <li *ngIf="navToggleChecked || currentWindowWidth >= 1200" @fadeSlideFromLeft class="p-mb-2 p-mt-2">
        <small class="menu-subheader">BUSTE PAGA</small>
      </li>
      <li>
        <a #nuovaBustaPaga="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/nuova-busta-paga">
          <span class="las la-donate"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Nuova</small>
          <span>Nuova</span>
        </a>
      </li>
      <li>
        <a #bustePaga="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/buste-paga">
          <span class="las la-clipboard-list"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Lista buste</small>
          <span>Lista</span>
        </a>
      </li>
      <li *ngIf="navToggleChecked || currentWindowWidth >= 1200" @fadeSlideFromLeft class="p-mb-2 p-mt-2">
        <small class="menu-subheader">RAPPORTINI</small>
      </li>
      <li>
        <a #timesheet="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/rapportino">
          <span class="las la-business-time"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Mese corrente</small>
          <span>Mese corrente</span>
        </a>
      </li>
      <li>
        <a #timesheets="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/timesheets">
          <span class="las la-calendar"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Lista rapportini</small>
          <span>Lista</span>
        </a>
      </li>
      <li *ngIf="navToggleChecked || currentWindowWidth >= 1200" @fadeSlideFromLeft class="p-mb-2 p-mt-2">
        <small class="menu-subheader">DIPENDENTI</small>
      </li>
      <li>
        <a #dipendente="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/nuovo-dipendente">
          <span class="las la-user"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Nuovo dipendente</small>
          <span>Nuovo</span>
        </a>
      </li>
      <li>
        <a #dipendenti="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/dipendenti">
          <span class="las la-users"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Lista dipendenti</small>
          <span>Lista</span>
        </a>
      </li>
      <li *ngIf="navToggleChecked || currentWindowWidth >= 1200" @fadeSlideFromLeft class="p-mb-2 p-mt-2">
        <small class="menu-subheader">PROGETTI</small>
      </li>
      <li>
        <a #progetto="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/nuovo-progetto">
          <span class="las la-drafting-compass"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Nuovo prog.</small>
          <span>Nuovo</span>
        </a>
      </li>
      <li>
        <a #progetti="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/progetti">
          <span class="las la-toolbox"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Lista prog.</small>
          <span>Lista</span>
        </a>
      </li>
      <li *ngIf="navToggleChecked || currentWindowWidth >= 1200" @fadeSlideFromLeft class="p-mb-2 p-mt-2">
        <small class="menu-subheader">TIROCINI</small>
      </li>
      <li>
        <a #tirocinante="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/nuovo-tirocinante">
          <span class="las la-user"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Nuovo Tirocinante</small>
          <span>Nuovo</span>
        </a>
      </li>
      <li>
        <a #tirocinanti="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/tirocinanti">
          <span class="las la-users"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Lista tirocinanti</small>
          <span>Lista</span>
        </a>
      </li>
      <li class="hide">
        <a #progettoId="routerLinkActive" [routerLinkActive]="['active']" routerLink="/admin/progetto/id"></a>
      </li>
      <li class="hide">
        <a #vista_rapportino="routerLinkActive" [routerLinkActive]="['active']"
           routerLink="/admin/vista-rapportino/id"></a>
      </li>
      <li class="hide">
        <a #modifica_busta="routerLinkActive" [routerLinkActive]="['active']"
           routerLink="/admin/modifica-busta-paga/id"></a>
      </li>
      <li class="hide">
        <a #modificaPassword="routerLinkActive" [routerLinkActive]="['active']"
           routerLink="/admin/modifica-password"></a>
      </li>
      <li class="hide">
        <a #vista_libretto="routerLinkActive" [routerLinkActive]="['active']"
           routerLink="/admin/libretto-diario/id"></a>
      </li>
    </ul>
  </div>
</div>
