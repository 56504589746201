import {Pipe, PipeTransform} from '@angular/core';
import {UserInterface} from '../models/user-interface';

@Pipe({
  name: 'userAlias'
})
export class UserAliasPipe implements PipeTransform {
  transform(userId: number, dipendenti: UserInterface[]) {
    let dipendente: UserInterface = dipendenti.find((dipendente) => {
      return dipendente.id === userId;
    });
    return dipendente.alias;
  }
}
