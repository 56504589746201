<div class="main-area">
  <main>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <div class="titolo-sezione">
      <i class="las la-key"></i> Modifica password
    </div>
    <!--<div class="p-d-flex p-mt-2 p-mb-2">
       <div>
        <button pButton routerLink="/admin/profilo" type="button" label="Vai al profilo"
          class="p-button p-button-outlined p-button-primary"></button>
      </div>
    </div>-->
    <div class="p-d-flex p-jc-center p-mt-6">
      <div class="p-col-12 p-md-6 p-shadow-3 p-p-6">
        <form (ngSubmit)="modificaPassword()" [formGroup]="formModificaPassword">
          <div class="p-fluid">
            <div class="p-field">
              <label for="currentPassword">Password in uso</label>
              <input
                (input)="selectField=false"

                [ngClass]="{'ng-invalid ng-dirty': !formModificaPassword.get('currentPassword').valid && (formModificaPassword.get('currentPassword').dirty || formModificaPassword.get('currentPassword').touched)}"
                aria-describedby="currentPassword-help"
                formControlName="currentPassword"
                pInputText
                required
                type="password"/>
              <small class="p-mr-2" id="currentPassword-help">Digita la tua attuale password.</small>
              <br>
              <small
                *ngIf="!formModificaPassword.get('currentPassword').valid && (formModificaPassword.get('currentPassword').dirty || formModificaPassword.get('currentPassword').touched)"
                @fadeInAnimation
                class="p-error"
                id="currentPassword-errors">Attenzione! Campo obbligatorio non compilato.</small>

            </div>
            <div class="p-field">
              <label for="newPassword">Nuova password</label>
              <p-password

                (input)="selectField=true"
                [ngClass]="{'ng-invalid ng-dirty': !formModificaPassword.get('newPassword').valid && (formModificaPassword.get('newPassword').dirty || formModificaPassword.get('newPassword').touched)}"
                aria-describedby="newPassword-help"
                formControlName="newPassword"
                mediumLabel="Password efficace"
                promptLabel="Digita una password"
                required
                strongLabel="Password robusta"
                type="password"
                weakLabel="Password debole">
              </p-password>
              <small class="p-mr-2" id="newPassword-help">Inserisci la tua nuova password.</small>
              <br>
              <small *ngIf="!formModificaPassword.get('newPassword').valid && (formModificaPassword.get('newPassword').dirty || formModificaPassword.get('newPassword').touched)" @fadeInAnimation
                     class="p-error"
                     id="newPassword-errors">Attenzione! Campo obbligatorio non compilato.</small>
            </div>
            <div class="p-field">
              <label for="confirmNewPassword">Conferma password</label>
              <input
                (input)="selectField=true"

                [ngClass]="{'ng-invalid ng-dirty': !formModificaPassword.get('confirmNewPassword').valid && (formModificaPassword.get('confirmNewPassword').dirty || formModificaPassword.get('confirmNewPassword').touched)}"
                aria-describedby="confirmNewPassword-help"
                formControlName="confirmNewPassword"
                pInputText
                required
                type="password"/>
              <small class="p-mr-2" id="confirmNewPassword-help">Conferma la tua nuova password.</small>
              <br>
              <small *ngIf="!formModificaPassword.get('confirmNewPassword').valid && (formModificaPassword.get('confirmNewPassword').dirty || formModificaPassword.get('confirmNewPassword').touched)" @fadeInAnimation
                     class="p-error"
                     id="newPassword-errors">Attenzione! Campo obbligatorio non compilato.</small>
            </div>
            <div class="p-field p-d-flex p-jc-end">
              <button [disabled]="!formModificaPassword.valid || formModificaPassword.get('newPassword').value != formModificaPassword.get('confirmNewPassword').value" label="Conferma" pButton style="width: 10rem;"
                      type="submit"></button>
            </div>
          </div>
        </form>
        <div class="p-mb-2">
          <p-message *ngIf="formModificaPassword.get('newPassword').value != formModificaPassword.get('confirmNewPassword').value && selectField" @fadeInAnimation
                     severity="error"
                     text="Le password non corrispondono."></p-message>
        </div>
        <div class="p-mb-2">
          <p-message *ngIf="formModificaPassword.get('newPassword').value == formModificaPassword.get('confirmNewPassword').value && formModificaPassword.get('newPassword').value != '' && selectField" @fadeInAnimation
                     severity="success"
                     text="Le password corrispondono."></p-message>
        </div>
        <p-messages @fadeInAnimation [enableService]="true"></p-messages>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
      </div>
      <div class="p-col-12">
      </div>
    </div>
  </main>
</div>
