import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AccordionModule} from 'primeng/accordion';
import {BadgeModule} from 'primeng/badge';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FileUploadModule} from 'primeng/fileupload';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputTextModule} from 'primeng/inputtext';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {MultiSelectModule} from 'primeng/multiselect';
import {PasswordModule} from 'primeng/password';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TableModule} from 'primeng/table';
import {TagModule} from 'primeng/tag';
import {ToastModule} from 'primeng/toast';
import {CommonLayoutModule} from 'src/app/common-layout/common-layout.module';
import {MarkAsteriskDirectiveModule} from 'src/app/directives/mark-asterisk.directive';
import {AdminComponent} from 'src/app/pages/admin/admin.component';
import {BustePagaComponent} from 'src/app/pages/admin/buste-paga/buste-paga.component';
import {DipendentiComponent} from 'src/app/pages/admin/dipendenti/dipendenti.component';
import {ModificaBustaPagaComponent} from 'src/app/pages/admin/modifica-busta-paga/modifica-busta-paga.component';
import {ModificaPasswordComponent} from 'src/app/pages/admin/modifica-password/modifica-password.component';
import {NotificheComponent} from 'src/app/pages/admin/notifiche/notifiche.component';
import {NuovaBustaPagaComponent} from 'src/app/pages/admin/nuova-busta-paga/nuova-busta-paga.component';
import {NuovoDipendenteComponent} from 'src/app/pages/admin/nuovo-dipendente/nuovo-dipendente.component';
import {NuovoProgettoComponent} from 'src/app/pages/admin/nuovo-progetto/nuovo-progetto.component';
import {ProfiloComponent} from 'src/app/pages/admin/profilo/profilo.component';
import {ProgettiComponent} from 'src/app/pages/admin/progetti/progetti.component';
import {ProgettoComponent} from 'src/app/pages/admin/progetto/progetto.component';
import {RapportinoComponent} from 'src/app/pages/admin/rapportino/rapportino.component';
import {TimesheetsComponent} from 'src/app/pages/admin/timesheets/timesheets.component';
import {VistaRapportinoComponent} from 'src/app/pages/admin/vista-rapportino/vista-rapportino.component';
import {CustomPipesModule} from 'src/app/pipes/custom-pipes.module';
import {MansioniService} from 'src/app/services/mansioni.service';
import {ProgettiService} from 'src/app/services/progetti.service';
import {UserService} from 'src/app/services/user.service';
import {DefaultComponent} from './default.component';
import {RxReactiveFormsModule} from '@rxweb/reactive-form-validators';
import {TirocinantiComponent} from 'src/app/pages/admin/tirocinanti/tirocinanti.component';
import {NuovoTirocinanteComponent} from 'src/app/pages/admin/nuovo-tirocinante/nuovo-tirocinante.component';
import {StepsModule} from 'primeng/steps';
import {ProgettoFormativoComponent} from 'src/app/pages/admin/progetto-formativo/progetto-formativo.component';
import {TirocinanteService} from '../../services/tirocinante.service';
import {ProgettoFormativoService} from '../../services/progetto-formativo.service';
import {VistaLibrettoDiarioComponent} from '../../pages/admin/vista-libretto-diario/vista-libretto-diario.component';
import {TirocinanteAliasPipe} from '../../pipes/tirocinante-alias.pipe';

@NgModule({
  declarations: [
    DefaultComponent,
    AdminComponent,
    DipendentiComponent,
    ProgettiComponent,
    ProgettoComponent,
    NuovoDipendenteComponent,
    NuovoProgettoComponent,
    TimesheetsComponent,
    RapportinoComponent,
    ProfiloComponent,
    VistaRapportinoComponent,
    NotificheComponent,
    BustePagaComponent,
    NuovaBustaPagaComponent,
    ModificaBustaPagaComponent,
    ModificaPasswordComponent,
    TirocinantiComponent,
    NuovoTirocinanteComponent,
    ProgettoFormativoComponent,
    VistaLibrettoDiarioComponent,
    TirocinanteAliasPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    CommonLayoutModule,
    ChartModule,
    DividerModule,
    TableModule,
    TagModule,
    ButtonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    CalendarModule,
    MultiSelectModule,
    DialogModule,
    CustomPipesModule,
    NgxSpinnerModule,
    MessagesModule,
    ProgressSpinnerModule,
    ToastModule,
    InputMaskModule,
    ConfirmPopupModule,
    CheckboxModule,
    ChipModule,
    AccordionModule,
    BadgeModule,
    MessageModule,
    FileUploadModule,
    ColorPickerModule,
    InputNumberModule,
    PasswordModule,
    MarkAsteriskDirectiveModule,
    RxReactiveFormsModule,
    StepsModule
  ],
  providers: [
    UserService, ProgettiService, MansioniService, TirocinanteService, ProgettoFormativoService

  ]
})
export class DefaultModule {
}
