<div class="main-area">
  <main>
    <div class="p-grid">
      <div class="p-col">
        <button (click)="goBack()" class="p-button-primary p-button-raised" icon="pi pi-arrow-circle-left" label="Torna alla lista buste paga"
                pButton type="button"></button>
      </div>
    </div>
    <div class="titolo-sezione">
      <i class="las la-donate"></i> Modifica busta paga
      <button *ngIf="editMode" @simpleFadeAnimation [label]="'#ID ' + bustaPagaId" class="p-button-help p-button-text" pButton
              type="button"></button>
    </div>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <form (ngSubmit)="modificaBustaPaga()" *ngIf="dataLoaded" [formGroup]="formBustaPaga" class="p-mt-4">
      <div class="p-fluid p-formgrid p-grid p-jc-end">
        <div class="p-field p-col-12 p-md-6">
          <label for="alias">Dipendente</label>
          <input [(ngModel)]="alias" formControlName="alias" id="alias" pInputText required type="text">
        </div>
        <div class="p-field p-col-12 p-md-6"></div>
        <div class="p-field p-col-12 p-md-3">
          <label for="anno">Anno</label>
          <p-dropdown [options]="anni" formControlName="annoSelezionato" inputId="annoSelezionato" optionLabel="nome"
                      optionValue="codice" placeholder="Seleziona" required>
          </p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="mese">Mese</label>
          <p-dropdown [options]="mesi" formControlName="meseSelezionato" inputId="meseSelezionato" optionLabel="nome"
                      optionValue="codice" placeholder="Seleziona" required>
          </p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="importoNetto">Netto in busta</label>
          <p-inputNumber [minFractionDigits]="2"
                         [ngClass]="{'ng-invalid ng-dirty': !formBustaPaga.get('importoNetto').valid && (formBustaPaga.get('importoNetto').dirty || formBustaPaga.get('importoNetto').touched)}" currency="EUR"
                         formControlName="importoNetto"
                         locale="it-IT" mode="decimal" mode="decimal" mode="currency" required></p-inputNumber>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="costoAzienda">Costo azienda</label>
          <p-inputNumber (keypress)="changeImporto()" [minFractionDigits]="2"
                         [ngClass]="{'ng-invalid ng-dirty': !formBustaPaga.get('costoAzienda').valid && (formBustaPaga.get('costoAzienda').dirty || formBustaPaga.get('costoAzienda').touched)}"
                         currency="EUR" formControlName="costoAzienda" locale="it-IT" mode="currency" mode="decimal"
                         required></p-inputNumber>
        </div>
        <!-- <div class="p-field p-col-12 p-md-12 p-mt-3">
          <div class="p-field-checkbox">
            <p-checkbox formControlName="tredicesima" binary="true" inputId="binary"></p-checkbox>
            <label for="tredicesima">Tredicesima</label>
          </div>
        </div>-->
        <div class="p-field p-col-12 p-md-12 p-mt-3">
          <h4>File:</h4>
          <div *ngIf="(deleteFileIntent && (filePdf == null || filePdf == undefined)) || bustaPaga.filename == null"
               class="p-col-4 p-mt-3">
            <p-message severity="info" text="Non ci sono file associati a questa busta paga."></p-message>
          </div>
          <br>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <div class="p-grid">
            <div *ngIf="(bustaPaga.filename == null || deleteFileIntent) && editMode" class="p-col-12">
              <p-fileUpload (onRemove)="onRemoveFile()" (uploadHandler)="uploadHandler($event)" [auto]="true" [files]="uploadedFiles"
                            accept="application/pdf" cancelLabel="Cancella" chooseIcon="pi pi-upload"
                            chooseLabel="Seleziona file"
                            customUpload="true"
                            invalidFileSizeMessageDetail="La dimensione massima consentita è {0}."
                            invalidFileSizeMessageSummary="{0}: Dimensione non valida."
                            invalidFileTypeMessageDetail=""
                            invalidFileTypeMessageSummary="Attenzione! Il formato del documento selezionato non è valido." maxFileSize="1000000" name="uploadedFiles[]"
                            uploadLabel="Carica">
                <ng-template pTemplate="content">
                  <ul *ngIf="uploadedFiles.length">
                    <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                  </ul>
                </ng-template>
              </p-fileUpload>
            </div>
            <div *ngIf="bustaPaga.filename != null && !deleteFileIntent && filePdf == null" class="p-col-4">
              <button (click)="openPdf(this.bustaPaga.fileId)" [label]="bustaPaga.filename + ' (' + (bustaPaga.size | numToBytes) + ')'" class="p-button-text p-button-secondary p-button-text"
                      icon="pi pi-file-pdf" iconPos="left" pButton
                      type="button"></button>
            </div>
            <div *ngIf="bustaPaga.filename != null && !deleteFileIntent && filePdf == null" class="p-col-1">
              <p-confirmPopup></p-confirmPopup>
              <button (click)="deletePdf($event, this.bustaPaga.id)" [disabled]="!editMode" class="p-button-text p-button-danger p-button-text" icon="pi pi-trash" iconPos="left"
                      label="" pButton
                      type="button"></button>
            </div>
          </div>
        </div>
        <div *ngIf="editMode" class="p-field p-col-12 p-md-2">
          <button (click)="closeEditMode();revertForm();" class="p-button p-button-secondary" label="Annulla" pButton
                  type="button"></button>
        </div>
        <div *ngIf="editMode" class="p-field p-col-12 p-md-2">
          <button [disabled]="disableButton()" class="p-button p-button-success" label="Salva" pButton
                  type="submit"></button>
        </div>
        <div *ngIf="!editMode" class="p-field p-col-12 p-md-2">
          <button (click)="switchToEditMode()" class="p-button p-button-warning" label="Modifica" pButton
                  type="button"></button>
        </div>
      </div>
    </form>
    <div *ngIf="dataLoaded">
      <p-message *ngIf="utils.formValid(formBustaPaga).length > 0 && emptyFields().length == 0 && editMode" @simpleFadeAnimation
                 severity="error" text="Attenzione! Campi obbligatori non compilati."></p-message>
      <div class="p-md-12" style="padding: 0px"></div>
      <p-message *ngIf="emptyFields().length > 0" @simpleFadeAnimation
                 severity="error" text="Attenzione! Campo/i obbligatori non popolati"></p-message>
    </div>
    <p-messages [enableService]="true"></p-messages>
  </main>
</div>
