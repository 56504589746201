import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {LoginResponseInterface} from '../../models/login/login-response.interface';
import {StorageConstants} from '../../constants/storage-constants';
import {UserRoleEnum} from '../../models/enums/user-roles';
import {FrontendMessage} from '../../constants/frontend-message';
import {Constants} from '../../constants/constants';
import {RouterPaths} from '../../constants/RouterPaths';
import {TirocinanteService} from '../../services/tirocinante.service';

@Component({
  selector: 'app-header-tirocinante',
  templateUrl: './header-tirocinante.component.html',
  styleUrls: ['./header-tirocinante.component.scss']
})
export class HeaderTirocinanteComponent implements OnInit, OnDestroy {
  currentWindowWidth: any;
  userSub: Subscription;
  ruoloUtente: string;
  nome: string;
  cognome: string;

  totaleNotificheSblocco: number;

  constructor(private router: Router, private authService: AuthenticationService, private tirocinanteService: TirocinanteService) {
    const userData: LoginResponseInterface = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
    this.nome = userData.nome;
    this.cognome = userData.cognome;
    this.ruoloUtente = userData.role;
    this.tirocinanteService.getTirocinanteProfile().subscribe((resp) => {
      if (resp.primoAccesso) {
        this.router.navigateByUrl(RouterPaths.TIROCINANTE_ROUTE + '/' + RouterPaths.MODIFICA_PASSWORD);
      }
    });
    this.getNotificheSblocco();
  }

  ngOnInit(): void {
    this.currentWindowWidth = window.innerWidth;
  }

  @HostListener('window:resize')
  onResize() {
    this.currentWindowWidth = window.innerWidth;
  }

  ngOnDestroy() {

  }

  getNotificheSblocco() {

    this.totaleNotificheSblocco = 2;
  }

  logout() {
    this.authService.logout();
  }

  goToNotifiche() {

  }

  getRoleName(): string {
    if (UserRoleEnum.TIROCINANTE === this.ruoloUtente) {
      return FrontendMessage.TIROCINANTE;
    } else if (UserRoleEnum.ADMIN === this.ruoloUtente) {
      return FrontendMessage.AMMINISTRATORE;
    } else {
      return FrontendMessage.DIPENDENTE;
    }
  }

  getServerityByRole(): string {
    if (UserRoleEnum.ADMIN === this.ruoloUtente) {
      return Constants.SEVERITY_INFO;
    } else if (UserRoleEnum.DIPENDENTE === this.ruoloUtente) {
      return Constants.SEVERITY_WARNING;
    } else {
      return Constants.SEVERITY_SUCCESS;
    }

  }


}
