import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../constants/endpoints';
import {RiepilogoOreProgetto} from '../models/response/riepilogo-ore-progetto';
import {ServiceResponse} from '../models/response/service-response';
import {ApprovaTimesheetRequest} from '../models/timesheet/approva-timesheet-request';
import {ContaNotificheSblocco} from '../models/timesheet/conta-notifiche-sblocco';
import {RiepilogoTimesheet} from '../models/timesheet/riepilogo-timesheet.interface';
import {TimesheetExistsResponse} from '../models/timesheet/timesheet-exists-response';
import {TimesheetManagementInterface} from '../models/timesheet/timesheet-management-request';
import {TimesheetInterface} from '../models/timesheet/timesheet.interface';
import {WorkingDayInterface} from '../models/timesheet/working-day.interface';

@Injectable()
export class TimesheetService {

  constructor(private http: HttpClient) {
  }

  timesheetThisMonthExistsForUser(userId: number): Observable<TimesheetExistsResponse> {
    return this.http.get<TimesheetExistsResponse>(Endpoints.TIMESHEET_EXISTS_CURRENT_MONTH_BY_UID + userId);
  }

  createCurrentMonthTimesheet(timsheet: TimesheetInterface): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(Endpoints.NEW_TIMESHEET, timsheet);
  }

  getCurrentMonthTimesheetByUid(userId: number): Observable<TimesheetInterface> {
    return this.http.get<TimesheetInterface>(Endpoints.CURRENT_MONTH_TIMESHEET_BY_UID + userId);
  }

  newTimesheetActivity(workingDay: WorkingDayInterface): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(Endpoints.WORKING_DAY_NEW, workingDay);
  }

  deleteWorkingDay(workingDayId: string, timesheetId: string, userId: string): Observable<ServiceResponse> {
    let params = new HttpParams().set('workingDayId', workingDayId).set('timesheetId', timesheetId).set('userId', userId);
    return this.http.delete<ServiceResponse>(Endpoints.WORKING_DAY_DELETE, {params: params});
  }

  deleteOneDayDetail(workingDayId: string, timesheetId: string, activityId: string, tipoGiornata: string, userId: string): Observable<ServiceResponse> {
    let params = new HttpParams().set('workingDayId', workingDayId).set('timesheetId', timesheetId).set('activityId', activityId).set('tipoGiornata', tipoGiornata).set('userId', userId);
    return this.http.delete<ServiceResponse>(Endpoints.WORKING_DAY_DELETE_ONE_ACTIVITY, {params: params});
  }

  getAllTimesheets(): Observable<TimesheetInterface[]> {
    return this.http.get<TimesheetInterface[]>(Endpoints.GET_ALL_TIMESHEETS);
  }

  getAllTimesheetsByUserId(userId: string): Observable<TimesheetInterface[]> {
    return this.http.get<TimesheetInterface[]>(Endpoints.FIND_ALL_TIMESHEETS_BY_USERID + userId);
  }

  getAllTimesheetsByRichiestaSblocco(): Observable<TimesheetInterface[]> {
    return this.http.get<TimesheetInterface[]>(Endpoints.FIND_ALL_TIMESHEETS_BY_RICHIESTA_SBLOCCO);
  }

  getAllTimesheetsByInApprovazione(): Observable<TimesheetInterface[]> {
    return this.http.get<TimesheetInterface[]>(Endpoints.FIND_ALL_TIMESHEETS_BY_IN_APPROVAZIONE);
  }

  findTimesheetById(id: string): Observable<TimesheetInterface> {
    return this.http.get<TimesheetInterface>(Endpoints.FIND_TIMESHEET_BY_ID + id);
  }

  findTimesheetById_v2(timesheetId: string, userId: string): Observable<TimesheetInterface> {
    let params = new HttpParams().set('timesheetId', '' + timesheetId).set('userId', '' + userId);
    return this.http.get<TimesheetInterface>(Endpoints.FIND_TIMESHEET_BY_ID_v2, {params: params});
  }

  searchTimesheets(userId: string, mese: string, anno: string, stato: string): Observable<TimesheetInterface[]> {
    let params = new HttpParams().set('userId', '' + userId).set('mese', '' + mese).set('anno', '' + anno).set('stato', '' + stato);
    return this.http.get<TimesheetInterface[]>(Endpoints.SEARCH_TIMESHEETS, {params: params});
  }

  riepilogoOreProgettiByTimesheetId(timesheetId: number, userId: string): Observable<RiepilogoTimesheet[]> {
    let params = new HttpParams().set('id', '' + timesheetId).set('userId', userId);
    return this.http.get<RiepilogoTimesheet[]>(Endpoints.PROGETTI_ORE_BY_TIMESHEET_V2, {params: params});
  }

  /** Il conteggio si basa sulle ore totali dei dipendenti consuntivate e non */
  riepilogoOreSitazioneEconomicaProgetto(progettoId: string): Observable<RiepilogoOreProgetto[]> {
    return this.http.get<RiepilogoOreProgetto[]>(Endpoints.ORE_SITUAZIONE_ECONOMICA_PROGETTO + progettoId);
  }

  inviaTimesheet(request: TimesheetManagementInterface): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.SEND_TIMESHEET, request);
  }


  inviaRapportino(request: TimesheetManagementInterface): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.SEND_TIMESHEET_NEW, request);
  }

  approvaTimesheet(request: ApprovaTimesheetRequest): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.APPROVA_TIMESHEET, request);
  }

  richiestaSblocco(request: TimesheetManagementInterface): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.RICHIESTA_SBLOCCO_TIMESHEET, request);
  }

  sbloccaTimesheet(request: TimesheetManagementInterface): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.SBLOCCA_TIMESHEET, request);
  }


  sbloccaRapportino(request: TimesheetManagementInterface): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.SBLOCCA_TIMESHEET_NEW, request);
  }

  contaNotificheSblocco(): Observable<ContaNotificheSblocco> {
    return this.http.get<ContaNotificheSblocco>(Endpoints.NOTIFICHE_SBLOCCO_TIMESHEET);
  }

  exportTimesheetToPDF(timesheetId: string): any {
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'

    };
    return this.http.get<any>(Endpoints.TIMESHEET_EXPORT_TO_PDF + timesheetId, httpOptions);
  }
}
