export class InitPasswordRecoveryRequest {
  private username: string;
  private tokenId: string;

  constructor(username: string, tokenId: string) {
    this.username = username;
    this.tokenId = tokenId;
  }

  public getUsername(): string {
    return this.username;
  }

  public getTokenId(): string {
    return this.tokenId;
  }
}
