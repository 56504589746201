import {Role} from '../models/role';

export class Constants {

  // REGEX
  public static readonly EMAIL_REGEX = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  public static readonly CODICE_FISCALE_REGEX = '^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$';
  public static readonly TEXT_REGEX = /^[a-zA-Z]+$/;
  public static readonly PHONE_REGEX = /^[0-9+]+$/;
  public static readonly TEXT_AND_NUMBERS_REGEX = '^[a-zA-Z0-9]+$';
  public static readonly REGEX_TEXT_NUMBERS_NO_SPACE_AT_BEGIN = '^[a-zA-Z0-9]+.*?[^\s]$';
  public static readonly REGEX_TEXT_NUMBERS_NO_SPACE_AT_BEGIN_v2 = /^[a-zA-Z0-9]+.*?\w+(\s+\w+)*$/;
  public static readonly REGEX_TEXT_NUMBERS_NO_NUMBERS_AT_BEGIN = '^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$';
  public static readonly REGEX_TEXT_NO_SPACE_AT_BEGIN = '^[a-zA-Z]+.*?[^\s]$';
  public static readonly REGEX_HOUR_MINUTES = '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$';
  public static readonly NUMBERS_REGEX = '/^[0-9]+$/';
  public static readonly DATE_REGEX = '\d{4}-[0-1]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\+\d{4}';
  public static readonly DOMINIO_EMAIL = '@datasound.it';
  public static readonly MESSAGE_LIFE_IN_MILLISECONDS = 10000;
  public static readonly INITIAL_DATE_DATASOUND = new Date(2017, 9, 1);
  public static readonly MAX_ORE_SETTIMANALI = 168;
  public static readonly ALPHA_REGEX_CON_APOSTROFO = /^[A-Za-z][a-zA-Z'\s]*$/;
  public static readonly ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO = /^[A-Za-zÀ-ÿ][A-Za-zÀ-ÿ'\s]*$/;
  public static readonly REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE = /[^\s]/;
  public static readonly REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE_NOTE = /(?![0-9]+$)(?![!@#\$%\^\&\*\(\),.?":{}|<>]+$)[a-zA-Z0-9"!@#\$%\^\&*\)\(+=._-ùèéà]+.*?[a-zA-Z0-9!?"@#\$%\^\&*\)\(+=._-ùèéà]$/;
  public static readonly REGEX_ALPHANUM_SPECIAL_NO_LEAD_TRAIL_SPACE = /(?!^\d+$)^.+$/;
  public static readonly REGEX_ALPHANUM_NO_ONLY_SPACE_SPECIAL = /(?![0-9]+$)(?![!@#\$%\^\&\*\(\),.?":{}|<>£$%]+$)[a-zA-Z0-9"!@#\$%\^\&*\)\(+=._-ùèéà]+.*?[a-zA-Z0-9!?"@#\$%\^\&*\)\(+=._-ùèéà]$/;


  public static readonly MAX_TELEPHONE = 13;
  public static readonly MIN_TELEPHONE = 10;

  // Buste paga
  public static readonly BUSTA_PAGA_MIN_VALUE = 1;

  // Rapportino
  public static readonly ACTIVITY_MIN_VALUE = 1;
  public static readonly ACTIVITY_MAX_VALUE = 24;

  // Reset PWD
  public static readonly RESET_PWD_USERNAME_PARAM = 'username';
  public static readonly RESET_PWD_TOKEN_ID_PARAM = 'tokenId';

  // Service status
  public static readonly STATUS_CODE_OK = 'OK';
  public static readonly STATUS_CODE_KO = 'KO';

  // Ruoli utente
  public static readonly ADMIN = 'ADMIN';
  public static readonly USER = 'USER';
  public static readonly TIROCINANTE = 'TIROCINANTE';
  public static readonly ROLE_ADMIN: Role = {id: 4, name: Constants.ADMIN};
  public static readonly ROLE_USER: Role = {id: 5, name: Constants.USER};
  public static readonly ROLE_TIROCINANTE: Role = {id: 6, name: Constants.TIROCINANTE};
  public static readonly SEVERITY_INFO = 'info';
  public static readonly SEVERITY_WARNING = 'warning';
  public static readonly SEVERITY_SUCCESS = 'success';
  public static readonly DIPENDENTE = 'Dipendente';

  /** Time */
  public static readonly DATA_FORMAT_US = 'YYYY-MM-DD';

  /** Variabili ambiente risorse statiche */
  public static readonly PPM_LOGO_MINI = 'PPM_LOGO_MINI';
  public static readonly PPM_LOGO_MINI_150 = 'PPM_LOGO_MINI_150';
  public static readonly PPM_LOGO_CIRCOLARE = 'PPM_LOGO_CIRCOLARE';

  /** File statici */
  public static readonly PPM_LOGO_CIRCOLARE_OFFLINE = 'assets/images/datasound-tondo.png';
  public static readonly PPM_LOGO_MINI_OFFLINE = 'assets/images/datasound-mini.png';
  public static readonly PPM_LOGO_MINI_150_OFFLINE = 'assets/images/datasound-mini-150.png';

  /** Validatori */
  public static readonly INPUT_TEXT_MAX_LENGHT = 254;
  public static readonly INPUT_TEXT_MIN_LENGHT = 2;
  public static readonly CODICE_FISCALE_MAX_LENGTH = 16;

  public static readonly INPUT_TEXT_MAX_LENGHT_NOTE = 8000;

  /** Tirocinante */
  public static readonly YEAR_REGEX = '[0-9]{4}/[0-9]{4}';
  public static readonly CAP = '[0-9]{5}';
}
