import {Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {MessageService} from 'primeng/api';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ITirocinante} from '../../models/tirocinio/tirocinante-interface';
import {TirocinanteService} from '../../services/tirocinante.service';
import TimeUtils from '../../utils/time-utils';
import GenericUtils from '../../utils/generic-utils';

@Component({
  selector: 'app-tirocinante',
  templateUrl: './tirocinante.component.html',
  styleUrls: ['./tirocinante.component.scss'],
  animations: [trigger('fadeSlideInOut', [
    transition(':enter', [
      style({opacity: 0, transform: 'translateY(10px)'}),
      animate('1s', style({opacity: 1, transform: 'translateY(0)'})),
    ]),
    transition(':leave', [
      animate('1s', style({opacity: 0, transform: 'translateY(10px)'})),
    ]),
  ])],
  providers: [MessageService, NgxSpinnerService]
})
export class TirocinanteComponent implements OnInit {

  tirocinante: ITirocinante;
  formTirocinante: FormGroup;
  timeUtils: any;
  utils: any;
  dataLoaded: Promise<boolean>;
  phonePrefix: string;

  constructor(private authService: AuthenticationService,
              private tirocinanteService: TirocinanteService,
              private fb: FormBuilder,
              private spinner: NgxSpinnerService) {
    this.phonePrefix = '';
    this.utils = GenericUtils;
    this.timeUtils = TimeUtils;
    this.loadProfile();
  }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
  }


  loadProfile() {
    this.spinner.show();


    this.tirocinanteService.getTirocinanteProfile().subscribe(profilo => {
      this.tirocinante = {
        id: profilo.id,
        username: profilo.username,
        nome: profilo.nome,
        cognome: profilo.cognome,
        codiceFiscale: profilo.codiceFiscale,
        luogoDiNascita: profilo.luogoDiNascita,
        dataDiNascita: profilo.dataDiNascita,
        matricola: profilo.matricola,
        annoImmatricolazione: profilo.annoImmatricolazione,
        residenza: profilo.residenza,
        provincia: profilo.provincia,
        citta: profilo.citta,
        cap: profilo.cap,
        cellulare: profilo.cellulare,
        email: profilo.email,
        progettoFormativo: profilo.progettoFormativo,
        stato: profilo.stato
      };


      const phone = profilo.cellulare.split(' ')[1];
      this.phonePrefix = profilo.cellulare.split(' ')[0];
      this.formTirocinante = this.fb.group({
        nome: [profilo.nome],
        cognome: [profilo.cognome],
        codiceFiscale: [profilo.codiceFiscale],
        luogoDiNascita: [profilo.luogoDiNascita],
        dataDiNascita: [new Date(profilo.dataDiNascita)],
        matricola: [profilo.matricola],
        annoImmatricolazione: [profilo.annoImmatricolazione],
        residenza: [profilo.residenza],
        provincia: [profilo.provincia],
        citta: [profilo.citta],
        cap: [profilo.cap],
        cellulare: [phone],
        prefix: [this.phonePrefix],
        email: [profilo.email],

      });
      this.dataLoaded = Promise.resolve(true);
      this.formTirocinante.disable();
    });
    this.spinner.hide();


  }
}
