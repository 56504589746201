import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import TimeUtils from '../../../utils/time-utils';
import GenericUtils from '../../../utils/generic-utils';
import * as codiceFiscaleUtils from '@marketto/codice-fiscale-utils/src/index';
import {Constants} from '../../../constants/constants';
import {MessageService} from 'primeng/api';
import {ProgettiService} from '../../../services/progetti.service';
import {UserService} from '../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {simpleFadeAnimation} from '../../../animations/simple-fade-animation';
import {fadeInAnimation} from '../../../animations/fade-in-animation';
import {UserInterface} from '../../../models/user-interface';
import areaScientifica from '../../../../assets/areaScientifica.json';
import {ITirocinante} from '../../../models/tirocinio/tirocinante-interface';
import {ProgettoFormativoService} from '../../../services/progetto-formativo.service';
import {FrontendMessage} from '../../../constants/frontend-message';
import {HttpErrorResponse} from '@angular/common/http';
import {RouterPaths} from '../../../constants/RouterPaths';
import {TirocinantiComponent} from '../tirocinanti/tirocinanti.component';
import {PHONE_PREFIX_MOCK} from '../../../mock/phone-prefix.mock';
import {IProgettoFormativo} from '../../../models/tirocinio/progetto-formativo.interface';

@Component({
  selector: 'app-progetto-formativo',
  templateUrl: './progetto-formativo.component.html',
  styleUrls: ['./progetto-formativo.component.scss'],
  providers: [MessageService],
  animations: [simpleFadeAnimation, fadeInAnimation]
})
export class ProgettoFormativoComponent implements OnInit {

  formNuovoProgettoFormativo: FormGroup;
  areaScientifica1: any[];
  cellulare: string;
  timeUtils = TimeUtils;
  utils = GenericUtils;
  dataFineDisabled: boolean;
  cfUtil = codiceFiscaleUtils;
  cfValid: boolean;
  startCase: boolean;
  initialDateValue = Constants.INITIAL_DATE_DATASOUND;
  dipendenti: UserInterface[];
  progettoToStore: IProgettoFormativo;

  areaScientificaName: string;
  tutorAziendaleName: string;
  selectedAreaScientifica: boolean;
  selectedTutorAziendale: boolean;
  country: any[];
  tirocinanteComponent: TirocinantiComponent;
  @Output() datiAnagrafici = new EventEmitter<boolean>();
  @Output() displayModal = new EventEmitter<boolean>();
  @Output() activateIndex = new EventEmitter<number>();
  @Output() formNuovoTirocinante = new EventEmitter<FormGroup>();

  @Input() tirocinanteToStore: ITirocinante;
  switchToEditMode: boolean;

  constructor(private fb: FormBuilder,
              private progettiService: ProgettiService,
              private messageService: MessageService,
              private userService: UserService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private progettoFormativoService: ProgettoFormativoService) {
    this.dipendenti = [];
    this.messageService.clear();
    this.dataFineDisabled = true;
    this.loadFormEmpity();
    this.country = PHONE_PREFIX_MOCK;
    this.switchToEditMode = false;
    this.areaScientificaName = '';
    this.tutorAziendaleName = '';
    this.selectedAreaScientifica = false;
    this.selectedTutorAziendale = false;
    this.userService.getAllDipendenti().subscribe(dipendenti => {

      for (const dipendente of dipendenti) {
        this.dipendenti.push({
          id: dipendente.id,
          username: dipendente.username,
          nome: dipendente.nome,
          cognome: dipendente.cognome,
          alias: dipendente.cognome + ' ' + dipendente.nome,
          email: dipendente.email,
          cellulare: dipendente.cellulare,
          dataDiNascita: dipendente.dataDiNascita,
          status: dipendente.status,
          codiceFiscale: dipendente.codiceFiscale,
          tipoContratto: dipendente.tipoContratto,
          dataInizioRapporto: dipendente.dataInizioRapporto,
          dataFineRapporto: dipendente.dataFineRapporto,
          oreSettimanali: dipendente.oreSettimanali,
          chiaviUfficio: dipendente.chiaviUfficio,
          telefonoAziendale: dipendente.telefonoAziendale,
          comodatoPc: dipendente.comodatoPc,
          mansione: dipendente.mansione,
          roles: dipendente.roles,
          progetti: dipendente.progetti
        });
      }
      this.dipendenti.sort((a, b) => a.alias.localeCompare(b.alias));
    });
    this.areaScientifica1 = areaScientifica;
  }

  ngOnInit(): void {
    if (this.tirocinanteToStore.progettoFormativo != null) {
      this.loadForm();
    }
    this.onLoadData();
  }

  loadForm() {
    this.spinner.show();
    const cellullareTutor = this.tirocinanteToStore.progettoFormativo.tutorAziendale.cellulare;
    let tutorPhone = cellullareTutor.split(' ')[1];
    let tutorPhonePrefix = cellullareTutor.split(' ')[0];
    if (tutorPhone == null) {
      tutorPhone = tutorPhonePrefix;
      tutorPhonePrefix = '+39';
    }
    const alias = this.tirocinanteToStore.progettoFormativo.tutorAziendale.nome + ' ' + this.tirocinanteToStore.progettoFormativo.tutorAziendale.cognome;
    this.formNuovoProgettoFormativo = this.fb.group({
      dataInizioTirocinio: [new Date(this.tirocinanteToStore.progettoFormativo.dataInizioTirocinio), [Validators.required, this.timeUtils.dateValidator]],
      dataFineTirocinio: [new Date(this.tirocinanteToStore.progettoFormativo.dataFineTirocinio), [Validators.required, this.timeUtils.dateValidator]],
      ore: [this.tirocinanteToStore.progettoFormativo.totaleOre, [Validators.required, RxwebValidators.minNumber({value: 1}), RxwebValidators.maxNumber({value: 9999})]],
      cognomeTutorUniversitario: [this.tirocinanteToStore.progettoFormativo.tutorUniversitario.cognome, [
        Validators.required,
        Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]
      ],
      nomeTutorUniversitario: [this.tirocinanteToStore.progettoFormativo.tutorUniversitario.nome, [
        Validators.required,
        Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]],
      areaScientifica: [this.tirocinanteToStore.progettoFormativo.tutorUniversitario.areaScientifica, Validators.required],
      emailTutorUniversitario: [this.tirocinanteToStore.progettoFormativo.tutorUniversitario.email, [Validators.required, Validators.pattern(Constants.EMAIL_REGEX)]],
      tutorAziendale: [alias, Validators.required],
      prefixTutorAziendale: [tutorPhonePrefix,],
      cellulareTutorAziendale: [tutorPhone, Validators.required],
      emailTutorAziendale: [this.tirocinanteToStore.progettoFormativo.tutorAziendale.email, [Validators.required, Validators.pattern(Constants.EMAIL_REGEX)]],
      obiettivi: [this.tirocinanteToStore.progettoFormativo.obiettivi, [Validators.required, Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE_NOTE)]],

    });
    this.formNuovoProgettoFormativo.disable();

    this.formNuovoProgettoFormativo.get('cellulareTutorAziendale').disable();
    this.formNuovoProgettoFormativo.get('prefixTutorAziendale').disable();
    this.formNuovoProgettoFormativo.get('emailTutorAziendale').disable();
    this.spinner.hide();
  }

  loadFormEmpity() {
    this.spinner.show();
    this.formNuovoProgettoFormativo = this.fb.group({
      dataInizioTirocinio: ['', [Validators.required, this.timeUtils.dateValidator]],
      dataFineTirocinio: ['', [Validators.required, this.timeUtils.dateValidator]],
      ore: ['', [Validators.required, RxwebValidators.minNumber({value: 1}), RxwebValidators.maxNumber({value: 9999})]],
      cognomeTutorUniversitario: ['', [
        Validators.required,
        Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]
      ],
      nomeTutorUniversitario: ['', [
        Validators.required,
        Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]],
      areaScientifica: ['', Validators.required],
      emailTutorUniversitario: ['', [Validators.required, Validators.pattern(Constants.EMAIL_REGEX)]],
      tutorAziendale: ['', Validators.required],
      prefixTutorAziendale: ['',],
      cellulareTutorAziendale: ['', Validators.required],
      emailTutorAziendale: ['', [Validators.required, Validators.pattern(Constants.EMAIL_REGEX)]],
      obiettivi: ['', [Validators.required, Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE_NOTE)]],

    });

    this.formNuovoProgettoFormativo.get('cellulareTutorAziendale').disable();
    this.formNuovoProgettoFormativo.get('prefixTutorAziendale').disable();
    this.formNuovoProgettoFormativo.get('emailTutorAziendale').disable();
    this.spinner.hide();
  }

  onLoadData() {
    if (this.tirocinanteToStore.progettoFormativo == null) {
      this.areaScientificaName = 'Seleziona';
      this.tutorAziendaleName = 'Seleziona';
    } else {
      this.areaScientificaName = this.tirocinanteToStore.progettoFormativo.tutorUniversitario.areaScientifica;
      this.tutorAziendaleName = this.tirocinanteToStore.progettoFormativo.tutorAziendale.nome + ' ' + this.tirocinanteToStore.progettoFormativo.tutorAziendale.cognome;
    }
  }

  onInputCodiceFiscale(cf: string) {
    this.startCase = true;
    const {Validator} = this.cfUtil;
    if (Validator.codiceFiscale(cf).valid) {
      this.cfValid = true;
    } else {
      this.cfValid = false;
    }
  }


  detectDataChangeTutorAziendale() {
    this.selectedTutorAziendale = true;
    const cellullareTutor = this.formNuovoProgettoFormativo.get('tutorAziendale').value.cellulare;
    const emailTutor = this.formNuovoProgettoFormativo.get('tutorAziendale').value.email;
    let tutorPhone = cellullareTutor.split(' ')[1];
    let tutorPhonePrefix = cellullareTutor.split(' ')[0];
    if (tutorPhone == null) {
      tutorPhone = tutorPhonePrefix;
      tutorPhonePrefix = '+39';
    }
    this.formNuovoProgettoFormativo.get('cellulareTutorAziendale').setValue(tutorPhone);
    this.formNuovoProgettoFormativo.get('prefixTutorAziendale').setValue(tutorPhonePrefix);
    this.formNuovoProgettoFormativo.get('emailTutorAziendale').setValue(emailTutor);
  }


  inviaformNuovoProgettoFormativo() {
    this.spinner.show();

    const dataInizioTirocinio = new Date(Date.UTC(this.formNuovoProgettoFormativo.get('dataInizioTirocinio').value.getFullYear(), this.formNuovoProgettoFormativo.get('dataInizioTirocinio').value.getMonth(), this.formNuovoProgettoFormativo.get('dataInizioTirocinio').value.getDate()));
    const dataFineTirocinio = new Date(Date.UTC(this.formNuovoProgettoFormativo.get('dataFineTirocinio').value.getFullYear(), this.formNuovoProgettoFormativo.get('dataFineTirocinio').value.getMonth(), this.formNuovoProgettoFormativo.get('dataFineTirocinio').value.getDate()));


    const ProgettoToStore: IProgettoFormativo = {
      id: null,
      dataInizioTirocinio,
      dataFineTirocinio,
      totaleOre: this.formNuovoProgettoFormativo.value.ore,
      tutorUniversitario: {
        id: null,
        nome: this.formNuovoProgettoFormativo.value.nomeTutorUniversitario,
        cognome: this.formNuovoProgettoFormativo.value.cognomeTutorUniversitario,
        areaScientifica: this.formNuovoProgettoFormativo.value.areaScientifica.Nome,
        email: this.formNuovoProgettoFormativo.value.emailTutorUniversitario
      },
      tutorAziendale: {
        id: this.dipendenti.filter( dipendenti => dipendenti.email === this.formNuovoProgettoFormativo.value.tutorAziendale.email )[0].id,
        nome: this.formNuovoProgettoFormativo.value.tutorAziendale.nome,
        cognome: this.formNuovoProgettoFormativo.value.tutorAziendale.cognome,
        email: this.formNuovoProgettoFormativo.value.tutorAziendale.email,
        cellulare: this.formNuovoProgettoFormativo.value.tutorAziendale.cellulare
      },
      tirocinante: this.tirocinanteToStore,
      obiettivi: this.formNuovoProgettoFormativo.value.obiettivi
    };


    this.progettoFormativoService.nuovoProgettoFormativo(ProgettoToStore).subscribe(response => {
      this.spinner.hide();
      this.messageService.clear();
      this.messageService.add(FrontendMessage.TIROCINIO_OK);
      setTimeout(() => {
        this.router.navigateByUrl(RouterPaths.ADMIN_ROUTE + '/' + RouterPaths.LISTA_TIROCINANTI);
      }, 2000);
      this.formNuovoProgettoFormativo.reset();


    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: '',
        detail: errorResponse.error.message
      });
    });

  }

  resetForm() {
    this.formNuovoProgettoFormativo.reset();
    this.cfValid = false;
    this.startCase = false;

  }


  getYearRange(): string {
    const currentYear = new Date();
    const finalYear = currentYear.getFullYear() - 18;
    const startingYear = currentYear.getFullYear() - 90;
    return startingYear + ':' + finalYear;
  }

  generateUsername(email: string) {
    return email.split('@')[0];
  }

  generateUsernameV2(nome: string, cognome: string) {
    const nomeInput = nome.toLowerCase().trim().split(' ').join('');
    const cognomeInput = cognome.toLowerCase().trim().split(' ').join('');
    return nomeInput + '.' + cognomeInput;
  }

  onDataInizioChange() {
    const dataInizio = this.formNuovoProgettoFormativo.get('dataInizioTirocinio').value;
    this.formNuovoProgettoFormativo.controls['dataFineTirocinio'].reset();
    this.dataFineDisabled = false;

    if (dataInizio != null && dataInizio > Constants.INITIAL_DATE_DATASOUND) {
      this.dataFineDisabled = false;
    } else {
      this.dataFineDisabled = true;
    }
  }

  goBack() {
    this.datiAnagrafici.emit(true);
    this.activateIndex.emit(0);
  }

  closeEditMode() {
    this.formNuovoProgettoFormativo.disable();
    this.loadForm();
    this.switchToEditMode = false;
  }

  closeEditModifica() {
    this.formNuovoProgettoFormativo.disable();
    this.switchToEditMode = false;
  }

  switchToEdit() {
    this.switchToEditMode = true;
    this.formNuovoProgettoFormativo.enable();
    this.formNuovoProgettoFormativo.get('cellulareTutorAziendale').disable();
    this.formNuovoProgettoFormativo.get('prefixTutorAziendale').disable();
    this.formNuovoProgettoFormativo.get('emailTutorAziendale').disable();
  }

  enableAndResetRelatedDateField(control1, control2) {
    const abilita = this.timeUtils.enableAndResetDateField(control1, control2);

    if (abilita) {
      this.dataFineDisabled = false;
    } else {
      this.dataFineDisabled = true;
    }
  }

  onChange() {
    this.selectedAreaScientifica = true;
  }

  onChangeTutorAziendale() {

  }

  modificaProgetto(formDirective: FormGroupDirective, form: any) {


    const dataInizioTirocinio = new Date(Date.UTC(this.formNuovoProgettoFormativo.get('dataInizioTirocinio').value.getFullYear(), this.formNuovoProgettoFormativo.get('dataInizioTirocinio').value.getMonth(), this.formNuovoProgettoFormativo.get('dataInizioTirocinio').value.getDate()));
    const dataFineTirocinio = new Date(Date.UTC(this.formNuovoProgettoFormativo.get('dataFineTirocinio').value.getFullYear(), this.formNuovoProgettoFormativo.get('dataFineTirocinio').value.getMonth(), this.formNuovoProgettoFormativo.get('dataFineTirocinio').value.getDate()));

    let areaScientifica;
    let tutorAziendale;
    if (this.selectedAreaScientifica) {
      areaScientifica = this.formNuovoProgettoFormativo.value.areaScientifica.Nome;
    } else {
      areaScientifica = this.tirocinanteToStore.progettoFormativo.tutorUniversitario.areaScientifica;
    }
    if (this.selectedTutorAziendale) {
      tutorAziendale = {
        id: this.dipendenti.filter( dipendenti => dipendenti.email === this.formNuovoProgettoFormativo.value.tutorAziendale.email )[0].id,
        nome: this.formNuovoProgettoFormativo.value.tutorAziendale.nome,
        cognome: this.formNuovoProgettoFormativo.value.tutorAziendale.cognome,
        email: this.formNuovoProgettoFormativo.value.tutorAziendale.email,
        cellulare: this.formNuovoProgettoFormativo.value.tutorAziendale.cellulare
      };
    } else {
      tutorAziendale = {
        id: this.tirocinanteToStore.progettoFormativo.tutorAziendale.id,
        nome: this.tirocinanteToStore.progettoFormativo.tutorAziendale.nome,
        cognome: this.tirocinanteToStore.progettoFormativo.tutorAziendale.cognome,
        email: this.tirocinanteToStore.progettoFormativo.tutorAziendale.email,
        cellulare: this.tirocinanteToStore.progettoFormativo.tutorAziendale.cellulare
      };
    }

    const ProgettoToStore: IProgettoFormativo = {
      id: this.tirocinanteToStore.progettoFormativo.id,
      dataInizioTirocinio,
      dataFineTirocinio,
      totaleOre: this.formNuovoProgettoFormativo.value.ore,
      tutorUniversitario: {
        id: this.tirocinanteToStore.progettoFormativo.tutorUniversitario.id,
        nome: this.formNuovoProgettoFormativo.value.nomeTutorUniversitario,
        cognome: this.formNuovoProgettoFormativo.value.cognomeTutorUniversitario,
        areaScientifica,
        email: this.formNuovoProgettoFormativo.value.emailTutorUniversitario
      },
      tutorAziendale,
      tirocinante: this.tirocinanteToStore,
      obiettivi: this.formNuovoProgettoFormativo.value.obiettivi

    };

    this.spinner.show();
    this.progettoFormativoService.updateProgettoFormativo(ProgettoToStore).subscribe(response => {
      this.spinner.hide();
      this.messageService.clear();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_PROGETTO_FORMATIVO_MODIFICATO);
      this.closeEditModifica();
      setTimeout(() => {
        this.displayModal.emit(true);
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_TIROCINIO_UPDATE);
      }, 800);
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: '',
        detail: errorResponse.error.message,
        key: 'tableProgettiFormativi'
      });
    });

  }

  emptyFields() {
    const fields = [];
    const controls = this.formNuovoProgettoFormativo.controls;
    for (const name in controls) {
      if (this.utils.formValid(this.formNuovoProgettoFormativo).includes(name) && controls[name].invalid && (controls[name].value == null || controls[name].value == '')) {
        fields.push(name);
      }
    }
    return fields;
  }
}
