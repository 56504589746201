import {HttpErrorResponse} from '@angular/common/http';
import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {fadeInAnimation} from 'src/app/animations/fade-in-animation';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {StorageConstants} from 'src/app/constants/storage-constants';
import {ChangePasswordNoTokenRequest} from 'src/app/models/password-recovery/change-password-no-token-request';
import {AuthenticationService} from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-modifica-password',
  templateUrl: './modifica-password.component.html',
  styleUrls: ['./modifica-password.component.scss'],
  providers: [MessageService],
  animations: [
    fadeInAnimation
  ]
})
export class ModificaPasswordComponent {

  formModificaPassword: FormGroup;
  nuovaPassword: string;

  selectField = false;

  constructor(private spinner: NgxSpinnerService, private router: Router, private fb: FormBuilder, private authService: AuthenticationService, private messageService: MessageService) {
    this.formModificaPassword = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
    });

    this.selectField = false;
  }

  modificaPassword() {
    this.spinner.show();
    this.messageService.clear();
    // console.log(this.formModificaPassword);
    const userData = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
    const request: ChangePasswordNoTokenRequest = {
      userId: userData.userId,
      currentPassword: this.formModificaPassword.get('currentPassword').value,
      newPassword: this.formModificaPassword.get('newPassword').value,
      confirmNewPassword: this.formModificaPassword.get('confirmNewPassword').value
    };

    this.selectField = false;
    this.authService.changePasswordNoToken(request).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.formModificaPassword.reset();
        this.spinner.hide();
        this.messageService.add(FrontendMessage.CHANGE_PASSWORD_OK);
        setTimeout(() => {
          this.messageService.clear();
        }, 2000);
      }
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: 'Attenzione! ',
        detail: 'Il campo Password in uso non è corretto'
      });
      setTimeout(() => {

        this.messageService.clear();
      }, 3000);

    });
  }

}
