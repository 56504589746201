<div class="main-area">
  <main>
    <div class="titolo-sezione">
      <i class="las la-donate"></i> Nuova busta paga
    </div>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <form (ngSubmit)="creaBustaPaga()" [formGroup]="formBustaPaga" class="p-mt-4">
      <div class="p-fluid p-formgrid p-grid p-jc-end">
        <div class="p-field p-col-12 p-md-6">
          <label for="dipendenteSelezionato">Dipendente</label>
          <p-dropdown (onChange)="onDipendenteChange()" [ngClass]="{'ng-invalid ng-dirty': !formBustaPaga.get('dipendenteSelezionato').valid && (formBustaPaga.get('dipendenteSelezionato').dirty || formBustaPaga.get('dipendenteSelezionato').touched)}" [options]="dipendenti"
                      formControlName="dipendenteSelezionato"
                      inputId="dipendenteSelezionato" optionLabel="alias" optionValue="id" placeholder="Seleziona"
                      required>
          </p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-6"></div>

        <div class="p-field p-col-12 p-md-3">
          <label for="anno">Anno</label>
          <p-dropdown (onChange)="onChangeAnno()" [disabled]="anniDropdownDisabled"
                      [ngClass]="{'ng-invalid ng-dirty': !formBustaPaga.get('annoSelezionato').valid && (formBustaPaga.get('annoSelezionato').dirty || formBustaPaga.get('annoSelezionato').touched)}" [options]="anni" formControlName="annoSelezionato" inputId="annoSelezionato"
                      optionLabel="nome"
                      optionValue="codice" placeholder="Seleziona" required>
          </p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="mese">Mese</label>
          <p-dropdown [disabled]="mesiDropdownDisabled" [ngClass]="{'ng-invalid ng-dirty': !formBustaPaga.get('meseSelezionato').valid && (formBustaPaga.get('meseSelezionato').dirty || formBustaPaga.get('meseSelezionato').touched)}" [options]="mesi" formControlName="meseSelezionato"
                      inputId="meseSelezionato"
                      optionLabel="nome"
                      optionValue="codice" placeholder="Seleziona" required>
          </p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="importo">Netto in busta</label>


          <p-inputNumber
            [minFractionDigits]="2"
            [ngClass]="{'ng-invalid ng-dirty': !formBustaPaga.get('importo').valid && (formBustaPaga.get('importo').dirty || formBustaPaga.get('importo').touched)}"
            currency="EUR" formControlName="importo"
            locale="it-IT"
            mode="decimal" mode="currency" required></p-inputNumber>


        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="costoAzienda">Costo azienda</label>
          <p-inputNumber
            (keypress)="utils.onlyPositiveNumbers($event)"
            [minFractionDigits]="2"
            [ngClass]="{'ng-invalid ng-dirty': !formBustaPaga.get('costoAzienda').valid && (formBustaPaga.get('costoAzienda').dirty || formBustaPaga.get('costoAzienda').touched)}" currency="EUR"
            formControlName="costoAzienda"
            locale="it-IT"
            mode="currency" mode="decimal" required></p-inputNumber>


        </div>
        <!-- <div class="p-field p-col-12 p-md-12 p-mt-3">
          <div class="p-field-checkbox">
            <p-checkbox formControlName="tredicesima" binary="true" inputId="binary"></p-checkbox>
            <label for="tredicesima">Tredicesima</label>
          </div>
        </div> -->

        <div class="p-field p-col-12 p-md-12">
          <p-fileUpload (onRemove)="onRemoveFile()" (uploadHandler)="uploadHandler($event)" [auto]="true" [files]="uploadedFiles"
                        [ngClass]="{'ng-invalid ng-dirty': uploadedFiles.length == 0}" accept="application/pdf"
                        cancelIcon="pi pi-trash" cancelLabel="Cancella"
                        chooseIcon="pi pi-upload" chooseLabel="Seleziona file"
                        customUpload="true"
                        invalidFileSizeMessageDetail="La dimensione massima consentita è {0}."
                        invalidFileSizeMessageSummary="{0}: Dimensione non valida."
                        invalidFileTypeMessageDetail=""
                        invalidFileTypeMessageSummary="Attenzione! Il formato del documento selezionato non è valido." maxFileSize="1000000" name="uploadedFiles[]"
                        uploadLabel="Carica">
            <ng-template pTemplate="content">
              <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
              </ul>
            </ng-template>
          </p-fileUpload>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <button (click)="resetForm()" class="p-button p-button-secondary" label="Annulla" pButton
                  type="button"></button>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <button [disabled]="!formBustaPaga.valid || filePdf == null" class="p-button p-button-success" label="Salva" pButton
                  type="submit"></button>
        </div>
      </div>
    </form>
    <p-message *ngIf="utils.formValid(formBustaPaga).length > 0 && emptyFields().length == 0" @simpleFadeAnimation
               severity="error" text="Attenzione! Campi obbligatori non compilati."></p-message>
    <div class="p-md-12" style="padding: 0px"></div>
    <p-message *ngIf="emptyFields().length > 0" @simpleFadeAnimation
               severity="error" text="Attenzione! Campo/i obbligatori non popolati"></p-message>
    <p-messages [enableService]="true"></p-messages>
  </main>
</div>
