import {Component, OnInit, ViewChild} from '@angular/core';
import TimeUtils from '../../../utils/time-utils';
import {LibrettoDiarioService} from '../../../services/libretto-diario.service';
import {StorageConstants} from '../../../constants/storage-constants';
import {IMonthDetail} from '../../../models/tirocinio/month-detail.interface';
import {TirocinanteService} from '../../../services/tirocinante.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ILibrettoDiario} from '../../../models/tirocinio/libretto-diario.interface';
import {simpleFadeAnimation} from '../../../animations/simple-fade-animation';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Constants} from '../../../constants/constants';
import {FrontendMessage} from '../../../constants/frontend-message';
import {EStatoTirocinio} from '../../../models/tirocinio/stato-tirocnio.enums';
import {ConfirmationService, MessageService} from 'primeng/api';
import {IWorkingDayTirocinio} from '../../../models/tirocinio/working-day-tirocinio.interface';
import GenericUtils from '../../../utils/generic-utils';
import {ILibrettoManagement} from '../../../models/tirocinio/libretto-management-request.interface';
import {GiorniFestiviService} from '../../../services/giorni-festivi.service';

@Component({
  selector: 'app-libretto-diario',
  templateUrl: './libretto-diario.component.html',
  styleUrls: ['./libretto-diario.component.scss'],
  animations: [simpleFadeAnimation],
  providers: [MessageService, ConfirmationService, GiorniFestiviService]
})
export class LibrettoDiarioComponent implements OnInit {
  meseAnno: string;
  checkHours: string;
  initialMonth: boolean;
  month: number;
  year: number;
  userData = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));

  monthDetail: IMonthDetail;
  librettoDiario: ILibrettoDiario;

  timeUtils: any;
  daysCurrentMonth: any;
  giorniDaDisabilitare: any;
  giorniFestivi: any;
  inizioDafault: any;
  fineDefault: any;

  oraInizio: any;
  oraFine: any;

  defaultDate: string;
  formNuovoGiorno: FormGroup;
  displayModal: boolean;
  displayModalActivity: boolean;
  selectedDay: any;
  mostraRiepilogo: boolean;
  incompatibleHour: boolean;
  utils: any;
  displayModalInfo: boolean;
  descrizione: string;
  startDate: boolean;
  busyHours: boolean;
  hoursExceeded: boolean;
  @ViewChild('initialHour') initialHour;
  @ViewChild('finalHour') finalHour;

  constructor(private librettoDiarioService: LibrettoDiarioService,
              private tirocinanteService: TirocinanteService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private giorniFestiviService: GiorniFestiviService) {
    this.spinner.show();
    this.checkHours = '00:00';
    this.defaultDate = '09:00';
    this.meseAnno = '';
    this.inizioDafault = new Date();
    this.startDate = false;
    this.busyHours = false;
    this.fineDefault = new Date();

    this.inizioDafault.setHours(9);
    this.inizioDafault.setMinutes(0);
    this.inizioDafault.setSeconds(0);
    this.timeUtils = TimeUtils;
    this.month = 0;
    this.year = 0;
    this.initialMonth = false;
    this.giorniDaDisabilitare = null;
    this.giorniFestivi = null;
    this.displayModalInfo = false;
    this.displayModal = false;
    this.displayModalActivity = false;
    this.selectedDay = null;
    this.mostraRiepilogo = false;
    this.incompatibleHour = false;
    this.utils = GenericUtils;
    this.descrizione = '';
    this.hoursExceeded = false;

    this.monthDetail = {
      oreMensili: '',
      workingDayTirocinio: null,
      id: 0,
      anno: 0,
      mese: 0,
      tirocinante_Id: 0
    };

    this.librettoDiario = {
      id: 0,
      dataFineTirocinio: new Date(),
      dataInizioTirocinio: new Date(),
      dayDetailsTirocinio: null,
      totaleOre: '',
      statoTirocinio: {
        id: 0,
        nome: null,
      },
      richiestaSblocco: false,
      note: ''
    };

    this.loadLibretto();
    this.createForm();
  }

  ngOnInit(): void {

  }

  loadLibretto() {
    this.librettoDiarioService.getLibrettoDiario(this.userData.userId).subscribe((libretto) => {
      this.librettoDiario = libretto;
      this.librettoDiario.statoTirocinio = libretto.statoTirocinio;
      this.librettoDiario.dataInizioTirocinio = new Date(libretto.dataInizioTirocinio);
      this.librettoDiario.dataFineTirocinio = new Date(libretto.dataFineTirocinio);
      this.librettoDiarioService.getAllMonthDetail(this.userData.userId).subscribe((resp) => {

        for (let i = 0; i < resp.length; i++) {
          if (resp[i].workingDayTirocinio.length > 0) {
            this.initialMonth = true;
            this.loadMonthDetail(resp[i].mese - 1, resp[i].anno);
            break;
          }
        }
        if (!this.initialMonth) {
          this.loadMonthDetail(this.librettoDiario.dataInizioTirocinio.getMonth(), this.librettoDiario.dataInizioTirocinio.getFullYear());
        }
      });

    }, (error: HttpErrorResponse) => {
    });

  }

  loadMonthDetail(mese: number, anno: number) {
    if (mese === -1) {
      mese = 11;
      anno = anno - 1;
    }
    if (mese === 12) {
      mese = 0;
      anno = anno + 1;
    }
    this.librettoDiarioService.getMonthDetailByYearAndMonth(this.userData.userId, mese + 1, anno).subscribe((monthDetail) => {
      this.monthDetail = monthDetail;
      this.utils.renderRichiediSblocco(this.librettoDiario.richiestaSblocco, this.librettoDiario.statoTirocinio.id);
      this.month = monthDetail.mese - 1;
      this.year = monthDetail.anno;
      this.monthDetail.oreMensili = monthDetail.oreMensili;

      this.meseAnno = moment('' + this.monthDetail.anno + '-' + this.timeUtils.prepareMonthForMoment(this.monthDetail.mese - 1) + '-01').locale('it').format('MMMM YYYY');

      this.daysCurrentMonth = this.timeUtils.getDaysArray_v2(this.monthDetail.anno, this.monthDetail.mese - 1);
      this.timeUtils.mergeWorkedDaysTirocinioWithCalendar(this.monthDetail, this.daysCurrentMonth);
      if (this.daysCurrentMonth[0].giornoSettimana == 0) {
        this.giorniDaDisabilitare = Array.from(Array(this.daysCurrentMonth[0].giornoSettimana + 6), (x, i) => i);
      } else {
        this.giorniDaDisabilitare = Array.from(Array(this.daysCurrentMonth[0].giornoSettimana - 1), (x, i) => i);
      }

      this.giorniFestivi = this.giorniFestiviService.listMonthHolydays(this.daysCurrentMonth);
      // console.log(this.giorniFestivi);
    });
    this.spinner.hide();
  }

  createForm() {
    this.formNuovoGiorno = this.fb.group({
      oraInizio: ['', [Validators.required]],
      oraFine: ['', [Validators.required]],
      descrizione: ['', [Validators.required, Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE_NOTE)]]
    });
  }

  checkHour(event: Event) {

    const inizio = this.formNuovoGiorno.get('oraInizio').value;
    const fine = this.formNuovoGiorno.get('oraFine').value;
    if (inizio != null && fine != null) {
      if (inizio.getHours() > fine.getHours()) {
        this.incompatibleHour = true;
      } else if (inizio.getHours() === fine.getHours() && inizio.getMinutes() >= fine.getMinutes()) {
        this.incompatibleHour = true;
      } else {
        this.incompatibleHour = false;
      }
    }

  }

getData(giorno) {
    this.messageService.clear();
    if (this.librettoDiario.statoTirocinio.id === EStatoTirocinio.SOSPESO) {
      this.messageService.clear();
      this.messageService.add(FrontendMessage.LIBRETTO_LOCKED_BLOCCATO_MSG);
    } else if (this.librettoDiario.statoTirocinio.id === EStatoTirocinio.IN_APPROVAZIONE) {
      this.messageService.clear();
      this.messageService.add(FrontendMessage.LIBRETTO_LOCKED_MSG);
      // sola visualizzazione
      this.selectedDay = giorno;
      if (giorno.activities != undefined && giorno.totaleOreGiornata !== '00:00') {
        this.mostraRiepilogo = true;
        this.displayModalActivity = true;
      } else {
        this.displayModalActivity = false;
        this.mostraRiepilogo = false;
      }
    } else if (this.librettoDiario.statoTirocinio.id === EStatoTirocinio.CHIUSO) {
      this.messageService.clear();
      this.messageService.add(FrontendMessage.LIBRETTO_LOCKED_CHIUSO_MSG);
    } else {
      if (this.giorniFestivi.includes(giorno, 0) || giorno.giornoSettimana == 6 || giorno.giornoSettimana == 0) {
        this.messageService.clear();
        this.messageService.add(FrontendMessage.GIORNO_FESTIVO_MSG);
      }
      else {

        if ((giorno.dataCompleta.getFullYear() <= this.librettoDiario.dataInizioTirocinio.getFullYear() &&
            giorno.dataCompleta.getMonth() <= this.librettoDiario.dataInizioTirocinio.getMonth() &&
            giorno.dataCompleta.getDate() < this.librettoDiario.dataInizioTirocinio.getDate()) ||
          (giorno.dataCompleta.getFullYear() >= this.librettoDiario.dataFineTirocinio.getFullYear() &&
            giorno.dataCompleta.getMonth() >= this.librettoDiario.dataFineTirocinio.getMonth() &&
            giorno.dataCompleta.getDate() > this.librettoDiario.dataFineTirocinio.getDate())) {
          this.messageService.clear();
          this.messageService.add(FrontendMessage.LIBRETTO_LOCKED_SOSPESO_MSG);
        } else {
          this.displayModal = true;
          this.selectedDay = giorno;
        }
        if (giorno.activities != undefined && giorno.totaleOreGiornata !== '00:00') {
          this.mostraRiepilogo = true;

        } else {
          this.mostraRiepilogo = false;
        }
      }

    }
  }

  exportToPDF() {
    this.spinner.show();
    this.librettoDiarioService.exportLibrettoToPDF(this.userData.userId).subscribe((response) => {
      const file = new Blob([response], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.spinner.hide();
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_ERROR,
        detail: errorResponse.error.message
      });
    });
  }

  resetForm() {
    this.formNuovoGiorno.reset();
    this.incompatibleHour = false;
    this.busyHours = false;
    this.startDate = false;
    this.initialHour.overlayVisible = false;
    this.finalHour.overlayVisible = false;

    this.inizioDafault.setHours(9);
    this.inizioDafault.setMinutes(0);
    this.inizioDafault.setSeconds(0);
  }

  finalHourDefault(): any {
    if (this.oraInizio == undefined) {
      return this.inizioDafault;
    } else {
      return this.oraInizio;
    }
  }

  showModalDialog(descrizione) {
    this.descrizione = descrizione;
    this.displayModalInfo = true;
  }

  salvaDettaglioGiorno(selectedDay) {
    const dataCalendario = new Date(Date.UTC(this.selectedDay.dataCompleta.getFullYear(), this.selectedDay.dataCompleta.getMonth(), this.selectedDay.dataCompleta.getDate()));
    this.spinner.show();
    const Inizio = this.formNuovoGiorno.get('oraInizio').value;
    const Fine = this.formNuovoGiorno.get('oraFine').value;


    const startDate = new Date(0, 0, 0, Inizio.getHours(), Inizio.getMinutes(), 0);
    const endDate = new Date(0, 0, 0, Fine.getHours(), Fine.getMinutes(), 0);
    let diff = endDate.getTime() - startDate.getTime();
    const hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);
    const parzialeOre = (hours < 9 ? '0' : '') + hours + ':' + (minutes < 9 ? '0' : '') + minutes;
    const dayDetailsTirocinio = [{
      librettoDiarioId: this.librettoDiario.id,
      oraInizio: (Inizio.getHours() <= 9 ? '0' : '') + Inizio.getHours() + ':' + (Inizio.getMinutes() <= 9 ? '0' : '') + Inizio.getMinutes(),
      oraFine: (Fine.getHours() <= 9 ? '0' : '') + Fine.getHours() + ':' + (Fine.getMinutes() <= 9 ? '0' : '') + Fine.getMinutes(),
      parzialeOre,
      descrizione: this.formNuovoGiorno.get('descrizione').value

    }];

    const workingDayTirocinio: IWorkingDayTirocinio = {
      dataCalendario,
      dayDetailsTirocinio,
      monthDetailId: this.monthDetail.id,
      descrizione: this.formNuovoGiorno.get('descrizione').value

    };

    if (selectedDay.activities != undefined) {
      selectedDay.activities.forEach((detail) => {
        if ((dayDetailsTirocinio[0].oraInizio >= detail.oraInizio && dayDetailsTirocinio[0].oraInizio <= detail.oraFine)
          || (dayDetailsTirocinio[0].oraFine >= detail.oraInizio && dayDetailsTirocinio[0].oraFine <= detail.oraFine)
          || dayDetailsTirocinio[0].oraInizio <= detail.oraInizio && dayDetailsTirocinio[0].oraFine >= detail.oraFine) {
          this.busyHours = true;
        }
      });
    }

    let totalHour = 0;
    let totalMinutes = 0;
    if (selectedDay.totaleOreGiornata != undefined) {
      const parzialSplit = parzialeOre.split(':');
      const totalHourSplit = selectedDay.totaleOreGiornata.split(':');
      totalHour = +parzialSplit[0] + +totalHourSplit[0];
      totalMinutes = +parzialSplit[1] + +totalHourSplit[1];
      if (totalMinutes >= 60) {
        totalMinutes = 0;
        totalHour += 1;
      }
    }
    if (totalHour === 24 && (totalMinutes >= 1 && totalMinutes < 60)) {

      this.hoursExceeded = true;
    } else if (totalHour > 24 && (totalMinutes >= 0 && totalMinutes < 60)) {
      this.hoursExceeded = true;
    } else {
      this.hoursExceeded = false;
      if (!this.busyHours) {
        this.librettoDiarioService.newTirocinioActivity(workingDayTirocinio).subscribe((res) => {
          this.reloadLibretto();
          this.loadMonthDetail(this.month, this.year);
          this.displayModal = false;
          this.incompatibleHour = false;
          this.displayModalInfo = false;
          this.inizioDafault.setHours(9);
          this.inizioDafault.setMinutes(0);
          this.inizioDafault.setSeconds(0);
          this.busyHours = false;
          this.startDate = false;
          this.spinner.hide();
          this.messageService.add(FrontendMessage.INSERIMENTO_ATTIVITA);
          setTimeout(() => {
            this.messageService.clear();
          }, 2000);
        }, error => {
          this.messageService.clear();
          this.spinner.hide();
          this.displayModal = false;
          this.displayModalInfo = false;
          if (error.status == 409) {
            this.messageService.add(FrontendMessage.ORARIO_OCCUPATO);
          } else {
            this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
          }

        });
      }
    }

    this.spinner.hide();
  }

  reloadLibretto() {
    this.librettoDiarioService.getLibrettoDiario(this.userData.userId).subscribe((libretto) => {
      this.librettoDiario = libretto;
      this.librettoDiario.statoTirocinio = libretto.statoTirocinio;
      this.librettoDiario.dataInizioTirocinio = new Date(libretto.dataInizioTirocinio);
      this.librettoDiario.dataFineTirocinio = new Date(libretto.dataFineTirocinio);
    });
  }

  deleteWorkingDay(workingDayId, timesheetId) {
    this.spinner.show();
    this.librettoDiarioService.deleteWorkingDay(workingDayId, timesheetId, this.userData.userId).subscribe(response => {
      this.displayModal = false;
      this.displayModalInfo = false;
      this.reloadLibretto();
      this.loadMonthDetail(this.month, this.year);
      this.spinner.hide();
      this.messageService.add(FrontendMessage.DELETE_ACTIVITY_MSG);
      setTimeout(() => {
        this.messageService.clear();
      }, 2000);
    }, error => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }

  deleteOneDayDetail(event: Event, dayDetailId, monthDetailId, workingDayId) {
    this.messageService.clear();
    this.confirmationService.confirm({
      target: event.target,
      message: 'Attenzione! Sei sicuro di voler eliminare l\'attività selezionata?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: FrontendMessage.CONFERMA,
      rejectLabel: FrontendMessage.ANNULLA,
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.spinner.show();
        if (this.selectedDay.activities.length > 1) {
          this.librettoDiarioService.deleteOneDayDetail(dayDetailId, monthDetailId, this.userData.userId).subscribe(response => {
            this.displayModal = false;
            this.displayModalInfo = false;
            this.reloadLibretto();
            this.loadMonthDetail(this.month, this.year);
            this.spinner.hide();
            this.messageService.add(FrontendMessage.DELETE_ACTIVITY_MSG);
            setTimeout(() => {
              this.messageService.clear();
            }, 2000);
          }, error => {
            this.spinner.hide();
            this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
          });
        } else {
          this.deleteWorkingDay(workingDayId, monthDetailId);
        }
      },
      reject: () => {
      }
    });

  }

  inviaLibretto() {
    this.spinner.show();
    const request: ILibrettoManagement = {
      librettoId: this.librettoDiario.id,
      tirocinanteId: this.userData.userId
    };
    this.librettoDiarioService.inviaLibretto(request).subscribe(response => {
      this.loadLibretto();
      this.loadMonthDetail(this.month, this.year);
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.librettoDiarioService.getLibrettoDiario(this.userData.userId).subscribe((libretto) => {
        });
        this.loadMonthDetail(this.month, this.year);
        this.spinner.hide();
        this.messageService.add(FrontendMessage.LIBRETTO_CONCLUSO);
      } else {
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
      }
    }, error => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.ORE_NON_COMPLETE);
    });
  }

  inviaRichiestaSblocco() {
    this.spinner.show();
    const request: ILibrettoManagement = {
      librettoId: this.librettoDiario.id,
      tirocinanteId: this.userData.userId
    };
    this.librettoDiarioService.richiestaSblocco(request).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.librettoDiarioService.getLibrettoDiario(this.userData.userId).subscribe((libretto) => {
        });
        this.loadMonthDetail(this.month, this.year);
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_OK);
      } else {
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
      }
    }, error => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }

}
