import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {simpleFadeAnimation} from 'src/app/animations/simple-fade-animation';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {STATUS_PROGETTI_MOCK} from 'src/app/mock/status-progetti.mock';
import {ProgettoInterface} from 'src/app/models/progetto.interface';
import {StatusProgetto} from 'src/app/models/status-progetto';
import {ProgettiService} from 'src/app/services/progetti.service';
import GenericUtils from 'src/app/utils/generic-utils';
import TimeUtils from '../../../utils/time-utils';
import {Router} from '@angular/router';
import {RouterPaths} from '../../../constants/RouterPaths';

@Component({
  selector: 'app-nuovo-progetto',
  templateUrl: './nuovo-progetto.component.html',
  styleUrls: ['./nuovo-progetto.component.scss'],
  providers: [MessageService],
  animations: [simpleFadeAnimation]
})
export class NuovoProgettoComponent implements OnInit {

  formNuovoProgetto: FormGroup;
  statusProgetto: StatusProgetto[] = STATUS_PROGETTI_MOCK;
  timeUtils = TimeUtils;
  coloreStatistiche: string;
  utils = GenericUtils;
  dataFineDisabled: boolean;

  constructor(private fb: FormBuilder, private spinner: NgxSpinnerService, private progettiService: ProgettiService, private msgService: MessageService, private router: Router) {
    this.dataFineDisabled = true;
    this.formNuovoProgetto = this.fb.group({
      code: ['', [
        Validators.required,
        Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE),
        Validators.pattern(Constants.REGEX_ALPHANUM_SPECIAL_NO_LEAD_TRAIL_SPACE),
        RxwebValidators.maxLength({value: Constants.INPUT_TEXT_MAX_LENGHT}),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator]
      ],
      nome: ['', [
        Validators.required,
        Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE),
        Validators.pattern(Constants.REGEX_ALPHANUM_SPECIAL_NO_LEAD_TRAIL_SPACE),
        RxwebValidators.maxLength({value: Constants.INPUT_TEXT_MAX_LENGHT}),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator]
      ],
      cliente: ['', [
        Validators.required,
        Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE),
        Validators.pattern(Constants.REGEX_ALPHANUM_SPECIAL_NO_LEAD_TRAIL_SPACE),
        RxwebValidators.maxLength({value: Constants.INPUT_TEXT_MAX_LENGHT}),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator]
      ],
      status: ['', Validators.required],
      dataInizio: ['', [Validators.required, RxwebValidators.minDate({value: Constants.INITIAL_DATE_DATASOUND})]],
      dataFine: ['', [Validators.required, RxwebValidators.minDate({fieldName: 'dataInizio'})]],
      spesePreventivate: [''],
      oreUomoStimate: ['', [RxwebValidators.minNumber({value: 0})]],
      budgetComplessivo: ['', [Validators.required, this.utils.greaterThanOneNumberValidator]],
      coloreStatistiche: ['']
    });
  }

  ngOnInit(): void {
  }

  inviaFormNuovoProgetto() {
    this.msgService.clear();
    this.spinner.show();
    // console.log(this.formNuovoProgetto.get('spesePreventivate').value);
    let spesePreventivate = this.formNuovoProgetto.get('spesePreventivate').value;
    let budgetComplessivo = this.formNuovoProgetto.get('budgetComplessivo').value;
    if (spesePreventivate == '') {
      spesePreventivate = 0;
    }
    if (budgetComplessivo == '') {
      budgetComplessivo = 0;
    }
    if (this.coloreStatistiche == undefined) {
      this.coloreStatistiche = this.utils.random_hex_color_code();
    }


    const dataInizioProgetto = new Date(Date.UTC(this.formNuovoProgetto.get('dataInizio').value.getFullYear(), this.formNuovoProgetto.get('dataInizio').value.getMonth(), this.formNuovoProgetto.get('dataInizio').value.getDate()));
    const dataFineProgetto = new Date(Date.UTC(this.formNuovoProgetto.get('dataFine').value.getFullYear(), this.formNuovoProgetto.get('dataFine').value.getMonth(), this.formNuovoProgetto.get('dataFine').value.getDate()));
    const progetto: ProgettoInterface = {
      code: this.formNuovoProgetto.get('code').value,
      nome: this.formNuovoProgetto.get('nome').value,
      status: this.formNuovoProgetto.get('status').value.name,
      cliente: this.formNuovoProgetto.get('cliente').value,
      dataInizio: dataInizioProgetto,
      dataFine: dataFineProgetto,
      spesePreventivate: parseFloat(spesePreventivate).toFixed(2),
      budgetComplessivo: parseFloat(budgetComplessivo).toFixed(2),
      oreUomoStimate: this.formNuovoProgetto.get('oreUomoStimate').value,
      coloreStatistiche: this.coloreStatistiche
    };
    // console.log(progetto);
    this.progettiService.nuovoProgetto_v2(progetto).subscribe(response => {
      if (Constants.STATUS_CODE_OK == response.statusCode) {
        this.spinner.hide();
        this.formNuovoProgetto.reset();
        this.msgService.add(FrontendMessage.PROGETTO_INSERTED_MSG);
        const currRoute = this.router.url;
        setTimeout(() => {
          this.msgService.clear();
          if (currRoute === this.router.url) { this.router.navigateByUrl(RouterPaths.ADMIN_ROUTE + '/' + RouterPaths.LISTA_PROGETTI); }
        }, 2000);
      }
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.msgService.add({severity: FrontendMessage.SEVERITY_ERROR, summary: '', detail: errorResponse.error.message});
    });
  }

  detectProjectChange() {
    // console.log(this.formNuovoProgetto.get('status').value);
  }

  onDataInizioChange() {
    const dataInizio = this.formNuovoProgetto.get('dataInizio').value;
    this.formNuovoProgetto.controls['dataFine'].reset();
    this.dataFineDisabled = false;

    if (dataInizio != null && dataInizio > Constants.INITIAL_DATE_DATASOUND) {
      this.dataFineDisabled = false;
    } else {
      this.dataFineDisabled = true;
    }
  }

  enableAndResetRelatedDateField(control1, control2) {
    const abilita = this.timeUtils.enableAndResetDateField(control1, control2);
    // console.log(abilita);

    if (abilita) {
      this.dataFineDisabled = false;
    } else {
      this.dataFineDisabled = true;
    }
  }

  resetForm() {
    //// console.log(this.emptyFields());
    this.formNuovoProgetto.reset();

  }

  emptyFields() {
    const fields = [];
    const controls = this.formNuovoProgetto.controls;
    for (const name in controls) {
      if (this.utils.formValid(this.formNuovoProgetto).includes(name) && controls[name].invalid && (controls[name].value == null || controls[name].value == '')) {
        fields.push(name);
      }
    }
    return fields;
  }

}
