import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {STATUS_PROGETTI_MOCK} from 'src/app/mock/status-progetti.mock';
import {ProgettoInterface} from 'src/app/models/progetto.interface';
import {StatusProgetto} from 'src/app/models/status-progetto';
import {ProgettiService} from 'src/app/services/progetti.service';
import TimeUtils from '../../../utils/time-utils';


@Component({
  selector: 'app-progetti',
  templateUrl: './progetti.component.html',
  styleUrls: ['./progetti.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ProgettiComponent implements OnInit {


  progetti: ProgettoInterface[] = [];
  displayModal: boolean;
  formProgetto: FormGroup;
  statusProgetti: StatusProgetto[] = STATUS_PROGETTI_MOCK;
  progettoSelezionato: any;
  switchToEditMode: boolean;
  statusSelezionato: StatusProgetto;
  msgProgettiNonPresenti = FrontendMessage.PROGETTI_NON_PRESENTI;
  timeUtils = TimeUtils;
  coloreStatistiche: string;
  showTable: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private progettiService: ProgettiService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) {
    this.loadProgetti();
  }

  ngOnInit(): void {

  }

  loadProgetti() {
    this.progetti = [];
    this.spinner.show();
    this.progettiService.getProgettiSorted().subscribe(response => {

      this.progetti = response;
      if (response.length > 0) {
        this.showTable = true;
      } else {
        this.showTable = false;
      }
      this.spinner.hide();
    });
  }

  goToSingleProjectView(id: string) {
    this.router.navigateByUrl('/admin/progetto/id/' + id);
  }

  modificaProgetto() {
    this.messageService.clear();
    this.spinner.show();

    const dataInizioProgetto = new Date(Date.UTC(this.formProgetto.get('dataInizio').value.getFullYear(), this.formProgetto.get('dataInizio').value.getMonth(), this.formProgetto.get('dataInizio').value.getDate()));
    const dataFineProgetto = new Date(Date.UTC(this.formProgetto.get('dataFine').value.getFullYear(), this.formProgetto.get('dataFine').value.getMonth(), this.formProgetto.get('dataFine').value.getDate()));
    const progetto: ProgettoInterface = {
      id: this.progettoSelezionato.id,
      code: this.formProgetto.get('code').value,
      nome: this.formProgetto.get('nome').value,
      status: this.formProgetto.get('status').value.name,
      cliente: this.formProgetto.get('cliente').value,
      dataInizio: dataInizioProgetto,
      dataFine: dataFineProgetto,
      spesePreventivate: this.formProgetto.get('spesePreventivate').value,
      budgetComplessivo: this.formProgetto.get('budgetComplessivo').value,
      oreUomoStimate: this.formProgetto.get('oreUomoStimate').value,
      coloreStatistiche: this.coloreStatistiche
    };


  }

  switchToEdit() {
    this.switchToEditMode = true;
    this.formProgetto.enable();


  }

  onHideModal() {
    this.switchToEditMode = false;
    this.formProgetto.disable();
  }

  closeEditMode() {
    this.switchToEditMode = false;
    this.formProgetto.disable();


  }

  detectStatusChange() {
    // console.log(this.formProgetto.get('status').value);
  }

  goToNuovoProgetto() {
    this.router.navigateByUrl('/admin/nuovo-progetto');
  }

  confirmDelete_v2(event: Event, progettoId) {
    this.messageService.clear();
    this.confirmationService.confirm({
      target: event.target,
      message: FrontendMessage.POPUP_CONFERMA_ELIMINAZIONE_PROGETTO,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: FrontendMessage.CONFERMA,
      rejectLabel: FrontendMessage.ANNULLA,
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.spinner.show();
        // console.log(progettoId);
        this.progettiService.eliminaProgetto(progettoId).subscribe(response => {
          if (Constants.STATUS_CODE_OK == response.statusCode) {
            this.loadProgetti();
            this.spinner.hide();
            this.messageService.add({severity: FrontendMessage.SEVERITY_SUCCESS, summary: '', detail: response.message});
          }
        }, (errorResponse: HttpErrorResponse) => {
          this.spinner.hide();
          this.messageService.add({severity: FrontendMessage.SEVERITY_ERROR, summary: '', detail: errorResponse.error.message});
        });
      },
      reject: () => {

      }
    });
  }
}
