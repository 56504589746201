import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'meseBustaPaga'
})
export class MeseBustaPagaPipe implements PipeTransform {
  monthsArray: string[] = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre', 'Tredicesima'];

  transform(num: number) {
    return this.monthsArray[num];
  }
}
