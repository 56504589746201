import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {MultiSelectModule} from 'primeng/multiselect';
import {TableModule} from 'primeng/table';
import {TagModule} from 'primeng/tag';
import {ToastModule} from 'primeng/toast';
import {CommonLayoutModule} from 'src/app/common-layout/common-layout.module';
import {StoricoComponent} from 'src/app/pages/user/storico/storico.component';
import {TimesheetComponent} from 'src/app/pages/user/timesheet/timesheet.component';
import {UserComponent} from 'src/app/pages/user/user.component';
import {VistaTimesheetComponent} from 'src/app/pages/user/vista-timesheet/vista-timesheet.component';
import {CustomPipesModule} from 'src/app/pipes/custom-pipes.module';
import {MansioniService} from 'src/app/services/mansioni.service';
import {TimesheetService} from 'src/app/services/timesheet.service';
import {DipendenteComponent} from './dipendente.component';
import {ChipModule} from 'primeng/chip';
import {BustePagaDipendenteComponent} from 'src/app/pages/user/buste-paga-dipendente/buste-paga-dipendente.component';
import {BustePagaService} from 'src/app/services/buste-paga.service';
import {ModificaPasswordUtenteComponent} from 'src/app/pages/user/modifica-password-utente/modifica-password-utente.component';
import {PasswordModule} from 'primeng/password';
import {MarkAsteriskDirectiveModule} from 'src/app/directives/mark-asterisk.directive';
import {InputMaskModule} from 'primeng/inputmask';
import {ConfirmPopupModule} from 'primeng/confirmpopup';

@NgModule({
  declarations: [
    DipendenteComponent,
    UserComponent,
    StoricoComponent,
    VistaTimesheetComponent,
    TimesheetComponent,
    BustePagaDipendenteComponent,
    ModificaPasswordUtenteComponent
  ],
    imports: [
        CommonModule,
        CommonLayoutModule,
        RouterModule,
        DividerModule,
        FormsModule,
        ReactiveFormsModule,
        DropdownModule,
        InputTextModule,
        TableModule,
        MessagesModule,
        MessageModule,
        TagModule,
        DialogModule,
        ButtonModule,
        CalendarModule,
        MultiSelectModule,
        CustomPipesModule,
        NgxSpinnerModule,
        CheckboxModule,
        ToastModule,
        ChipModule,
        PasswordModule,
        MarkAsteriskDirectiveModule,
        InputMaskModule,
        ConfirmPopupModule
    ],
  providers: [
    MansioniService, TimesheetService, MessageService, BustePagaService
  ]
})
export class DipendenteModule {
}
