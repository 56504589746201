<div class="main-area">
  <main>
    <div class="titolo-sezione">
      <i class="las la-calendar"></i> Storico rapportini
    </div>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <form (ngSubmit)="loadTimesheets()" [formGroup]="formRicerca" class="p-mt-4">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-2 p-md-2">
          <label for="mese">Mese</label>
          <p-dropdown [options]="mesi" formControlName="meseSelezionato" inputId="meseSelezionato"
                      optionLabel="nome" optionValue="codice" placeholder="Seleziona">
          </p-dropdown>
        </div>
        <div class="p-field p-col-2 p-md-2">
          <label for="anno">Anno</label>
          <p-dropdown [options]="anni" formControlName="annoSelezionato" inputId="annoSelezionato"
                      optionLabel="nome" optionValue="codice" placeholder="Seleziona">
          </p-dropdown>
        </div>
        <div class="p-field p-col-2 p-md-2">
          <label for="stato">Stato</label>
          <p-dropdown [options]="statiRapportino" formControlName="statoSelezionato" inputId="statoSelezionato"
                      optionLabel="nome" optionValue="id" placeholder="Seleziona">
          </p-dropdown>
        </div>
        <div class="p-field p-col margine-sup">
          <button class="p-button p-button-primary" label="Cerca" pButton type="submit"></button>
        </div>
        <div class="p-field p-col margine-sup">
          <button (click)="resetSearch()" class="p-button p-button-secondary" label="Annulla" pButton
                  type="button"></button>
        </div>
      </div>
    </form>
    <p-messages [enableService]="true"></p-messages>

    <p-table *ngIf="showTable && timesheetsFiltrati.length > 0"
             [paginator]="true"
             [rowHover]="true"
             [rowsPerPageOptions]="[10,20,30]"
             [rows]="10" [showCurrentPageReport]="true" [totalRecords]="timesheetsFiltrati.length"
             [value]="timesheetsFiltrati"
             currentPageReportTemplate="Risultati da {first} a {last}. Totale {totalRecords} rapportino/i"
             sortField="status"
             sortOrder="-1"
             styleClass="p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th class="p-text-center" scope="col">Mese</th>
          <th class="p-text-center" scope="col">Anno</th>
          <th class="p-text-center" scope="col">Totale ore</th>
          <th class="p-text-center" scope="col">Stato</th>
          <th class="p-text-center" scope="col">Azioni</th>
        </tr>
      </ng-template>
      <ng-template let-timesheet pTemplate="body">
        <tr style="height:34px">
          <td class="p-text-center">{{timesheet.mese - 1 | getNomeMese}}</td>
          <td class="p-text-center">{{timesheet.anno}}</td>
          <td class="p-text-center">{{timesheet.totaleOre}}</td>
          <td class="p-text-center">
            <p-tag [severity]="timesheet.statoRapportino.id | statoRapportino" [style]="{'width': '100%'}"
                   [value]="timesheet.statoRapportino.nome"></p-tag>
          </td>
          <td class="p-text-center">
            <button (click)="goToTimesheetDetail(timesheet)" class="p-button p-button-text" icon="pi pi-eye" pButton
                    type="button"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>


    <div *ngIf="(!showTable || timesheetsFiltrati.length == 0) && searchDone" class="p-grid">
      <div class="p-col-12">
        <div class="msgNoData">
          <span class="las la-5x la-info-circle"></span>
          <h3>Nessun rapportino trovato corrispondente ai criteri di ricerca inseriti.</h3>
        </div>
      </div>

    </div>
  </main>
</div>
