import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as codiceFiscaleUtils from '@marketto/codice-fiscale-utils';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {fadeInAnimation} from 'src/app/animations/fade-in-animation';
import {simpleFadeAnimation} from 'src/app/animations/simple-fade-animation';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {STATUS_DIPENDENTI_MOCK} from 'src/app/mock/status-dipendenti.mock';
import {MansioneInterface} from 'src/app/models/mansione.interface';
import {Progetto} from 'src/app/models/progetto';
import {Role} from 'src/app/models/role';
import {StatusDipendente} from 'src/app/models/status-dipendente';
import {TipoContrattoInterface} from 'src/app/models/tipo-contratto.interface';
import {UserInterface} from 'src/app/models/user-interface';
import {MansioniService} from 'src/app/services/mansioni.service';
import {ProgettiService} from 'src/app/services/progetti.service';
import {UserService} from 'src/app/services/user.service';
import GenericUtils from 'src/app/utils/generic-utils';
import TimeUtils from '../../../utils/time-utils';
import {TirocinanteService} from '../../../services/tirocinante.service';
import {PHONE_PREFIX_MOCK} from '../../../mock/phone-prefix.mock';
import {RouterPaths} from '../../../constants/RouterPaths';
import {Router} from '@angular/router';

@Component({
  selector: 'app-nuovo-dipendente',
  templateUrl: './nuovo-dipendente.component.html',
  styleUrls: ['./nuovo-dipendente.component.scss'],
  providers: [MessageService],
  animations: [simpleFadeAnimation, fadeInAnimation]
})
export class NuovoDipendenteComponent implements OnInit {

  formNuovoDipendente: FormGroup;
  statusDip: StatusDipendente[] = STATUS_DIPENDENTI_MOCK;
  userRoles: Role[] = [];
  progetti: Progetto[] = [];
  startEmail: boolean;
  existEmail: boolean;

  progettiSelezionati: any;
  cellulare: string;
  mansioni: MansioneInterface[] = [];
  tipiContratto: TipoContrattoInterface[] = [];
  pcAziendaleCheckBox: boolean;
  timeUtils = TimeUtils;
  utils = GenericUtils;
  dataFineDisabled: boolean;
  cfUtil = codiceFiscaleUtils;
  cfValid: boolean;
  startCase: boolean;
  initialDateValue = Constants.INITIAL_DATE_DATASOUND;
  country: any[];

  constructor(private fb: FormBuilder, private tirocinantiService: TirocinanteService, private progettiService: ProgettiService, private messageService: MessageService, private userService: UserService, private spinner: NgxSpinnerService, private mansioniService: MansioniService, private router: Router) {
    this.messageService.clear();
    this.dataFineDisabled = true;
    this.startEmail = false;
    this.existEmail = false;
    this.mansioniService.getMansioni().subscribe(mansioni => {
      this.mansioni = mansioni;
    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

    this.mansioniService.getTipiContratto().subscribe(tipiContratto => {
      this.tipiContratto = tipiContratto;
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
    this.country = PHONE_PREFIX_MOCK;
    this.formNuovoDipendente = this.fb.group({
      nome: ['', [
        Validators.required,
        Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]
      ],
      cognome: ['', [
        Validators.required,
        Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]],
      codiceFiscale: ['', [
        Validators.required,
        Validators.maxLength(Constants.CODICE_FISCALE_MAX_LENGTH),
        Validators.pattern(Constants.CODICE_FISCALE_REGEX),
        this.utils.codiceFiscaleValidator]
      ],
      email: ['', [Validators.required, Validators.pattern(Constants.EMAIL_REGEX)]],
      cellulare: ['', Validators.required],
      prefix: ['+39',],
      statusSelezionato: ['', Validators.required],
      mansione: ['', Validators.required],
      dataDiNascita: ['', [Validators.required, this.timeUtils.dataDiNascitaValidator]],
      progetti: [''],
      roles: ['', Validators.required],
      dataInizioRapporto: ['', [Validators.required, this.timeUtils.dateValidator]],
      dataFineRapporto: [''],
      tipoContratto: ['', Validators.required],
      oreSettimanali: ['', [Validators.required, RxwebValidators.minNumber({value: 1}), RxwebValidators.maxNumber({value: Constants.MAX_ORE_SETTIMANALI})]],
      chiaviUfficio: [''],
      telefonoAziendale: [''],
      pcAziendale: [''],
      serialePc: [''],
      modelloPc: [''],
    });
    this.progettiService.getProgetti().subscribe(progetti => {
      this.progetti = progetti;
    }, error => {
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

    this.userService.getUserRoles().subscribe(ruoliUtente => {
      this.userRoles = ruoliUtente;
    }, error => {
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

  }

  ngOnInit(): void {
  }

  checkEmail(email: string) {
    this.startEmail = true;
    this.tirocinantiService.existEmailUser(email).subscribe(response => {

      this.existEmail = response;
    });
  }

  onInputCodiceFiscale(cf: string) {
    this.startCase = true;
    const {Validator} = this.cfUtil;
    if (Validator.codiceFiscale(cf).valid) {
      this.cfValid = true;
    } else {
      this.cfValid = false;
    }
  }


  detectComodatoChange(event) {

    if (event.checked) {
      this.pcAziendaleCheckBox = true;
      this.formNuovoDipendente.get('serialePc').setValidators([Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE), this.utils.whitespaceValidator, this.utils.endWithSpaceValidator]);
      this.formNuovoDipendente.get('modelloPc').setValidators([Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE), this.utils.whitespaceValidator, this.utils.endWithSpaceValidator]);
    } else {
      this.formNuovoDipendente.get('serialePc').reset();
      this.formNuovoDipendente.get('serialePc').clearValidators();
      this.formNuovoDipendente.get('serialePc').updateValueAndValidity();
      this.formNuovoDipendente.get('modelloPc').reset();
      this.formNuovoDipendente.get('modelloPc').clearValidators();
      this.formNuovoDipendente.get('modelloPc').updateValueAndValidity();
      this.pcAziendaleCheckBox = false;
    }
  }


  onSelectDataInizioRapporto() {
    const dataInizioRapporto = this.formNuovoDipendente.get('dataInizioRapporto').value;
    const dataFineRapporto = this.formNuovoDipendente.get('dataFineRapporto').value;
    // console.log(dataInizioRapporto);
    // console.log(dataFineRapporto);
    if (dataInizioRapporto < dataFineRapporto) {
      // console.log('dataInizioRapporto < dataFineRapporto');

    } else if (dataFineRapporto < dataInizioRapporto && dataFineRapporto != '') {
      // console.log('dataFineRapporto < dataInizioRapporto');
    }

    // console.log('dataFine > dataInizio = ' + this.timeUtils.dataFineMaggioreDataInizio(dataFineRapporto, dataInizioRapporto));

  }

  inviaFormNuovoDipendente() {
    this.spinner.show();
    const serialePc = this.formNuovoDipendente.get('pcAziendale').value == true ? this.formNuovoDipendente.value.serialePc : null;
    const modelloPc = this.formNuovoDipendente.get('pcAziendale').value == true ? this.formNuovoDipendente.value.modelloPc : null;
    let comodatoPc = null;
    let phoneCode = '';
    if (this.formNuovoDipendente.get('prefix').touched && this.formNuovoDipendente.value.prefix.phonecode != null) {
      phoneCode = this.formNuovoDipendente.value.prefix.phonecode;
    } else {
      phoneCode = '+39';
    }
    if (null != serialePc && null != modelloPc) {
      comodatoPc = {modello: modelloPc, seriale: serialePc};
    }
    const dataDiNascita = new Date(Date.UTC(this.formNuovoDipendente.get('dataDiNascita').value.getFullYear(), this.formNuovoDipendente.get('dataDiNascita').value.getMonth(), this.formNuovoDipendente.get('dataDiNascita').value.getDate()));
    const userToStore: UserInterface = {
      username: this.generateUsernameFromEmail(this.formNuovoDipendente.value.email),
      nome: this.formNuovoDipendente.value.nome.trim(),
      cognome: this.formNuovoDipendente.value.cognome.trim(),
      email: this.formNuovoDipendente.value.email,
      cellulare: phoneCode + ' ' + this.formNuovoDipendente.value.cellulare,
      dataDiNascita: dataDiNascita,
      status: this.formNuovoDipendente.value.statusSelezionato.name,
      codiceFiscale: this.formNuovoDipendente.value.codiceFiscale,
      tipoContratto: this.formNuovoDipendente.value.tipoContratto,
      dataInizioRapporto: this.formNuovoDipendente.value.dataInizioRapporto,
      dataFineRapporto: this.formNuovoDipendente.value.dataFineRapporto,
      oreSettimanali: this.formNuovoDipendente.value.oreSettimanali,
      chiaviUfficio: this.formNuovoDipendente.value.chiaviUfficio,
      telefonoAziendale: this.formNuovoDipendente.value.telefonoAziendale,
      comodatoPc: comodatoPc,
      mansione: this.formNuovoDipendente.value.mansione,
      roles: [this.formNuovoDipendente.value.roles],
      progetti: []
    };


    this.userService.registrazioneUtente(userToStore).subscribe(response => {
      this.spinner.hide();
      this.formNuovoDipendente.reset();
      this.startEmail = false;
      this.existEmail = false;
      this.messageService.add(FrontendMessage.DIPENDENTE_INSERTED_MSG);
      const currRoute = this.router.url;
      setTimeout(() => {
        this.messageService.clear();
        if (currRoute === this.router.url) { this.router.navigateByUrl(RouterPaths.ADMIN_ROUTE + '/' + RouterPaths.LISTA_DIPENDENTI); }
      }, 2000);
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: '',
        detail: errorResponse.error.message
      });
      setTimeout(() => {
        this.messageService.clear();
      }, 2500);
    });
  }

  resetForm() {
    this.cfValid = false;
    this.startCase = false;
    this.formNuovoDipendente.reset();
    this.startEmail = false;
    this.existEmail = false;
  }

  onDataInizioChange() {
    const dataInizio = this.formNuovoDipendente.get('dataInizioRapporto').value;
    this.formNuovoDipendente.controls['dataFineRapporto'].reset();
    this.dataFineDisabled = false;

    if (dataInizio != null && dataInizio > Constants.INITIAL_DATE_DATASOUND) {
      this.dataFineDisabled = false;
    } else {
      this.dataFineDisabled = true;
    }
  }

  enableAndResetRelatedDateField(control1, control2) {
    const abilita = this.timeUtils.enableAndResetDateField(control1, control2);

    if (abilita) {
      this.dataFineDisabled = false;
    } else {
      this.dataFineDisabled = true;
    }
  }

  getYearRange(): string {
    const currentYear = new Date();
    const finalYear = currentYear.getFullYear() - 18;
    const startingYear = currentYear.getFullYear() - 60;
    return startingYear + ':' + finalYear;
  }

  generateUsername(email: string) {
    return email.split('@')[0];
  }

  generateUsernameV2(nome: string, cognome: string) {
    const nomeInput = nome.toLowerCase().trim().split(' ').join('');
    const cognomeInput = cognome.toLowerCase().trim().split(' ').join('');
    return nomeInput + '.' + cognomeInput;
  }

  generateUsernameFromEmail(email: string) {
    return email.toLowerCase().trim().split(' ').join('');
  }

  emptyFields() {
    const fields = [];
    const controls = this.formNuovoDipendente.controls;
    for (const name in controls) {
      if (this.utils.formValid(this.formNuovoDipendente).includes(name) && controls[name].invalid && (controls[name].value == null || controls[name].value == '')) {
        fields.push(name);
      }
    }
    return fields;
  }
}
