import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {ChangePasswordRequest} from 'src/app/models/password-recovery/change-password-request';
import {InitPasswordRecoveryRequest} from 'src/app/models/password-recovery/init-password-recovery-request';
import {AuthenticationService} from 'src/app/services/authentication/authentication.service';
import {StaticContentService} from 'src/app/services/static-content.service';


@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [MessageService]
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  password1: string;
  password2: string;

  resetTokenValid: boolean;
  showBackButton: boolean;

  passwordMatch: string = FrontendMessage.RESET_PWD_MATCH;
  username: string;
  tokenId: string;
  procedureCompleted: boolean;
  fullLogoUrl: string;


  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService,
    private staticContentService: StaticContentService) {
    this.spinner.show();
    this.password1 = '';
    this.password2 = '';
    this.staticContentService.getAsset(Constants.PPM_LOGO_MINI).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.fullLogoUrl = response.fileUrl;
      } else {
        this.fullLogoUrl = Constants.PPM_LOGO_MINI_OFFLINE;
      }
    }, (errorResponse: HttpErrorResponse) => {
      this.fullLogoUrl = Constants.PPM_LOGO_MINI_OFFLINE;
    });

    this.showBackButton = false;
    this.activatedRoute.queryParams.subscribe(params => {
      const pwdRecoveryInitRequest = new InitPasswordRecoveryRequest(params.username, params.tokenId);
      this.username = params.username;
      this.tokenId = params.tokenId;
      this.authService.passwordRecoveryInit(pwdRecoveryInitRequest).subscribe(response => {

        spinner.hide();
        this.resetTokenValid = true;


      }, error => {
        setTimeout(() => {
          this.spinner.hide();
          this.resetTokenValid = false;
          this.messageService.clear();
          this.messageService.add(FrontendMessage.RESET_PWD_INVALID_LINK_MSG);
          this.showBackButton = true;
        }, 2000);
      });
    });

    this.resetPasswordForm = this.fb.group({
      password1: ['', Validators.required],
      password2: ['', Validators.required]
    });
  }

  ngOnInit() {

  }

  doPasswordReset() {
    this.spinner.show();
    const changePasswordRequest = new ChangePasswordRequest(this.username, this.tokenId, this.resetPasswordForm.value.password1, this.resetPasswordForm.value.password2);

    this.authService.changePassword(changePasswordRequest).subscribe(response => {
      this.spinner.hide();
      this.procedureCompleted = true;
      this.messageService.clear();
      this.messageService.add(FrontendMessage.RECOVER_PASSWORD_OK);
    }, error => {
      this.spinner.hide();
      this.messageService.clear();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }

  passwordMatches() {
    this.resetPasswordForm.valueChanges.subscribe(formValues => {
    });

  }
}
