import {LoginResponseInterface} from './login-response.interface';

export class LoginResponse implements LoginResponseInterface {
  userId: number;
  role: string;
  token: string;
  redirectTo: string;
  expiringOn: Date;
  nome: string;
  cognome: string;

  constructor(userId: number, role: string, token: string, redirectTo: string, expiringOn: Date, nome: string, cognome: string) {
    this.userId = userId;
    this.role = role;
    this.token = token;
    this.redirectTo = redirectTo;
    this.expiringOn = expiringOn;
    this.nome = nome;
    this.cognome = cognome;
  }

  public getUserId() {
    return this.userId;
  }

  public getRole() {
    return this.role;
  }

  public getToken() {
    return this.token;
  }

  public getRedirectTo() {
    return this.redirectTo;
  }

  public getExpiringOn() {
    return this.expiringOn;
  }

  public getNome() {
    return this.nome;
  }

  public getCognome() {
    return this.cognome;
  }
}
