import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../constants/endpoints';
import {RiepilogoOreProgetto} from '../models/response/riepilogo-ore-progetto';
import {RiepilogoConsuntivo} from '../models/riepilogo-consuntivo';

@Injectable()
export class ConsuntivazioneService {

  constructor(private http: HttpClient) {
  }

  getRiepilogoConsuntivoByProgettoId(progettoId: number): Observable<RiepilogoConsuntivo> {
    return this.http.get<RiepilogoConsuntivo>(Endpoints.FIND_CONSUNTIVAZIONE_BY_PROGETTOID + progettoId);
  }

  /** Il conteggio si basa sulle ore consuntivate */
  riepilogoOreSitazioneEconomicaProgetto(progettoId: string): Observable<RiepilogoOreProgetto[]> {
    return this.http.get<RiepilogoOreProgetto[]>(Endpoints.CONSUNTIVAZIONE_DETAIL_OVERVIEW_BY_PROGETTOID + progettoId);
  }
}
