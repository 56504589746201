import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {StorageConstants} from 'src/app/constants/storage-constants';
import {UserRoleEnum} from 'src/app/models/enums/user-roles';
import {LoginResponseInterface} from 'src/app/models/login/login-response.interface';
import {AuthenticationService} from 'src/app/services/authentication/authentication.service';
import {TimesheetService} from 'src/app/services/timesheet.service';
import {LibrettoDiarioService} from '../../services/libretto-diario.service';

@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.scss'],
  providers: [TimesheetService]
})

export class HeaderAdminComponent implements OnInit, OnDestroy {
  currentWindowWidth: any;
  datiUtente;
  userSub: Subscription;
  ruoloUtente: string;
  nome: string;
  cognome: string;
  totaleNotificheSblocco: number;
  interval: any;
  totaleNotificheSbloccoTirocinio: number;

  constructor(private router: Router, private authService: AuthenticationService, private timesheetService: TimesheetService, private librettoDiarioService: LibrettoDiarioService) {
    const userData: LoginResponseInterface = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));

    this.nome = userData.nome;
    this.cognome = userData.cognome;
    this.ruoloUtente = userData.role;
    this.totaleNotificheSblocco = 0;
    this.totaleNotificheSbloccoTirocinio = 0;

    this.getNotificheSblocco();
    this.getNotificheSbloccoLibretto();
  }

  getNotificheSblocco() {
    this.timesheetService.contaNotificheSblocco().subscribe(response => {
      this.totaleNotificheSblocco = response.notificheSblocco;
    }, error => {
      this.totaleNotificheSblocco = 0;
    });
  }

  getNotificheSbloccoLibretto() {
    this.librettoDiarioService.contaNotifiche().subscribe(response => {
      this.totaleNotificheSbloccoTirocinio = response.notificheSblocco;
    }, error => {
      this.totaleNotificheSbloccoTirocinio = 0;
    });
  }

  ngOnInit(): void {
    this.currentWindowWidth = window.innerWidth;

    this.interval = setInterval(() => {
      this.getNotificheSblocco();
      this.getNotificheSbloccoLibretto();
    }, 10000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }


  @HostListener('window:resize')
  onResize() {
    this.currentWindowWidth = window.innerWidth;
  }

  logout() {
    this.authService.logout();
  }

  goToNotifiche() {
    this.router.navigateByUrl('/admin/notifiche');
  }

  getRoleName(): string {
    return (UserRoleEnum.ADMIN == this.ruoloUtente) ? FrontendMessage.AMMINISTRATORE : FrontendMessage.DIPENDENTE;
  }

  getServerityByRole(): string {
    return (UserRoleEnum.ADMIN == this.ruoloUtente) ? Constants.SEVERITY_INFO : Constants.SEVERITY_WARNING;
  }
}
