import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import HttpStatusCode from 'src/app/models/enums/http-status-code';
import {LoginRequest} from 'src/app/models/login/login-request';
import {LoginResponseInterface} from 'src/app/models/login/login-response.interface';
import {PasswordRecoveryRequest} from 'src/app/models/password-recovery/password-recovery-request';
import {AuthenticationService} from 'src/app/services/authentication/authentication.service';
import {StaticContentService} from 'src/app/services/static-content.service';
import GenericUtils from 'src/app/utils/generic-utils';
import {UserService} from '../../services/user.service';
import {StatusDipendente} from '../../models/status-dipendente';
import {STATUS_DIPENDENTI_MOCK} from '../../mock/status-dipendenti.mock';
import {UserRoleEnum} from '../../models/enums/user-roles';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  savePassword: boolean;
  loginForm: FormGroup;
  resetPasswordForm: FormGroup;
  statusDip: StatusDipendente[] = STATUS_DIPENDENTI_MOCK;

  loginRequest: LoginRequest;
  passwordRecoveryRequest: PasswordRecoveryRequest;

  showResetPassword: boolean;
  showChangePassword: boolean;

  resetPasswordFormSent: boolean;
  logoUrl: string;
  utils = GenericUtils;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private userService: UserService,
    private staticContentService: StaticContentService) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required]
    });
    this.resetPasswordForm = this.fb.group({
      email: ['', [Validators.required, RxwebValidators.email()]]
    });

    this.staticContentService.getAsset(Constants.PPM_LOGO_MINI).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.logoUrl = response.fileUrl;
      } else {
        this.logoUrl = Constants.PPM_LOGO_MINI_OFFLINE;
      }
    }, (errorResponse: HttpErrorResponse) => {
      this.logoUrl = Constants.PPM_LOGO_MINI_OFFLINE;
    });

    this.authService.autoLogin();
  }

  ngOnInit(): void {

  }

  accedi() {
    this.messageService.clear();
    this.spinner.show();

    this.loginRequest = new LoginRequest(this.loginForm.value.username, this.loginForm.value.password);
    this.authService.generateToken(this.loginRequest)
      .subscribe((loginResponse: LoginResponseInterface) => {
          // console.log(loginResponse);
          if (loginResponse.role !== UserRoleEnum.TIROCINANTE) {
            this.userService.getOneUser(loginResponse.userId.toString()).subscribe((res) => {

              if (res.status === Constants.DIPENDENTE) {
                setTimeout(() => {
                  this.spinner.hide();
                  this.router.navigateByUrl('/' + loginResponse.redirectTo);
                  // console.log(loginResponse.redirectTo);
                }, 2000);
              } else {
                setTimeout(() => {
                  this.authService.logout();
                  this.spinner.hide();
                  this.messageService.add(FrontendMessage.ACCESS_NOT_ALLOWED_MSG);
                }, 2000);
              }
            });
          } else {
            setTimeout(() => {
              this.spinner.hide();
              this.router.navigateByUrl('/' + loginResponse.redirectTo);
              // console.log(loginResponse.redirectTo);
            }, 2000);
          }

        },

        (httpErrorResponse: HttpErrorResponse) => {
          // console.log(httpErrorResponse);
          if (httpErrorResponse.status == HttpStatusCode.UNAUTHORIZED) {
            // console.log(HttpStatusCode.UNAUTHORIZED);
            setTimeout(() => {
              this.spinner.hide();
              this.messageService.add(FrontendMessage.INVALID_CREDENTIALS_MSG);
            }, 2000);
          } else {
            setTimeout(() => {
              this.spinner.hide();
              this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
            }, 2000);
          }
        });
  }

  goToResetPassword() {
    this.resetPasswordForm.reset();
    this.showResetPassword = true;
    this.resetPasswordForm.enable();
  }

  sendMailResetPassword() {
    this.messageService.clear();
    this.spinner.show();
    this.passwordRecoveryRequest = new PasswordRecoveryRequest(this.resetPasswordForm.value.email);
    this.authService.sendMailResetLink(this.passwordRecoveryRequest).subscribe(response => {
      this.spinner.hide();
      this.messageService.add({severity: FrontendMessage.SEVERITY_SUCCESS, summary: '', detail: response.message});

      this.resetPasswordForm.disable();
      this.resetPasswordFormSent = true;
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: '',
        detail: errorResponse.error.message
      });
      this.resetPasswordForm.disable();
    });
  }


  backToLogin() {
    this.showResetPassword = false;
    this.loginForm.reset();
    this.resetPasswordFormSent = false;
  }

}
