import {Component} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {TimesheetManagementInterface} from 'src/app/models/timesheet/timesheet-management-request';
import {TimesheetInterface} from 'src/app/models/timesheet/timesheet.interface';
import {UserInterface} from 'src/app/models/user-interface';
import {TimesheetService} from 'src/app/services/timesheet.service';
import {UserService} from 'src/app/services/user.service';
import {LibrettoDiarioService} from '../../../services/libretto-diario.service';
import {ILibrettoDiario} from '../../../models/tirocinio/libretto-diario.interface';
import {ITirocinante} from '../../../models/tirocinio/tirocinante-interface';
import {TirocinanteService} from '../../../services/tirocinante.service';
import {ILibrettoManagement} from '../../../models/tirocinio/libretto-management-request.interface';
import {RouterPaths} from '../../../constants/RouterPaths';
import {NavigationExtras, Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {simpleFadeAnimation} from '../../../animations/simple-fade-animation';
import {fadeInAnimation} from '../../../animations/fade-in-animation';
import {StorageConstants} from '../../../constants/storage-constants';

@Component({
  selector: 'app-notifiche',
  templateUrl: './notifiche.component.html',
  styleUrls: ['./notifiche.component.scss'],
  providers: [TimesheetService, MessageService],
  animations: [simpleFadeAnimation, fadeInAnimation]
})
export class NotificheComponent {

  lockedTimesheets: TimesheetInterface[] = [];
  inApprovazioneTimesheets: TimesheetInterface[] = [];
  librettoDiario: ILibrettoDiario[];
  showTable: Promise<boolean>;
  dipendenti: UserInterface[] = [];
  tirocinanti: ITirocinante[] = [];

  displayModal: boolean;
  formNote: FormGroup;
  librettoSelected: any;
  userData = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));


  constructor(
    private timesheetService: TimesheetService,
    private router: Router,
    private librettoDiarioService: LibrettoDiarioService,
    private tirocinanteService: TirocinanteService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private messageService: MessageService,
  ) {


    this.tirocinanti = [];
    this.librettoDiario = [];

    this.librettoSelected = null;

    this.loadDipendenti();
    this.loadTirocinanti();


  }


  loadDipendenti() {
    this.userService.getAllUsersSorted().subscribe(dipendenti => {

      for (let dipendente of dipendenti) {
        this.dipendenti.push({
          id: dipendente.id,
          username: dipendente.username,
          nome: dipendente.nome,
          cognome: dipendente.cognome,
          alias: dipendente.cognome + ' ' + dipendente.nome + ' - ' + dipendente.codiceFiscale,
          email: dipendente.email,
          cellulare: dipendente.cellulare,
          dataDiNascita: dipendente.dataDiNascita,
          status: dipendente.status,
          codiceFiscale: dipendente.codiceFiscale,
          tipoContratto: dipendente.tipoContratto,
          dataInizioRapporto: dipendente.dataInizioRapporto,
          dataFineRapporto: dipendente.dataFineRapporto,
          oreSettimanali: dipendente.oreSettimanali,
          chiaviUfficio: dipendente.chiaviUfficio,
          telefonoAziendale: dipendente.telefonoAziendale,
          comodatoPc: dipendente.comodatoPc,
          mansione: dipendente.mansione,
          roles: dipendente.roles,
          progetti: dipendente.progetti
        });
      }

      this.loadTimesheets();
      this.spinner.hide();
    });
  }

  loadTirocinanti() {
    this.spinner.show();
    this.tirocinanteService.getAllTirocinantiSorted().subscribe(tirocinanti => {

      for (let tirocinante of tirocinanti) {
        this.tirocinanti.push({
          id: tirocinante.id,
          username: tirocinante.username,
          nome: tirocinante.nome,
          cognome: tirocinante.cognome,
          alias: tirocinante.cognome + ' ' + tirocinante.nome + ' - ' + tirocinante.codiceFiscale,
          email: tirocinante.email,
          cellulare: tirocinante.cellulare,
          dataDiNascita: tirocinante.dataDiNascita,
          stato: tirocinante.stato,
          codiceFiscale: tirocinante.codiceFiscale,
          luogoDiNascita: tirocinante.luogoDiNascita,
          matricola: tirocinante.matricola,
          annoImmatricolazione: tirocinante.annoImmatricolazione,
          residenza: tirocinante.residenza,
          cap: tirocinante.cap,
          provincia: tirocinante.provincia,
          citta: tirocinante.citta,
          progettoFormativo: tirocinante.progettoFormativo,
          librettoDiario: tirocinante.librettoDiario
        });

      }
      this.spinner.hide();
      this.loadLibretti();
    });
  }

  loadTimesheets() {
    this.timesheetService.getAllTimesheetsByRichiestaSblocco().subscribe(timesheets => {

      this.lockedTimesheets = timesheets;
      this.spinner.hide();
      this.showTable = Promise.resolve(true);
    }, error => {
      // console.log(error);
      this.spinner.hide();
    });

    this.timesheetService.getAllTimesheetsByInApprovazione().subscribe(timesheets => {

      this.inApprovazioneTimesheets = timesheets;
      this.spinner.hide();
      this.showTable = Promise.resolve(true);
    }, error => {
      // console.log(error);
      this.spinner.hide();
    });
  }

  loadLibretti() {
    this.messageService.clear();
    this.spinner.show();
    this.librettoDiarioService.getAllLibrettiToApprove().subscribe(librettoDiario => {

      this.librettoDiario = librettoDiario;
      this.spinner.hide();
      this.showTable = Promise.resolve(true);
    }, error => {
      // console.log(error);
      this.spinner.hide();
    });
  }

  getCognomeDipendente(userId: number): string {
    let dipendente: UserInterface = this.dipendenti.find((dipendente) => {
      return dipendente.id == userId;
    });
    return dipendente.cognome;
  }

  sbloccaTimesheet(timesheet) {
    this.spinner.show();
    const request: TimesheetManagementInterface = {
      timesheetId: timesheet.id,
      userId: timesheet.user_id
    };
    this.timesheetService.sbloccaRapportino(request).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.loadTimesheets();
        this.spinner.hide();
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
        this.messageService.add(FrontendMessage.RAPPORTINO_UNLOCKED_MSG);
      } else {
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
      }
    }, error => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }


  goToTimesheetDetail(timesheet) {
    const notification = true;
    this.router.navigateByUrl('/admin/vista-rapportino/id/' + timesheet.id + '/' + notification);
  }

  sbloccaLibretto(libretto) {

    const tirocinante: ITirocinante = this.tirocinanti.find((tirocinante) => {
      return tirocinante.librettoDiario.id === libretto.id;
    });
    this.spinner.show();
    const request: ILibrettoManagement = {
      librettoId: libretto.id,
      tirocinanteId: tirocinante.id
    };
    this.librettoDiarioService.sbloccaLibretto(request).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.loadLibretti();
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_OK);
      } else {
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
      }
    }, error => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }

  golibrettoDetail(idLibretto) {
    const tirocinante: ITirocinante = this.tirocinanti.find((tirocinante) => {
      return tirocinante.librettoDiario.id === idLibretto;
    });
    const id = tirocinante.id;
    const notification = true;
    this.router.navigateByUrl(RouterPaths.ADMIN_ROUTE + '/' + RouterPaths.VISTA_LIBRETTO_DIARIO + '/id/' + id + '/' + notification);
  }


}
