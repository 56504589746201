import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from 'src/app/constants/endpoints';
import {StorageConstants} from 'src/app/constants/storage-constants';
import {LoginResponseInterface} from 'src/app/models/login/login-response.interface';
import {ServiceResponse} from 'src/app/models/response/service-response';
import {User} from 'src/app/models/user';
import {Role} from '../models/role';
import {UserInterface} from '../models/user-interface';
import {Utente} from '../models/utente';

@Injectable()
export class UserService {

  /* N.B. I metodi v2 sono allineati alla documentazione */

  constructor(private http: HttpClient) {
  }

  getUserProfile(): Observable<User> {
    const userData: LoginResponseInterface = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
    return this.http.get<User>(Endpoints.GET_USER_PROFILE + userData.userId);
  }

  getUserProfile_v2(): Observable<Utente> {
    const userData: LoginResponseInterface = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
    return this.http.get<Utente>(Endpoints.GET_USER_PROFILE + userData.userId);
  }

  getUserProfile_v3(): Observable<UserInterface> {
    const userData: LoginResponseInterface = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
    return this.http.get<UserInterface>(Endpoints.GET_USER_PROFILE + userData.userId);
  }

  updateUserProfile(user: User): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.UPDATE_USER_PROFILE, user);
  }

  updateUserProfile_v2(utente: Utente): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.UPDATE_USER_PROFILE, utente);
  }

  getAllUsers(): Observable<Utente[]> {
    return this.http.get<Utente[]>(Endpoints.GET_ALL_USERS);
  }

  getAllUsers_v2(): Observable<UserInterface[]> {
    return this.http.get<UserInterface[]>(Endpoints.GET_ALL_USERS);
  }

  /** Ordinamento per cognome */
  getAllUsersSorted(): Observable<UserInterface[]> {
    return this.http.get<UserInterface[]>(Endpoints.GET_ALL_SORTED_USERS);
  }

  getUserRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(Endpoints.GET_ALL_ROLES);
  }

  getAllDipendenti(): Observable<UserInterface[]> {
    return this.http.get<UserInterface[]>(Endpoints.GET_DIPENDENTI);
  }

  registrazioneUtente(user: UserInterface) {
    return this.http.post<ServiceResponse>(Endpoints.REGISTRAZIONE, user);
  }

  getOneUser(userId: string): Observable<UserInterface> {
    return this.http.get<UserInterface>(Endpoints.GET_ONE_USER + userId);
  }

  eliminaUtente(userId: number): Observable<ServiceResponse> {
    return this.http.delete<ServiceResponse>(Endpoints.DELETE_USER + userId);
  }
}
