import {HttpErrorResponse} from '@angular/common/http';
import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {simpleFadeAnimation} from 'src/app/animations/simple-fade-animation';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {Anno} from 'src/app/models/anno';
import {MeseBustaPaga} from 'src/app/models/mese-busta-paga';
import {NuovaBustaPagaRequest} from 'src/app/models/request/nuova-busta-paga-request';
import {UserInterface} from 'src/app/models/user-interface';
import {BustePagaService} from 'src/app/services/buste-paga.service';
import {UserService} from 'src/app/services/user.service';
import GenericUtils from 'src/app/utils/generic-utils';
import TimeUtils from 'src/app/utils/time-utils';

@Component({
  selector: 'app-nuova-busta-paga',
  templateUrl: './nuova-busta-paga.component.html',
  styleUrls: ['./nuova-busta-paga.component.scss'],
  providers: [MessageService, BustePagaService],
  animations: [simpleFadeAnimation]
})
export class NuovaBustaPagaComponent {
  formBustaPaga: FormGroup;
  dipendenti: UserInterface[] = [];
  timeUtils = TimeUtils;
  anni: Anno[] = [];
  mesi: MeseBustaPaga[] = [];
  uploadedFiles: any[] = [];
  filePdf: any;
  utils = GenericUtils;
  anno: number;
  mesiDropdownDisabled: boolean;
  anniDropdownDisabled: boolean;

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private bustaPagaService: BustePagaService) {
    this.spinner.show();
    this.anni = this.timeUtils.yearsForDropdown();
    this.mesi = this.timeUtils.getMesiBustaPaga();
    this.mesiDropdownDisabled = true;
    this.anniDropdownDisabled = true;

    this.formBustaPaga = this.fb.group({
      dipendenteSelezionato: ['', Validators.required],
      meseSelezionato: ['', Validators.required],
      annoSelezionato: ['', Validators.required],
      importo: ['', [Validators.required, this.utils.greaterThanOneNumberValidator]],
      costoAzienda: ['', [Validators.required, this.utils.greaterThanOneNumberValidator]]
    });

    this.userService.getAllUsersSorted().subscribe(dipendenti => {


      for (let dipendente of dipendenti) {
        this.dipendenti.push({
          id: dipendente.id,
          username: dipendente.username,
          nome: dipendente.nome,
          cognome: dipendente.cognome,
          alias: dipendente.cognome + ' ' + dipendente.nome + ' - ' + dipendente.codiceFiscale,
          email: dipendente.email,
          cellulare: dipendente.cellulare,
          dataDiNascita: dipendente.dataDiNascita,
          status: dipendente.status,
          codiceFiscale: dipendente.codiceFiscale,
          tipoContratto: dipendente.tipoContratto,
          dataInizioRapporto: dipendente.dataInizioRapporto,
          dataFineRapporto: dipendente.dataFineRapporto,
          oreSettimanali: dipendente.oreSettimanali,
          chiaviUfficio: dipendente.chiaviUfficio,
          telefonoAziendale: dipendente.telefonoAziendale,
          comodatoPc: dipendente.comodatoPc,
          mansione: dipendente.mansione,
          roles: dipendente.roles,
          progetti: dipendente.progetti
        });
      }

      this.spinner.hide();
    });
  }

  onDipendenteChange() {
    this.formBustaPaga.controls['meseSelezionato'].reset();
    this.formBustaPaga.controls['annoSelezionato'].reset();
    this.anniDropdownDisabled = false;
    const userId = this.formBustaPaga.get('dipendenteSelezionato').value;
    this.dipendenti.forEach(dip => {
      if (userId == dip.id) {
        let dataInizio = new Date(dip.dataInizioRapporto);
        this.anni = this.timeUtils.anniPerBustaPaga(dataInizio.getFullYear());
      }
    });
  }

  onChangeAnno() {
    this.formBustaPaga.controls['meseSelezionato'].reset();
    this.mesiDropdownDisabled = false;
    const annoSelezionato = this.formBustaPaga.get('annoSelezionato').value;
    const userId = this.formBustaPaga.get('dipendenteSelezionato').value;
    this.dipendenti.forEach(dip => {
      if (userId == dip.id) {
        let dataInizio = new Date(dip.dataInizioRapporto);
        let meseInizio = dataInizio.getMonth();
        for (let i = 0; i < meseInizio; i++) {
          if (annoSelezionato == dataInizio.getFullYear()) {
            this.mesi[i].setDisabled(true);
          } else {
            this.mesi = this.timeUtils.getMesiBustaPaga();
          }
        }
      }
    });
  }

  creaBustaPaga() {
    this.spinner.show();

    const nuovaBustaPaga = new FormData();

    const nuovaBustaPagaRequest: NuovaBustaPagaRequest = {
      mese: this.formBustaPaga.get('meseSelezionato').value,
      anno: this.formBustaPaga.get('annoSelezionato').value,
      userId: this.formBustaPaga.get('dipendenteSelezionato').value,
      importo: this.formBustaPaga.get('importo').value,
      costoAzienda: this.formBustaPaga.get('costoAzienda').value
    };

    nuovaBustaPaga.append('file', this.filePdf);
    nuovaBustaPaga.append('bustaPagaJsonRequest', JSON.stringify(nuovaBustaPagaRequest));


    this.bustaPagaService.nuovaBustaPaga(nuovaBustaPaga).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {

        this.messageService.clear();
        this.messageService.add({severity: FrontendMessage.SEVERITY_SUCCESS, summary: '', detail: response.message});
        this.spinner.hide();
        this.formBustaPaga.reset();
        this.uploadedFiles = [];
      }
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.clear();
      this.messageService.add({severity: FrontendMessage.SEVERITY_ERROR, summary: '', detail: errorResponse.error.message});
    });

  }

  resetForm() {
    this.formBustaPaga.reset();
    this.uploadedFiles = [];
  }

  uploadHandler(event) {
    this.filePdf = event.files[0];
  }

  onRemoveFile() {
    this.filePdf = null;
  }

  emptyFields() {
    const fields = [];
    const controls = this.formBustaPaga.controls;
    for (const name in controls) {
      if (this.utils.formValid(this.formBustaPaga).includes(name) && controls[name].invalid && (controls[name].value == null || controls[name].value == '')) {
        fields.push(name);
      }
    }
    return fields;
  }
}
