<div class="main-body-login">
  <div class="container-box-brand">
    <div class="brand-main-content">

    </div>
  </div>
  <div *ngIf="!showResetPassword" class="container-box">
    <ngx-spinner [fullScreen]="false" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2" size="medium"
                 type="ball-clip-rotate-multiple"><p style="color: #118ab2"> Accesso in corso... </p></ngx-spinner>
    <div class="logo-container">
      <img [src]="logoUrl" alt="" width="200px">
    </div>
    <div class="login-container">
      <h3>Login</h3>
    </div>
    <p-messages [enableService]="true"></p-messages>
    <form (ngSubmit)="accedi()" [formGroup]="loginForm">
      <div class="login-form">
        <div class="p-fluid">
          <div class="p-field">

          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-user"></i>
            <input [ngClass]="{'ng-invalid ng-dirty': !loginForm.get('username').valid && (loginForm.get('username').dirty || loginForm.get('username').touched)}" class="p-inputtext-lg"
                   formControlName="username"
                   id="username" pInputText type="username">
            <label for="float-input">Username</label>
          </span>

          </div>
          <div class="top-space"></div>
          <div class="p-field">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-key"></i>
            <input class="p-inputtext-lg" formControlName="password" id="password" pInputText type="password">
            <label for="float-input">Password</label>
          </span>
          </div>
          <div class="p-field">
          </div>
          <button [disabled]="!loginForm.valid" class="p-button-lg p-button-warning" icon="pi pi-unlock" label="Accedi" pButton
                  type="submit"></button>
          <p-divider></p-divider>
          <button (click)="goToResetPassword()" class="p-button-text p-button-primary" icon="las la-lg la-exclamation-triangle" label="Hai dimenticato la password?"
                  pButton type="button"></button>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="showResetPassword" class="container-box">
    <ngx-spinner [fullScreen]="false" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2" size="medium"
                 type="ball-clip-rotate-multiple"><p style="color: #118ab2">Operazione in corso... </p></ngx-spinner>
    <div class="logo-container">
      <img [src]="logoUrl" alt="" width="200px">
    </div>
    <div class="login-container">
      <h3>Password dimenticata?</h3>
    </div>
    <p-messages [enableService]="true"></p-messages>
    <form (ngSubmit)="sendMailResetPassword()" [formGroup]="resetPasswordForm">
      <div class="login-form">
        <div class="p-fluid">
          <div class="p-field">

          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-envelope"></i>
            <input class="p-inputtext-lg" formControlName="email" id="email" pInputText type="email">
            <label for="float-input">Indirizzo Email</label>
          </span>

          </div>
          <div class="top-space"></div>
          <div *ngIf="!resetPasswordFormSent" class="p-field">
          </div>
          <button [disabled]="!resetPasswordForm.valid" class="p-button-lg p-button-danger" icon="pi pi-power-off" label="Reset password"
                  pButton type="submit"></button>
          <p-divider></p-divider>
          <button (click)="backToLogin()" class="p-button-text p-button-help" icon="las la-lg la-arrow-circle-left" label="Torna alla pagina di accesso"
                  pButton type="button"></button>
        </div>
      </div>
    </form>
  </div>


</div>
