import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {RECAPTCHA_LANGUAGE, RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {DividerModule} from 'primeng/divider';
import {InputTextModule} from 'primeng/inputtext';
import {MessagesModule} from 'primeng/messages';
import {LoginComponent} from 'src/app/pages/login/login.component';
import {ResetPasswordComponent} from 'src/app/pages/reset-password/reset-password.component';
import {AuthComponent} from './auth.component';
import {PasswordModule} from 'primeng/password';
import {MessageModule} from 'primeng/message';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    DividerModule,
    ButtonModule,
    CheckboxModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxSpinnerModule,
    MessagesModule,
    MessageModule,
    PasswordModule
  ],
  providers: [


    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'it',
    }
  ]
})
export class AuthModule {
}
