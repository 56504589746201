<div class="main-area">
  <main>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <div class="titolo-sezione">
      <i class="las la-id-card"></i> Profilo
    </div>
    <form *ngIf="dataLoaded | async" [formGroup]="formTirocinante">

      <div class="p-fluid p-formgrid p-grid p-jc-end">
        <div class="p-field p-col-12 p-md-4">
          <label for="nome">Nome</label>
          <input formControlName="nome" id="nome"
                 pInputText required type="text"/>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="cognome">Cognome</label>
          <input formControlName="cognome" id="cognome"
                 pInputText required type="text">
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="codiceFiscale">Codice fiscale</label>
          <input formControlName="codiceFiscale"
                 id="codiceFiscale"
                 pInputText
                 required
                 type="text">

        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="luogoDiNascita">Luogo di nascita</label>
          <input formControlName="luogoDiNascita" id="luogoDiNascita"
                 pInputText required type="text">
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="dataDiNascita">Data di nascita</label>
          <p-calendar dateFormat="dd/mm/yy"
                      formControlName="dataDiNascita"
                      required
          ></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-4">
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="matricola">Matricola</label>
          <input formControlName="matricola" id="matricola"
                 pInputText required type="text"
          >
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="annoImmatricolazione">A.A immatricolazione</label>
          <input formControlName="annoImmatricolazione" id="annoImmatricolazione" pInputText
                 required placeholder="AAAA/AAAA">
        </div>

        <div class="p-field p-col-12 p-md-4">
        </div>
        <div class="p-field p-col-12 p-md-12">
          <label for="residenza">Residente in</label>
          <input formControlName="residenza" id="residenza"
                 required pInputText>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="provincia">Prov.</label>
          <p-dropdown [placeholder]="tirocinante.provincia" formControlName="provincia" required
                      inputId="provincia"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="citta">Città</label>
          <p-dropdown [placeholder]="tirocinante.citta" formControlName="citta"
                      inputId="citta" required styleClass="stileRuoli"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="cap">Cap</label>
          <p-inputMask formControlName="cap" id="cap"
                       required mask="99999">
          </p-inputMask>
        </div>

        <div class="p-field p-col-12 p-md-4 parent">
          <label for="prefix">Cellulare<label class="required-asterisk">*</label></label>

          <div class="block-div">
            <div class="first">
              <input [placeholder]="this.phonePrefix" formControlName="prefix"
                     id="prefix" pInputText required>
            </div>
            <div class="last">
              <p-inputMask [ngClass]="{'ng-invalid ng-dirty': !formTirocinante.get('cellulare').valid && (formTirocinante.get('cellulare').dirty || formTirocinante.get('cellulare').touched)}" formControlName="cellulare" id="cellulare"
                           mask="9999999999"

                           required></p-inputMask>
            </div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="email">Indirizzo email</label>
          <input formControlName="email" id="email"
                 pInputText required type="email">
        </div>
        <div class="p-field p-col-12 p-md-4">
        </div>


        <div class="p-field p-col-12 p-md-12">
          <p-divider type="dashed"></p-divider>
        </div>
      </div>
    </form>

    <p-message *ngIf="utils.formValid(formTirocinante).length > 0" @simpleFadeAnimation
               severity="error" text="Attenzione! Campo/i non popolati correttamente"></p-message>
    <p-messages [enableService]="true"></p-messages>
  </main>

</div>

