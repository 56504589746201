import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import * as codiceFiscaleUtils from '@marketto/codice-fiscale-utils';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {fadeInAnimation} from 'src/app/animations/fade-in-animation';
import {simpleFadeAnimation} from 'src/app/animations/simple-fade-animation';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {STATUS_DIPENDENTI_MOCK} from 'src/app/mock/status-dipendenti.mock';
import {ComodatoPc} from 'src/app/models/comodato.pc';
import {MansioneInterface} from 'src/app/models/mansione.interface';
import {ProgettoDipendente} from 'src/app/models/progetto-dipendente';
import {Role} from 'src/app/models/role';
import {StatusDipendente} from 'src/app/models/status-dipendente';
import {TipoContrattoInterface} from 'src/app/models/tipo-contratto.interface';
import {Utente} from 'src/app/models/utente';
import {MansioniService} from 'src/app/services/mansioni.service';
import {UserService} from 'src/app/services/user.service';
import GenericUtils from 'src/app/utils/generic-utils';
import TimeUtils from 'src/app/utils/time-utils';

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.scss'],
  providers: [MessageService],
  animations: [simpleFadeAnimation, fadeInAnimation]
})
export class ProfiloComponent implements OnInit {


  profiloAdmin: Utente;
  formProfilo: FormGroup;

  nome: string;

  statusDip: StatusDipendente[] = STATUS_DIPENDENTI_MOCK;
  progetti: ProgettoDipendente[] = [];
  progettiSelezionati: ProgettoDipendente[] = [];
  switchToEditMode: boolean;
  statusSelezionato: StatusDipendente;
  ruoliUtente: Role[];
  ruoloSelezionato: Role;
  dataLoaded: Promise<boolean>;
  mansioni: MansioneInterface[] = [];
  tipiContratto: TipoContrattoInterface[] = [];
  pcAziendaleCheckBox: boolean;
  timeUtils = TimeUtils;
  utils = GenericUtils;
  cfUtil = codiceFiscaleUtils;
  cfValid: boolean;
  dataFineDisabled: boolean;
  phonePrefix: string;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private messageService: MessageService,
    private mansioniService: MansioniService) {
    this.dataFineDisabled = true;
    this.messageService.clear();
    this.spinner.show();
    this.mansioniService.getMansioni().subscribe(mansioni => {
      this.mansioni = mansioni;
    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

    this.mansioniService.getTipiContratto().subscribe(tipiContratto => {
      this.tipiContratto = tipiContratto;
    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

    this.userService.getUserProfile_v2().subscribe(profilo => {
      this.profiloAdmin = new Utente(
        profilo.id,
        profilo.username,
        profilo.nome,
        profilo.cognome,
        profilo.email,
        profilo.cellulare,
        profilo.dataDiNascita,
        profilo.status,
        profilo.codiceFiscale,
        profilo.tipoContratto,
        profilo.dataInizioRapporto,
        profilo.dataFineRapporto,
        profilo.oreSettimanali,
        profilo.chiaviUfficio,
        profilo.telefonoAziendale,
        profilo.comodatoPc,
        profilo.mansione,
        profilo.roles,
        profilo.progetti);
      this.nome = profilo.nome;
      this.statusSelezionato = new StatusDipendente(profilo.status, profilo.status);
      this.pcAziendaleCheckBox = profilo.comodatoPc ? true : false;
      const serialePc = profilo.comodatoPc != null ? profilo.comodatoPc.$seriale : null;
      const modelloPc = profilo.comodatoPc != null ? profilo.comodatoPc.$modello : null;
      this.ruoloSelezionato = {
        id: profilo.roles[0].id,
        name: profilo.roles[0].name
      };
      const phone = profilo.cellulare.split(' ')[1];
      this.phonePrefix = profilo.cellulare.split(' ')[0];
      this.formProfilo = this.fb.group({
        nome: [profilo.nome, [Validators.required, Validators.minLength(2), Validators.pattern(Constants.REGEX_TEXT_NO_SPACE_AT_BEGIN)]],
        cognome: [profilo.cognome, [Validators.required, Validators.minLength(2), Validators.pattern(Constants.REGEX_TEXT_NO_SPACE_AT_BEGIN)]],
        codiceFiscale: [profilo.codiceFiscale, [Validators.required, Validators.pattern(Constants.CODICE_FISCALE_REGEX), this.utils.codiceFiscaleValidator]],
        email: [profilo.email, [Validators.required, Validators.pattern(Constants.EMAIL_REGEX)]],
        cellulare: [phone, Validators.required],
        prefix: [this.phonePrefix, Validators.required],
        dataDiNascita: [new Date(profilo.dataDiNascita), Validators.required],
        statusSelezionato: [this.statusSelezionato, Validators.required],
        ruolo: [this.ruoloSelezionato, Validators.required],
        mansione: [profilo.mansione, Validators.required],
        dataInizioRapporto: [new Date(profilo.dataInizioRapporto), Validators.required],
        dataFineRapporto: [profilo.dataFineRapporto || 0],
        tipoContratto: [profilo.tipoContratto, Validators.required],
        oreSettimanali: [profilo.oreSettimanali, Validators.required],
        chiaviUfficio: [profilo.chiaviUfficio],
        telefonoAziendale: [profilo.telefonoAziendale],
        pcAziendale: [this.pcAziendaleCheckBox],
        serialePc: [serialePc],
        modelloPc: [modelloPc],
        progetti: ['']
      });
      this.formProfilo.disable();
      this.dataLoaded = Promise.resolve(true);

      this.onInputCodiceFiscale(profilo.codiceFiscale);

      this.spinner.hide();
    });

    this.userService.getUserRoles().subscribe(ruoliUtente => {
      this.ruoliUtente = ruoliUtente;
    }, error => {
      this.messageService.clear();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }

  ngOnInit() {
  }

  onDataInizioChange() {
    this.formProfilo.controls['dataFineRapporto'].reset();
    this.dataFineDisabled = false;
  }

  onInputCodiceFiscale(cf: string) {
    const {Validator} = this.cfUtil;
    if (Validator.codiceFiscale(cf).valid) {
      this.cfValid = true;
    } else {
      this.cfValid = false;
    }
  }

  getFutureYearRange(): string {
    const currentYear = new Date();
    const finalYear = currentYear.getFullYear() + 2;
    const startingYear = currentYear.getFullYear() - 2;
    return startingYear + ':' + finalYear;
  }

  detectComodatoChange(event) {
    // console.log(this.formProfilo.get('serialePc'));
    // console.log(this.formProfilo.get('modelloPc'));
    // console.log(event);

    if (event.checked) {
      this.pcAziendaleCheckBox = true;
    } else {
      this.formProfilo.get('serialePc').reset();
      this.formProfilo.get('serialePc').clearValidators();
      this.formProfilo.get('serialePc').updateValueAndValidity();
      this.formProfilo.get('modelloPc').reset();
      this.formProfilo.get('modelloPc').clearValidators();
      this.formProfilo.get('modelloPc').updateValueAndValidity();
      this.pcAziendaleCheckBox = false;
    }
  }

  inviaForm() {
    this.spinner.show();
    const dataDiNascita = new Date(Date.UTC(this.formProfilo.get('dataDiNascita').value.getFullYear(), this.formProfilo.get('dataDiNascita').value.getMonth(), this.formProfilo.get('dataDiNascita').value.getDate()));
    const dataInizioRapporto = new Date(Date.UTC(this.formProfilo.get('dataInizioRapporto').value.getFullYear(), this.formProfilo.get('dataInizioRapporto').value.getMonth(), this.formProfilo.get('dataInizioRapporto').value.getDate()));
    const ruoliUtente: Role[] = [];
    ruoliUtente.push(this.formProfilo.get('ruolo').value);
    const dataFineRapporto = (this.formProfilo.get('dataFineRapporto').value != 0) ? new Date(Date.UTC(this.formProfilo.get('dataFineRapporto').value.getFullYear(), this.formProfilo.get('dataFineRapporto').value.getMonth(), this.formProfilo.get('dataFineRapporto').value.getDate())) : null;
    const userToUpdate = new Utente(
      this.profiloAdmin.id,
      null,
      this.formProfilo.get('nome').value,
      this.formProfilo.get('cognome').value,
      this.formProfilo.get('email').value,
      this.formProfilo.get('cellulare').value,
      dataDiNascita,
      this.statusSelezionato.getName(),
      this.formProfilo.get('codiceFiscale').value,
      this.formProfilo.get('tipoContratto').value,
      dataInizioRapporto,
      dataFineRapporto,
      this.formProfilo.get('oreSettimanali').value,
      this.formProfilo.get('chiaviUfficio').value,
      this.formProfilo.get('telefonoAziendale').value,
      (this.pcAziendaleCheckBox ? new ComodatoPc(this.formProfilo.get('modelloPc').value, this.formProfilo.get('serialePc').value) : null),
      this.formProfilo.get('mansione').value,
      ruoliUtente,
      null);
    // console.log(userToUpdate);

    this.userService.updateUserProfile_v2(userToUpdate).subscribe(response => {
      // console.log(response);
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_USER_UPDATED_OK);
      this.closeEditMode();
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_WARNING,
        detail: errorResponse.error.message
      });
    });
  }

  detectDataChange() {

  }

  switchToEdit() {
    this.switchToEditMode = true;
    this.formProfilo.enable();
    this.messageService.clear();
  }

  closeEditMode() {
    this.switchToEditMode = false;
    this.formProfilo.disable();
  }

  revertForm() {

    this.formProfilo.reset();
    const serialePc = this.profiloAdmin.comodatoPc != null ? this.profiloAdmin.comodatoPc.$seriale : null;
    const modelloPc = this.profiloAdmin.comodatoPc != null ? this.profiloAdmin.comodatoPc.$modello : null;

    if (this.profiloAdmin.comodatoPc != null) {
      this.pcAziendaleCheckBox = true;
    }

    this.formProfilo.patchValue({
      nome: this.profiloAdmin.nome,
      cognome: this.profiloAdmin.cognome,
      codiceFiscale: this.profiloAdmin.codiceFiscale,
      email: this.profiloAdmin.email,
      cellulare: this.profiloAdmin.cellulare,
      dataDiNascita: new Date(this.profiloAdmin.dataDiNascita),
      statusSelezionato: this.statusSelezionato,
      ruolo: this.ruoloSelezionato,
      mansione: this.profiloAdmin.mansione,
      dataInizioRapporto: new Date(this.profiloAdmin.dataInizioRapporto),
      dataFineRapporto: this.profiloAdmin.dataFineRapporto || 0,
      tipoContratto: this.profiloAdmin.tipoContratto,
      oreSettimanali: this.profiloAdmin.oreSettimanali,
      chiaviUfficio: this.profiloAdmin.chiaviUfficio,
      telefonoAziendale: this.profiloAdmin.telefonoAziendale,
      pcAziendale: this.pcAziendaleCheckBox,
      serialePc: serialePc,
      modelloPc: modelloPc,
      progetti: ['']
    });


  }

  getYearRange(): string {
    const currentYear = new Date();
    const finalYear = currentYear.getFullYear() - 18;
    const startingYear = currentYear.getFullYear() - 60;
    return startingYear + ':' + finalYear;
  }
}
