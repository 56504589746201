// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backendEndpoint: 'https://ppmapi-staging.datasound.it',
  CRYPTO_SECRET_KEY: 'BRl9Iq&@h?5r8xly!m90',
  COMPANY_NAME: 'Datasound',
  LOGO_URL: 'http://mars.datasound.it/datasound-tondo.png',
  FULL_LOGO_URL: 'http://mars.datasound.it/datasound-mini-150.png'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
