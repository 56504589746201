export class StorageConstants {
  public static readonly TOKEN = 'token';
  public static readonly USER_ID = 'userId';
  public static readonly EXPIRING_ON = 'expiringOn';
  public static readonly NOME = 'nome';
  public static readonly COGNOME = 'cognome';
  public static readonly RUOLO = 'ruolo';

  public static readonly USER_DATA = 'userData';
}
