import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {animate, style, transition, trigger} from '@angular/animations';
import {environment} from 'src/environments/environment';
import {StaticContentService} from 'src/app/services/static-content.service';
import {Constants} from 'src/app/constants/constants';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-light-sidebar',
  templateUrl: './light-sidebar.component.html',
  styleUrls: ['./light-sidebar.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(10px)'}),
        animate('1s', style({opacity: 1, transform: 'translateY(0)'})),
      ]),
      transition(':leave', [
        animate('.1s', style({opacity: 0, transform: 'translateY(10px)'})),
      ]),
    ]),
    trigger('fadeSlideFromLeft', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateX(10px)'}),
        animate('1s', style({opacity: 1, transform: 'translateX(0)'})),
      ]),
      transition(':leave', [
        animate('.1s', style({opacity: 0, transform: 'translateX(10px)'})),
      ]),
    ])
  ]
})
export class LightSidebarComponent implements OnInit {

  currentWindowWidth: any;
  navToggleChecked: boolean = false;
  companyName = environment.COMPANY_NAME;
  datasoundImageUrl = environment.LOGO_URL;
  fullLogoUrl = environment.FULL_LOGO_URL;

  constructor(private router: Router, private staticContentService: StaticContentService) {
    this.staticContentService.getAsset(Constants.PPM_LOGO_MINI_150).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.fullLogoUrl = response.fileUrl;
      } else {
        this.fullLogoUrl = Constants.PPM_LOGO_MINI_150_OFFLINE;
      }
    }, (errorResponse: HttpErrorResponse) => {
      this.fullLogoUrl = Constants.PPM_LOGO_MINI_150_OFFLINE;
    });

    this.staticContentService.getAsset(Constants.PPM_LOGO_CIRCOLARE).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.datasoundImageUrl = response.fileUrl;
      } else {
        this.datasoundImageUrl = Constants.PPM_LOGO_CIRCOLARE_OFFLINE;
      }
    }, (errorResponse: HttpErrorResponse) => {
      this.datasoundImageUrl = Constants.PPM_LOGO_CIRCOLARE_OFFLINE;
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.currentWindowWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.currentWindowWidth = window.innerWidth;
  }

  toggleSidebar() {
    this.navToggleChecked = !this.navToggleChecked;
    // console.log(this.navToggleChecked);

  }

}
