<div class="main-area">
  <main>
    <div class="titolo-sezione">
      <i class="las la-drafting-compass"></i> Inserimento progetto
    </div>
    <p-divider type="dashed"></p-divider>
    <form (ngSubmit)="inviaFormNuovoProgetto()" [formGroup]="formNuovoProgetto">
      <div class="p-fluid p-formgrid p-grid p-jc-end">
        <div class="p-field p-col-12 p-md-4">
          <label for="code">Acronimo</label>
          <input [ngClass]="{'ng-invalid ng-dirty': !formNuovoProgetto.get('code').valid && (formNuovoProgetto.get('code').dirty || formNuovoProgetto.get('code').touched)}"
                 formControlName="code"
                 id="code"
                 pInputText required type="text">
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="nome">Nome</label>
          <input [ngClass]="{'ng-invalid ng-dirty': !formNuovoProgetto.get('nome').valid && (formNuovoProgetto.get('nome').dirty || formNuovoProgetto.get('nome').touched)}"
                 formControlName="nome"
                 id="nome"
                 pInputText required type="text">
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="cliente">Cliente</label>
          <input [ngClass]="{'ng-invalid ng-dirty': !formNuovoProgetto.get('cliente').valid && (formNuovoProgetto.get('cliente').dirty || formNuovoProgetto.get('cliente').touched)}"
                 formControlName="cliente"
                 id="cliente"
                 pInputText required type="text">
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="status">Stato</label>
          <p-dropdown (onChange)="detectProjectChange()"
                      [ngClass]="{'ng-invalid ng-dirty': !formNuovoProgetto.get('status').valid && (formNuovoProgetto.get('status').dirty || formNuovoProgetto.get('status').touched)}"
                      [options]="statusProgetto"
                      formControlName="status"
                      inputId="status"
                      optionLabel="name"
                      optionLabel="name" placeholder="Seleziona" required></p-dropdown>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="dataInizio">Data inizio progetto</label>
          <p-calendar [monthNavigator]="true"
                      [ngClass]="{'ng-invalid ng-dirty': !formNuovoProgetto.get('dataInizio').valid && (formNuovoProgetto.get('dataInizio').dirty || formNuovoProgetto.get('dataInizio').touched)}"
                      [yearNavigator]="true"
                      [yearRange]="timeUtils.getFutureYearRange()"
                      dateFormat="dd/mm/yy"
                      formControlName="dataInizio"
                      required></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="dataFine">Data fine progetto</label>
          <p-calendar [monthNavigator]="true"
                      [ngClass]="{'ng-invalid ng-dirty': !formNuovoProgetto.get('dataFine').valid && (formNuovoProgetto.get('dataFine').dirty || formNuovoProgetto.get('dataFine').touched)}"
                      [yearNavigator]="true"
                      [yearRange]="timeUtils.getFutureYearRange()"
                      dateFormat="dd/mm/yy"

                      formControlName="dataFine"
                      required></p-calendar>
        </div>

        <div class="p-field p-col-12 p-md-4 fix-non-required-inline">
          <label for="budgetComplessivo">Spese preventivate</label>
          <p-inputNumber (keypress)="utils.onlyPositiveNumbers($event)"
                         [minFractionDigits]="2"
                         [min]="0"
                         currency="EUR"
                         formControlName="spesePreventivate" locale="it-IT"
                         mode="currency"
                         mode="decimal"></p-inputNumber>
        </div>

        <div class="p-field p-col-12 p-md-4 fix-non-required-inline">
          <label for="oreUomoStimate">Ore stimate</label>
          <input (keypress)="utils.onlyPositiveNumbers($event)" formControlName="oreUomoStimate" id="oreUomoStimate"
                 min="0" pInputText type="number">
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="budgetComplessivo">Budget complessivo</label>
          <p-inputNumber (keypress)="utils.onlyPositiveNumbers($event)"
                         [minFractionDigits]="2"
                         [min]="0"
                         [ngClass]="{'ng-invalid ng-dirty': !formNuovoProgetto.get('budgetComplessivo').valid && (formNuovoProgetto.get('budgetComplessivo').dirty || formNuovoProgetto.get('budgetComplessivo').touched)}"
                         currency="EUR" formControlName="budgetComplessivo"
                         locale="it-IT"
                         mode="decimal"
                         mode="currency" required></p-inputNumber>
        </div>

        <div class="p-field p-col-12">
          <label for="coloreStatistiche">Colore statistiche</label>
          <br>
          <p-colorPicker [(ngModel)]="coloreStatistiche" formControlName="coloreStatistiche"></p-colorPicker>
        </div>

        <div class="p-field p-col-12 p-md-12">
          <p-divider type="dashed"></p-divider>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <button (click)="resetForm()" class="p-button p-button-danger" label="Annulla" pButton type="button"></button>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <button [disabled]="!formNuovoProgetto.valid" class="p-button p-button-success" label="Salva" pButton
                  type="submit"></button>
        </div>
      </div>
    </form>
    <p-message *ngIf="utils.formValid(formNuovoProgetto).length > 0 && emptyFields().length == 0" @simpleFadeAnimation
               severity="error" text="Attenzione! Campo/i non popolati correttamente"></p-message>
    <div class="p-md-12" style="padding: 0px"></div>
    <p-message *ngIf="emptyFields().length > 0" @simpleFadeAnimation
               severity="error" text="Attenzione! Campo/i obbligatori non popolati"></p-message>
    <p-messages [enableService]="true"></p-messages>
  </main>
</div>
