import {Component, OnInit} from '@angular/core';
import TimeUtils from '../../../utils/time-utils';
import GenericUtils from '../../../utils/generic-utils';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StorageConstants} from '../../../constants/storage-constants';
import {ActivatedRoute, Router} from '@angular/router';
import {TimesheetService} from '../../../services/timesheet.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {NgxSpinnerService} from 'ngx-spinner';
import {FrontendMessage} from '../../../constants/frontend-message';
import * as moment from 'moment';
import {Constants} from '../../../constants/constants';
import {HttpErrorResponse} from '@angular/common/http';
import {IMonthDetail} from '../../../models/tirocinio/month-detail.interface';
import {ILibrettoDiario} from '../../../models/tirocinio/libretto-diario.interface';
import {LibrettoDiarioService} from '../../../services/libretto-diario.service';
import {TirocinanteService} from '../../../services/tirocinante.service';
import {RouterPaths} from '../../../constants/RouterPaths';
import {ILibrettoManagement} from '../../../models/tirocinio/libretto-management-request.interface';
import {ISospensioneTirocinio} from '../../../models/tirocinio/sospensione-tirocinio.interface';
import {Dialog} from 'primeng/dialog';
import {GiorniFestiviService} from '../../../services/giorni-festivi.service';

@Component({
  selector: 'app-vista-libretto-diario',
  templateUrl: './vista-libretto-diario.component.html',
  styleUrls: ['./vista-libretto-diario.component.scss'],
  providers: [MessageService, ConfirmationService, TimesheetService]
})
export class VistaLibrettoDiarioComponent implements OnInit {

  meseAnno: string;
  initialMonth: boolean;
  month: number;
  year: number;
  fromNotification: boolean;

  monthDetail: IMonthDetail;
  librettoDiario: ILibrettoDiario;
  userData: any;

  timeUtils: any;
  daysCurrentMonth: any;
  giorniDaDisabilitare: any;
  giorniFestivi: any;
  displayModal: boolean;
  displayModalActivity: boolean;
  selectedDay: any;
  mostraRiepilogo: boolean;
  incompatibleHour: boolean;
  utils: any;
  userId: number;
  descrizione: string;
  formNote: FormGroup;
  displayModalInfo: boolean;

  constructor(
    private librettoDiarioService: LibrettoDiarioService,
    private tirocinanteService: TirocinanteService,
    private spinner: NgxSpinnerService,
    public activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private giorniFestiviService: GiorniFestiviService,
    private fb: FormBuilder
  ) {
    this.spinner.show();
    this.userId = 0;
    this.meseAnno = '';
    this.userData = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
    this.timeUtils = TimeUtils;
    this.month = 0;
    this.year = 0;
    this.initialMonth = false;
    this.giorniDaDisabilitare = null;

    this.displayModal = false;
    this.displayModalActivity = false;
    this.selectedDay = null;
    this.mostraRiepilogo = false;
    this.incompatibleHour = false;
    this.displayModalInfo = false;
    this.utils = GenericUtils;
    this.descrizione = '';

    this.createForm();
    this.displayModal = false;
    this.monthDetail = {
      oreMensili: '',
      workingDayTirocinio: null,
      id: 0,
      anno: 0,
      mese: 0,
      tirocinante_Id: 0
    };

    this.librettoDiario = {
      id: 0,
      dataFineTirocinio: new Date(),
      dataInizioTirocinio: new Date(),
      dayDetailsTirocinio: null,
      totaleOre: '',
      statoTirocinio: {
        id: 0,
        nome: null,
      },
      richiestaSblocco: false,
      note: ''
    };
    if (this.activatedRoute.snapshot.params.notification == 'true') {
      this.fromNotification = true;
    } else {
      this.fromNotification = false;
    }

    this.loadLibretto();

  }

  ngOnInit(): void {

  }

  showModalDialog(descrizione) {
    this.descrizione = descrizione;
    this.displayModalInfo = true;
  }

  loadLibretto() {

    this.userId = this.activatedRoute.snapshot.params.id;
    this.librettoDiarioService.getLibrettoDiario(this.userId).subscribe((libretto) => {
      this.librettoDiario = libretto;
      this.librettoDiario.statoTirocinio = libretto.statoTirocinio;
      this.librettoDiario.dataInizioTirocinio = new Date(libretto.dataInizioTirocinio);
      this.librettoDiario.dataFineTirocinio = new Date(libretto.dataFineTirocinio);
      this.librettoDiarioService.getAllMonthDetail(this.userId).subscribe((resp) => {

        for (let i = 0; i < resp.length; i++) {
          if (resp[i].workingDayTirocinio.length > 0) {
            this.initialMonth = true;
            this.loadMonthDetail(resp[i].mese - 1, resp[i].anno);
            break;
          }
        }
        if (!this.initialMonth) {
          this.loadMonthDetail(this.librettoDiario.dataInizioTirocinio.getMonth(), this.librettoDiario.dataInizioTirocinio.getFullYear());

        }
      });

    }, (error: HttpErrorResponse) => {
      // console.log(error);
    });
    this.spinner.hide();
  }

  approvaLibretto(libretto) {
    this.spinner.show();
    const request: ILibrettoManagement = {
      librettoId: this.librettoDiario.id,
      tirocinanteId: +this.userId,
      amministratoreId: this.userData.userId
    };
    this.librettoDiarioService.approvaLibretto(request).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.loadLibretto();
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_OK);
      }
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_ERROR,
        detail: errorResponse.error.message
      });
    });

  }

  loadMonthDetail(mese: number, anno: number) {
    if (mese === -1) {
      mese = 11;
      anno = anno - 1;
    }
    if (mese === 12) {
      mese = 0;
      anno = anno + 1;
    }
    this.librettoDiarioService.getMonthDetailByYearAndMonth(this.userId, mese + 1, anno).subscribe((monthDetail) => {
      this.monthDetail = monthDetail;
      this.month = monthDetail.mese - 1;
      this.year = monthDetail.anno;
      this.monthDetail.oreMensili = monthDetail.oreMensili;


      this.meseAnno = moment('' + this.monthDetail.anno + '-' + this.timeUtils.prepareMonthForMoment(this.monthDetail.mese - 1) + '-01').locale('it').format('MMMM YYYY');

      this.daysCurrentMonth = this.timeUtils.getDaysArray_v2(this.monthDetail.anno, this.monthDetail.mese - 1);
      this.timeUtils.mergeWorkedDaysTirocinioWithCalendar(this.monthDetail, this.daysCurrentMonth);
      if (this.daysCurrentMonth[0].giornoSettimana == 0) {
        this.giorniDaDisabilitare = Array.from(Array(this.daysCurrentMonth[0].giornoSettimana + 6), (x, i) => i);
      } else {
        this.giorniDaDisabilitare = Array.from(Array(this.daysCurrentMonth[0].giornoSettimana - 1), (x, i) => i);
      }
      this.giorniFestivi = this.giorniFestiviService.listMonthHolydays(this.daysCurrentMonth);

    });
    this.spinner.hide();
  }

  goback() {
    this.router.navigateByUrl(RouterPaths.ADMIN_ROUTE + '/' + RouterPaths.LISTA_TIROCINANTI);
  }

  goNotification() {
    this.router.navigateByUrl(RouterPaths.ADMIN_ROUTE + '/notifiche');
  }

  inviaDettaglioNote() {

    const note: ISospensioneTirocinio = {
      librettoId: this.librettoDiario.id,
      tirocinanteId: +this.userId,
      note: this.formNote.get('note').value,
      amministratoreId: this.userData.userId
    };
    this.displayModal = false;
    this.spinner.show();
    this.librettoDiarioService.sospendiLibretto(note).subscribe((resp) => {
      this.loadLibretto();
      this.spinner.hide();
      this.displayModal = false;
      this.messageService.add(FrontendMessage.REACTIVATE_LIBRETTO);
    }, (error) => {
      // console.log(error);
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_ERROR,
        detail: error.error.message
      });
    });
  }

  exportToPDF() {
    this.spinner.show();
    this.librettoDiarioService.exportLibrettoToPDF(this.userId.toString()).subscribe((response) => {
      let file = new Blob([response], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.spinner.hide();
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_ERROR,
        detail: errorResponse.error.message
      });
    });
  }

  createForm() {
    this.formNote = this.fb.group({
      note: ['', [Validators.required, Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE_NOTE), Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT_NOTE)]]
    });
  }

  getData(giorno) {
    this.selectedDay = giorno;
    if (giorno.activities != undefined && giorno.totaleOreGiornata !== '00:00') {
      this.mostraRiepilogo = true;

      this.displayModalActivity = true;

    } else {

      this.displayModalActivity = false;
      this.mostraRiepilogo = false;
    }
  }

  hideModal() {
    this.displayModal = false;
    this.formNote.reset();
  }

  openDialog() {
    this.displayModal = true;
    this.createForm();
  }

  showDialogMaximized(dialog: Dialog) {
    dialog.maximize();
  }


}
