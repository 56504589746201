<form #formDirective="ngForm" [formGroup]="formNuovoProgettoFormativo">


  <div class="p-fluid p-formgrid p-grid p-jc-end">
    <div class="p-field p-col-12 p-md-12">
      <p-divider align="left">
        <p-button label="Durata tirocinio:" styleClass="p-button-text"></p-button>
      </p-divider>
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="dataInizioTirocinio">Data inizio tirocinio</label>
      <p-calendar (keypress)="enableAndResetRelatedDateField(formNuovoProgettoFormativo.controls['dataInizioTirocinio'], formNuovoProgettoFormativo.controls['dataFineTirocinio'])" (onClose)="onDataInizioChange()"
                  (onSelect)="onDataInizioChange()"
                  [minDate]="initialDateValue"
                  [monthNavigator]="true"
                  [ngClass]="{' ng-dirty': !formNuovoProgettoFormativo.get('dataInizioTirocinio').valid && (formNuovoProgettoFormativo.get('dataInizioTirocinio').dirty || formNuovoProgettoFormativo.get('dataInizioTirocinio').touched)}"
                  [yearNavigator]="true"
                  [yearRange]="timeUtils.getFutureYearRange()"
                  dateFormat="dd/mm/yy"
                  formControlName="dataInizioTirocinio"
                  id="dataInizioTirocinio"
                  required></p-calendar>
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="dataFineTirocinio">Data fine tirocinio</label>
      <p-calendar [disabled]="dataFineDisabled" [minDate]="timeUtils.setMinDate(formNuovoProgettoFormativo.get('dataInizioTirocinio').value)"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  [yearRange]="timeUtils.getFutureYearRange()"
                  dateFormat="dd/mm/yy"
                  formControlName="dataFineTirocinio"
                  id="dataFineTirocinio"
                  required></p-calendar>
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="ore">Ore </label>
      <input (keydown)="this.utils.disablePointKeyPress($event)" [ngClass]="{' ng-dirty': !formNuovoProgettoFormativo.get('ore').valid && (formNuovoProgettoFormativo.get('ore').dirty || formNuovoProgettoFormativo.get('ore').touched)}"
             formControlName="ore"
             id="ore"
             pInputText
             required type="number">

    </div>

    <div class="p-field p-col-12 p-md-12">
      <p-divider align="left">
        <p-button label="Tutor universitario:" styleClass="p-button-text"></p-button>
      </p-divider>
    </div>

    <div class="p-field p-col-12 p-md-4">
      <label for="cognomeTutorUniversitario">Cognome</label>
      <input [ngClass]="{'ng-dirty': !formNuovoProgettoFormativo.get('cognomeTutorUniversitario').valid && (formNuovoProgettoFormativo.get('cognomeTutorUniversitario').dirty )}" formControlName="cognomeTutorUniversitario"
             id="cognomeTutorUniversitario"
             pInputText required type="text">
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="nomeTutorUniversitario">Nome</label>
      <input [ngClass]="{'ng-dirty': !formNuovoProgettoFormativo.get('nomeTutorUniversitario').valid && (formNuovoProgettoFormativo.get('nomeTutorUniversitario').dirty || formNuovoProgettoFormativo.get('nomeTutorUniversitario').touched)}" formControlName="nomeTutorUniversitario"
             id="nomeTutorUniversitario"
             pInputText required type="text"/>
    </div>
    <div class="p-field p-col-12 p-md-4"></div>

    <div class="p-field p-col-12 p-md-4">
      <label for="areaScientifica">Area Scientifica</label>
      <p-dropdown (onChange)="onChange()" [ngClass]="{'ng-dirty': !formNuovoProgettoFormativo.get('areaScientifica').valid && (formNuovoProgettoFormativo.get('areaScientifica').dirty || formNuovoProgettoFormativo.get('areaScientifica').touched)}"
                  [options]="areaScientifica1"
                  [placeholder]="areaScientificaName"
                  formControlName="areaScientifica"
                  inputId="areaScientifica"
                  optionLabel="Nome" required></p-dropdown>
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="emailTutorUniversitario">Indirizzo email</label>
      <input [ngClass]="{' ng-dirty': !formNuovoProgettoFormativo.get('emailTutorUniversitario').valid && (formNuovoProgettoFormativo.get('emailTutorUniversitario').dirty || formNuovoProgettoFormativo.get('emailTutorUniversitario').touched)}" formControlName="emailTutorUniversitario"
             id="emailTutorUniversitario"
             pInputText required type="email">
    </div>
    <div class="p-field p-col-12 p-md-4"></div>
    <div class="p-field p-col-12 p-md-12">
      <p-divider align="left">
        <p-button label="Tutor aziendale:" styleClass="p-button-text"></p-button>
      </p-divider>
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="tutorAziendale">Tutor Aziendale</label>
      <p-dropdown (onChange)="detectDataChangeTutorAziendale()" [ngClass]="{'ng-dirty': !formNuovoProgettoFormativo.get('tutorAziendale').valid && (formNuovoProgettoFormativo.get('tutorAziendale').dirty || formNuovoProgettoFormativo.get('tutorAziendale').touched)}" [options]="dipendenti"
                  [placeholder]="tutorAziendaleName"
                  formControlName="tutorAziendale"
                  id="tutorAziendale"
                  inputId="tutorAziendale"
                  optionLabel="alias" required></p-dropdown>
    </div>
    <div class="p-field p-col-12 p-md-4 parent">
      <label for="cellulareTutorAziendale">Cellulare<label class="required-asterisk">*</label></label>

      <div class="block-div">
        <div class="first">
            <input [placeholder]="" formControlName="prefixTutorAziendale"
                   id="prefixTutorAziendale" pInputText readonly>
        </div>
        <div class="last">
          <p-inputMask [ngClass]="{'ng-invalid ng-dirty': !formNuovoProgettoFormativo.get('cellulareTutorAziendale').valid && (formNuovoProgettoFormativo.get('cellulareTutorAziendale').dirty || formNuovoProgettoFormativo.get('cellulareTutorAziendale').touched)}" formControlName="cellulareTutorAziendale"
                       id="cellulareTutorAziendale"
                       mask="9999999999"
                       readonly required></p-inputMask>
        </div>
      </div>
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="emailTutorAziendale">Indirizzo email</label>
      <input [ngClass]="{'ng-invalid ng-dirty': !formNuovoProgettoFormativo.get('emailTutorAziendale').valid && (formNuovoProgettoFormativo.get('emailTutorAziendale').dirty || formNuovoProgettoFormativo.get('emailTutorAziendale').touched)}" formControlName="emailTutorAziendale"
             id="emailTutorAziendale"
             pInputText readonly required type="email">
    </div>

    <div class="p-field p-col-12 p-md-4"></div>
    <div class="p-field p-col-12 p-md-12">
      <p-divider align="left">
        <p-button label="Obiettivi e modalità di tirocinio:" styleClass="p-button-text"></p-button>
      </p-divider>
    </div>
    <div class="p-field p-col-12 p-md-12">
      <textarea [ngClass]="{'ng-dirty': !formNuovoProgettoFormativo.get('obiettivi').valid && (formNuovoProgettoFormativo.get('obiettivi').dirty || formNuovoProgettoFormativo.get('obiettivi').touched)}" formControlName="obiettivi"
                id="obiettivi"
                pInputText required type="text"></textarea>
    </div>
    <div class="p-field p-col-12 p-md-12">
      <p-divider type="dashed"></p-divider>
    </div>
    <div *ngIf="tirocinanteToStore.progettoFormativo==null && !switchToEditMode" class="p-field p-col-12 p-md-2">
      <button (click)="resetForm()" class="p-button p-button-danger" label="Annulla" pButton type="button"></button>
    </div>
    <div *ngIf="tirocinanteToStore.progettoFormativo!=null && switchToEditMode" class="p-field p-col-12 p-md-2">
      <button (click)="closeEditMode()" class="p-button p-button-danger" label="Annulla" pButton type="button"></button>
    </div>
    <div *ngIf="!switchToEditMode" class="p-field p-col-12 p-md-2">
      <button (click)="goBack()" class="p-button p-button-danger" label="Indietro" pButton
              type="button"></button>
    </div>
    <div *ngIf="!switchToEditMode && tirocinanteToStore.progettoFormativo != null && (tirocinanteToStore.stato == 'Attivo' || tirocinanteToStore.stato == 'Sospeso')"
         class="p-field p-col-12 p-md-2">
      <button (click)="switchToEdit()" class="p-button p-button-warning" label="Modifica" pButton
      ></button>
    </div>
    <div *ngIf="switchToEditMode" class="p-field p-col-12 p-md-2">
      <button (click)="modificaProgetto(formDirective, formNuovoProgettoFormativo)" [disabled]="!formNuovoProgettoFormativo.valid" class="p-button p-button-success"
              label="Salva" pButton
      ></button>
    </div>
    <div *ngIf="tirocinanteToStore.progettoFormativo== null && !switchToEditMode" class="p-field p-col-12 p-md-2">
      <button (click)="inviaformNuovoProgettoFormativo()" [disabled]="!formNuovoProgettoFormativo.valid" class="p-button p-button-success" label="Salva"
              pButton
      ></button>
    </div>
  </div>

</form>
<p-message *ngIf="utils.formValid(formNuovoProgettoFormativo).length > 0 && emptyFields().length == 0" @simpleFadeAnimation
           severity="error" text="Attenzione! Campo/i non popolati correttamente"></p-message>
<div class="p-md-12" style="padding: 0px"></div>
<p-message *ngIf="emptyFields().length > 0" @simpleFadeAnimation
           severity="error" text="Attenzione! Campo/i obbligatori non popolati"></p-message>
<p-messages [enableService]="true" key="tableProgettiFormativi"></p-messages>

