<div>

  <app-sidebar-tirocinante></app-sidebar-tirocinante>

  <app-header-tirocinante></app-header-tirocinante>

  <div>
    <router-outlet></router-outlet>
  </div>

</div>
