import {Injectable} from '@angular/core';
import {HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {of} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthenticationService, private router: Router, private spinner: NgxSpinnerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {


    const userData = this.authService.currentLoginRespValue;
    const dateNow = new Date();

    if (userData == null || userData.token == null || !userData.token || userData.token == '' || dateNow > userData.expiringOn) {
      // console.log('ENTRO QUI! TOKEN SCADUTO O NULL');
      return next.handle(req);
    }

    const modifiedRequest = req.clone({headers: this.handleHttpHeader(userData.token)});

    return next.handle(modifiedRequest).pipe(
      catchError(
        (err, caught) => {

          if (err.status === 401) {
            this.handleAuthError();
            return of(err);
          }
          throw err;
        }
      )
    );

  }

  handleHttpHeader(jwtToken: string) {
    return new HttpHeaders({
      'Authorization': 'Bearer ' + jwtToken
    });
  }

  private handleAuthError() {

    localStorage.clear();
    this.router.navigateByUrl('/');
    this.spinner.hide();
  }

}
