<div class="main-area">
  <main>
    <div class="titolo-sezione">
      <i class="las la-user"></i> Inserimento dipendente
    </div>
    <p-divider type="dashed"></p-divider>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <form (ngSubmit)="inviaFormNuovoDipendente()" [formGroup]="formNuovoDipendente">
      <div class="p-fluid p-formgrid p-grid p-jc-end">
        <div class="p-field p-col-12 p-md-3">
          <label for="nome">Nome</label>
          <input [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('nome').valid && (formNuovoDipendente.get('nome').dirty || formNuovoDipendente.get('nome').touched)}" formControlName="nome"
                 id="nome"
                 pInputText required type="text">
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="cognome">Cognome</label>
          <input [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('cognome').valid && (formNuovoDipendente.get('cognome').dirty || formNuovoDipendente.get('cognome').touched)}" formControlName="cognome"
                 id="cognome"
                 pInputText required type="text">

        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="codiceFiscale">Codice fiscale</label>
          <input (input)="onInputCodiceFiscale(formNuovoDipendente.get('codiceFiscale').value)"
                 [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('codiceFiscale').valid && (formNuovoDipendente.get('codiceFiscale').dirty || formNuovoDipendente.get('codiceFiscale').touched)}"
                 formControlName="codiceFiscale"
                 id="codiceFiscale"
                 pInputText
                 required type="text">
          <small *ngIf="!cfValid && startCase" @fadeInAnimation class="p-error" id="cf-help">Codice fiscale non
            valido</small>
          <small *ngIf="cfValid" @fadeInAnimation class="success-text" id="cf-success">Codice fiscale valido</small>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="dataDiNascita">Data di nascita</label>
          <p-calendar [maxDate]="timeUtils.maxDateForBirthDay()"
                      [monthNavigator]="true"
                      [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('dataDiNascita').valid && (formNuovoDipendente.get('dataDiNascita').dirty || formNuovoDipendente.get('dataDiNascita').touched)}"
                      [yearNavigator]="true"
                      [yearRange]="getYearRange()"
                      dateFormat="dd/mm/yy"
                      formControlName="dataDiNascita" required></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-3 parent">
          <label for="prefix">Cellulare<label class="required-asterisk">*</label></label>

          <div class="block-div">
            <div class="first">
              <p-dropdown [options]="country" formControlName="prefix"
                          id="prefix"
                          optionLabel="phonecode" placeholder="+39" styleClass="stileRuoli"></p-dropdown>
            </div>
            <div class="last">
              <p-inputMask [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('cellulare').valid && (formNuovoDipendente.get('cellulare').dirty || formNuovoDipendente.get('cellulare').touched)}" formControlName="cellulare" id="cellulare"
                           mask="9999999999"

                           required></p-inputMask>
            </div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="state">Stato</label>
          <p-dropdown [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('statusSelezionato').valid && (formNuovoDipendente.get('statusSelezionato').dirty || formNuovoDipendente.get('statusSelezionato').touched)}" [options]="statusDip"
                      formControlName="statusSelezionato"
                      inputId="statusSelezionato"
                      optionLabel="name"
                      placeholder="Seleziona" required></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="roles">Ruolo</label>
          <p-dropdown [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('roles').valid && (formNuovoDipendente.get('roles').dirty || formNuovoDipendente.get('roles').touched)}" [options]="userRoles"
                      formControlName="roles"
                      inputId="ruoloSelezionato"
                      optionLabel="name" placeholder="Seleziona" required styleClass="stileRuoli"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="state">Mansione</label>
          <p-dropdown [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('mansione').valid && (formNuovoDipendente.get('mansione').dirty || formNuovoDipendente.get('mansione').touched)}" [options]="mansioni"
                      formControlName="mansione"
                      inputId="mansione"
                      optionLabel="nome" placeholder="Seleziona" required></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="email">Indirizzo email</label>
          <input (cancel)="startEmail=false" (input)="checkEmail(formNuovoDipendente.get('email').value)"
                 [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('email').valid && (formNuovoDipendente.get('email').dirty || formNuovoDipendente.get('email').touched)}"
                 formControlName="email"
                 id="email"
                 pInputText required type="email">
          <small *ngIf="existEmail" @fadeInAnimation class="p-error" id="cf-help">Attenzione! Indirizzo email già in
            uso.</small>

          <!-- <div class="p-inputgroup">
            <input id="email" formControlName="email" type="email" pInputText required>
            <span class="p-inputgroup-addon">@datasound.it</span>
          </div>-->
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="dataInizioRapporto">Data inizio rapporto</label>
          <p-calendar (keypress)="enableAndResetRelatedDateField(formNuovoDipendente.controls['dataInizioRapporto'], formNuovoDipendente.controls['dataFineRapporto'])"
                      (onClose)="onDataInizioChange()"
                      (onSelect)="onDataInizioChange()"
                      [minDate]="initialDateValue"
                      [monthNavigator]="true"
                      [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('dataInizioRapporto').valid && (formNuovoDipendente.get('dataInizioRapporto').dirty || formNuovoDipendente.get('dataInizioRapporto').touched)}"
                      [yearNavigator]="true"
                      [yearRange]="timeUtils.getFutureYearRange()"
                      dateFormat="dd/mm/yy"
                      formControlName="dataInizioRapporto"
                      required></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-4 fix-non-required-inline">
          <label for="dataFineRapporto">Data fine rapporto</label>
          <p-calendar [disabled]="dataFineDisabled"
                      [minDate]="timeUtils.setMinDate(formNuovoDipendente.get('dataInizioRapporto').value)"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      [yearRange]="timeUtils.getFutureYearRange()"
                      dateFormat="dd/mm/yy"
                      formControlName="dataFineRapporto"></p-calendar>

        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="tipoContratto">Tipo contratto</label>
          <p-dropdown [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('tipoContratto').valid && (formNuovoDipendente.get('tipoContratto').dirty || formNuovoDipendente.get('tipoContratto').touched)}" [options]="tipiContratto"
                      formControlName="tipoContratto"
                      inputId="tipoContratto" optionLabel="nome" placeholder="Seleziona" required></p-dropdown>
        </div>

        <div class="p-field p-col-12 p-md-6">
          <label for="oreSettimanali">Ore settimanali</label>
          <input (keydown)="this.utils.disablePointKeyPress($event)" [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('oreSettimanali').valid && (formNuovoDipendente.get('oreSettimanali').dirty || formNuovoDipendente.get('oreSettimanali').touched)}"
                 formControlName="oreSettimanali"
                 id="oreSettimanali"
                 pInputText
                 required type="number">
        </div>

        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <p-button label="In possesso di:" styleClass="p-button-text"></p-button>
          </p-divider>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <div class="p-field-checkbox">
            <p-checkbox binary="true" formControlName="chiaviUfficio" inputId="chiaviUfficio"></p-checkbox>
            <label for="chiaviUfficio">Chiavi ufficio</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox binary="true" formControlName="telefonoAziendale" inputId="telefonoAziendale"></p-checkbox>
            <label for="telefonoAziendale">Telefono aziendale</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox (onChange)="detectComodatoChange($event)" [(ngModel)]="pcAziendaleCheckBox"
                        binary="true" formControlName="pcAziendale" inputId="pcAziendale"></p-checkbox>
            <label for="pcAziendale">Pc aziendale</label>
          </div>
        </div>

        <div *ngIf="pcAziendaleCheckBox" class="p-field p-col-6 p-md-6">
          <label for="serialePc">Seriale Pc</label>
          <input [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('serialePc').valid && (formNuovoDipendente.get('serialePc').dirty || formNuovoDipendente.get('serialePc').touched)}" [required]="pcAziendaleCheckBox || null" formControlName="serialePc"
                 id="serialePc"
                 pInputText type="text">
        </div>
        <div *ngIf="pcAziendaleCheckBox" class="p-field p-col-6 p-md-6">
          <label for="modelloPc">Modello Pc</label>
          <input [ngClass]="{'ng-invalid ng-dirty': !formNuovoDipendente.get('modelloPc').valid && (formNuovoDipendente.get('modelloPc').dirty || formNuovoDipendente.get('modelloPc').touched)}" [required]="pcAziendaleCheckBox || null"
                 formControlName="modelloPc"
                 id="modelloPc" pInputText type="text">
        </div>
        <!--
        <div class="p-field p-col-12 p-md-6">
          <label for="progetti">Assegnazione progetto/i</label>
          <p-multiSelect [options]="progetti" [(ngModel)]="progettiSelezionati" optionLabel="nome" formControlName="progetti"></p-multiSelect>
        </div> -->

        <div class="p-field p-col-12 p-md-12">
          <p-divider type="dashed"></p-divider>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <button (click)="resetForm()" class="p-button p-button-danger" label="Annulla" pButton type="button"></button>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <button [disabled]="!formNuovoDipendente.valid || existEmail" class="p-button p-button-success" label="Salva" pButton
                  type="submit"></button>
        </div>
      </div>
    </form>
    <div>
    <p-message *ngIf="utils.formValid(formNuovoDipendente).length > 0 && emptyFields().length == 0" @simpleFadeAnimation
               severity="error" text="Attenzione! Campo/i non popolati correttamente"></p-message>
    </div>
    <p-message *ngIf="emptyFields().length > 0" @simpleFadeAnimation
               severity="error" text="Attenzione! Campo/i obbligatori non popolati"></p-message>
    <p-messages [enableService]="true"></p-messages>
  </main>
</div>
