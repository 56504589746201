import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../constants/endpoints';
import {MansioneInterface} from '../models/mansione.interface';
import {TipoContrattoInterface} from '../models/tipo-contratto.interface';

@Injectable()
export class MansioniService {

  constructor(private http: HttpClient) {
  }

  getMansioni(): Observable<MansioneInterface[]> {
    return this.http.get<MansioneInterface[]>(Endpoints.GET_ALL_MANSIONI);
  }

  getTipiContratto(): Observable<TipoContrattoInterface[]> {
    return this.http.get<TipoContrattoInterface[]>(Endpoints.GET_ALL_TIPI_CONTRATTO);
  }

}
