import {Component, HostListener, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {StatisticheService} from 'src/app/services/statistiche.service';
import {MonthOverview} from 'src/app/models/month-overview';
import {HttpErrorResponse} from '@angular/common/http';
import {MessageService} from 'primeng/api';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {NgxSpinnerService} from 'ngx-spinner';
import {AnalyticsOverview} from 'src/app/models/analytics/analytics-overview';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(10px)'}),
        animate('1s', style({opacity: 1, transform: 'translateY(0)'})),
      ]),
      transition(':leave', [
        animate('1s', style({opacity: 0, transform: 'translateY(10px)'})),
      ]),
    ])
  ],
  providers: [StatisticheService]
})
export class AdminComponent implements OnInit {

  data: any;

  lastThreeMonthsData: AnalyticsOverview;
  monthOverview: MonthOverview;
  lastSixMonthsData: AnalyticsOverview;

  monthOverviewLoaded: Promise<boolean>;
  lastThreeMonthsOverviewLoaded: Promise<boolean>;
  lastSixMonthsOverviewLoaded: Promise<boolean>;


  constructor(private statisticheService: StatisticheService, private messageService: MessageService, private spinner: NgxSpinnerService) {
    this.spinner.show();
    this.statisticheService.getMonthOverview().subscribe(monthOverview => {
      // console.log(monthOverview);
      this.monthOverview = monthOverview;

      this.monthOverviewLoaded = Promise.resolve(true);
      this.spinner.hide();
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_ERROR,
        detail: errorResponse.error.message
      });
    });
    this.spinner.show();
    this.statisticheService.getLastThreeMonths().subscribe(data => {
      this.lastThreeMonthsData = data;
      this.lastThreeMonthsOverviewLoaded = Promise.resolve(true);
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_ERROR,
        detail: errorResponse.error.message
      });
    });

    this.statisticheService.getLastSixMonths().subscribe(data => {
      this.lastSixMonthsData = data;
      this.lastSixMonthsOverviewLoaded = Promise.resolve(true);
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_ERROR,
        detail: errorResponse.error.message
      });
    });


  }

  ngOnInit() {
  }


  stringHeight(intValue, stringPart = 'px'): string {
    return intValue + stringPart;
  }

}
