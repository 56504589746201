<div class="main-area">
  <main>
    <div class="riepilogo">
      <div class="riepilogo-single p-shadow-3">
        <div>
          <p>TOTALE</p>
          <h4>{{librettoDiario.totaleOre}}</h4>
          <span>ore lavorate</span>
        </div>
        <div>
          <span class="las la-user-clock"></span>
        </div>
      </div>
      <div class="riepilogo-single p-shadow-3">
        <div>
          <p>NOTE</p>
          <h5 class="print-note">{{librettoDiario.note}}</h5>
        </div>
        <div>
          <span class="las la-info"></span>
        </div>
      </div>
    </div>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p> Caricamento... </p>
    </ngx-spinner>
    <p-toast position="bottom-right"></p-toast>
    <div class="calendar-month">
      <section class="calendar-month-header">
        <div class="selected-month" id="selected-month">
          <i (click)="loadMonthDetail(month-1, year)" *ngIf="month > librettoDiario.dataInizioTirocinio.getMonth() || year>librettoDiario.dataInizioTirocinio.getFullYear()"
             class="las la-chevron-left"></i>
          &nbsp; <i
          class="las la-calendar"></i> {{meseAnno}} &nbsp; <i (click)="loadMonthDetail(month+1, year)"
                                                              *ngIf="month < librettoDiario.dataFineTirocinio.getMonth() || year< librettoDiario.dataFineTirocinio.getFullYear()"
                                                              class="las la-chevron-right"></i>
        </div>
        <br>
        <div class="riepilogo">
          <div class="riepilogo-single p-shadow-3">
            <div>
              <p>TOTALE</p>
              <h4>{{monthDetail.oreMensili}}</h4>
              <span>ore mensili</span>
            </div>
            <div>
              <span class="las la-user-clock"></span>
            </div>
          </div>
          <div class="riepilogo-single p-shadow-3">
            <div>
              <p>STATO</p>
              <h4>
                <p-tag [severity]="utils.statoLibrettoStyle(librettoDiario.statoTirocinio.nome)"
                       [value]="librettoDiario.statoTirocinio.nome"></p-tag>
              </h4>
              <span *ngIf="librettoDiario.richiestaSblocco">Hai richiesto lo sblocco</span>
            </div>
            <div>
              <span class="las la-calendar-check"></span>
            </div>
          </div>
        </div>
        <div *ngIf="monthDetail" class="p-grid p-mt-1">
        </div>
      </section>

      <ol class="day-of-week" id="days-of-week">
        <li>Lun</li>
        <li>Mar</li>
        <li>Mer</li>
        <li>Gio</li>
        <li>Ven</li>
        <li class="festivita">Sab</li>
        <li class="festivita">Dom</li>
      </ol>
      <ol class="days-grid" id="calendar-days">
        <li *ngFor="let giornoDaDisabilitare of giorniDaDisabilitare" class="calendar-day calendar-day--not-current">
          <span></span>
        </li>
        <li (click)="getData(giorno)" *ngFor="let giorno of daysCurrentMonth; let i = index" class="calendar-day">
          <span [class]="'giorno ' + (giorno.giornoSettimana == 6 || giorno.giornoSettimana == 0 || giorniFestivi.includes(giorno, 0) ? 'festivita' : '')">{{
            giorno.numeroGiorno }}</span>

          <span *ngIf="giorno.totaleOreGiornata !== checkHours && giorno.totaleOreGiornata"
                class=" parzialeOre"> {{giorno.totaleOreGiornata}}h </span>
          <span *ngFor="let act of giorno.activities; let i = index" class="monteOre">
           {{ act.oraInizio }} - {{act.oraFine}}
          </span>


        </li>
      </ol>
    </div>


    <div *ngIf="librettoDiario" class="p-fluid p-formgrid p-grid p-mt-4 p-jc-end">
      <div (click)="inviaLibretto()"
           *ngIf="utils.renderPulsanteInviaLibretto(this.librettoDiario.richiestaSblocco, this.librettoDiario.statoTirocinio.id)"
           class="p-field p-col-12 p-md-2">
        <button class="p-button p-button-success" label="Concludi" pButton type="button"></button>

      </div>
      <div *ngIf="utils.renderEsporta(this.librettoDiario.richiestaSblocco, this.librettoDiario.statoTirocinio.id)"
           class="p-field p-col-12 p-md-2"
      >
        <button (click)="exportToPDF()" class="p-button p-button-primary" label="Esporta pdf"
                pButton type="button"></button>
      </div>
    </div>

    <!--                        -->

    <p-dialog (onHide)="displayModalActivity=false"
              *ngIf="selectedDay" [(visible)]="displayModalActivity" [draggable]="false" [header]="'Attività del ' + (selectedDay.dataCompleta | date:'dd/MM/YYYY')" modal="true">

      <div *ngIf="mostraRiepilogo">

        <p-table [style]="{width: '80vw'}" [value]="selectedDay.activities">
          <ng-template pTemplate="header">
            <tr>
              <th class="time" scope="col">Ora inizio</th>
              <th class="time" scope="col">Ora fine</th>
              <th class="description" scope="col">Descrizione</th>
            </tr>
          </ng-template>
          <ng-template let-activity pTemplate="body">
            <tr>

              <td class="time">{{activity.oraInizio}}</td>
              <td class="time">{{ activity.oraFine }}</td>
              <td (click)="showModalDialog(activity.descrizione)" class="description">{{activity.descrizione}}</td>
            </tr>
          </ng-template>
          <p-confirmPopup></p-confirmPopup>
        </p-table>

        <div class="p-col-12 totOre">
          Totale ore: {{ selectedDay.totaleOreGiornata }}
        </div>

        <p-dialog [(visible)]="displayModalInfo" [draggable]="false" [style]="{width: '50vw'}" header="Descrizione">
          <p>{{descrizione}}</p>

        </p-dialog>
      </div>
    </p-dialog>

    <!--                         -->


    <p-dialog (onHide)="resetForm()"
              *ngIf="selectedDay" [(visible)]="displayModal" [draggable]="false" [header]="'Inserimento attività ' + (selectedDay.dataCompleta | date:'dd/MM/YYYY')" modal="true">

      <form (ngSubmit)="salvaDettaglioGiorno(selectedDay)" [formGroup]="formNuovoGiorno">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-4 p-md-4">
            <label for="oraInizio" required>Ora inizio</label>

            <p-calendar #initialHour (click)="startDate=false; busyHours=false" (ngModelChange)="checkHour($event)" [(ngModel)]="oraInizio"
                        [defaultDate]="inizioDafault" [stepHour]="1" [stepMinute]="5" [timeOnly]="true"
                        class="timer" dateFormat="HH:mm" formControlName="oraInizio"
                        hourFormat="24" readonlyInput="true"></p-calendar>
          </div>
          <div class="p-field p-col-4 p-md-4">
            <label for="oraFine" required>Ora fine</label>
            <p-calendar #finalHour (click)="startDate=true; busyHours=false" (ngModelChange)="checkHour($event)" [(ngModel)]="oraFine" [defaultDate]="finalHourDefault()"
                        [stepHour]="1" [stepMinute]="5" [timeOnly]="true"
                        class="timer" dateFormat="HH:mm" formControlName="oraFine"
                        hourFormat="24" readonlyInput="true"></p-calendar>
          </div>
          <div class="p-field p-col-12 descr">
            <label for="descrizione" required>Descrizione</label>
            <textarea class="descrizione" formControlName="descrizione" id="descrizione" pInputText
                      type="text"></textarea>
          </div>

          <div class="p-field p-col-3 p-md-3 p-offset-6">
            <button (click)="resetForm()" class="p-button p-button-danger" label="Annulla" pButton type="button"
            ></button>
          </div>
          <div class="p-field p-col-3 p-md-3">
            <button [disabled]="!formNuovoGiorno.valid || incompatibleHour" class="p-button p-button-success" label="Aggiungi"
                    pButton type="submit"></button>
          </div>
        </div>
      </form>
      <p-message *ngIf="incompatibleHour  && startDate" @simpleFadeAnimation
                 severity="error" text="Attenzione! Campo/i non compilati correttamente"></p-message>
      <p-message *ngIf="busyHours" @simpleFadeAnimation
                 severity="error" text="Attenzione! Nell'orario indicato hai precedentemente inserito un'attività"></p-message>
      <p-message *ngIf="!busyHours && hoursExceeded"
                 @simpleFadeAnimation
                 severity="error" text="Attenzione! Per il giorno selezionato è stato raggiunto il numero massimo di ore lavorabili."></p-message>

      <p-messages [enableService]="true"></p-messages>
      <div *ngIf="mostraRiepilogo">
        <div class="p-field p-col-12 p-md-12">
          <h3>Riepilogo</h3>
          <p-divider type="dashed"></p-divider>
        </div>
        <p-table [style]="{width: '80vw'}" [value]="selectedDay.activities">
          <ng-template pTemplate="header">
            <tr>
              <th class="button" scope="col"></th>
              <th class="time" scope="col">Ora inizio</th>
              <th class="time" scope="col">Ora fine</th>
              <th class="description" scope="col">Descrizione</th>
            </tr>
          </ng-template>
          <ng-template let-activity pTemplate="body">
            <tr>
              <td class="button">
                <button (click)="deleteOneDayDetail($event, activity.id,monthDetail.id, selectedDay.id)" class="p-button p-button-text p-button-danger" icon="pi pi-trash" pButton
                        type="button"></button>
              </td>
              <td class="time">{{activity.oraInizio}}</td>
              <td class="time">{{ activity.oraFine }}</td>
              <td (click)="showModalDialog(activity.descrizione)" class="description">{{activity.descrizione}}</td>
            </tr>
          </ng-template>
          <p-confirmPopup></p-confirmPopup>
        </p-table>

        <div class="p-col-12 totOre">
          Totale ore: {{ selectedDay.totaleOreGiornata }}
        </div>

        <p-dialog [(visible)]="displayModalInfo" [draggable]="false" [style]="{width: '50vw'}" header="Descrizione">
          <p>{{descrizione}}</p>

        </p-dialog>
      </div>
      <p-divider type="none"></p-divider>
      <div *ngIf="selectedDay.activities" class="p-grid">
        <div class="p-col-12">
          <button (click)="deleteWorkingDay(selectedDay.id, monthDetail.id)" [label]="'Elimina tutte le attività del '+(selectedDay.dataCompleta | date:'dd/MM/YYYY')"
                  class="p-button p-button-outlined p-button-sm p-button-danger"
                  pButton
                  type="button"></button>
        </div>
      </div>
    </p-dialog>

  </main>
</div>

