<div class="main-area">
  <main>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <div *ngIf="!monthOverviewLoaded" class="p-grid">
      <div class="p-col-12 p-text-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </div>
    <div *ngIf="monthOverviewLoaded" @fadeSlideInOut class="cards">
      <div class="card-single">
        <div>
          <h1>{{ monthOverview.totaleOreLavorate }}</h1>
          <span>Ore lavorate</span>
          <p>{{ monthOverview.variazioneOreMesePrecedente > 0 ? '+' + (monthOverview.variazioneOreMesePrecedente | number: '1.2-2') : (monthOverview.variazioneOreMesePrecedente | number: '1.2-2') }}
            % risp. mese precedente</p>
        </div>
        <div>
          <span class="las la-clock"></span>
        </div>
      </div>
      <div class="card-single">
        <div>
          <h1>{{ monthOverview.numeroRapportiniDaApprovare == null ? 0 : monthOverview.numeroRapportiniDaApprovare }}</h1>
          <span>Timesheets</span>
          <p>da approvare</p>
        </div>
        <div>
          <span class="las la-calendar"></span>
        </div>
      </div>
      <div class="card-single">
        <div>
          <h1>{{ monthOverview.numeroProgettiAttivi }}</h1>
          <span>Progetti</span>

          <p>{{ monthOverview.variazioneNumeroProgettiMesePrecedente > 0 ? '+' + (monthOverview.variazioneNumeroProgettiMesePrecedente | number: '1.2-2' | zeroInfinity) : (monthOverview.variazioneNumeroProgettiMesePrecedente | number: '1.2-2' | zeroInfinity) }}
            % risp. mese precedente</p>
        </div>
        <div>
          <span class="las la-toolbox"></span>
        </div>
      </div>
      <div class="card-single">
        <div>
          <h1>{{ monthOverview.numeroDipendentiCorrenti }}</h1>
          <span>Dipendenti</span>

          <p>{{ monthOverview.variazioneNumeroDipendenti > 0 ? '+' + (monthOverview.variazioneNumeroDipendenti | number: '1.2-2') : (monthOverview.variazioneNumeroDipendenti | number: '1.2-2') }}
            % risp. mese precedente</p>
        </div>
        <div>
          <span class="las la-users"></span>
        </div>
      </div>
    </div>
    <!-- <p-divider type="dashed" align="center">
      <span>Ultimi 6 mesi</span>
    </p-divider>
    <p-chart type="line" [data]="data"></p-chart> -->
    <div class="p-grid">
      <div class="p-col-12 p-md-6 p-text-center">
        <p-divider align="center" type="dashed">
          <p-button label="Ultimi 3 mesi" styleClass="p-button-text"></p-button>
        </p-divider>
        <p-progressSpinner *ngIf="!lastThreeMonthsOverviewLoaded"></p-progressSpinner>
        <div>
          <p-chart *ngIf="lastThreeMonthsOverviewLoaded" [data]="lastThreeMonthsData" type="bar" [height]="stringHeight(((lastThreeMonthsData.datasets.length / 4) + 20), 'rem')"></p-chart> <!-- assuming there's an average of 4 projects per row you sum that by the desired canvas size -->
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-text-center">
        <p-divider align="center" type="dashed">
          <p-button label="Ultimi 6 mesi" styleClass="p-button-text"></p-button>
        </p-divider>
        <p-progressSpinner *ngIf="!lastSixMonthsOverviewLoaded"></p-progressSpinner>
        <div>
          <p-chart *ngIf="lastSixMonthsOverviewLoaded" [data]="lastSixMonthsData" type="bar" [height]="stringHeight(((lastSixMonthsData.datasets.length / 4) + 20), 'rem')"></p-chart>
        </div>
      </div>
    </div>
  </main>


</div>
