import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'zeroInfinity'
})
export class ZeroInfinityPipe implements PipeTransform {
  transform(value) {
    if (value == 'Infinity' || value == Infinity) {
      return '&infin;';
    }
    if (value == null) {
      return 0;
    }
    return value;
  }
}
