import {StatoRapportino} from './stato-rapportino.interface';
import {Stato} from './stato.enum';

export const STATI_RAPPORTINO: StatoRapportino[] = [
  {id: Stato.APPROVATO, nome: 'Approvato'},
  {id: Stato.CONSUNTIVATO, nome: 'Consuntivato'},
  {id: Stato.IN_APPROVAZIONE, nome: 'In approvazione'},
  {id: Stato.IN_LAVORAZIONE, nome: 'In lavorazione'}

];
