import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {StorageConstants} from 'src/app/constants/storage-constants';
import {ProgettoInterface} from 'src/app/models/progetto.interface';
import {RiepilogoTimesheet} from 'src/app/models/timesheet/riepilogo-timesheet.interface';
import {Stato} from 'src/app/models/timesheet/stato.enum';
import {TimesheetManagementInterface} from 'src/app/models/timesheet/timesheet-management-request';
import {TimesheetInterface} from 'src/app/models/timesheet/timesheet.interface';
import {WorkingDayInterface} from 'src/app/models/timesheet/working-day.interface';
import {UserInterface} from 'src/app/models/user-interface';
import {ProgettiService} from 'src/app/services/progetti.service';
import {TimesheetService} from 'src/app/services/timesheet.service';
import {UserService} from 'src/app/services/user.service';
import GenericUtils from 'src/app/utils/generic-utils';
import TimeUtils from 'src/app/utils/time-utils';
import {TipologiaRapportino} from '../../../models/timesheet/tipologia-rapportino';
import {TIPOLOGIA_RAPPORTINO_MOCK} from '../../../mock/tipologia-rapportino.mock';
import {GiorniFestiviService} from '../../../services/giorni-festivi.service';

@Component({
  selector: 'app-vista-timesheet',
  templateUrl: './vista-timesheet.component.html',
  styleUrls: ['./vista-timesheet.component.scss'],
  providers: [ConfirmationService]
})
export class VistaTimesheetComponent {

  timesheetId: string;
  daysCurrentMonth: any;
  hoursExceeded: boolean;

  giorniDaDisabilitare: any;
  giorniFestivi: any;
  timeUtils = TimeUtils;
  utils = GenericUtils;
  timesheet: TimesheetInterface;
  meseAnno: string;
  progetti: ProgettoInterface[] = [];
  oreProgettiByTimesheetId: RiepilogoTimesheet[] = [];
  dipendente: UserInterface;
  dataLoaded: Promise<boolean>;
  formTimesheetStatus: FormGroup;
  userData = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
  mostraRiepilogo: boolean;
  getAllTimesheetsB: boolean;

  formNuovoInserimento: FormGroup;
  displayModal: boolean;
  selectedDay: any;

  tipologiaRapportino: TipologiaRapportino[] = TIPOLOGIA_RAPPORTINO_MOCK;

  dipendentePreviuos: string;
  mesePreviuos: string;
  annoPreviuos: string;
  statoRapportinoPreviuos: string;


  showProjectDiv = false;
  requiredHour = true;
  requiredDescrizione = true;
  daysActivities: any;

  descrizione: string;
  displayModalInfo: boolean;
  displayModalActivity: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private timesheetService: TimesheetService,
              private messageService: MessageService,
              private progettiService: ProgettiService,
              private spinner: NgxSpinnerService,
              private userService: UserService,
              private fb: FormBuilder,
              private confirmationService: ConfirmationService,
              private giorniFestiviService: GiorniFestiviService,
              private router: Router) {
    this.hoursExceeded = false;
    this.activatedRoute.params.subscribe(
      (param) => {
        this.mesePreviuos = this.router.getCurrentNavigation().extras.state.mese;
        this.annoPreviuos = this.router.getCurrentNavigation().extras.state.anno;
        this.statoRapportinoPreviuos = this.router.getCurrentNavigation().extras.state.statoRapportino;
        this.getAllTimesheetsB = this.router.getCurrentNavigation().extras.state.getAllTimesheetsB;
      });

    this.messageService.clear();
    this.loadTimesheet();
    this.spinner.show();
    this.progettiService.getProgetti_v2().subscribe(progetti => {
      progetti.forEach((progetto) => {
        if (progetto.status === 'Attivo') {
          this.progetti.push(progetto);
        }
      });
      this.progetti.sort((a, b) => a.nome.localeCompare(b.nome));
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }

  riepilogo(activity): string {
    let nomeAttivita = '';
    if (activity.nome !== TipologiaRapportino.MALATTIA && activity.nome !== TipologiaRapportino.ROL &&
      activity.nome !== TipologiaRapportino.FERIE && activity.nome !== TipologiaRapportino.BANCAORE) {
      nomeAttivita = activity.nome;
    } else {
      nomeAttivita = '-';
    }
    return nomeAttivita;
  }

  tipologia(activity): string {
    let riepilogo = '';
    if (activity.nome !== TipologiaRapportino.MALATTIA && activity.nome !== TipologiaRapportino.ROL &&
      activity.nome !== TipologiaRapportino.FERIE && activity.nome !== TipologiaRapportino.BANCAORE) {
      riepilogo = 'Progetto';
    } else {
      riepilogo = activity.nome;
    }
    return riepilogo;
  }

  totHour(day): number {
    let total = 0;
    for (const activity of day.activities) {
      total = activity.parzialeOre + total;
    }
    for (const activity of day.extras) {
      total = activity.parzialeOre + total;
    }
    return total;
  }

  loadTimesheet() {
    this.spinner.show();
    this.timesheetId = this.activatedRoute.snapshot.params.id;
    this.timesheetService.findTimesheetById_v2(this.timesheetId, this.userData.userId).subscribe(timesheet => {
      this.timesheet = timesheet;

      this.daysCurrentMonth = this.timeUtils.getDaysArray_v2(timesheet.anno, timesheet.mese - 1);
      this.meseAnno = moment('' + timesheet.anno + '-' + this.timeUtils.prepareMonthForMoment(timesheet.mese - 1) + '-01').locale('it').format('MMMM YYYY');

      this.createForm();
      if (this.daysCurrentMonth[0].giornoSettimana == 0) {
        this.giorniDaDisabilitare = Array.from(Array(this.daysCurrentMonth[0].giornoSettimana + 6), (x, i) => i);
      } else {
        this.giorniDaDisabilitare = Array.from(Array(this.daysCurrentMonth[0].giornoSettimana - 1), (x, i) => i);
      }
      this.timeUtils.mergeWorkedDaysWithCalendar_v2(this.timesheet, this.daysCurrentMonth);

      this.giorniFestivi = this.giorniFestiviService.listMonthHolydays(this.daysCurrentMonth);

      this.timesheetService.riepilogoOreProgettiByTimesheetId(this.timesheet.id, this.timesheet.user_id).subscribe(oreRiepilogo => {
        this.oreProgettiByTimesheetId = oreRiepilogo;
        this.calculateActivitiesHours();
      }, error => {
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
      });

      this.userService.getOneUser(this.timesheet.user_id).subscribe(dipendente => {
        this.dipendente = dipendente;
        this.spinner.hide();
        this.dataLoaded = Promise.resolve(true);
      }, error => {
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
      });

    }, error => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }

  createForm() {
    let validators;
    let ore = Validators.required;
    if (this.showProjectDiv) {
      validators = Validators.required;
    }
    if (this.requiredHour == false) {
      ore = Validators.nullValidator;
    }
    this.formNuovoInserimento = this.fb.group({

      tipologiaSelezionata: ['', Validators.required],
      parzialeOre: new FormControl({value: ''},
        [ore, Validators.pattern('^[0-9]*$'), Validators.maxLength(2),
          RxwebValidators.minNumber({value: Constants.ACTIVITY_MIN_VALUE}),
          RxwebValidators.maxNumber({value: Constants.ACTIVITY_MAX_VALUE})]),
      progettoSelezionato: ['', validators],
      // note: ['']
      descrizione: ['', [Validators.required, Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE_NOTE)]]
    });
  }

  getData(giorno) {
      this.formNuovoInserimento.reset();
      this.messageService.clear();
      this.selectedDay = giorno;
      if (this.timesheet.statoRapportino.id == Stato.IN_APPROVAZIONE) {
        this.messageService.clear();
        if (this.timesheet.richiestaSblocco == true) {
          this.messageService.add(FrontendMessage.TIMESHEET_WAITING_UNLOCK_MSG);
        } else {
          this.messageService.add(FrontendMessage.TIMESHEET_LOCKED_MSG);
        }
        if (giorno.activities != undefined) {
          this.mostraRiepilogo = true;
          this.displayModalActivity = true;
          // console.log(this.displayModalActivity);
        }
      } else if (this.timesheet.statoRapportino.id == Stato.APPROVATO || this.timesheet.statoRapportino.id == Stato.CONSUNTIVATO) {
        this.messageService.clear();
        this.messageService.add(FrontendMessage.TIMESHEET_LOCKED_APPROVED_MSG);
        if (giorno.activities != undefined) {
          this.mostraRiepilogo = true;
          this.displayModalActivity = true;
          // console.log(this.displayModalActivity);
        }
      } else {
        if (giorno.activities != undefined) {
          this.mostraRiepilogo = true;
          // this.formNuovoInserimento.patchValue({note: giorno.note});
        } else {
          this.mostraRiepilogo = false;
        }
        this.displayModal = true;

      }
  }

  resetForm() {
    this.formNuovoInserimento.reset();
    this.showProjectDiv = false;
    this.displayModalActivity = false;
    this.displayModalInfo = false;
    this.displayModal = false;
    this.messageService.clear();
    this.requiredHour = true;
    this.requiredDescrizione = true;
    this.hoursExceeded = false;
  }

  simulateCloseClick() {
    this.resetForm();
    const htmlElement: HTMLElement = document.getElementsByClassName('p-dialog-header-icon p-dialog-header-close p-link p-ripple ng-star-inserted')[0] as HTMLElement;
    htmlElement.click();
    this.showProjectDiv = false;
  }

  salvaDettaglioGiorno() {
    this.spinner.show();
    const parzialeOre = this.formNuovoInserimento.get('parzialeOre').value;
    const dataCompleta = this.selectedDay.dataCompleta.getFullYear() + '-' + (this.selectedDay.dataCompleta.getMonth() + 1) + '-' + this.selectedDay.dataCompleta.getDate();
    const tipoGiornataId = this.formNuovoInserimento.get('tipologiaSelezionata').value.codice;
    const descrizione = this.formNuovoInserimento.get('descrizione').value;
    let progettoId;
    if (this.formNuovoInserimento.get('progettoSelezionato').value === null) {
      progettoId = null;
    } else {
      progettoId = this.formNuovoInserimento.get('progettoSelezionato').value.id;
    }
    const dayDetails = [
      {
        progettoId,
        parzialeOre,
        descrizione
      }
    ];

    const dayDetailsExtraProjects = [
      {
        tipoGiornataId,
        parzialeOre,
        descrizione
      }
    ];
    const workingDay: WorkingDayInterface = {
      dataCalendario: new Date(Date.UTC(this.selectedDay.dataCompleta.getFullYear(), this.selectedDay.dataCompleta.getMonth(), this.selectedDay.dataCompleta.getDate())),
      // note: this.formNuovoInserimento.get('note').value,
      dayDetails,
      dayDetailsExtraProjects,
      timesheetId: this.timesheet.id,
      tipoGiornataId
    };
    let sumHour = 0;
    if (this.selectedDay.activities != undefined || this.selectedDay.extras != undefined) {
      for (const activity of this.selectedDay.activities) {
        sumHour += activity.parzialeOre;
      }
      for (const extras of this.selectedDay.extras) {
        sumHour += extras.parzialeOre;
      }

      sumHour = sumHour + parzialeOre;
    } else {

      sumHour = parzialeOre;
    }
    if (sumHour <= 24) {
      this.hoursExceeded = false;

      this.timesheetService.newTimesheetActivity(workingDay).subscribe(response => {

        this.loadTimesheet();
        this.displayModal = false;
        this.displayModalInfo = false;
        this.showProjectDiv = false;
        this.spinner.hide();
        this.messageService.add(FrontendMessage.INSERIMENTO_ATTIVITA);
      }, error => {
        this.spinner.hide();
        this.displayModal = false;
        this.displayModalInfo = false;
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
      });
    } else {
      this.spinner.hide();
      this.hoursExceeded = true;
    }
  }

  inviaRapportino() {
    this.spinner.show();
    const request: TimesheetManagementInterface = {
      timesheetId: this.timesheet.id,
      userId: this.timesheet.user_id
    };
    this.timesheetService.inviaRapportino(request).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.loadTimesheet();
        this.spinner.hide();
        this.messageService.add(FrontendMessage.RAPPORTINO_SENT_MSG);
      } else {
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
      }
    }, error => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }

  inviaRichiestaSblocco() {
    this.spinner.show();
    const request: TimesheetManagementInterface = {
      timesheetId: this.timesheet.id,
      userId: this.timesheet.user_id
    };
    this.timesheetService.richiestaSblocco(request).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.loadTimesheet();
        this.spinner.hide();
        this.messageService.add(FrontendMessage.TIMESHEET_UNLOCK_REQUEST_MSG);
      } else {
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
      }
    }, error => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }

  deleteWorkingDay(workingDayId, timesheetId) {
    this.spinner.show();
    this.timesheetService.deleteWorkingDay(workingDayId, timesheetId, this.userData.userId).subscribe(response => {
      this.displayModal = false;
      this.displayModalInfo = false;
      this.loadTimesheet();
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_OK);
    }, error => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }

  goback() {
    const navigationExtras: NavigationExtras = {
      state: {
        anno: this.annoPreviuos,
        mese: this.mesePreviuos,
        statoRapportino: this.statoRapportinoPreviuos,
        getAllTimesheetsB: this.getAllTimesheetsB
      },
    };
    this.router.navigate(['/dipendente/storico'], navigationExtras);
  }

  onChange(event) {
    if (event.value.codice === 0) {
      this.showProjectDiv = true;
      this.requiredDescrizione = true;
      this.formNuovoInserimento.controls['progettoSelezionato'].setValidators([Validators.required]);
      this.formNuovoInserimento.controls['descrizione'].setValidators([Validators.required, Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE_NOTE)]);
      this.formNuovoInserimento.controls['progettoSelezionato'].updateValueAndValidity();
      this.formNuovoInserimento.controls['descrizione'].updateValueAndValidity();
    } else {
      this.showProjectDiv = false;
      this.requiredDescrizione = false;
      this.formNuovoInserimento.controls['progettoSelezionato'].clearValidators();
      this.formNuovoInserimento.controls['descrizione'].clearValidators();
      this.formNuovoInserimento.controls['progettoSelezionato'].updateValueAndValidity();
      this.formNuovoInserimento.controls['descrizione'].updateValueAndValidity();
    }
    if (event.value.codice === 4) {
      this.requiredHour = false;
    } else {
      this.requiredHour = true;
    }
  }

  calculateActivitiesHours() {
    this.daysActivities = {};
    this.daysActivities.activities = {};
    this.daysActivities.extra = {};
    for (const day of this.timesheet.workingDays) {
      this.daysActivities.activities[(day.dataCalendario as any)] = {};
      for (const [key, value] of Object.entries(day.dayDetails)) {
        if (!this.daysActivities.activities[(day.dataCalendario as any)][(value as any).progetto.nome]) {
          this.daysActivities.activities[(day.dataCalendario as any)][(value as any).progetto.nome] = {
            nome: '',
            ore: 0
          };
        }
        this.daysActivities.activities[(day.dataCalendario as any)][(value as any).progetto.nome].nome = (value as any).progetto.nome;
        this.daysActivities.activities[(day.dataCalendario as any)][(value as any).progetto.nome].ore += value.parzialeOre;
      }
      this.daysActivities.extra[(day.dataCalendario as any)] = {};
      for (const [key, value] of Object.entries(day.dayDetailsExtraProjects)) {
        if (!this.daysActivities.extra[(day.dataCalendario as any)][(value as any).tipoGiornata.nome]) {
          this.daysActivities.extra[(day.dataCalendario as any)][(value as any).tipoGiornata.nome] = {
            nome: '',
            ore: 0
          };
        }
        this.daysActivities.extra[(day.dataCalendario as any)][(value as any).tipoGiornata.nome].nome = (value as any).tipoGiornata.nome;
        this.daysActivities.extra[(day.dataCalendario as any)][(value as any).tipoGiornata.nome].ore += value.parzialeOre;
      }
    }
    this.daysActivities.activities = this.convertActivityObjToArray(this.daysActivities.activities);
    this.daysActivities.extra = this.convertActivityObjToArray(this.daysActivities.extra);
  }

  convertDayToActivityIndex(dataCompleta: any) {
    return TimeUtils.convertDayToActivityIndex(dataCompleta);
  }

  convertActivityObjToArray(obj: any) {
    let temp = {}
    for (const dataunicakey of Object.keys(obj)) {
      const dataunica = obj[dataunicakey];
      let arr = [];
      for (const key of Object.keys(dataunica)) {
        arr.push({nome: key, ore: dataunica[key].ore});
      }
      temp[dataunicakey] = arr.sort((a, b) => (a.ore < b.ore) ? -1 : 1);
    }
    return temp;
  }

  showModalDialog(descrizione) {
    this.descrizione = descrizione;
    this.displayModalInfo = true;
  }

  deleteOneDayDetail(event: Event, workingDayId, timesheetId, activityId, tipoGiornataId) {
    this.messageService.clear();
    this.confirmationService.confirm({
      target: event.target,
      message: 'Attenzione! Sei sicuro di voler eliminare l\'attività selezionata?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: FrontendMessage.CONFERMA,
      rejectLabel: FrontendMessage.ANNULLA,
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.spinner.show();
        // let activity;
        let tipoGiornata;
        if (tipoGiornataId === undefined) {
          // activity = activityId;
          tipoGiornata = 0;
        } else {
          // activity = tipoGiornataId;
          tipoGiornata = 1;
        }
        this.timesheetService.deleteOneDayDetail(workingDayId, timesheetId, activityId, tipoGiornata, this.userData.userId).subscribe(response => {
          this.displayModal = false;
          this.displayModalInfo = false;
          this.loadTimesheet();
          this.spinner.hide();
          this.messageService.add(FrontendMessage.DELETE_ACTIVITY_MSG);
          setTimeout(() => {
            this.messageService.clear();
          }, 2000);
        }, error => {
          this.spinner.hide();
          this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
        });
      }, reject: () => {}
    });
  }
}
