<div class="main-area">
  <main>
    <div class="titolo-sezione">
      <i class="las la-donate"></i> Buste paga
    </div>
    <p-divider type="none">
    </p-divider>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <form (ngSubmit)="doSearch()" [formGroup]="formRicerca">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-3 p-md-3">
          <label for="mese">Mese</label>
          <p-dropdown [options]="mesi" formControlName="meseSelezionato" inputId="meseSelezionato" optionLabel="nome"
                      optionValue="codice" placeholder="Seleziona">
          </p-dropdown>
        </div>
        <div class="p-field p-col-3 p-md-3">
          <label for="anno">Anno</label>
          <p-dropdown [options]="anni" formControlName="annoSelezionato" inputId="annoSelezionato" optionLabel="nome"
                      optionValue="codice" placeholder="Seleziona">
          </p-dropdown>
        </div>
        <div class="p-field p-col margine-sup">
          <button class="p-button p-button-primary" label="Filtra" pButton type="submit"></button>
        </div>
        <div class="p-field p-col margine-sup">
          <button (click)="resetSearch()" class="p-button p-button-secondary" label="Annulla" pButton
                  type="button"></button>
        </div>
      </div>
      <!--  <div class="p-field p-col-12 p-md-12">
         <div class="p-field-checkbox">
           <p-checkbox formControlName="tredicesima" binary="true" inputId="binary"></p-checkbox>
           <label for="tredicesima">Tredicesima</label>
         </div>
       </div>-->
    </form>

    <p-table
      *ngIf="showTable && bustePagaFiltered.length > 0"
      [autoLayout]="true"
      [paginator]="true"
      [rowHover]="true"
      [rowsPerPageOptions]="[10,20,30]" [rows]="10" [showCurrentPageReport]="true"
      [totalRecords]="bustePagaFiltered.length"
      [value]="bustePagaFiltered"
      currentPageReportTemplate="Risultati da {first} a {last}. Totale {totalRecords} busta/e paga"
      sortOrder="-1"
      styleClass="p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>

          <th class="p-text-center" scope="col">Periodo</th>

          <th class="p-text-center" scope="col">Netto in busta</th>
          <th class="p-text-center" scope="col">File PDF</th>
        </tr>
      </ng-template>
      <ng-template let-bustaPaga pTemplate="body">
        <tr style="height:34px">
          <td class="p-text-center">{{ bustaPaga.mese - 1 | meseBustaPaga }} {{ bustaPaga.anno }}</td>

          <td class="p-text-center">{{ bustaPaga.importo | currency:'EUR' }}</td>
          <td class="p-text-center">

            <button (click)="openPdf(this.bustaPaga.id)" class="p-button-text p-button-secondary p-button-text" icon="pi pi-file-pdf" pButton
                    type="button"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-messages [enableService]="true"></p-messages>
    <div *ngIf="!firstLanding && (!showTable || bustePagaFiltered.length == 0)" class="p-grid">
      <div class="p-col-12">
        <div class="msgNoData">
          <span class="las la-5x la-info-circle"></span>
          <h3>Nessuna busta paga trovata corrispondente ai criteri di ricerca inseriti.</h3>
        </div>
      </div>
    </div>
    <!-- <div class="p-grid" *ngIf="firstLanding && (!showTable || bustePagaFiltered.length == 0)" >
      <div class="p-col-12">
        <div class="msgNoData">
          <span class="las la-5x la-info-circle"></span>
          <h3>Non hai effettuato alcuna ricerca.</h3>
        </div>
      </div>
    </div>-->
  </main>
</div>
