<div class="main-area">
  <main>

    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <div class="titolo-sezione">
      <i class="las la-id-card"></i> Profilo
    </div>

    <form (ngSubmit)="inviaForm()" *ngIf="dataLoaded | async" [formGroup]="formProfilo">
      <div class="p-fluid p-formgrid p-grid p-mt-4">
        <div class="p-field p-col-6 p-md-3">
          <label for="nome">Nome</label>
          <input [(ngModel)]="profiloUtente.nome" formControlName="nome" id="nome" pInputText required type="text">
        </div>
        <div class="p-field p-col-6 p-md-3">
          <label for="cognome">Cognome</label>
          <input [(ngModel)]="profiloUtente.cognome" formControlName="cognome" id="cognome" pInputText required type="text">
        </div>
        <div class="p-field p-col-6 p-md-3">
          <label for="codiceFiscale">Codice fiscale</label>
          <input formControlName="codiceFiscale" id="codiceFiscale" pInputText required type="text">
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="dataDiNascita">Data di nascita</label>
          <p-calendar [monthNavigator]="true" [yearNavigator]="true" [yearRange]="getYearRange()"
                      dateFormat="dd/mm/yy" formControlName="dataDiNascita" required></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-3 parent">
          <label for="prefix">Cellulare<label class="required-asterisk">*</label></label>

          <div class="block-div">
            <div class="first">
              <input [placeholder]="this.phonePrefix" formControlName="prefix"
                     id="prefix" pInputText
                     required>
            </div>
            <div class="last">
              <p-inputMask [ngClass]="{'ng-invalid ng-dirty': !formProfilo.get('cellulare').valid && (formProfilo.get('cellulare').dirty || formProfilo.get('cellulare').touched)}"
                     formControlName="cellulare" id="cellulare"
                     mask="9999999999" required></p-inputMask>
            </div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="state">Stato</label>
          <p-dropdown [options]="statusDip" formControlName="statusSelezionato" inputId="statusSelezionato"
                      optionLabel="name" placeholder="Seleziona" required></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="state">Ruolo</label>
          <p-dropdown [options]="ruoliUtente" formControlName="ruolo"
                      inputId="ruolo"
                      optionLabel="name" placeholder="Seleziona" styleClass="stileRuoli" required></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="state">Mansione</label>
          <p-dropdown [options]="mansioni" formControlName="mansione" inputId="mansione" optionLabel="nome"
                      placeholder="Seleziona" required></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="email">Indirizzo email</label>

          <input formControlName="email" id="email" pInputText type="email" required>


        </div>
        <div class="p-field p-col-6 p-md-4">
          <label for="dataDiNascita">Data inizio rapporto</label>
          <p-calendar [monthNavigator]="true" [yearNavigator]="true" [yearRange]="timeUtils.getFutureYearRange()"
                      dateFormat="dd/mm/yy" formControlName="dataInizioRapporto" required></p-calendar>
        </div>
        <div class="p-field p-col-6 p-md-4">
          <label for="dataFineRapporto">Data fine rapporto</label>
          <p-calendar [monthNavigator]="true" [yearNavigator]="true" [yearRange]="timeUtils.getFutureYearRange()"
                      dateFormat="dd/mm/yy" formControlName="dataFineRapporto"></p-calendar>
        </div>
        <!-- <div class="p-field p-col-12 p-md-4">
          <label for="tipoContratto">Tipo contratto</label>
          <input id="tipoContratto" formControlName="tipoContratto" type="text" pInputText>
        </div>-->
        <div class="p-field p-col-12 p-md-4">
          <label for="tipoContratto">Tipo contratto</label>
          <p-dropdown [options]="tipiContratto" formControlName="tipoContratto" inputId="tipoContratto"
                      optionLabel="nome" placeholder="Seleziona" required></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="oreSettimanali">Ore settimanali</label>
          <input formControlName="oreSettimanali" id="oreSettimanali" pInputText required type="number">
        </div>
        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <p-button label="In possesso di:" styleClass="p-button-text"></p-button>
          </p-divider>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <div class="p-field-checkbox">
            <p-checkbox binary="true" formControlName="chiaviUfficio" inputId="chiaviUfficio"></p-checkbox>
            <label for="chiaviUfficio">Chiavi ufficio</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox binary="true" formControlName="telefonoAziendale" inputId="telefonoAziendale"></p-checkbox>
            <label for="telefonoAziendale">Telefono aziendale</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox [(ngModel)]="pcAziendaleCheckBox" binary="true" formControlName="pcAziendale"
                        inputId="pcAziendale"></p-checkbox>
            <label for="pcAziendale">Pc aziendale</label>
          </div>
        </div>
        <div (change)="detectComodatoChange()" *ngIf="pcAziendaleCheckBox" class="p-field p-col-6 p-md-6">
          <label for="serialePc">Seriale Pc</label>
          <input formControlName="serialePc" id="serialePc" pInputText type="text">
        </div>
        <div (change)="detectComodatoChange()" *ngIf="pcAziendaleCheckBox" class="p-field p-col-6 p-md-6">
          <label for="modelloPc">Modello Pc</label>
          <input formControlName="modelloPc" id="modelloPc" pInputText type="text">
        </div>
        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <p-button label="Progetti" styleClass="p-button-text"></p-button>
          </p-divider>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <div *ngIf="profiloUtente.progetti" class="p-d-flex p-ai-center">
            <p-chip *ngFor="let progetto of profiloUtente.progetti" [label]="progetto.nome"
                    styleClass="p-mr-2"></p-chip>
          </div>
        </div>
        <!--
        <div class="p-field p-col-2 p-md-2" *ngIf="!switchToEditMode">
          <button pButton type="button" label="Modifica" class="p-button p-button-warning" (click)="switchToEdit()"></button>
        </div>
        <div class="p-field p-col-2 p-md-2" *ngIf="switchToEditMode">
          <button pButton type="button" label="Annulla" class="p-button p-button-danger" (click)="closeEditMode()"></button>
        </div>
        <div class="p-field p-col-2 p-md-2" *ngIf="switchToEditMode">
          <button pButton type="submit" label="Salva" class="p-button p-button-success"></button>
        </div>-->


     </div>
    </form>


    <p-messages [enableService]="true"></p-messages>
  </main>
</div>
