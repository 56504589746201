import {Message} from 'primeng/api';

export class FrontendMessage {

  /** Header */
  public static readonly AMMINISTRATORE = 'Amministratore';
  public static readonly DIPENDENTE = 'Dipendente';
  public static readonly TIROCINANTE = 'Tirocinante';

  /** Messaggi generici */
  public static readonly SEVERITY_SUCCESS = 'success';
  public static readonly SUMMARY_SUCCESS = 'OK';
  public static readonly DETAIL_SUCCESS = 'Operazione completata.';
  public static readonly RECOVER_PASSWORD_MESSAGE = 'Password modificata correttamente';
  public static readonly MESSAGE_SERVICE_OK: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.DETAIL_SUCCESS
  };
  public static readonly RECOVER_PASSWORD_OK: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.RECOVER_PASSWORD_MESSAGE
  };
  public static readonly USER_DELETED = 'Dipendente eliminato correttamente';
  public static readonly USER_UPDATED_OK = 'Dipendente modificato correttamente';
  public static readonly MESSAGE_SERVICE_USER_UPDATED_OK: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.USER_UPDATED_OK
  };
  public static readonly MESSAGE_SERVICE_DIPENDENTE_MODIFICATO: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.USER_UPDATED_OK,
    key: 'tableDipendenti'
  };
  public static readonly MESSAGE_SERVICE_DIPENDENTE_ELIMINATO: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.USER_DELETED,
    key: 'tableDipendenti'
  };
  public static readonly CODICE_FISCALE_VALIDO = 'Codice fiscale valido';
  public static readonly CODICE_FISCALE_NON_VALIDO = 'Codice fiscale non valido';
  public static readonly CHANGE_PASSWORD_OK: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: 'Password modificata correttamente'
  };


  public static readonly SEVERITY_WARNING = 'warn';
  public static readonly SEVERITY_ERROR = 'error';
  public static readonly SUMMARY_ERROR = 'Errore';
  public static readonly SUMMARY_WARNING = 'Attenzione';


  public static readonly DETAIL_GENERIC_ERROR = 'Si è verificato un errore.';
  public static readonly MESSAGE_SERVICE_KO: Message = {
    severity: FrontendMessage.SEVERITY_ERROR,
    summary: '',
    detail: FrontendMessage.DETAIL_GENERIC_ERROR
  };

  public static readonly CONFERMA = 'Conferma';
  public static readonly ANNULLA = 'Annulla';
  public static readonly POPUP_CONFERMA_TEXT = 'Sei proprio sicuro?';
  public static readonly POPUP_DELETE_USER_TEXT = 'Attenzione! Sei sicuro di voler eliminare il dipendente selezionato?';

  public static readonly GIORNO_FESTIVO = 'Non è possibile inserire ore nei giorni festivi';
  public static readonly GIORNO_FESTIVO_MSG: Message = {
    severity: FrontendMessage.SEVERITY_WARNING,
    summary: FrontendMessage.SUMMARY_WARNING,
    detail: FrontendMessage.GIORNO_FESTIVO
  };


  /** Buste paga */
  public static readonly POPUP_CONFERMA_ELIMINAZIONE_BUSTA_PAGA = 'Attenzione! Sei sicuro di voler eliminare la busta paga selezionata?';

  /** Authentication */
  public static readonly INVALID_CREDENTIALS = 'Credenziali non valide.';
  public static readonly ACCESS_NOT_ALLOWED = 'Utente non abilitato';
  public static readonly INVALID_CREDENTIALS_MSG: Message = {
    severity: FrontendMessage.SEVERITY_ERROR,
    summary: '',
    detail: FrontendMessage.INVALID_CREDENTIALS
  };
  public static readonly ACCESS_NOT_ALLOWED_MSG: Message = {
    severity: FrontendMessage.SEVERITY_ERROR,
    summary: '',
    detail: FrontendMessage.ACCESS_NOT_ALLOWED
  };

  /** Password reset */
  public static readonly EMAIL_PWD_RECOVERY_SENT = 'E\' stata inviata una mail all\'indirizzo specificato per effettuare il reset della password.';
  public static readonly RESET_PWD_MATCH = 'Le password corrispondono';
  public static readonly RESET_PWD_NOT_MATCH = 'Le password non corrispondono';
  public static readonly RESET_PWD_INVALID_LINK = 'Link non valido o scaduto.';
  public static readonly RESET_PWD_VALID_LINK = 'Il link è valido.';
  public static readonly RESET_PWD_EMAIL_SENT: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.EMAIL_PWD_RECOVERY_SENT
  };
  public static readonly RESET_PWD_VALID_LINK_MSG: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.RESET_PWD_VALID_LINK
  };
  public static readonly RESET_PWD_INVALID_LINK_MSG: Message = {
    severity: FrontendMessage.SEVERITY_ERROR,
    summary: '',
    detail: FrontendMessage.RESET_PWD_INVALID_LINK
  };

  /** Progetti */
  public static readonly PROGETTI_NON_PRESENTI = 'Nessun progetto presente.';
  public static readonly DIPENDENTI_NON_PRESENTI = 'Nessun dipendente presente.';
  public static readonly POPUP_CONFERMA_ELIMINAZIONE_PROGETTO = 'Attenzione! Sei sicuro di voler eliminare il progetto selezionato?';

  /** Timesheets */
  public static readonly TIMESHEET_LOCKED = 'Richiedi lo sblocco.';
  public static readonly TIMESHEET_APPROVED = 'Il rapportino risulta approvato.';
  public static readonly TIMESHEET_NOT_EDITABLE = 'Non puoi modificare questo rapportino.';
  public static readonly TIMESHEET_LOCKED_SUMMARY = 'Rapportino bloccato';
  public static readonly TIMESHEET_APPROVED_OK_DETAIL = 'Rapportino approvato correttamente';
  public static readonly TIMESHEET_SUMMARY_NOT_AVAILABLE_MSG = 'Riepilogo non disponibile.';
  public static readonly TIMESHEET_UNLOCK_REQUEST = 'Richiesta di sblocco inviata correttamente';
  public static readonly TIMESHEET_WAITING_UNLOCK = 'Il rapportino è in attesa di essere sbloccato dall\'amministratore';
  public static readonly TIMESHEET_SUMMARY_NOT_AVAILABLE: Message = {
    severity: FrontendMessage.SEVERITY_WARNING,
    summary: FrontendMessage.SUMMARY_WARNING,
    detail: FrontendMessage.TIMESHEET_SUMMARY_NOT_AVAILABLE_MSG
  };
  public static readonly TIMESHEET_LOCKED_MSG: Message = {
    severity: FrontendMessage.SEVERITY_WARNING,
    summary: FrontendMessage.TIMESHEET_LOCKED_SUMMARY,
    detail: FrontendMessage.TIMESHEET_LOCKED
  };
  public static readonly TIMESHEET_WAITING_UNLOCK_MSG: Message = {
    severity: FrontendMessage.SEVERITY_WARNING,
    summary: FrontendMessage.TIMESHEET_LOCKED_SUMMARY,
    detail: FrontendMessage.TIMESHEET_WAITING_UNLOCK
  };
  public static readonly TIMESHEET_LOCKED_APPROVED_MSG: Message = {
    severity: FrontendMessage.SEVERITY_WARNING,
    summary: FrontendMessage.TIMESHEET_LOCKED_SUMMARY,
    detail: FrontendMessage.TIMESHEET_APPROVED
  };
  public static readonly TIMESHEET_NOT_EDITABLE_MSG: Message = {
    severity: FrontendMessage.SEVERITY_WARNING,
    summary: '',
    detail: FrontendMessage.TIMESHEET_NOT_EDITABLE
  };
  public static readonly TIMESHEET_APPROVED_OK: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.TIMESHEET_APPROVED_OK_DETAIL
  };
  public static readonly TIMESHEET_UNLOCK_REQUEST_MSG: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.TIMESHEET_UNLOCK_REQUEST
  };

  /** FORM */
  public static readonly DETAIL_CAMPI_NON_COMPILATI = 'Attenzione! Campi obbligatori non compilati.';
  public static readonly MESSAGE_SERVICE_REQUIRED_FIELDS: Message = {
    severity: FrontendMessage.SEVERITY_ERROR,
    summary: '',
    detail: FrontendMessage.DETAIL_CAMPI_NON_COMPILATI
  };

  /** TIROCINANTI */
  public static readonly TIROCINANTI_NON_PRESENTI = 'Nessun tirocinante presente.';
  public static readonly TIROCINANTE_DELETED = 'Tirocinante eliminato correttamente';
  public static readonly TIROCINANTE_UPDATE_OK = 'Tirocinante modificato correttamente';
  public static readonly POP_UP_DELETE_TIROCINANTE = 'Attenzione! Sei sicuro di voler eliminare il tirocinante selezionato?';
  public static readonly MESSAGE_SERVICE_TIROCINANTE_ELIMINATO: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.TIROCINANTE_DELETED,
    key: 'tableTirocinanti'
  };
  public static readonly MESSAGE_SERVICE_TIROCINANTE_MODIFICATO: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.TIROCINANTE_UPDATE_OK,
    key: 'tableTirocinanti'
  };
  public static readonly TIROCINIO_OK: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: 'Tirocinio inserito correttamente',
    key: 'tableProgettiFormativi'
  };

  /** PROGETTO FORMATIVO */

  public static readonly PROGETTO_FORMATIVO_UPDATE_OK = 'Piano formativo modificato correttamente';
  public static readonly TIROCINIO_UPDATE = 'Tirocinio modificato correttamente';
  public static readonly MESSAGE_SERVICE_PROGETTO_FORMATIVO_MODIFICATO: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.PROGETTO_FORMATIVO_UPDATE_OK,
    key: 'tableProgettiFormativi'
  };

  public static readonly MESSAGE_SERVICE_TIROCINIO_UPDATE: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.TIROCINIO_UPDATE,
    key: 'tableAllTirocinanti'
  };

  /** LIBRETTO DIARIO*/

  public static readonly ATTIVITA_INSERITA = 'Attività inserita correttamente';
  public static readonly LIBRETTO_LOCKED_SUMMARY = 'Tirocinio Concluso';
  public static readonly LIBRETTO_CLOSED = 'Il tirocinio risulta chiuso';
  public static readonly LIBRETTO_SOSPESO = 'Il giorno selezionato non comprende il periodo di  tirocinio';
  public static readonly LIBRETTO_LOCKED_CHIUSO_MSG: Message = {
    severity: FrontendMessage.SEVERITY_WARNING,
    summary: FrontendMessage.LIBRETTO_LOCKED_SUMMARY,
    detail: FrontendMessage.LIBRETTO_CLOSED
  };
  public static readonly LIBRETTO_LOCKED_SOSPESO_MSG: Message = {
    severity: FrontendMessage.SEVERITY_WARNING,
    summary: 'Attenzione!',
    detail: FrontendMessage.LIBRETTO_SOSPESO
  };
  public static readonly LIBRETTO_LOCKED_BLOCCATO_MSG: Message = {
    severity: FrontendMessage.SEVERITY_WARNING,
    summary: 'Attenzione!',
    detail: 'Il tirocinio è momentaneamente sospeso'
  };
  public static readonly LIBRETTO_LOCKED = 'Il tuo tirocinio è in fase di approvazione';
  public static readonly LIBRETTO_LOCKED_MSG: Message = {
    severity: FrontendMessage.SEVERITY_WARNING,
    summary: '',
    detail: FrontendMessage.LIBRETTO_LOCKED
  };
  public static readonly ORARIO_OCCUPATO: Message = {
    severity: FrontendMessage.SEVERITY_WARNING,
    summary: 'Attenzione!',
    detail: 'Il seguente slot orario è occupato'
  };
  public static readonly ORE_NON_COMPLETE: Message = {
    severity: FrontendMessage.SEVERITY_WARNING,
    summary: 'Attenzione!',
    detail: 'Devi completare tutte le ore di tirocinio previste dal piano formativo'
  };
  public static readonly INSERIMENTO_ATTIVITA: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.ATTIVITA_INSERITA
  };
  public static readonly LIBRETTO_CONCLUSO: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: 'Piano formativo in approvazione'
  };

  public static readonly REACTIVATE_LIBRETTO: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: 'Piano formativo riattivato'
  };

  public static readonly DELETE_ACTIVITY = 'Attività eliminata correttamente';
  public static readonly DELETE_ACTIVITY_MSG: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.DELETE_ACTIVITY
  };

  /** ADMIN */

  public static readonly RAPPORTINO_UNLOCKED = 'Rapportino sbloccato correttamente';
  public static readonly RAPPORTINO_UNLOCKED_MSG: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.RAPPORTINO_UNLOCKED
  };

  public static DIPENDENTE_INSERTED = 'Dipendente inserito correttamente';
  public static readonly DIPENDENTE_INSERTED_MSG: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.DIPENDENTE_INSERTED
  };

  public static PROGETTO_INSERTED = 'Progetto inserito correttamente';
  public static readonly PROGETTO_INSERTED_MSG: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.PROGETTO_INSERTED
  };

  public static BUSTA_PAGA_DELETED = 'Busta paga eliminata correttamente';
  public static readonly BUSTA_PAGA_DELETED_MSG: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.BUSTA_PAGA_DELETED
  };
  /** DIPENDENTI */
  public static readonly RAPPORTINO_SENT = 'Rapportino inviato correttamente';
  public static readonly RAPPORTINO_SENT_MSG: Message = {
    severity: FrontendMessage.SEVERITY_SUCCESS,
    summary: '',
    detail: FrontendMessage.RAPPORTINO_SENT
  };



}
