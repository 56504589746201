export class ComodatoPc {
  private modello: string;
  private seriale: string;

  constructor($modello: string, $seriale: string) {
    this.modello = $modello;
    this.seriale = $seriale;
  }


  public get $modello(): string {
    return this.modello;
  }


  public set $modello(value: string) {
    this.modello = value;
  }


  public get $seriale(): string {
    return this.seriale;
  }


  public set $seriale(value: string) {
    this.seriale = value;
  }


}
