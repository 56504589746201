import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GiorniFestiviService {
  private holyDays: { Pasquetta: Date; Ognissanti: Date; Capodanno: Date; FestaDelLavoro: Date; Liberazione: Date; Assunzione: Date; Natale: Date; Epifania: Date; FestaDellaRepubblica: Date; ImmacolataConcezione: Date; SantoStefano: Date };

  constructor() {
    this.holyDays = null;
  }

  generateHolydays(currentDate) {
    currentDate = currentDate ? currentDate : new Date();
    this.holyDays = {
      Pasquetta: new Date(),
      Capodanno: new Date(currentDate.getFullYear(), 0, 1),
      Epifania: new Date(currentDate.getFullYear(), 0, 6),
      Liberazione: new Date(currentDate.getFullYear(), 3, 25),
      FestaDelLavoro: new Date(currentDate.getFullYear(), 4, 1),
      FestaDellaRepubblica: new Date(currentDate.getFullYear(), 5, 2),
      Assunzione: new Date(currentDate.getFullYear(), 7, 15),
      Ognissanti: new Date(currentDate.getFullYear(), 10, 1),
      ImmacolataConcezione: new Date(currentDate.getFullYear(), 11, 8),
      Natale: new Date(currentDate.getFullYear(), 11, 25),
      SantoStefano: new Date(currentDate.getFullYear(), 11, 26)
    };
    this.holyDays.Pasquetta = (res => {
      const a = Math.floor(currentDate.getFullYear() % 19);
      const b = Math.floor(currentDate.getFullYear() / 100);
      const c = Math.floor(currentDate.getFullYear() % 100);
      const d = Math.floor(b / 4);
      const e = Math.floor(b % 4);
      const g = Math.floor((8 * b + 13) / 25);
      const h = Math.floor((19 * a + b - d - g + 15) % 30);
      const j = Math.floor(c / 4);
      const k = Math.floor(c % 4);
      const m = Math.floor((a + 11 * h) / 319);
      const r = Math.floor((2 * e + 2 * j - k - h + m + 32) % 7);
      const n = Math.floor((h - m + r + 90) / 25);
      const p = Math.floor((h - m + r + n + 19) % 32);
      const pasquetta = new Date(currentDate.getFullYear(), n - 1, p);
      pasquetta.setDate(pasquetta.getDate() + 1);
      // console.log(pasquetta + ' n = ' + n + ' p = ' + p);
      return pasquetta;
    })();
  }

  isHolyday (day) {
    this.generateHolydays(day);
    for (const [key, value] of Object.entries(this.holyDays)) {
      if (day.getTime() == value.getTime()) return true;
    }
    return false;
  }

  listMonthHolydays (month) {
    let holydaysOfMonth = [];
    this.generateHolydays(month[0].dataCompleta);
    for (let day of month) {
      for (const [key, value] of Object.entries(this.holyDays)) {
        if (day.dataCompleta.getTime() == value.getTime()) holydaysOfMonth.push(day);
      }
    }
    return holydaysOfMonth;
  }

}
