import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Constants} from 'src/app/constants/constants';
import {Endpoints} from 'src/app/constants/endpoints';
import {StorageConstants} from 'src/app/constants/storage-constants';
import {LoginRequest} from 'src/app/models/login/login-request';
import {LoginResponse} from 'src/app/models/login/login-response';
import {LoginResponseInterface} from 'src/app/models/login/login-response.interface';
import {ChangePasswordNoTokenRequest} from 'src/app/models/password-recovery/change-password-no-token-request';
import {ChangePasswordRequest} from 'src/app/models/password-recovery/change-password-request';
import {InitPasswordRecoveryRequest} from 'src/app/models/password-recovery/init-password-recovery-request';
import {PasswordRecoveryRequest} from 'src/app/models/password-recovery/password-recovery-request';
import {ServiceResponse} from 'src/app/models/response/service-response';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private loginResponseSubj: BehaviorSubject<LoginResponse>;
  private tokenExpirationTimer: any;

  constructor(private http: HttpClient, private router: Router) {
    this.loginResponseSubj = new BehaviorSubject<LoginResponse>(JSON.parse(localStorage.getItem(StorageConstants.USER_DATA)));
  }

  public get currentLoginRespValue(): LoginResponse {
    return this.loginResponseSubj.value;
  }

  generateToken(loginRequest: LoginRequest): Observable<LoginResponseInterface> {
    return this.http.post<LoginResponseInterface>(Endpoints.GENERATE_TOKEN_URL, loginRequest)
      .pipe(
        tap(respData => {
          const userData = new LoginResponse(respData.userId, respData.role, respData.token, respData.redirectTo, respData.expiringOn, respData.nome, respData.cognome);
          localStorage.setItem(StorageConstants.USER_DATA, JSON.stringify(userData));
          const expirationInMillis = new Date(respData.expiringOn).getTime() - new Date().getTime();
          // console.log('expirationInMillis ', expirationInMillis);
          this.loginResponseSubj = new BehaviorSubject<LoginResponse>(userData);
          this.autoLogout(expirationInMillis);
        })
      );
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/');
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
    this.loginResponseSubj.next(null);
  }

  autoLogout(expirationInMillis: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationInMillis);
  }

  autoLogin() {
    const userData: LoginResponse = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
    if (!userData) {
      return;
    }
    const dateNow = new Date();
    if (userData.token != null && dateNow < new Date(userData.expiringOn)) {
      this.loginResponseSubj.next(userData);
      const refreshExpiration = new Date(userData.expiringOn).getTime() - new Date().getTime();
      this.autoLogout(refreshExpiration);
      this.router.navigateByUrl('/' + userData.redirectTo);
    }
  }

  sendMailResetLink(pwdRecoveryRequest: PasswordRecoveryRequest): Observable<any> {
    return this.http.post<ServiceResponse>(Endpoints.SEND_MAIL_PWD_RECOVERY, pwdRecoveryRequest);
  }

  registration(pwdRecoveryRequest: PasswordRecoveryRequest): Observable<any> {
    return this.http.post<ServiceResponse>(Endpoints.registration, pwdRecoveryRequest);
  }

  passwordRecoveryInit(initPasswordRecoveryRequest: InitPasswordRecoveryRequest): Observable<ServiceResponse> {
    const params = new HttpParams()
      .set(Constants.RESET_PWD_USERNAME_PARAM, initPasswordRecoveryRequest.getUsername())
      .set(Constants.RESET_PWD_TOKEN_ID_PARAM, initPasswordRecoveryRequest.getTokenId());
    return this.http.get<ServiceResponse>(Endpoints.CHECK_TOKEN_PWD_RECOVERY, {params});
  }

  changePassword(changePasswordRequest: ChangePasswordRequest): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(Endpoints.CHANGE_PASSWORD_PWD_RECOVERY, changePasswordRequest);
  }

  changePasswordNoToken(changePasswordNoTokenRequest: ChangePasswordNoTokenRequest): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(Endpoints.CHANGE_PASSWORD_NO_TOKEN, changePasswordNoTokenRequest);
  }

  changePasswordNoTokenTirocinante(changePasswordNoTokenRequest: ChangePasswordNoTokenRequest): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(Endpoints.CHANGE_PASSWORD_NO_TOKEN + 'Tirocinante', changePasswordNoTokenRequest);
  }

}
