export class StatusDipendente {
  private name: string;
  private code: string;

  constructor(name: string, code: string) {
    this.name = name;
    this.code = code;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string) {
    this.name = name;
  }

  public getCode(): string {
    return this.code;
  }

  public setCode(code: string) {
    this.code = code;
  }
}
