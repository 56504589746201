<div class="main-area">
  <main>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p> Caricamento... </p>
    </ngx-spinner>
    <div class="p-grid">
      <div class="p-col">
        <button (click)="goback()" *ngIf="!fromNotification" class="p-button-primary p-button-raised" icon="pi pi-arrow-circle-left"
                label="Torna alla lista tirocini"
                pButton type="button"></button>
        <button (click)="goNotification()" *ngIf="fromNotification" class="p-button-primary p-button-raised" icon="pi pi-arrow-circle-left"
                label="Torna alle notifiche"
                pButton type="button"></button>
      </div>


    </div>
    <div class="riepilogo">
      <div class="riepilogo-single p-shadow-3">
        <div>
          <p>TOTALE</p>
          <h4>{{librettoDiario.totaleOre}}</h4>
          <span>ore lavorate</span>
        </div>
        <div>
          <span class="las la-user-clock"></span>
        </div>
      </div>
      <div class="riepilogo-single p-shadow-3">
        <div>
          <p>NOTE</p>
          <h5 class="print-note">{{librettoDiario.note}}</h5>
        </div>
        <div>
          <span class="las la-info"></span>
        </div>
      </div>
    </div>
    <p-toast position="bottom-right"></p-toast>
    <div class="calendar-month">
      <section class="calendar-month-header">
        <div class="selected-month" id="selected-month">
          <i (click)="loadMonthDetail(month-1, year)" *ngIf="month > librettoDiario.dataInizioTirocinio.getMonth() || year>librettoDiario.dataInizioTirocinio.getFullYear()"
             class="las la-chevron-left"></i>
          &nbsp; <i
          class="las la-calendar"></i> {{meseAnno}} &nbsp; <i (click)="loadMonthDetail(month+1, year)"
                                                              *ngIf="month < librettoDiario.dataFineTirocinio.getMonth() || year< librettoDiario.dataFineTirocinio.getFullYear()"
                                                              class="las la-chevron-right"></i>
        </div>
        <br>
        <div class="riepilogo">
          <div class="riepilogo-single p-shadow-3">
            <div>
              <p>TOTALE</p>
              <h4>{{monthDetail.oreMensili}}</h4>
              <span>ore mensili</span>
            </div>
            <div>
              <span class="las la-user-clock"></span>
            </div>
          </div>
          <div class="riepilogo-single p-shadow-3">
            <div>
              <p>STATO</p>
              <h4>
                <p-tag [severity]="utils.statoLibrettoStyle(librettoDiario.statoTirocinio.nome)"
                       [value]="librettoDiario.statoTirocinio.nome"></p-tag>
              </h4>
              <span *ngIf="librettoDiario.richiestaSblocco">Hai richiesto lo sblocco</span>
            </div>
            <div>
              <span class="las la-calendar-check"></span>
            </div>
          </div>
        </div>
        <div *ngIf="monthDetail" class="p-grid p-mt-1">
        </div>
      </section>

      <ol class="day-of-week" id="days-of-week">
        <li>Lun</li>
        <li>Mar</li>
        <li>Mer</li>
        <li>Gio</li>
        <li>Ven</li>
        <li class="festivita">Sab</li>
        <li class="festivita">Dom</li>
      </ol>
      <ol class="days-grid" id="calendar-days">
        <li *ngFor="let giornoDaDisabilitare of giorniDaDisabilitare" class="calendar-day calendar-day--not-current">
          <span></span>
        </li>
        <li (click)="getData(giorno)" *ngFor="let giorno of daysCurrentMonth; let i = index" class="calendar-day">
          <span [class]="'giorno ' + (giorno.giornoSettimana == 6 || giorno.giornoSettimana == 0 || giorniFestivi.includes(giorno, 0) ? 'festivita' : '')">{{
            giorno.numeroGiorno }}</span>

          <span *ngIf="giorno.totaleOreGiornata !== '00:00' && giorno.totaleOreGiornata"
                class=" parzialeOre"> {{giorno.totaleOreGiornata}}h </span>
          <span *ngFor="let act of giorno.activities; let i = index" class="monteOre">
           {{ act.oraInizio }} - {{act.oraFine}}
          </span>


        </li>
      </ol>
    </div>
    <p-dialog (onHide)="displayModalActivity=false"
              *ngIf="selectedDay" [(visible)]="displayModalActivity" [draggable]="false" [header]="'Attività del ' + (selectedDay.dataCompleta | date:'dd/MM/YYYY')" modal="true">

      <div *ngIf="mostraRiepilogo">

        <p-table [style]="{width: '80vw'}" [value]="selectedDay.activities">
          <ng-template pTemplate="header">
            <tr>
              <th class="time" scope="col">Ora inizio</th>
              <th class="time" scope="col">Ora fine</th>
              <th class="description" scope="col">Descrizione</th>
            </tr>
          </ng-template>
          <ng-template let-activity pTemplate="body">
            <tr>

              <td class="time">{{activity.oraInizio}}</td>
              <td class="time">{{ activity.oraFine }}</td>
              <td (click)="showModalDialog(activity.descrizione)" class="description">{{activity.descrizione}}</td>
            </tr>
          </ng-template>
          <p-confirmPopup></p-confirmPopup>
        </p-table>

        <div class="p-col-12 totOre">
          Totale ore: {{ selectedDay.totaleOreGiornata }}
        </div>

        <p-dialog [(visible)]="displayModalInfo" [draggable]="false" [style]="{width: '50vw'}" header="Descrizione">
          <p>{{descrizione}}</p>

        </p-dialog>
      </div>
    </p-dialog>
    <div *ngIf="librettoDiario" class="p-fluid p-formgrid p-grid p-mt-4 p-jc-end">
      <div class="p-field p-col-12 p-md-2">
        <button (click)="approvaLibretto(librettoDiario)" *ngIf="!librettoDiario.richiestaSblocco && librettoDiario.statoTirocinio.id == 4 " class="p-button p-button-success"
                label="Approva"
                pButton type="button"></button>
      </div>
      <div class="p-field p-col-12 p-md-2">
        <button (click)="openDialog()" *ngIf="!librettoDiario.richiestaSblocco && librettoDiario.statoTirocinio.id == 4 " class="p-button p-button-warning"
                label="Riattiva"
                pButton type="button"></button>
      </div>
      <div class="p-field p-col-12 p-md-2">
        <button (click)="exportToPDF()" class="p-button p-button-primary" label="Esporta pdf"
                pButton type="button"></button>
      </div>

      <!--&nbsp;&nbsp;
      <button *ngIf="!librettoDiario.richiestaSblocco && librettoDiario.statoTirocinio.id == 3 " pButton type="button" label=Esporta"
              class="p-button p-button-success"></button>-->
    </div>

    <p-dialog (onHide)="hideModal()" [(visible)]="displayModal" [draggable]="false" [style]="{width: '40vw'}" closeOnEscape="true"
              header="Motivazione riapertura tirocinio" maximizable="true" modal="true" position="center" resizable="true"
    >

      <form (ngSubmit)="inviaDettaglioNote()" [formGroup]="formNote">

        <div class="p-fluid p-formgrid p-grid p-jc-end">
          <div class="p-field p-col-12 ">
            <textarea formControlName="note" id="note"
                      pInputText required type="text"></textarea>

          </div>
          <div class="p-field p-col-12 p-md-4">
            <button (click)="hideModal()" class="p-button p-button-danger" label="Annulla" pButton
                    type="button"></button>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <button [disabled]=" !formNote.valid" class="p-button p-button-success" label="Invia" pButton type="submit"
            ></button>
          </div>

        </div>

      </form>

    </p-dialog>
  </main>
</div>
