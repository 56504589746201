import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import TimeUtils from '../../../utils/time-utils';
import GenericUtils from '../../../utils/generic-utils';
import {FrontendMessage} from '../../../constants/frontend-message';
import * as codiceFiscaleUtils from '@marketto/codice-fiscale-utils';
import {Constants} from '../../../constants/constants';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {ProgettiService} from '../../../services/progetti.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {simpleFadeAnimation} from '../../../animations/simple-fade-animation';
import {fadeInAnimation} from '../../../animations/fade-in-animation';
import {ITirocinante} from '../../../models/tirocinio/tirocinante-interface';
import {StatusTirocinante} from '../../../models/tirocinio/status-tirocinante';
import {STATUS_TIROCINANTI_MOCK} from '../../../mock/status-tirocinanti.mock';
import {IProgettoFormativo} from '../../../models/tirocinio/progetto-formativo.interface';
import {ProgettoFormativoComponent} from '../progetto-formativo/progetto-formativo.component';

import italy from '../../../mock/italy.json';
import {IProvince} from '../../../models/tirocinio/province-interface';
import {Dialog} from 'primeng/dialog';
import {TirocinanteService} from '../../../services/tirocinante.service';
import {Tirocinante} from '../../../models/tirocinio/tirocinante';
import {HttpErrorResponse} from '@angular/common/http';
import {AppComponent} from '../../../app.component';
import {RouterPaths} from '../../../constants/RouterPaths';
import {PHONE_PREFIX_MOCK} from '../../../mock/phone-prefix.mock';
import {ProgettoFormativoService} from '../../../services/progetto-formativo.service';

@Component({
  selector: 'app-tirocinanti',
  templateUrl: './tirocinanti.component.html',
  styleUrls: ['./tirocinanti.component.scss'],
  providers: [MessageService, ConfirmationService],
  animations: [simpleFadeAnimation, fadeInAnimation]
})
export class TirocinantiComponent implements OnInit {


  tirocinanti: ITirocinante[];
  selectProvinceControl: boolean;
  tirocinanteSelezionato: ITirocinante;
  displayModal: boolean;
  formTirocinante: FormGroup;
  statusTirocinante: StatusTirocinante[];
  progettiFormativi: IProgettoFormativo[];
  switchToEditMode: boolean;
  statusProgettoSelezionato: StatusTirocinante;
  timeUtils = TimeUtils;
  utils = GenericUtils;
  msgNessunTirocinante = FrontendMessage.TIROCINANTI_NON_PRESENTI;
  stepNuovoTirocinante: MenuItem[];
  existEmail: boolean;
  startEmail: boolean;
  datiAnagrafici: boolean;
  activeIndex: number;
  province: IProvince[];
  citta: any[];
  showCity: boolean;

  cfUtil: any;
  cfValid: boolean;
  dataFineDisabled: boolean;
  initialDateValue: any;

  immatricolazioneValid: boolean;
  startWriting: boolean;
  startMatricola: boolean;
  startCase: boolean;
  interval: any;
  existTirocinante: boolean;
  country: any[];
  phonePrefix: string;
  @ViewChild(ProgettoFormativoComponent) child;
  @ViewChild(ProgettoFormativoComponent) load;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private progettiService: ProgettiService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private tirocinantiService: TirocinanteService,
    private appComponent: AppComponent,
    private progettoFormativoService: ProgettoFormativoService) {

    this.initialDateValue = Constants.INITIAL_DATE_DATASOUND;

    this.immatricolazioneValid = true;
    this.existTirocinante = false;
    this.existEmail = false;
    this.startEmail = false;
    this.cfUtil = codiceFiscaleUtils;
    this.cfValid = false;
    this.country = PHONE_PREFIX_MOCK;
    this.phonePrefix = '';
    this.dataFineDisabled = true;
    this.messageService.clear();


    this.tirocinanteSelezionato = undefined;
    this.statusTirocinante = STATUS_TIROCINANTI_MOCK;
    this.progettiFormativi = [];

    this.datiAnagrafici = true;
    this.activeIndex = 0;
    this.selectProvinceControl = false;
    this.province = this.appComponent.getProvince();
    this.citta = [];

    this.showCity = false;
    this.startCase = false;
    this.stepNuovoTirocinante = [{
      label: 'Dati anagrafici tirocinante',
      disabled: true,
    },
      {
        label: 'Dati piano formativo',
        disabled: true
      }];
    this.loadTirocinante();
    this.startMatricola = false;
  }

  ngOnInit(): void {

    this.immatricolazioneValid = true;
    this.existTirocinante = false;
  }

  loadTirocinante() {

    this.spinner.show();
    this.tirocinanti = [];

    this.tirocinantiService.getAllTirocinantiSorted().subscribe(utenti => {
      this.progettoFormativoService.getAllProgettiFormativi().subscribe(progettiFormativi => {
      for (let index = 0; index < utenti.length; index++) {
        const utente = utenti[index];
        if (utente.cellulare.length === 10) {
          this.checkPhonePrefix(utente);
        }
        utente.progettoFormativo = progettiFormativi.filter(progetto => progetto.id === utente.progettoFormativo.id)[0];
        this.tirocinanti.push(utente);
      }
      this.spinner.hide();

    });
    }, error => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });


  }

  checkPhonePrefix(tirocinante) {
    tirocinante.cellulare = '+39 ' + tirocinante.cellulare;
    this.tirocinantiService.updateTirocinanteProfile(tirocinante).subscribe(() => {
    });
  }

  onDataInizioChange() {
    const dataInizio = this.formTirocinante.get('dataInizioRapporto').value;
    this.formTirocinante.controls['dataFineRapporto'].reset();
    this.dataFineDisabled = false;

    if (dataInizio != null && dataInizio > Constants.INITIAL_DATE_DATASOUND) {
      this.dataFineDisabled = false;
    } else {
      this.dataFineDisabled = true;
    }
  }

  getCfValidationMessage(): string {
    return this.cfValid == true ? FrontendMessage.CODICE_FISCALE_VALIDO : FrontendMessage.CODICE_FISCALE_NON_VALIDO;
  }

  selezionaRiga(tirocinante) {

    this.tirocinanteSelezionato = tirocinante;

    this.displayModal = true;
    this.createForm();
  }

  nextPage() {
    this.datiAnagrafici = false;
    this.activeIndex = 1;
  }

  getYearRange(): string {
    const currentYear = new Date();
    const finalYear = currentYear.getFullYear() - 18;
    const startingYear = currentYear.getFullYear() - 90;
    return startingYear + ':' + finalYear;
  }

  switchToEdit() {
    this.switchToEditMode = true;

    this.formTirocinante.enable();
    const province = this.tirocinanteSelezionato.provincia;

    italy.forEach((city) => {
      this.spinner.show();
      if (city.Provincia === province) {
        this.citta.push(city);
      }

    });

    this.spinner.hide();
  }

  onHideModal() {
    this.switchToEditMode = false;
    this.datiAnagrafici = true;
    this.activeIndex = 0;
    this.formTirocinante.disable();
    this.cfValid = undefined;
    this.startCase = false;
    this.startMatricola = false;
    this.startWriting = false;
    this.immatricolazioneValid = true;
    this.existTirocinante = false;
    this.showCity = false;
    this.selectProvinceControl = false;
    this.existEmail = false;
    this.startEmail = false;
  }

  closeDialg() {
    this.loadTirocinante();
    this.displayModal = false;
    this.messageService.add(FrontendMessage.MESSAGE_SERVICE_TIROCINIO_UPDATE);
  }

  createForm() {
    this.spinner.show();
    const phone = this.tirocinanteSelezionato.cellulare.split(' ')[1];
    this.phonePrefix = this.tirocinanteSelezionato.cellulare.split(' ')[0];
    this.statusProgettoSelezionato = new StatusTirocinante(this.tirocinanteSelezionato.stato, this.tirocinanteSelezionato.stato);
    this.formTirocinante = this.fb.group({
      nome: [this.tirocinanteSelezionato.nome, [
        Validators.required,
        Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]
      ],
      cognome: [this.tirocinanteSelezionato.cognome, [
        Validators.required,
        Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]],
      codiceFiscale: [this.tirocinanteSelezionato.codiceFiscale, [
        Validators.required,
        Validators.maxLength(Constants.CODICE_FISCALE_MAX_LENGTH),
        Validators.pattern(Constants.CODICE_FISCALE_REGEX),
        this.utils.codiceFiscaleValidator]
      ],
      luogoDiNascita: [this.tirocinanteSelezionato.luogoDiNascita, [Validators.required,
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]],
      dataDiNascita: [new Date(this.tirocinanteSelezionato.dataDiNascita), [Validators.required, this.timeUtils.dataDiNascitaValidator]],
      matricola: [this.tirocinanteSelezionato.matricola, [Validators.required, Validators.maxLength(10), Validators.pattern(Constants.TEXT_AND_NUMBERS_REGEX)]],
      annoImmatricolazione: [this.tirocinanteSelezionato.annoImmatricolazione, [Validators.required, Validators.pattern(Constants.YEAR_REGEX), this.utils.immatricolazioneValidator]],
      residenza: [this.tirocinanteSelezionato.residenza, [Validators.required, Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT), Validators.pattern(Constants.REGEX_ALPHANUM_NO_ONLY_SPACE_SPECIAL)]],
      provincia: [this.tirocinanteSelezionato.provincia, Validators.required],
      citta: [this.tirocinanteSelezionato.citta, Validators.required],
      cap: [this.tirocinanteSelezionato.cap, [Validators.required, Validators.pattern(Constants.CAP)]],
      prefix: [this.phonePrefix, Validators.required],
      cellulare: [phone, [Validators.required, Validators.maxLength(Constants.MAX_TELEPHONE), Validators.minLength(Constants.MIN_TELEPHONE), Validators.pattern(Constants.PHONE_REGEX)]],
      email: [this.tirocinanteSelezionato.email, [Validators.required, Validators.pattern(Constants.EMAIL_REGEX), Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT)]],

    });
    this.formTirocinante.disable();
    this.spinner.hide();
  }

  closeEditMode() {
    this.formTirocinante.disable();
    this.createForm();
    this.switchToEditMode = false;
    this.showCity = false;
    this.startWriting = false;
    this.immatricolazioneValid = true;
    this.existTirocinante = false;
    this.startMatricola = false;
    this.selectProvinceControl = false;
    this.existEmail = false;
    this.startEmail = false;
    this.cfValid = true;
  }

  confirmDelete(event: Event, tirocinanteId) {
    this.messageService.clear();
    this.confirmationService.confirm({
      target: event.target,
      message: FrontendMessage.POP_UP_DELETE_TIROCINANTE,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: FrontendMessage.CONFERMA,
      rejectLabel: FrontendMessage.ANNULLA,
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.spinner.show();
        this.tirocinantiService.eliminaTirocinante(tirocinanteId).subscribe(response => {
          this.loadTirocinante();
          this.spinner.hide();
          this.displayModal = false;
          this.messageService.add(FrontendMessage.MESSAGE_SERVICE_TIROCINANTE_ELIMINATO);
        }, (errorResponse: HttpErrorResponse) => {
          this.spinner.hide();
          this.messageService.add({
            severity: FrontendMessage.SEVERITY_ERROR,
            summary: '',
            detail: errorResponse.error.message,
            key: 'tableAllTirocinanti'
          });
          setTimeout(() => {
            this.messageService.clear();
          }, 1500);
        });
      },
      reject: () => {
      }
    });
  }

  showDialogMaximized(dialog: Dialog) {
    dialog.maximize();
  }

  selectCity(): string {
    if (this.showCity) {
      return 'Seleziona...';
    } else {
      return this.tirocinanteSelezionato.citta;
    }

  }

  selectedProvince(): string {
    return this.tirocinanteSelezionato.provincia;

  }

  checkMatricola(matricola: string) {
    this.startMatricola = true;
    this.tirocinantiService.existTirocinante(matricola).subscribe(response => {
      if (matricola == this.tirocinanteSelezionato.matricola) {
        this.existTirocinante = false;
      } else {

        this.existTirocinante = response;
      }
    });
  }

  checkEmail(email: string) {
    this.startEmail = true;
    this.tirocinantiService.existEmailUser(email).subscribe(response => {
      if (email == this.tirocinanteSelezionato.email) {
        this.existEmail = false;
      } else {

        this.existEmail = response;
      }
    });
  }

  selectProvince() {
    this.formTirocinante.get('citta').setValue('');
    this.spinner.show();
    this.showCity = true;

    this.selectProvinceControl = true;
    this.citta = [];
    const provinceCode = this.formTirocinante.get('provincia').value.name;

    italy.forEach((city) => {
      this.spinner.show();
      if (city.Provincia === provinceCode) {
        this.citta.push(city);
      }

    });

    this.spinner.hide();
  }

  loadTirocinanteFormProgect() {
    this.datiAnagrafici = true;
    this.activeIndex = 0;
    this.displayModal = false;
    this.tirocinanti = [];
    this.loadTirocinante();
  }

  childToParent(name) {
    this.datiAnagrafici = true;
    this.activeIndex = 0;
  }

  onInputCodiceFiscale(cf: string) {
    this.startCase = true;
    const {Validator} = this.cfUtil;
    if (Validator.codiceFiscale(cf).valid) {
      this.cfValid = true;
    } else {
      this.cfValid = false;
    }
  }

  onInputAnnoImmatricolazione(anno: string) {
    const annoIniziale = new Date().getFullYear();
    const annoDiNascita = this.formTirocinante.get('dataDiNascita').value.getFullYear();
    this.startWriting = true;
    anno.split('/');
    const primaParte = anno[0] + anno[1] + anno[2] + anno[3];
    const secondaParte = anno[5] + anno[6] + anno[7] + anno[8];
    if ((+secondaParte - +primaParte) === 1) {
      if ((+primaParte <= annoIniziale) && +primaParte >= annoDiNascita + 18) {
        this.immatricolazioneValid = true;
        this.formTirocinante.get('annoImmatricolazione').markAsPristine();
      }
    } else {
      this.immatricolazioneValid = false;
      this.formTirocinante.get('annoImmatricolazione').markAsDirty();
    }
  }

  modificaTirocinante() {
    this.spinner.show();
    let provincia = '';

    let phoneCode = '';
    if (this.formTirocinante.get('prefix').touched && this.formTirocinante.get('prefix').value.phonecode != null) {
      phoneCode = this.formTirocinante.get('prefix').value.phonecode;
    } else {
      phoneCode = this.tirocinanteSelezionato.cellulare.split(' ')[0];
    }
    if (this.selectProvinceControl) {
      provincia = this.formTirocinante.value.provincia.name;
    } else {
      provincia = this.tirocinanteSelezionato.provincia;
    }
    let citta = '';
    if (this.showCity) {
      citta = this.formTirocinante.get('citta').value.Nome;
    } else {
      citta = this.tirocinanteSelezionato.citta;
    }
    const dataDiNascita = new Date(Date.UTC(this.formTirocinante.get('dataDiNascita').value.getFullYear(), this.formTirocinante.get('dataDiNascita').value.getMonth(), this.formTirocinante.get('dataDiNascita').value.getDate()));
    const tirocinanteToUpdate = new Tirocinante(
      this.tirocinanteSelezionato.id,
      this.tirocinanteSelezionato.username,
      this.formTirocinante.get('nome').value,
      this.formTirocinante.get('cognome').value,
      this.tirocinanteSelezionato.stato,
      this.formTirocinante.get('email').value,
      phoneCode + ' ' + this.formTirocinante.get('cellulare').value,
      dataDiNascita,
      this.formTirocinante.get('luogoDiNascita').value,
      this.formTirocinante.get('codiceFiscale').value,
      this.formTirocinante.get('matricola').value,
      this.formTirocinante.get('annoImmatricolazione').value,
      this.formTirocinante.get('residenza').value,
      provincia,
      citta,
      this.formTirocinante.get('cap').value);

    this.tirocinantiService.updateTirocinanteProfile(tirocinanteToUpdate).subscribe(response => {

      this.loadTirocinante();
      this.spinner.hide();

      this.immatricolazioneValid = true;
      this.existTirocinante = false;
      this.showCity = false;

      this.selectProvinceControl = false;
      setTimeout(() => {
        this.messageService.clear();
        this.nextPage();
      }, 100);

      this.switchToEditMode = false;
      this.formTirocinante.disable();

    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.clear();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: '',
        detail: errorResponse.error.message,
        key: 'tableTirocinanti'
      });
    });


  }

  goLibrettoDetail(id: number) {
    const notification = false;
    this.router.navigateByUrl(RouterPaths.ADMIN_ROUTE + '/' + RouterPaths.VISTA_LIBRETTO_DIARIO + '/id/' + id + '/' + notification);

  }

  emptyFields() {
    const fields = [];
    const controls = this.formTirocinante.controls;
    for (const name in controls) {
      if (this.utils.formValid(this.formTirocinante).includes(name) && controls[name].invalid && (controls[name].value == null || controls[name].value == '')) {
        fields.push(name);
      }
    }
    return fields;
  }
}
