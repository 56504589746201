<div class="main-area">
  <main>
    <div class="titolo-sezione">
      <i class="las la-user"></i> Inserimento tirocinante
    </div>
    <p-divider type="invisible"></p-divider>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <div class="p-fluid  p-jc-center">
      <p-steps [(activeIndex)]="activeIndex" [model]="stepNuovoTirocinante" [readonly]="true"></p-steps>
    </div>
    <div class="p-field p-col-10 p-md-12">
      <p-divider type="dashed"></p-divider>
    </div>
    <app-progetto-formativo (activateIndex)="childToParent($event)" (datiAnagrafici)="childToParent($event)"
                            *ngIf="!datiAnagrafici"
                            [tirocinanteToStore]="tirocinanteToStore"></app-progetto-formativo>
    <form (ngSubmit)="inviaformNuovoTirocinante()" *ngIf="datiAnagrafici" [formGroup]="formNuovoTirocinante">

      <div class="p-fluid p-formgrid p-grid p-jc-end">
        <div class="p-field p-col-12 p-md-4">
          <label for="nome">Nome</label>
          <input [ngClass]="{'ng-invalid ng-dirty': !formNuovoTirocinante.get('nome').valid && (formNuovoTirocinante.get('nome').dirty || formNuovoTirocinante.get('nome').touched)}" formControlName="nome"
                 id="nome"
                 pInputText required type="text"/>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="cognome">Cognome</label>
          <input [ngClass]="{'ng-invalid ng-dirty': !formNuovoTirocinante.get('cognome').valid && (formNuovoTirocinante.get('cognome').dirty || formNuovoTirocinante.get('cognome').touched)}" formControlName="cognome"
                 id="cognome"
                 pInputText required type="text">

        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="codiceFiscale">Codice fiscale</label>
          <input (input)="onInputCodiceFiscale(formNuovoTirocinante.get('codiceFiscale').value)"
                 [ngClass]="{'ng-invalid ng-dirty': !formNuovoTirocinante.get('codiceFiscale').valid && (formNuovoTirocinante.get('codiceFiscale').dirty || formNuovoTirocinante.get('codiceFiscale').touched)}"
                 formControlName="codiceFiscale"
                 id="codiceFiscale"
                 pInputText
                 required type="text">
          <small *ngIf="!cfValid && startCase" @fadeInAnimation class="p-error" id="cf-help">Codice fiscale non
            valido</small>
          <small *ngIf="cfValid" @fadeInAnimation class="success-text" id="cf-success">Codice fiscale valido</small>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="luogoDiNascita">Luogo di nascita</label>
          <input [ngClass]="{'ng-invalid ng-dirty': !formNuovoTirocinante.get('luogoDiNascita').valid && (formNuovoTirocinante.get('luogoDiNascita').dirty || formNuovoTirocinante.get('luogoDiNascita').touched)}" formControlName="luogoDiNascita"
                 id="luogoDiNascita"
                 pInputText required type="text">

        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="dataDiNascita">Data di nascita</label>
          <p-calendar [maxDate]="timeUtils.maxDateForBirthDay()"
                      [monthNavigator]="true"
                      [ngClass]="{'ng-invalid ng-dirty': !formNuovoTirocinante.get('dataDiNascita').valid && (formNuovoTirocinante.get('dataDiNascita').dirty || formNuovoTirocinante.get('dataDiNascita').touched)}"
                      [yearNavigator]="true"
                      [yearRange]="getYearRange()"
                      dateFormat="dd/mm/yy"
                      formControlName="dataDiNascita" required></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-4">
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="matricola">Matricola</label>
          <input (cancel)="startMatricola=false" (input)="checkMatricola(formNuovoTirocinante.get('matricola').value)"
                 [ngClass]="{'ng-invalid ng-dirty': !formNuovoTirocinante.get('matricola').valid && (formNuovoTirocinante.get('matricola').dirty || formNuovoTirocinante.get('matricola').touched)}"
                 formControlName="matricola" id="matricola"
                 pInputText
                 required
                 type="text">
          <small *ngIf="existTirocinante" @fadeInAnimation class="p-error" id="cf-help">Matricola già presente</small>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="annoImmatricolazione">A.A immatricolazione</label>
          <input (input)="onInputAnnoImmatricolazione(formNuovoTirocinante.get('annoImmatricolazione').value, formNuovoTirocinante.get('dataDiNascita').value)" [ngClass]="{'ng-invalid ng-dirty': (!formNuovoTirocinante.get('annoImmatricolazione').valid && (formNuovoTirocinante.get('annoImmatricolazione').dirty || formNuovoTirocinante.get('annoImmatricolazione').touched))  || !immatricolazioneValid}" formControlName="annoImmatricolazione"
                 id="annoImmatricolazione"
                 pInputText
                 placeholder="AAAA/AAAA" required>
          <small *ngIf="!immatricolazioneValid && startWriting" @fadeInAnimation class="p-error" id="cf-help">Anno di
            immatricolazione non valido</small>
          <small *ngIf="immatricolazioneValid" @fadeInAnimation class="success-text" id="cf-success">Anno di
            immatricolazione valido</small>
        </div>

        <div class="p-field p-col-12 p-md-4">
        </div>
        <div class="p-field p-col-12 p-md-12">
          <label for="residenza">Residente in</label>
          <input [ngClass]="{'ng-invalid ng-dirty': !formNuovoTirocinante.get('residenza').valid && (formNuovoTirocinante.get('residenza').dirty || formNuovoTirocinante.get('residenza').touched)}" formControlName="residenza"
                 id="residenza"
                 pInputText required>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="provincia">Prov.</label>
          <p-dropdown (onChange)="selectProvince()" [ngClass]="{'ng-invalid ng-dirty': !formNuovoTirocinante.get('provincia').valid && (formNuovoTirocinante.get('provincia').dirty || formNuovoTirocinante.get('provincia').touched)}"
                      [options]="province"
                      formControlName="provincia"
                      inputId="provincia"

                      optionLabel="name"
                      placeholder="Seleziona" required></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="citta">Città</label>
          <p-dropdown [ngClass]="{'ng-invalid ng-dirty': !formNuovoTirocinante.get('citta').valid && (formNuovoTirocinante.get('citta').dirty || formNuovoTirocinante.get('citta').touched)}" [options]="city"
                      formControlName="citta"
                      inputId="citta"
                      optionLabel="Nome" placeholder="Seleziona" required styleClass="stileRuoli"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="cap">Cap</label>
          <p-inputMask [ngClass]="{'ng-invalid ng-dirty': !formNuovoTirocinante.get('cap').valid && (formNuovoTirocinante.get('cap').dirty || formNuovoTirocinante.get('cap').touched)}" formControlName="cap"
                       id="cap"
                       mask="99999" required>
          </p-inputMask>
        </div>

        <div class="p-field p-col-12 p-md-4 parent">
          <label for="prefix">Cellulare<label class="required-asterisk">*</label></label>

          <div class="block-div">
            <div class="first">
              <p-dropdown [options]="country" formControlName="prefix"
                          id="prefix"
                          optionLabel="phonecode" placeholder="+39" styleClass="stileRuoli"></p-dropdown>
            </div>
            <div class="last">
              <p-inputMask [ngClass]="{'ng-invalid ng-dirty': !formNuovoTirocinante.get('cellulare').valid && (formNuovoTirocinante.get('cellulare').dirty || formNuovoTirocinante.get('cellulare').touched)}" formControlName="cellulare" id="cellulare"
                           mask="9999999999"

                           required></p-inputMask>
            </div>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="email">Indirizzo email</label>
          <input (cancel)="startEmail=false" (input)="checkEmail(formNuovoTirocinante.get('email').value)"
                 [ngClass]="{'ng-invalid ng-dirty': !formNuovoTirocinante.get('email').valid && (formNuovoTirocinante.get('email').dirty || formNuovoTirocinante.get('email').touched)}"
                 formControlName="email"
                 id="email"
                 pInputText required type="email">
          <small *ngIf="existEmail" @fadeInAnimation class="p-error" id="cf-help">Attenzione! Indirizzo email già in
            uso.</small>

        </div>
        <div class="p-field p-col-12 p-md-4">
        </div>


        <div class="p-field p-col-12 p-md-12">
          <p-divider type="dashed"></p-divider>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <button (click)="resetForm()" class="p-button p-button-danger" label="Annulla" pButton type="button"></button>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <button [disabled]="!formNuovoTirocinante.valid || !immatricolazioneValid || existTirocinante || existEmail || formNuovoTirocinante.get('citta').value==''" class="p-button p-button-success" label="Continua" pButton
                  type="submit"
          ></button>
        </div>
      </div>
    </form>

    <p-message *ngIf="utils.formValid(formNuovoTirocinante).length > 0 && emptyFields().length == 0" @simpleFadeAnimation
               severity="error" text="Attenzione! Campo/i non popolati correttamente"></p-message>
    <div class="p-md-12" style="padding: 0px"></div>
    <p-message *ngIf="emptyFields().length > 0" @simpleFadeAnimation
               severity="error" text="Attenzione! Campo/i obbligatori non popolati"></p-message>
    <p-messages [enableService]="true"></p-messages>
  </main>

</div>


