import {Component} from '@angular/core';
import {MessageService} from 'primeng/api';
import {fadeInAnimation} from '../../../animations/fade-in-animation';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import GenericUtils from '../../../utils/generic-utils';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {StorageConstants} from '../../../constants/storage-constants';
import {ChangePasswordNoTokenRequest} from '../../../models/password-recovery/change-password-no-token-request';
import {Constants} from '../../../constants/constants';
import {FrontendMessage} from '../../../constants/frontend-message';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-modifica-password-tirocinante',
  templateUrl: './modifica-password-tirocinante.component.html',
  styleUrls: ['./modifica-password-tirocinante.component.scss'],
  providers: [MessageService],
  animations: [
    fadeInAnimation
  ]
})
export class ModificaPasswordTirocinanteComponent {

  formModificaPassword: FormGroup;
  nuovaPassword: string;
  utils = GenericUtils;
  selectField: boolean;

  constructor(private spinner: NgxSpinnerService, private router: Router, private fb: FormBuilder, private authService: AuthenticationService, private messageService: MessageService) {
    this.formModificaPassword = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
    });
    this.selectField = false;
  }

  modificaPassword() {
    this.spinner.show();
    this.messageService.clear();
    const userData = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
    const request: ChangePasswordNoTokenRequest = {
      userId: userData.userId,
      currentPassword: this.formModificaPassword.get('currentPassword').value,
      newPassword: this.formModificaPassword.get('newPassword').value,
      confirmNewPassword: this.formModificaPassword.get('confirmNewPassword').value
    };

    this.selectField = false;
    this.authService.changePasswordNoTokenTirocinante(request).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.formModificaPassword.reset();
        this.spinner.hide();
        this.messageService.add(FrontendMessage.CHANGE_PASSWORD_OK);
        setTimeout(() => {
          this.messageService.clear();
        }, 2000);
      }
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: 'Attenzione! ',
        detail: 'Il campo Password in uso non è corretto'
      });
      setTimeout(() => {

        this.messageService.clear();
      }, 3000);

    });
  }

}
