import {MeseBustaPaga} from '../models/mese-busta-paga';

export var MESI_BUSTA_PAGA: MeseBustaPaga[] = [
  new MeseBustaPaga('Gennaio', 1, false),
  new MeseBustaPaga('Febbraio', 2, false),
  new MeseBustaPaga('Marzo', 3, false),
  new MeseBustaPaga('Aprile', 4, false),
  new MeseBustaPaga('Maggio', 5, false),
  new MeseBustaPaga('Giugno', 6, false),
  new MeseBustaPaga('Luglio', 7, false),
  new MeseBustaPaga('Agosto', 8, false),
  new MeseBustaPaga('Settembre', 9, false),
  new MeseBustaPaga('Ottobre', 10, false),
  new MeseBustaPaga('Novembre', 11, false),
  new MeseBustaPaga('Dicembre', 12, false),
  new MeseBustaPaga('Tredicesima', 13, false)
];
