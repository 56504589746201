export class MeseBustaPaga {
  private nome: string;
  private codice: number;
  private disabled: boolean;

  constructor(nome: string, codice: number, disabled: boolean) {
    this.nome = nome;
    this.codice = codice;
    this.disabled = disabled;
  }

  public getNome(): string {
    return this.nome;
  }

  public setNome(nome: string) {
    this.nome = nome;
  }

  public getCodice(): number {
    return this.codice;
  }

  public setCodice(codice: number) {
    this.codice = codice;
  }

  public isDisabled() {
    return this.disabled;
  }

  public setDisabled(disabled: boolean) {
    this.disabled = disabled;
  }
}
