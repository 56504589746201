import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceResponse} from '../models/response/service-response';
import {Endpoints} from '../constants/endpoints';
import {IProgettoFormativo} from '../models/tirocinio/progetto-formativo.interface';
import {Observable} from 'rxjs';
import {LoginResponseInterface} from '../models/login/login-response.interface';
import {StorageConstants} from '../constants/storage-constants';

@Injectable()
export class ProgettoFormativoService {

  constructor(private http: HttpClient) {
  }

  nuovoProgettoFormativo(progettoFormativo: IProgettoFormativo) {
    return this.http.post<ServiceResponse>(Endpoints.NUOVO_PROGETTO_FORMATIVO, progettoFormativo);
  }

  updateProgettoFormativo(progettoFormativo: IProgettoFormativo) {
    return this.http.put<ServiceResponse>(Endpoints.MODIFICA_PROGETTO_FORMATIVO, progettoFormativo);
  }

  getProgettoFormativo(): Observable<IProgettoFormativo> {
    const userData: LoginResponseInterface = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
    return this.http.get<IProgettoFormativo>(Endpoints.GET_PROGETTO_FORMATIVO_TIROCINANTE + userData.userId);
  }

  getOneProgettoFormativo(id): Observable<IProgettoFormativo> {
    return this.http.get<IProgettoFormativo>(Endpoints.GET_PROGETTO_FORMATIVO_TIROCINANTE + id);
  }

  getAllProgettiFormativi(): Observable<IProgettoFormativo[]> {
    return this.http.get<IProgettoFormativo[]>(Endpoints.GET_ALL_PROGETTI_FORMATIVI);
  }
}
