import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {State} from 'country-state-city';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'dashboard-frontend';
  province: any[];

  constructor(private config: PrimeNGConfig) {
    this.province = [];
  }

  ngOnInit() {
    this.config.setTranslation({
      startsWith: 'Inizia con',
      contains: 'Contiene',
      notContains: 'Non contiene',
      endsWith: 'Termina con',
      equals: 'Uguale',
      notEquals: 'Non uguale',
      noFilter: 'Nessun filtro',
      lt: 'Minore di',
      lte: 'Minore o uguale a',
      gt: 'Maggiore di',
      gte: 'Maggiore o uguale',
      is: 'Is',
      isNot: 'Is not',
      before: 'Prima',
      after: 'Dopo',
      clear: 'Pulisci',
      apply: 'Applica',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Yes',
      reject: 'No',
      choose: 'Scegli',
      upload: 'Upload',
      cancel: 'Cancella',
      dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
      monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
      today: 'Oggi',
      weekHeader: 'St'
    });
  }


  getProvince(): any {
    const citta = State.getStatesOfCountry('IT');
    citta.forEach((province) => {
      if (province.name.startsWith('Province') || province.name.endsWith('Province') ||
        province.name.startsWith('Metropolitan City of') || province.name.startsWith('Libero consorzio comunale di ')) {
        this.province.push(province);
        // console.log('qaa');
      }

    });
    for (let i = 0; i < this.province.length; i++) {
      this.province[i].name = this.province[i].name.replace('Metropolitan City of ', '');
      this.province[i].name = this.province[i].name.replace('Libero consorzio comunale di ', '');
      this.province[i].name = this.province[i].name.replace('Province of ', '');
      this.province[i].name = this.province[i].name.replace(' and ', ' e ');
      this.province[i].name = this.province[i].name.replace(' Province', '');
    }
    this.province.sort(function(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return this.province;
  }

}
