<div class="main-area">
  <main>
    <div class="titolo-sezione">
      <i class="lab la-telegram"></i> Notifiche
    </div>
    <p-messages [enableService]="true"></p-messages>
    <div *ngIf="inApprovazioneTimesheets.concat(lockedTimesheets).length == 0" class="p-grid">
      <div class="p-col-12">
        <div class="msgNoData">
          <span class="las la-5x la-info-circle"></span>
          <h3>Non ci sono notifiche.</h3>
        </div>
      </div>
    </div>
    <div *ngIf="inApprovazioneTimesheets.concat(lockedTimesheets).length != 0" class="p-field  ">
      <p-divider align="left">
        <p-button label="Dipendenti" styleClass="p-button-text"></p-button>
      </p-divider>
    </div>
    <p-table *ngIf="(inApprovazioneTimesheets.concat(lockedTimesheets).length > 0) && showTable" [paginator]="true"
             [rowHover]="true"
             [rowsPerPageOptions]="[10,20,30]"
             [rows]="10"
             [showCurrentPageReport]="true" [totalRecords]="inApprovazioneTimesheets.concat(lockedTimesheets).length" [value]="inApprovazioneTimesheets.concat(lockedTimesheets)"
             currentPageReportTemplate="Risultati da {first} a {last}. Totale {totalRecords} notifiche dipendenti"
             sortField="status"
             sortOrder="-1"
             styleClass="p-mt-4 p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th class="p-text-center" scope="col">Dipendente</th>
          <th class="p-text-center" scope="col">Periodo rapportino</th>
          <th class="p-text-center" scope="col">Richiesta</th>
          <th class="p-text-center" scope="col">Azione</th>
        </tr>
      </ng-template>
      <ng-template let-timesheet pTemplate="body">
        <tr>
          <td class="p-text-center">{{ timesheet.user_id | userAlias:dipendenti}}</td>
          <td class="p-text-center">{{timesheet.mese - 1 | getNomeMese}} {{timesheet.anno}}</td>
          <td *ngIf="inApprovazioneTimesheets.includes(timesheet, 0)" class="p-text-center">Approvazione</td>
          <td *ngIf="lockedTimesheets.includes(timesheet, 0)"class="p-text-center">Sblocca</td>
          <td class="p-text-center">
            <button *ngIf="inApprovazioneTimesheets.includes(timesheet, 0)" (click)="goToTimesheetDetail(timesheet)" class="p-button p-button-outlined" label="Visualizza" pButton
                    type="button"></button>
            <button *ngIf="lockedTimesheets.includes(timesheet, 0)" (click)="sbloccaTimesheet(timesheet)" class="p-button  p-button-warning" label="Sblocca" pButton
                    type="button"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-field p-col-8 p-md-12"></div>
    <div *ngIf="librettoDiario.length != 0" class="p-field ">
      <p-divider align="left">
        <p-button label="Tirocinanti" styleClass="p-button-text"></p-button>
      </p-divider>
    </div>
    <p-table *ngIf="librettoDiario.length > 0 && showTable" [paginator]="true"
             [rowHover]="true"
             [rowsPerPageOptions]="[10,20,30]"
             [rows]="10"
             [showCurrentPageReport]="true" [totalRecords]="librettoDiario.length" [value]="librettoDiario"
             currentPageReportTemplate="Risultati da {first} a {last}. Totale {totalRecords} notifiche tirocinanti"
             sortField="status"
             sortOrder="-1"
             styleClass="p-mt-4 p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th class="p-text-center" scope="col">Tirocinante</th>
          <th class="p-text-center" scope="col">Richiesta</th>
          <th class="p-text-center" scope="col">Azione</th>
        </tr>
      </ng-template>
      <ng-template let-libretto pTemplate="body">
        <tr>
          <td class="p-text-center">{{ libretto.id | tirocinanteAlias:tirocinanti }}</td>
          <td *ngIf="libretto.richiestaSblocco" class="p-text-center">Sblocco</td>
          <td *ngIf="!libretto.richiestaSblocco && libretto.statoTirocinio.id == 4" class="p-text-center">Approvazione
          </td>
          <td class="p-text-center">


            &nbsp;&nbsp;
            <button (click)="golibrettoDetail(libretto.id)" *ngIf="!libretto.richiestaSblocco && libretto.statoTirocinio.id == 4 " class="p-button p-button-outlined"
                    label="Visualizza"
                    pButton type="button"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>


  </main>
</div>
