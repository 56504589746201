import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../constants/endpoints';
import {AnalyticsOverview} from '../models/analytics/analytics-overview';
import {MonthOverview} from '../models/month-overview';

@Injectable()
export class StatisticheService {

  constructor(private http: HttpClient) {
  }

  getMonthOverview(): Observable<MonthOverview> {
    return this.http.get<MonthOverview>(Endpoints.STATISTICHE_MONTH_OVERVIEW);
  }

  getLastThreeMonths(): Observable<AnalyticsOverview> {
    return this.http.get<AnalyticsOverview>(Endpoints.STATISTICHE_LAST_3_MONTHS);
  }

  getLastSixMonths(): Observable<AnalyticsOverview> {
    return this.http.get<AnalyticsOverview>(Endpoints.STATISTICHE_LAST_6_MONTHS);
  }
}
