import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {StorageConstants} from 'src/app/constants/storage-constants';
import {MESI_MOCK} from 'src/app/mock/mesi.mock';
import {Anno} from 'src/app/models/anno';
import {Mese} from 'src/app/models/mese';
import {STATI_RAPPORTINO} from 'src/app/models/timesheet/stati-rapportino-mock';
import {StatoRapportino} from 'src/app/models/timesheet/stato-rapportino.interface';
import {TimesheetInterface} from 'src/app/models/timesheet/timesheet.interface';
import {TimesheetService} from 'src/app/services/timesheet.service';
import {UserService} from 'src/app/services/user.service';
import TimeUtils from 'src/app/utils/time-utils';

@Component({
  selector: 'app-storico',
  templateUrl: './storico.component.html',
  styleUrls: ['./storico.component.scss'],
  providers: [MessageService, TimesheetService]
})
export class StoricoComponent implements OnInit {

  formSelezioneAnno: FormGroup;
  mesi: Mese[] = MESI_MOCK;
  anni: Anno[] = [];
  currentDate: Date;
  timesheets: TimesheetInterface[] = [];
  timesheetsFiltrati: TimesheetInterface[] = [];
  formRicerca: FormGroup;
  showTable: boolean;
  userData = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
  timeUtils = TimeUtils;
  searchDone: boolean;
  statiRapportino: StatoRapportino[] = STATI_RAPPORTINO;
  getAllTimesheetsB: boolean;


  mese: string = null;
  anno: string = null;
  statoRapportino: string = null;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private timesheetService: TimesheetService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private messageService: MessageService,
              private activatedRoute: ActivatedRoute
  ) {

    this.activatedRoute.params.subscribe(
      (param) => {
        this.mese = this.router.getCurrentNavigation().extras.state.mese;
        this.anno = this.router.getCurrentNavigation().extras.state.anno;
        this.statoRapportino = this.router.getCurrentNavigation().extras.state.statoRapportino;
        this.getAllTimesheetsB = this.router.getCurrentNavigation().extras.state.getAllTimesheetsB;
      });
    this.formRicerca = this.fb.group({
      meseSelezionato: [''],
      annoSelezionato: [''],
      statoSelezionato: ['']
    });

    this.anni = this.timeUtils.yearsForDropdown();

    this.currentDate = new Date();
  }

  ngOnInit(): void {
    if (!this.getAllTimesheetsB && (this.anno == null && this.mese == null && this.statoRapportino == null)) {
      this.statoRapportino = '';
      const month = this.currentDate.getMonth() + 1;
      this.mese = month.toString();
      this.anno = this.currentDate.getUTCFullYear().toString();

    }
    this.formRicerca.setValue({
      meseSelezionato: +this.mese - 1,
      annoSelezionato: this.anno,
      statoSelezionato: this.statoRapportino
    });
    if (!this.getAllTimesheetsB) {
      this.loadFirst();
    }
    else {
      this.resetSearch();
    }
  }

  loadFirst() {
    this.getAllTimesheetsB = false;
    const month = this.currentDate.getMonth() + 1;
    this.timesheetService.searchTimesheets(this.userData.userId, this.mese, this.anno, this.statoRapportino).subscribe(timesheets => {
      this.timesheets = timesheets;
      this.timesheetsFiltrati = timesheets;
      this.spinner.hide();
      this.showTable = true;
      this.searchDone = true;

      this.showTable = true;
    }, error => {
      this.spinner.hide();
      this.searchDone = true;
    });
  }

  loadTimesheets() {
    this.getAllTimesheetsB = false;
    this.spinner.show();
    this.mese = (this.formRicerca.get('meseSelezionato').value == null || this.formRicerca.get('meseSelezionato').value === '') ? '' : this.formRicerca.get('meseSelezionato').value + 1;
    this.anno = (this.formRicerca.get('annoSelezionato').value == null || this.formRicerca.get('annoSelezionato').value === '') ? '' : this.formRicerca.get('annoSelezionato').value;
    this.statoRapportino = (this.formRicerca.get('statoSelezionato').value == null) ? '' : this.formRicerca.get('statoSelezionato').value;

    if (this.anno != '' || this.mese != '' || this.statoRapportino != '') {

      this.timesheetService.searchTimesheets(this.userData.userId, this.mese, this.anno, this.statoRapportino).subscribe(timesheets => {

        this.timesheets = timesheets;
        this.timesheetsFiltrati = timesheets;
        this.spinner.hide();
        this.showTable = true;
        this.searchDone = true;
      }, error => {
        this.spinner.hide();
        this.searchDone = true;
      });
    } else {
      this.timesheetService.getAllTimesheetsByUserId(this.userData.userId).subscribe(timesheets => {

        this.timesheets = timesheets;
        this.timesheetsFiltrati = timesheets;
        this.spinner.hide();
        this.showTable = true;
        this.searchDone = true;
      }, error => {
        this.spinner.hide();
        this.searchDone = true;
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
      });
    }
  }

  resetSearch() {
    this.getAllTimesheetsB = true;
    this.timesheetService.getAllTimesheetsByUserId(this.userData.userId).subscribe(timesheets => {

      this.timesheets = timesheets;
      this.timesheetsFiltrati = timesheets;
      this.spinner.hide();
      this.showTable = true;
      this.searchDone = true;
    }, error => {
      this.spinner.hide();
      this.searchDone = true;
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
    this.timesheets = [];
    this.timesheetsFiltrati = [];
    this.showTable = false;
    this.searchDone = false;
    this.formRicerca.reset();
  }

  goToTimesheetDetail(timesheet) {
    const navigationExtras: NavigationExtras = {
      state: {
        anno: this.anno,
        mese: this.mese,
        statoRapportino: this.statoRapportino,
        getAllTimesheetsB: this.getAllTimesheetsB
      },
    };
    this.router.navigate(['/dipendente/vista-rapportino/id/' + timesheet.id], navigationExtras);
  }

}
