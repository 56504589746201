import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {StorageConstants} from 'src/app/constants/storage-constants';
import {MESI_BUSTA_PAGA} from 'src/app/mock/mesi-busta-paga';
import {Anno} from 'src/app/models/anno';
import {LoginResponse} from 'src/app/models/login/login-response';
import {MeseBustaPaga} from 'src/app/models/mese-busta-paga';
import {BustaPagaResponse} from 'src/app/models/response/busta-paga-response';
import {BustePagaService} from 'src/app/services/buste-paga.service';
import TimeUtils from 'src/app/utils/time-utils';

@Component({
  selector: 'app-buste-paga-dipendente',
  templateUrl: './buste-paga-dipendente.component.html',
  styleUrls: ['./buste-paga-dipendente.component.scss'],
  providers: [MessageService]
})
export class BustePagaDipendenteComponent implements OnInit {

  formRicerca: FormGroup;
  mesi: MeseBustaPaga[] = MESI_BUSTA_PAGA;
  anni: Anno[] = [];
  timeUtils = TimeUtils;
  showTable: boolean;
  bustePagaFiltered: BustaPagaResponse[] = [];
  firstLanding: boolean = true;
  userData: LoginResponse = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
  month: string;
  year: string;
  user: number;
  currentDate: Date;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private bustePagaService: BustePagaService) {
    this.anni = this.timeUtils.yearsForDropdown();
    this.month = '';
    this.year = '';
    this.user = 0;
    this.formRicerca = this.fb.group({
      meseSelezionato: [''],
      annoSelezionato: [this.anni[0].nome]
    });
    this.currentDate = new Date();
  }

  ngOnInit(): void {
    this.user = this.userData.userId;
    this.month = '';
    this.year = this.currentDate.getFullYear().toString();
    this.loadFirst();
  }

  doSearch() {
    this.spinner.show();
    this.month = (this.formRicerca.get('meseSelezionato').value == null || this.formRicerca.get('meseSelezionato').value === '') ? '' : this.formRicerca.get('meseSelezionato').value;
    this.year = (this.formRicerca.get('annoSelezionato').value == null || this.formRicerca.get('annoSelezionato').value === '') ? '' : this.formRicerca.get('annoSelezionato').value;
    this.user = this.userData.userId;
    this.bustePagaService.searchBustePaga(this.user.toString(), +this.month, this.year).subscribe(bustePaga => {
      this.bustePagaFiltered = bustePaga;
      this.spinner.hide();
      this.firstLanding = false;
      this.showTable = true;
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.firstLanding = false;

    });
  }

  loadFirst() {
    this.bustePagaService.searchBustePaga(this.user.toString(), +this.month, this.year).subscribe(bustePaga => {
      this.bustePagaFiltered = bustePaga;
      this.spinner.hide();
      this.firstLanding = false;
      this.showTable = true;
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.firstLanding = false;

    });
  }

  resetSearch() {
    this.showTable = false;
    this.firstLanding = false;
    this.formRicerca.reset();
    this.spinner.show();
    this.bustePagaService.searchBustePagaById(this.userData.userId).subscribe(bustePaga => {
      this.bustePagaFiltered = bustePaga;
      // console.log(bustePaga);
      this.spinner.hide();
      this.showTable = true;
    }, error => {
      this.spinner.hide();
    });
    // console.log(this.bustePagaFiltered);
  }

  goToBustaPaga(id: string) {
    this.router.navigateByUrl('/dipendente/busta-paga/id/' + id);
  }

  openPdf(bustaPagaId: string) {
    this.spinner.show();
    this.bustePagaService.getFilePdfBustaPaga_v2(bustaPagaId, this.userData.userId + '').subscribe((response) => {
      let file = new Blob([response], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.spinner.hide();
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_ERROR,
        detail: errorResponse.error.message
      });
    });
  }
}
