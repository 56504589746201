import {StatusProgettoEnum} from './enums/status-progetto';

export class StatusProgetto {
  private name: StatusProgettoEnum;
  private code: StatusProgettoEnum;

  constructor(name: StatusProgettoEnum, code: StatusProgettoEnum) {
    this.name = name;
    this.code = code;
  }

  public getName(): StatusProgettoEnum {
    return this.name;
  }

  public setName(name: StatusProgettoEnum) {
    this.name = name;
  }

  public getCode(): StatusProgettoEnum {
    return this.code;
  }

  public setCode(code: StatusProgettoEnum) {
    this.code = code;
  }
}
