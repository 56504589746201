<div class="main-area">
  <main>
    <div class="titolo-sezione">
      <i class="las la-calendar"></i> Lista rapportini
    </div>
    <p-divider type="dashed"></p-divider>
    <p-toast position="bottom-right"></p-toast>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p> Caricamento... </p>
    </ngx-spinner>
    <form (ngSubmit)="loadTimesheets()" [formGroup]="formRicerca">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-3 p-md-3">
          <label for="dipendenteSelezionato">Dipendente</label>
          <p-dropdown
            [filter]="false"
            [itemSize]="34"
            [options]="dipendenti"
            [virtualScroll]="true"
            formControlName="dipendenteSelezionato"
            inputId="dipendenteSelezionato" optionLabel="alias" optionValue="id"
            placeholder="Seleziona">
          </p-dropdown>
        </div>
        <div class="p-field p-col-2 p-md-2">
          <label for="mese">Mese</label>
          <p-dropdown [dataKey]="" [options]="mesi" formControlName="meseSelezionato" inputId="meseSelezionato"
                      optionLabel="nome" optionValue="codice" placeholder="Seleziona">
          </p-dropdown>
        </div>
        <div class="p-field p-col-2 p-md-2">
          <label for="anno">Anno</label>
          <p-dropdown [options]="anni" formControlName="annoSelezionato" inputId="annoSelezionato"
                      optionLabel="nome" optionValue="codice" placeholder="Seleziona">
          </p-dropdown>
        </div>
        <div class="p-field p-col-2 p-md-2">
          <label for="stato">Stato</label>
          <p-dropdown [options]="statiRapportino" formControlName="statoSelezionato" inputId="statoSelezionato"
                      optionLabel="nome" optionValue="id" placeholder="Seleziona">
          </p-dropdown>
        </div>
        <div class="p-field p-col margine-sup">
          <button class="p-button p-button-primary" label="Cerca" pButton type="submit"></button>
        </div>
        <div class="p-field p-col margine-sup">
          <button (click)="resetSearch()" class="p-button p-button-secondary" label="Annulla" pButton
                  type="button"></button>
        </div>
      </div>
    </form>
    <div *ngIf="!showTable" @fadeInAnimation class="p-grid">
      <div class="p-col-12">
        <div class="msgNoData">
          <span class="las la-5x la-info-circle"></span>
          <h3>Non hai effettuato alcuna ricerca.</h3>
        </div>
      </div>
    </div>
    <div *ngIf="timesheetsFiltrati.length == 0 && noDataFound" @fadeInAnimation class="p-grid">
      <div class="p-col-12">
        <div class="msgNoData">
          <span class="las la-5x la-info-circle"></span>
          <h3>Nessuna rapportino trovato corrispondente ai criteri di ricerca inseriti.</h3>
        </div>
      </div>
    </div>
    <p-table
      *ngIf="showTable && timesheetsFiltrati.length > 0"
      [paginator]="true"
      [rowHover]="true"
      [rowsPerPageOptions]="[10,20,30]"
      [rows]="10" [showCurrentPageReport]="true" [totalRecords]="timesheetsFiltrati.length"
      [value]="timesheetsFiltrati"
      currentPageReportTemplate="Risultati da {first} a {last}. Totale {totalRecords} rapportino/i"
      sortField="status"
      sortOrder="-1"
      styleClass="p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th class="p-text-center" scope="col">Dipendente</th>
          <th class="p-text-center" pSortableColumn="mese" scope="col">Mese
            <p-sortIcon field="mese"></p-sortIcon>
          </th>
          <th class="p-text-center" pSortableColumn="anno" scope="col">Anno
            <p-sortIcon field="mese"></p-sortIcon>
          </th>
          <th class="p-text-center" pSortableColumn="totaleOre" scope="col">Totale ore
            <p-sortIcon field="totaleOre"></p-sortIcon>
          </th>
          <th class="p-text-center" scope="col">
            Stato
          </th>
          <th class="p-text-center">Azioni</th>
        </tr>
      </ng-template>
      <ng-template let-timesheet pTemplate="body">
        <tr style="height:34px">
          <td class="p-text-center">{{ timesheet.user_id | userAlias:dipendenti }}</td>
          <td class="p-text-center">{{timesheet.mese - 1 | getNomeMese}}</td>
          <td class="p-text-center">{{timesheet.anno}}</td>
          <td class="p-text-center">{{timesheet.totaleOre}}</td>
          <td class="p-text-center">
            <p-tag [severity]="timesheet.statoRapportino.id | statoRapportino"
                   [style]="{'width': '100%'}"
                   [value]="timesheet.statoRapportino.nome"></p-tag>
          </td>
          <td class="p-text-center">
            <button (click)="goToTimesheetDetail(timesheet)" class="p-button p-button-text" icon="pi pi-eye" pButton
                    type="button"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <br>

    <p-dialog *ngIf="selectedDay" [(visible)]="displayModal" [draggable]="false"
              [header]="'Dettaglio ' + (selectedDay.dataCompleta | date:'dd/MM/YYYY')" modal="true">

      <p-table [autoLayout]="true" [value]="selectedDay.activities">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col">Codice Progetto</th>
            <th scope="col">Inizio</th>
            <th scope="col">Fine</th>
            <th scope="col">Ore</th>
          </tr>
        </ng-template>
        <ng-template let-activity pTemplate="body">
          <tr>
            <td>{{activity.codiceProgetto}}</td>
            <td>{{ activity.inizio | date:'dd/MM/YYYY HH:mm'  }}</td>
            <td>{{ moment(activity.fine).format('DD/MM/YYYY HH:mm')  }}</td>
            <td>{{ activity.parzialeOre }}</td>
          </tr>
        </ng-template>
      </p-table>

      <div class="p-col-12 totOre">
        Totale ore: {{ selectedDay.totaleOreGiornata }}
      </div>

    </p-dialog>
  </main>
</div>
