export class ChangePasswordRequest {
  private username: string;
  private tokenId: string;
  private password1: string;
  private password2: string;

  constructor(username: string, tokenId: string, password1: string, password2: string) {
    this.username = username;
    this.tokenId = tokenId;
    this.password1 = password1;
    this.password2 = password2;
  }

  public getUsername(): string {
    return this.username;
  }

  public getTokenId(): string {
    return this.tokenId;
  }

  public getPassword1(): string {
    return this.password1;
  }

  public getPassword2(): string {
    return this.password2;
  }
}
