import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import * as moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {fadeInAnimation} from 'src/app/animations/fade-in-animation';
import {simpleFadeAnimation} from 'src/app/animations/simple-fade-animation';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {MESI_MOCK} from 'src/app/mock/mesi.mock';
import {Anno} from 'src/app/models/anno';
import {Mese} from 'src/app/models/mese';
import {Timesheet} from 'src/app/models/timesheet';
import {TimesheetInterface} from 'src/app/models/timesheet/timesheet.interface';
import {UserInterface} from 'src/app/models/user-interface';
import {TimesheetService} from 'src/app/services/timesheet.service';
import {UserService} from 'src/app/services/user.service';
import TimeUtils from 'src/app/utils/time-utils';
import {STATI_RAPPORTINO} from 'src/app/models/timesheet/stati-rapportino-mock';
import {StatoRapportino} from 'src/app/models/timesheet/stato-rapportino.interface';
import {TimesheetStatus} from '../../../models/enums/timesheet-status';

@Component({
  selector: 'app-timesheets',
  templateUrl: './timesheets.component.html',
  styleUrls: ['./timesheets.component.scss'],
  providers: [TimesheetService, MessageService],
  animations: [simpleFadeAnimation, fadeInAnimation]
})
export class TimesheetsComponent implements OnInit {
  formRicerca: FormGroup;
  formGestioneTimesheet: FormGroup;
  mesi: Mese[] = MESI_MOCK;
  anni: Anno[] = [];
  timeUtils = TimeUtils;

  timesheets: TimesheetInterface[] = [];
  timesheetsFiltrati: TimesheetInterface[] = [];
  timesheetsFiltered: Timesheet[] = [];
  selectedTimesheet: Timesheet;
  selectedDay: any = undefined;
  moment: any = moment;
  statusArray: any;
  selectedStatus: any;
  noDataFound: boolean;
  getAllTimesheetsB: boolean;


  daysCurrentMonth: any;
  giorniSettimana = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
  giorniDaDisabilitare: any;
  meseAnno: string;
  currentDate: Date;
  selectedMonth: any;
  statiRapportino: StatoRapportino[] = STATI_RAPPORTINO;
  dipendenti: UserInterface[] = [];


  displayModal: boolean = false;
  showTable: boolean;

  annos: string;

  mese: string = null;
  anno: string = null;
  dipendente: string = null;
  statoRapportino: string = null;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private timesheetService: TimesheetService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute
  ) {

    this.activatedRoute.params.subscribe(
      (param) => {
        this.dipendente = this.router.getCurrentNavigation().extras.state.dipendente;
        this.mese = this.router.getCurrentNavigation().extras.state.mese;
        this.anno = this.router.getCurrentNavigation().extras.state.anno;
        this.statoRapportino = this.router.getCurrentNavigation().extras.state.statoRapportino;
        this.getAllTimesheetsB = this.router.getCurrentNavigation().extras.state.getAllTimesheetsB;
      });

    // console.log(this.getAllTimesheetsB);
    this.anni = this.timeUtils.yearsForDropdown();
    this.spinner.show();
    this.userService.getAllUsersSorted().subscribe(dipendenti => {


      for (let dipendente of dipendenti) {
        this.dipendenti.push({
          id: dipendente.id,
          username: dipendente.username,
          nome: dipendente.nome,
          cognome: dipendente.cognome,
          alias: dipendente.cognome + ' ' + dipendente.nome + ' - ' + dipendente.codiceFiscale,
          email: dipendente.email,
          cellulare: dipendente.cellulare,
          dataDiNascita: dipendente.dataDiNascita,
          status: dipendente.status,
          codiceFiscale: dipendente.codiceFiscale,
          tipoContratto: dipendente.tipoContratto,
          dataInizioRapporto: dipendente.dataInizioRapporto,
          dataFineRapporto: dipendente.dataFineRapporto,
          oreSettimanali: dipendente.oreSettimanali,
          chiaviUfficio: dipendente.chiaviUfficio,
          telefonoAziendale: dipendente.telefonoAziendale,
          comodatoPc: dipendente.comodatoPc,
          mansione: dipendente.mansione,
          roles: dipendente.roles,
          progetti: dipendente.progetti
        });
      }
      if (!this.getAllTimesheetsB && (this.anno == null && this.dipendente == null && this.mese == null && this.statoRapportino == null)) {
        this.dipendente = '';
        const month = this.currentDate.getMonth() + 1;
        this.mese = month.toString();
        this.anno = this.currentDate.getUTCFullYear().toString();
        this.statoRapportino = '';
      }

      this.formRicerca.setValue({
        dipendenteSelezionato: this.dipendente,
        meseSelezionato: +this.mese - 1,
        annoSelezionato: this.anno,
        statoSelezionato: this.statoRapportino
      });


      if (!this.getAllTimesheetsB) {
        this.loadFirst();
      }
      else {
        this.resetSearch();
      }
      this.spinner.hide();
    });


    this.formRicerca = this.fb.group({
      dipendenteSelezionato: [''],
      meseSelezionato: [''],
      annoSelezionato: [''],
      statoSelezionato: ['']
    });
    this.statusArray = [
      {name: TimesheetStatus.IN_LAVORAZIONE, code: TimesheetStatus.IN_LAVORAZIONE},
      {name: TimesheetStatus.DA_APPROVARE, code: TimesheetStatus.DA_APPROVARE},
      {name: TimesheetStatus.APPROVATO, code: TimesheetStatus.APPROVATO}
    ];


    this.currentDate = new Date();
    this.selectedMonth = this.mesi[this.currentDate.getMonth()];
    this.daysCurrentMonth = this.getDaysArray_v2(this.currentDate.getUTCFullYear(), this.currentDate.getMonth());
    if (this.daysCurrentMonth[0].giornoSettimana == 0) {
      this.giorniDaDisabilitare = Array.from(Array(this.daysCurrentMonth[0].giornoSettimana + 6), (x, i) => i);
    } else {
      this.giorniDaDisabilitare = Array.from(Array(this.daysCurrentMonth[0].giornoSettimana - 1), (x, i) => i);
    }
    this.meseAnno = moment().locale('it').format('MMMM YYYY');

  }

  ngOnInit(): void {

  }

  loadFirst() {
    this.getAllTimesheetsB = false;
    const month = this.currentDate.getMonth() + 1;
    this.timesheetService.searchTimesheets(this.dipendente, this.mese, this.anno, this.statoRapportino).subscribe(timesheets => {

      this.timesheets = timesheets;
      this.timesheetsFiltrati = timesheets;
      this.spinner.hide();
      // console.log(timesheets.length);
      if (timesheets.length === 0) {
        this.noDataFound = true;
      }
      this.showTable = true;
    }, error => {
      this.spinner.hide();
      this.noDataFound = true;
    });
  }

  loadTimesheets() {
    this.getAllTimesheetsB = false;
    this.spinner.show();
    this.mese = (this.formRicerca.get('meseSelezionato').value == null || this.formRicerca.get('meseSelezionato').value === '') ? '' : this.formRicerca.get('meseSelezionato').value + 1;
    this.anno = (this.formRicerca.get('annoSelezionato').value == null || this.formRicerca.get('annoSelezionato').value === '') ? '' : this.formRicerca.get('annoSelezionato').value;
    this.dipendente = (this.formRicerca.get('dipendenteSelezionato').value == null) ? '' : this.formRicerca.get('dipendenteSelezionato').value;
    this.statoRapportino = (this.formRicerca.get('statoSelezionato').value == null) ? '' : this.formRicerca.get('statoSelezionato').value;

    if (this.dipendente != '' || this.anno != '' || this.mese != '' || this.statoRapportino != '') {
      this.timesheetService.searchTimesheets(this.dipendente, this.mese, this.anno, this.statoRapportino).subscribe(timesheets => {
        this.timesheets = timesheets;
        this.timesheetsFiltrati = timesheets;
        this.spinner.hide();
        if (timesheets.length == 0) {
          this.noDataFound = true;
        }
        this.showTable = true;
      }, error => {
        this.spinner.hide();
        this.noDataFound = true;
      });
    } else {
      this.timesheetService.getAllTimesheets().subscribe(timesheets => {
        this.timesheets = timesheets;
        this.timesheetsFiltrati = timesheets;
        this.spinner.hide();
        if (timesheets.length == 0) {
          this.noDataFound = true;
        }
        this.showTable = true;
      }, error => {
        this.spinner.hide();
        this.noDataFound = true;
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
      });
    }
  }

  resetSearch() {
    this.getAllTimesheetsB = true;
    this.timesheetService.getAllTimesheets().subscribe(timesheets => {
      this.timesheets = timesheets;
      this.timesheetsFiltrati = timesheets;
      this.spinner.hide();
      if (timesheets.length == 0) {
        this.noDataFound = true;
      }
      this.showTable = true;
    }, error => {
      this.spinner.hide();
      this.noDataFound = true;
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

    this.timesheets = [];
    this.timesheetsFiltered = [];
    this.showTable = false;
    this.noDataFound = false;
    this.formRicerca.reset();
  }


  goToTimesheetDetail(timesheet) {
    const navigationExtras: NavigationExtras = {
      state: {
        dipendente: this.dipendente,
        anno: this.anno,
        mese: this.mese,
        statoRapportino: this.statoRapportino,
        getAllTimesheetsB: this.getAllTimesheetsB
      },
    };
    this.router.navigate(['/admin/vista-rapportino/id/' + timesheet.id], navigationExtras);

  }


  prepareMonthForMoment(month: number): string {
    if (month >= 1 && month <= 9) {
      return '0' + (month + 1);
    }
    return '' + (month + 1);
  }

  getCognomeDipendente(userId: number): string {
    let dipendente: UserInterface = this.dipendenti.find((dipendente) => {
      return dipendente.id == userId;
    });
    return dipendente.cognome;
  }


  getDaysArray_v2(year, month) {
    var monthIndex = month;

    var date = new Date(year, monthIndex, 1);
    var result = [];
    while (date.getMonth() == monthIndex) {
      result.push(
        {
          numeroGiorno: date.getDate(),
          giornoSettimana: date.getDay(),
          mese: date.getMonth(),
          dataCompleta: new Date(date.getFullYear(), date.getMonth(), date.getDate())
        }
      );
      date.setDate(date.getDate() + 1);
    }
    return result;
  }

  getData(giorno) {
    if (giorno.activities != undefined) {
      this.displayModal = true;
      this.selectedDay = giorno;
    }
  }


  mergeWorkedDaysWithCalendar(timesheet: Timesheet, calendarDays) {
    if (timesheet.giorniLavorati) {
      for (let activity of timesheet.giorniLavorati) {
        for (let day of calendarDays) {
          if (moment(day.dataCompleta).isSame(activity.data, 'day')) {
            Object.assign(day, {
              totaleOreGiornata: activity.totaleOreGiornata,
              codiceProgetto: activity.codiceProgetto,
              activities: activity.activities
            });
          }
        }
      }
    }

  }
}
