import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmationService, MessageService} from 'primeng/api';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {MESI_BUSTA_PAGA} from 'src/app/mock/mesi-busta-paga';
import {Anno} from 'src/app/models/anno';
import {MeseBustaPaga} from 'src/app/models/mese-busta-paga';
import {BustaPagaResponse} from 'src/app/models/response/busta-paga-response';
import {UserInterface} from 'src/app/models/user-interface';
import {BustePagaService} from 'src/app/services/buste-paga.service';
import {UserService} from 'src/app/services/user.service';
import TimeUtils from 'src/app/utils/time-utils';

@Component({
  selector: 'app-buste-paga',
  templateUrl: './buste-paga.component.html',
  styleUrls: ['./buste-paga.component.scss'],
  providers: [MessageService, BustePagaService, ConfirmationService]
})
export class BustePagaComponent implements OnInit {
  formRicerca: FormGroup;
  dipendenti: UserInterface[] = [];
  mesi: MeseBustaPaga[] = MESI_BUSTA_PAGA;
  anni: Anno[] = [];
  timeUtils = TimeUtils;
  showTable: boolean;
  bustePagaFiltered: BustaPagaResponse[] = [];
  firstLanding: boolean = true;
  currentDate: Date;

  select: string[];

  dipendente: string = null;
  anno: string = null;
  mese: string = null;

  constructor(private router: Router, private fb: FormBuilder, private userService: UserService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService, private spinner: NgxSpinnerService,
              private bustePagaService: BustePagaService,
              private activatedRoute: ActivatedRoute) {
    this.spinner.show();
    this.anni = this.timeUtils.yearsForDropdown();

    this.formRicerca = this.fb.group({
      dipendenteSelezionato: [''],
      meseSelezionato: [''],
      annoSelezionato: ['']
    });

    this.activatedRoute.params.subscribe(
      (param) => {
        if (this.router.getCurrentNavigation().extras.state) {
          this.dipendente = this.router.getCurrentNavigation().extras.state.dipendente;
          this.mese = this.router.getCurrentNavigation().extras.state.mese;
          this.anno = this.router.getCurrentNavigation().extras.state.anno;
        }

      });
    this.select = [];
    this.userService.getAllUsersSorted().subscribe(dipendenti => {


      for (let dipendente of dipendenti) {
        this.dipendenti.push({
          id: dipendente.id,
          username: dipendente.username,
          nome: dipendente.nome,
          cognome: dipendente.cognome,
          alias: dipendente.cognome + ' ' + dipendente.nome + ' - ' + dipendente.codiceFiscale,
          email: dipendente.email,
          cellulare: dipendente.cellulare,
          dataDiNascita: dipendente.dataDiNascita,
          status: dipendente.status,
          codiceFiscale: dipendente.codiceFiscale,
          tipoContratto: dipendente.tipoContratto,
          dataInizioRapporto: dipendente.dataInizioRapporto,
          dataFineRapporto: dipendente.dataFineRapporto,
          oreSettimanali: dipendente.oreSettimanali,
          chiaviUfficio: dipendente.chiaviUfficio,
          telefonoAziendale: dipendente.telefonoAziendale,
          comodatoPc: dipendente.comodatoPc,
          mansione: dipendente.mansione,
          roles: dipendente.roles,
          progetti: dipendente.progetti
        });


      }


      this.spinner.hide();
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_ERROR,
        detail: errorResponse.error.message
      });
    });
    this.currentDate = new Date();
  }

  ngOnInit() {
    if (this.anno == null && this.dipendente == null && this.mese == null) {
      this.dipendente = '';
      const month = this.currentDate.getMonth() + 1;
      this.mese = month.toString();
      this.anno = this.currentDate.getUTCFullYear().toString();


    }

    this.formRicerca.setValue({
      dipendenteSelezionato: this.dipendente,
      meseSelezionato: +this.mese,
      annoSelezionato: this.anno
    });

    if (this.dipendente == '' && this.mese == '' && this.anno == '') {
      this.resetSearch();
    } else {
      this.loadFirst();
    }
  }

  loadFirst() {
    this.select = [];
    this.bustePagaService.searchBustePaga(this.dipendente, +this.mese, this.anno).subscribe(bustePaga => {
      this.bustePagaFiltered = bustePaga;
      this.bustePagaFiltered.forEach(busta => {
        this.dipendenti.forEach(dip => {
          if (busta.userId === dip.id) {
            busta.alias = dip.cognome + ' ' + dip.nome + '  -  ' + dip.codiceFiscale;
          }
        });
      });

      // visualization fix (the dropbox wouldn't show the value otherwise)
      this.formRicerca.patchValue({ dipendenteSelezionato: this.dipendente });

      this.bustePagaFiltered.sort(function(a, b) {
        if (a.alias < b.alias) {
          return -1;
        }
        if (a.alias > b.alias) {
          return 1;
        }
        return 0;
      });
      this.spinner.hide();
      this.firstLanding = true;
      this.showTable = true;
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.firstLanding = false;
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: FrontendMessage.SUMMARY_ERROR,
        detail: errorResponse.error.message
      });
    });
  }

  resetSearch() {
    this.showTable = false;
    this.firstLanding = true;
    this.formRicerca.reset();
    this.dipendente = '';
    const month = '';
    this.mese = month.toString();
    this.anno = '';
    this.bustePagaService.getAllBustePaga().subscribe(bustePaga => {
      this.bustePagaFiltered = bustePaga;
      this.bustePagaFiltered.forEach(busta => {
        this.dipendenti.forEach(dip => {
          if (busta.userId === dip.id) {
            busta.alias = dip.cognome + ' ' + dip.nome + '  -  ' + dip.codiceFiscale;
          }
        });
      });
      this.bustePagaFiltered.sort(function(a, b) {
        if (a.alias < b.alias) {
          return -1;
        }
        if (a.alias > b.alias) {
          return 1;
        }
        return 0;
      });
      // console.log(bustePaga);
      this.spinner.hide();
      this.showTable = true;
    }, error => {
      this.spinner.hide();
    });
  }

  doSearch() {
    if (this.formRicerca.value.dipendenteSelezionato == null && this.formRicerca.value.meseSelezionato == null && this.formRicerca.value.annoSelezionato == null) {
      this.resetSearch();
    } else {

      this.spinner.show();

      this.mese = (this.formRicerca.get('meseSelezionato').value == null || this.formRicerca.get('meseSelezionato').value === '') ? '' : this.formRicerca.get('meseSelezionato').value;
      this.anno = (this.formRicerca.get('annoSelezionato').value == null || this.formRicerca.get('annoSelezionato').value === '') ? '' : this.formRicerca.get('annoSelezionato').value;
      this.dipendente = (this.formRicerca.get('dipendenteSelezionato').value == null) ? '' : this.formRicerca.get('dipendenteSelezionato').value;

      // console.log('DIP=' + this.dipendente + ' MESE=' + this.mese + ' ANNO=' + this.anno);

      this.bustePagaService.searchBustePaga(this.dipendente, +this.mese, this.anno).subscribe(bustePaga => {
        this.bustePagaFiltered = bustePaga;
        this.bustePagaFiltered.forEach(busta => {
          this.dipendenti.forEach(dip => {
            if (busta.userId === dip.id) {
              busta.alias = dip.cognome + ' ' + dip.nome + '  -  ' + dip.codiceFiscale;
            }
          });
        });
        this.bustePagaFiltered.sort(function(a, b) {
          if (a.alias < b.alias) {
            return -1;
          }
          if (a.alias > b.alias) {
            return 1;
          }
          return 0;
        });
        this.spinner.hide();
        this.firstLanding = false;
        this.showTable = true;
      }, (errorResponse: HttpErrorResponse) => {
        this.spinner.hide();
        this.firstLanding = false;
        this.messageService.add({
          severity: FrontendMessage.SEVERITY_ERROR,
          summary: FrontendMessage.SUMMARY_ERROR,
          detail: errorResponse.error.message
        });
      });
    }
  }

  confirmDelete_v2(event: Event, bustaPagaId) {
    this.messageService.clear();
    this.confirmationService.confirm({
      target: event.target,
      message: FrontendMessage.POPUP_CONFERMA_ELIMINAZIONE_BUSTA_PAGA,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: FrontendMessage.CONFERMA,
      rejectLabel: FrontendMessage.ANNULLA,
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.spinner.show();
        // console.log(bustaPagaId);
        this.bustePagaService.eliminaBustaPaga(bustaPagaId).subscribe(response => {
          this.messageService.add(FrontendMessage.BUSTA_PAGA_DELETED_MSG);
          this.removeBustaPagaFromArray(bustaPagaId);
          this.spinner.hide();
        }, (errorResponse: HttpErrorResponse) => {
          this.spinner.hide();
          this.messageService.add({
            severity: FrontendMessage.SEVERITY_ERROR,
            summary: FrontendMessage.SUMMARY_ERROR,
            detail: errorResponse.error.message
          });
        });
      },
      reject: () => {

      }
    });
  }

  removeBustaPagaFromArray(bustaPagaId: string) {
    this.bustePagaFiltered = this.bustePagaFiltered.filter(({id}) => id !== bustaPagaId);
  }

  goToModificaBustaPaga(id: string) {
    const navigationExtras: NavigationExtras = {
      state: {
        dipendente: this.dipendente,
        anno: this.anno,
        mese: this.mese
      },
    };
    this.router.navigate(['/admin/modifica-busta-paga/id/' + id], navigationExtras);
  }
}

