import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Dialog} from 'primeng/dialog';
import {simpleFadeAnimation} from 'src/app/animations/simple-fade-animation';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {StorageConstants} from 'src/app/constants/storage-constants';
import {STATUS_DIPENDENTI_MOCK} from 'src/app/mock/status-dipendenti.mock';
import {ComodatoPc} from 'src/app/models/comodato.pc';
import {MansioneInterface} from 'src/app/models/mansione.interface';
import {Progetto} from 'src/app/models/progetto';
import {ProgettoDipendente} from 'src/app/models/progetto-dipendente';
import {Role} from 'src/app/models/role';
import {StatusDipendente} from 'src/app/models/status-dipendente';
import {TipoContrattoInterface} from 'src/app/models/tipo-contratto.interface';
import {UserInterface} from 'src/app/models/user-interface';
import {Utente} from 'src/app/models/utente';
import {MansioniService} from 'src/app/services/mansioni.service';
import {ProgettiService} from 'src/app/services/progetti.service';
import {UserService} from 'src/app/services/user.service';
import GenericUtils from 'src/app/utils/generic-utils';
import TimeUtils from '../../../utils/time-utils';
import * as codiceFiscaleUtils from '@marketto/codice-fiscale-utils';
import {fadeInAnimation} from 'src/app/animations/fade-in-animation';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {TirocinanteService} from '../../../services/tirocinante.service';
import {PHONE_PREFIX_MOCK} from '../../../mock/phone-prefix.mock';

@Component({
  selector: 'app-dipendenti',
  templateUrl: './dipendenti.component.html',
  styleUrls: ['./dipendenti.component.scss'],
  providers: [MessageService, ConfirmationService],
  animations: [simpleFadeAnimation, fadeInAnimation]
})
export class DipendentiComponent implements OnInit {

  dipendenti: UserInterface[];
  dipendenteSelezionato: UserInterface = undefined;
  displayModal: boolean;
  formDipendente: FormGroup;
  statusDip: StatusDipendente[] = STATUS_DIPENDENTI_MOCK;
  progetti: ProgettoDipendente[] = [];
  progettiFromService: Progetto[] = [];
  switchToEditMode: boolean;
  statusSelezionato: StatusDipendente;
  progettiSelezionati: ProgettoDipendente[] = [];
  chiaviUfficio: boolean;
  ruoloSelezionato: Role;
  ruoliUtente: Role[];
  mansioni: MansioneInterface[] = [];
  tipiContratto: TipoContrattoInterface[] = [];
  pcAziendaleCheckBox: boolean;
  timeUtils = TimeUtils;
  utils = GenericUtils;
  msgNessunDipendente = FrontendMessage.DIPENDENTI_NON_PRESENTI;
  cfUtil = codiceFiscaleUtils;
  cfValid: boolean;
  dataFineDisabled: boolean;
  initialDateValue = Constants.INITIAL_DATE_DATASOUND;
  startEmail: boolean;
  existEmail: boolean;
  country: any[];
  phonePrefix: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private progettiService: ProgettiService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private mansioniService: MansioniService,
    private tirocinanteService: TirocinanteService) {
    this.dataFineDisabled = true;
    this.messageService.clear();
    this.spinner.show();
    this.existEmail = false;
    this.startEmail = false;
    this.progettiService.getProgetti().subscribe(response => {
      this.progettiFromService = response;

      response.forEach(progetto => {
        this.progetti.push(new ProgettoDipendente(progetto.nome, progetto.code));
      });
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

    this.loadUsers();
    this.country = PHONE_PREFIX_MOCK;
    this.mansioniService.getMansioni().subscribe(mansioni => {
      this.mansioni = mansioni;
    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

    this.mansioniService.getTipiContratto().subscribe(tipiContratto => {
      this.tipiContratto = tipiContratto;
    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });

    this.userService.getUserRoles().subscribe(ruoliUtente => {
      this.ruoliUtente = ruoliUtente;
    }, error => {
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
    this.phonePrefix = '';
  }

  ngOnInit(): void {

  }

  loadUsers() {
    this.spinner.show();
    this.dipendenti = [];
    const userData = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
    this.userService.getAllUsersSorted().subscribe(utenti => {


      for (let index = 0; index < utenti.length; index++) {
        const utente = utenti[index];
        if (utente.cellulare.length === 10) {
          this.checkPhonePrefix(utente);
        }
        this.dipendenti.push(utente);
        this.dipendenti[index].nomeRuolo = utente.roles[0].name;

      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
    });
  }

  onInputCodiceFiscale(cf: string) {
    const {Validator} = this.cfUtil;
    if (Validator.codiceFiscale(cf).valid) {
      this.cfValid = true;
    } else {
      this.cfValid = false;
    }
  }

  onDataInizioChange() {
    const dataInizio = this.formDipendente.get('dataInizioRapporto').value;
    this.formDipendente.controls['dataFineRapporto'].reset();
    this.dataFineDisabled = false;

    if (dataInizio != null && dataInizio > Constants.INITIAL_DATE_DATASOUND) {
      this.dataFineDisabled = false;
    } else {
      this.dataFineDisabled = true;
    }
  }

  enableAndResetRelatedDateField(control1, control2) {
    const abilita = this.timeUtils.enableAndResetDateField(control1, control2);

    if (abilita) {
      this.dataFineDisabled = false;
    } else {
      this.dataFineDisabled = true;
    }
  }

  mapRoleToObject(roleEnum: string): Role[] {
    const roles: Role[] = [];
    if (Constants.ADMIN == roleEnum) {
      roles.push(Constants.ROLE_ADMIN);
    } else {
      roles.push(Constants.ROLE_USER);
    }
    return roles;
  }

  mapProgettiToStore(progettiFromService, progettiUtente) {
    let progettiToStore: Progetto[] = [];
    for (let progFromService of progettiFromService) {
      for (let progetto of progettiUtente) {
        if (progetto.getNome() == progFromService.nome) {
          progettiToStore.push(progFromService);
        }
      }
    }
    return progettiToStore;
  }

  getCfValidationMessage(): string {
    return this.cfValid == true ? FrontendMessage.CODICE_FISCALE_VALIDO : FrontendMessage.CODICE_FISCALE_NON_VALIDO;
  }

  selezionaRiga(dipendente) {
    const phone = dipendente.cellulare.split(' ')[1];
    this.phonePrefix = dipendente.cellulare.split(' ')[0];
    this.onInputCodiceFiscale(dipendente.codiceFiscale);
    this.dipendenteSelezionato = dipendente;
    this.statusSelezionato = new StatusDipendente(dipendente.status, dipendente.status);
    this.progettiSelezionati = [];
    this.displayModal = true;
    this.ruoloSelezionato = {
      id: dipendente.roles[0].id,
      name: dipendente.roles[0].name
    };


    this.pcAziendaleCheckBox = dipendente.comodatoPc ? true : false;
    const serialePc = dipendente.comodatoPc != null ? dipendente.comodatoPc.seriale : null;
    const modelloPc = dipendente.comodatoPc != null ? dipendente.comodatoPc.modello : null;
    this.formDipendente = this.fb.group({
      nome: [dipendente.nome, [
        Validators.required,
        Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]
      ],
      cognome: [dipendente.cognome, [
        Validators.required,
        Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]
      ],
      codiceFiscale: [dipendente.codiceFiscale, [
        Validators.required,
        Validators.maxLength(Constants.CODICE_FISCALE_MAX_LENGTH),
        Validators.pattern(Constants.CODICE_FISCALE_REGEX),
        this.utils.codiceFiscaleValidator]],
      email: [dipendente.email, [Validators.required, Validators.pattern(Constants.EMAIL_REGEX)]],
      cellulare: [phone, [Validators.required, Validators.pattern(Constants.PHONE_REGEX)]],
      prefix: [this.phonePrefix, Validators.required],
      statusSelezionato: new FormControl(this.statusSelezionato, Validators.required),
      dataDiNascita: [new Date(dipendente.dataDiNascita), [Validators.required, this.timeUtils.dataDiNascitaValidator]],
      ruolo: [this.ruoloSelezionato],
      mansione: [dipendente.mansione, Validators.required],
      dataInizioRapporto: [new Date(dipendente.dataInizioRapporto), [Validators.required, RxwebValidators.minDate({value: Constants.INITIAL_DATE_DATASOUND})]],
      dataFineRapporto: [dipendente.dataFineRapporto != null ? new Date(dipendente.dataFineRapporto) : 0, null],
      tipoContratto: [dipendente.tipoContratto, Validators.required],
      oreSettimanali: [dipendente.oreSettimanali, [Validators.required, RxwebValidators.minNumber({value: 1}), RxwebValidators.maxNumber({value: Constants.MAX_ORE_SETTIMANALI})]],
      chiaviUfficio: [dipendente.chiaviUfficio],
      telefonoAziendale: [dipendente.telefonoAziendale],
      pcAziendale: [this.pcAziendaleCheckBox],
      serialePc: [serialePc, this.pcAziendaleCheckBox ? [Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE), this.utils.whitespaceValidator, this.utils.endWithSpaceValidator] : null],
      modelloPc: [modelloPc, this.pcAziendaleCheckBox ? [Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE), this.utils.whitespaceValidator, this.utils.endWithSpaceValidator] : null],
      progetti: ['']
    });
    this.formDipendente.disable();
  }

  showDialogMaximized(dialog: Dialog) {
    dialog.maximize();
  }

  getYearRange(): string {
    const currentYear = new Date();
    const finalYear = currentYear.getFullYear() - 18;
    const startingYear = currentYear.getFullYear() - 60;
    return startingYear + ':' + finalYear;
  }

  nuovoDipendente() {
    this.router.navigateByUrl('/admin/nuovo-dipendente');
  }

  checkPhonePrefix(user) {
    user.cellulare = '+39 ' + user.cellulare;
    this.userService.updateUserProfile_v2(user).subscribe(() => {
    });
  }

  modificaUtente() {
    this.spinner.show();
    let phoneCode = '';
    if (this.formDipendente.get('prefix').touched && this.formDipendente.get('prefix').value.phonecode != null) {
      phoneCode = this.formDipendente.get('prefix').value.phonecode;
    } else {
      phoneCode = this.dipendenteSelezionato.cellulare.split(' ')[0];
    }
    const dataDiNascita = new Date(Date.UTC(this.formDipendente.get('dataDiNascita').value.getFullYear(), this.formDipendente.get('dataDiNascita').value.getMonth(), this.formDipendente.get('dataDiNascita').value.getDate()));
    const dataInizioRapporto = new Date(Date.UTC(this.formDipendente.get('dataInizioRapporto').value.getFullYear(), this.formDipendente.get('dataInizioRapporto').value.getMonth(), this.formDipendente.get('dataInizioRapporto').value.getDate()));
    const ruoliUtente: Role[] = [];
    ruoliUtente.push(this.formDipendente.get('ruolo').value);
    const dataFineRapporto = (this.formDipendente.get('dataFineRapporto').value != 0 && this.formDipendente.get('dataFineRapporto').value != null) ? new Date(Date.UTC(this.formDipendente.get('dataFineRapporto').value.getFullYear(), this.formDipendente.get('dataFineRapporto').value.getMonth(), this.formDipendente.get('dataFineRapporto').value.getDate())) : null;
    const userToUpdate = new Utente(
      this.dipendenteSelezionato.id,
      null,
      this.formDipendente.get('nome').value,
      this.formDipendente.get('cognome').value,
      this.formDipendente.get('email').value,
      phoneCode + ' ' + this.formDipendente.get('cellulare').value,
      dataDiNascita,
      this.formDipendente.get('statusSelezionato').value.name,
      this.formDipendente.get('codiceFiscale').value,
      this.formDipendente.get('tipoContratto').value,
      dataInizioRapporto,
      dataFineRapporto,
      this.formDipendente.get('oreSettimanali').value,
      this.formDipendente.get('chiaviUfficio').value,
      this.formDipendente.get('telefonoAziendale').value,
      (this.pcAziendaleCheckBox ? new ComodatoPc(this.formDipendente.get('modelloPc').value, this.formDipendente.get('serialePc').value) : null),
      this.formDipendente.get('mansione').value,
      ruoliUtente,
      null);

    this.userService.updateUserProfile_v2(userToUpdate).subscribe(response => {
      this.existEmail = false;
      this.startEmail = false;
      this.loadUsers();
      this.spinner.hide();
      this.messageService.clear();
      this.messageService.add(FrontendMessage.MESSAGE_SERVICE_DIPENDENTE_MODIFICATO);
      this.closeEditMode();
      this.displayModal = false;
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.clear();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: '',
        detail: errorResponse.error.message,
        key: 'formModifica'
      });
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    });

  }


  checkEmail(email: string) {
    this.startEmail = true;
    this.tirocinanteService.existEmailUser(email).subscribe(response => {
      if (email == this.dipendenteSelezionato.email) {
        this.existEmail = false;
      } else {

        this.existEmail = response;
      }
    });
  }

  detectComodatoChange(event) {
    if (event.checked) {
      this.pcAziendaleCheckBox = true;
      this.formDipendente.get('serialePc').setValidators([Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE), this.utils.whitespaceValidator, this.utils.endWithSpaceValidator]);
      this.formDipendente.get('modelloPc').setValidators([Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE), this.utils.whitespaceValidator, this.utils.endWithSpaceValidator]);
    } else {
      this.formDipendente.get('serialePc').reset();
      this.formDipendente.get('serialePc').clearValidators();
      this.formDipendente.get('serialePc').updateValueAndValidity();
      this.formDipendente.get('modelloPc').reset();
      this.formDipendente.get('modelloPc').clearValidators();
      this.formDipendente.get('modelloPc').updateValueAndValidity();
      this.pcAziendaleCheckBox = false;
    }

  }

  switchToEdit() {
    this.switchToEditMode = true;
    this.formDipendente.enable();
    const dataFine = this.formDipendente.get('dataFineRapporto').value;


    if (dataFine == null || dataFine == 0) {
      this.dataFineDisabled = true;
    } else {
      this.dataFineDisabled = false;
    }
  }

  onHideModal() {
    this.switchToEditMode = false;
    this.formDipendente.disable();
    this.cfValid = undefined;
    this.existEmail = false;
    this.startEmail = false;
  }

  closeEditMode() {
    this.switchToEditMode = false;
    this.formDipendente.disable();
    this.existEmail = false;
    this.startEmail = false;
    this.cfValid = true;
  }

  confirmDelete_v2(event: Event, dipendenteId) {
    this.messageService.clear();
    this.confirmationService.confirm({
      target: event.target,
      message: FrontendMessage.POPUP_DELETE_USER_TEXT,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: FrontendMessage.CONFERMA,
      rejectLabel: FrontendMessage.ANNULLA,
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.spinner.show();
        this.userService.eliminaUtente(dipendenteId).subscribe(response => {
          this.loadUsers();
          this.spinner.hide();
          this.displayModal = false;
          this.messageService.add(FrontendMessage.MESSAGE_SERVICE_DIPENDENTE_ELIMINATO);
        }, (errorResponse: HttpErrorResponse) => {
          this.spinner.hide();
          this.messageService.add({
            severity: FrontendMessage.SEVERITY_ERROR,
            summary: '',
            detail: errorResponse.error.message,
            key: 'tableDipendenti'
          });
        });
      },
      reject: () => {


      }
    });
  }

  confirmDelete(event: Event) {
    this.spinner.show();
    this.messageService.clear();
    this.confirmationService.confirm({
      target: event.target,
      message: FrontendMessage.POPUP_DELETE_USER_TEXT,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: FrontendMessage.CONFERMA,
      rejectLabel: FrontendMessage.ANNULLA,
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.userService.eliminaUtente(this.dipendenteSelezionato.id).subscribe(response => {

          this.loadUsers();
          this.spinner.hide();
          this.displayModal = false;
          this.messageService.add(FrontendMessage.MESSAGE_SERVICE_OK);
        }, error => {
          this.spinner.hide();
          this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
        });
      },
      reject: () => {


      }
    });
  }

  revertForm() {
    this.formDipendente.reset();
    const serialePc = this.dipendenteSelezionato.comodatoPc != null ? this.dipendenteSelezionato.comodatoPc.seriale : null;
    const modelloPc = this.dipendenteSelezionato.comodatoPc != null ? this.dipendenteSelezionato.comodatoPc.modello : null;

    if (this.dipendenteSelezionato.comodatoPc != null) {
      this.pcAziendaleCheckBox = true;
    }

    const phone = this.dipendenteSelezionato.cellulare.split(' ')[1];
    const phonePrefix = this.dipendenteSelezionato.cellulare.split(' ')[0];

    this.formDipendente.patchValue({
      nome: this.dipendenteSelezionato.nome,
      cognome: this.dipendenteSelezionato.cognome,
      codiceFiscale: this.dipendenteSelezionato.codiceFiscale,
      email: this.dipendenteSelezionato.email,
      prefix: phonePrefix,
      cellulare: phone,
      dataDiNascita: new Date(this.dipendenteSelezionato.dataDiNascita),
      statusSelezionato: this.statusSelezionato,
      ruolo: this.ruoloSelezionato,
      mansione: this.dipendenteSelezionato.mansione,
      dataInizioRapporto: new Date(this.dipendenteSelezionato.dataInizioRapporto),
      dataFineRapporto: this.dipendenteSelezionato.dataFineRapporto != null ? new Date(this.dipendenteSelezionato.dataFineRapporto) : 0,
      tipoContratto: this.dipendenteSelezionato.tipoContratto,
      oreSettimanali: this.dipendenteSelezionato.oreSettimanali,
      chiaviUfficio: this.dipendenteSelezionato.chiaviUfficio,
      telefonoAziendale: this.dipendenteSelezionato.telefonoAziendale,
      pcAziendale: this.pcAziendaleCheckBox,
      serialePc: serialePc,
      modelloPc: modelloPc,
      progetti: ['']
    });
  }

  deleteDisabled(dipendente): boolean {
    const userData = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
    if (dipendente.id == userData.userId) {
      return true;
    }
    return false;
  }

  emptyFields() {
    const fields = [];
    const controls = this.formDipendente.controls;
    for (const name in controls) {
      if (this.utils.formValid(this.formDipendente).includes(name) && controls[name].invalid && (controls[name].value == null || controls[name].value == '')) {
        fields.push(name);
      }
    }
    return fields;
  }
}
