<div class="main-area">
  <main>
    <div class="p-grid">
      <div class="p-col">
        <button [routerLink]="['/admin/progetti']" class="p-button-primary p-button-raised" icon="pi pi-arrow-circle-left" label="Torna alla lista progetti"
                pButton type="button"></button>
      </div>
    </div>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>

    <div class="titolo-sezione">

      <i class="las la-toolbox"></i> Progetto {{progetto.nome}}
    </div>
    <p-divider type="dashed"></p-divider>
    <form (ngSubmit)="modificaProgetto()" *ngIf="dataLoaded" [formGroup]="formProgetto">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-6 p-md-3">
          <label for="code">Acronimo</label>
          <input formControlName="code"
                 id="code"
                 pInputText
                 required
                 type="text">
        </div>
        <div class="p-field p-col-6 p-md-3">
          <label for="nome">Nome</label>
          <input formControlName="nome"
                 id="nome"
                 pInputText
                 required type="text">
        </div>
        <div class="p-field p-col-6 p-md-3">
          <label for="cliente">Cliente</label>
          <input formControlName="cliente" id="cliente" pInputText required type="text">
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="state">Stato</label>
          <p-dropdown (onChange)="detectStatusChange()" [options]="statusProgetti" formControlName="status"
                      inputId="statusSelezionato" optionLabel="name" optionLabel="name" placeholder="Seleziona"
                      required>
          </p-dropdown>
        </div>
        <div class="p-field p-col-6 p-md-3">
          <label for="dataInizio">Data inizio progetto</label>
          <p-calendar
            (onSelect)="onDataInizioChange()"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="timeUtils.getFutureYearRange()"
            dateFormat="dd/mm/yy"
            formControlName="dataInizio" required></p-calendar>
        </div>
        <div class="p-field p-col-6 p-md-3">
          <label for="dataFine">Data fine progetto</label>
          <p-calendar
            [disabled]="dataFineDisabled"
            [minDate]="timeUtils.setMinDate(formProgetto.get('dataInizio').value)"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="timeUtils.getFutureYearRange()"
            dateFormat="dd/mm/yy"
            formControlName="dataFine" required></p-calendar>
        </div>

        <div class="p-field p-col-6 p-md-3">
          <!-- <label for="budgetComplessivo">Budget complessivo</label>
          <input id="budgetComplessivo" formControlName="budgetComplessivo" [value]="budgetComplessivo | importoInEuro" type="number" pInputText *ngIf="switchToEditMode">
          {{ budgetComplessivo | importoInEuro}}-->

          <label for="budgetComplessivo">Budget complessivo</label>


          <p-inputNumber (keypress)="utils.onlyPositiveNumbers($event)"
                         [minFractionDigits]="2"
                         [min]="0"
                         currency="EUR"
                         formControlName="budgetComplessivo" locale="it-IT"
                         mode="currency"
                         mode="decimal" required></p-inputNumber>
        </div>

        <div class="p-field p-col-12">
          <label for="coloreStatistiche">Colore statistiche</label>
          <br>
          <p-colorPicker [(ngModel)]="coloreStatistiche" formControlName="coloreStatistiche"></p-colorPicker>
        </div>

        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <p-button label="Situazione economica" styleClass="p-button-text"></p-button>
          </p-divider>
        </div>

        <div class="p-field p-col-6 p-md-3">
          <label for="oreUomoStimate">Ore stimate</label>
          <input formControlName="oreUomoStimate" id="oreUomoStimate" pInputText type="number">
        </div>

        <div class="p-field p-col-6 p-md-3">
          <label for="budgetComplessivo">Spese preventivate</label>


          <p-inputNumber (keypress)="utils.onlyPositiveNumbers($event)"
                         [minFractionDigits]="2"
                         [min]="0"
                         currency="EUR"
                         formControlName="spesePreventivate" locale="it-IT"
                         mode="currency"
                         mode="decimal"></p-inputNumber>

        </div>
        <div *ngIf="switchToEditMode" class="p-field p-col-2 p-md-2 p-xl-offset-8 p-md-offset-8">
          <button (click)="closeEditMode();revertForm();" class="p-button p-button-danger p-button-raised" label="Annulla" pButton
                  type="button"></button>
        </div>
        <div *ngIf="switchToEditMode" class="p-field p-col-2 p-md-2">
          <button [disabled]="!formProgetto.valid" class="p-button p-button-success p-button-raised" label="Salva" pButton
                  type="submit"></button>
        </div>
        <div *ngIf="!switchToEditMode" class="p-field p-col-2 p-md-2 p-xl-offset-10 p-md-offset-10">
          <button (click)="switchToEdit()" class="p-button p-button-warning p-button-raised" label="Modifica" pButton
                  type="button"></button>
        </div>
        <div><p-message *ngIf="utils.formValid(formProgetto).length > 0 && emptyFields().length == 0" @simpleFadeAnimation
                        severity="error" text="Attenzione! Campo/i non popolati correttamente"></p-message></div>
        <div class="p-md-12" style="padding: 0px"></div>
        <div><p-message *ngIf="emptyFields().length > 0" @simpleFadeAnimation
                   severity="error" text="Attenzione! Campo/i obbligatori non popolati"></p-message></div>


      </div>
    </form>

    <p-divider *ngIf="riepilogoConsuntivoProgetto" align="left">
      <p-button label="Consuntivi" styleClass="p-button-text"></p-button>
    </p-divider>
    <div *ngIf="riepilogoConsuntivoProgetto" class="riepilogo">
      <div class="riepilogo-single p-shadow-3">
        <div>
          <p>TOTALE</p>
          <h4>{{ riepilogoConsuntivoProgetto.oreEffettive != null ? riepilogoConsuntivoProgetto.oreEffettive : 0 }}</h4>
          <span>Ore effettive</span>
        </div>
        <div>
          <span class="las la-user-clock"></span>
        </div>
      </div>
      <div class="riepilogo-single p-shadow-3">
        <div>
          <p>TOTALE</p>
          <h4>{{ (riepilogoConsuntivoProgetto.speseEffettive != null ? riepilogoConsuntivoProgetto.speseEffettive : '0') | currency:'EUR':true}} </h4>
          <span>Spese effettive</span>
        </div>
        <div>
          <span class="las la-euro-sign"></span>
        </div>
      </div>
    </div>
    <p-divider *ngIf="totaleOreDipendenti.length > 0" align="left">
      <p-button label="Dettaglio ore dipendenti" styleClass="p-button-text"></p-button>
    </p-divider>
    <p-table *ngIf="totaleOreDipendenti.length > 0" [autoLayout]="true" [value]="totaleOreDipendenti"
             styleClass="p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="cognome" scope="col">Dipendente
            <p-sortIcon field="cognome"></p-sortIcon>
          </th>
          <th pSortableColumn="totaleOre" scope="col">Ore
            <p-sortIcon field="totaleOre"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template let-riepilogoDipendente pTemplate="body">
        <tr>
          <td>{{riepilogoDipendente.nome}} {{riepilogoDipendente.cognome}}</td>
          <td>{{riepilogoDipendente.totaleOre}}</td>
        </tr>
      </ng-template>
    </p-table>
    <p-messages [enableService]="true"></p-messages>
  </main>
</div>
