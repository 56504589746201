import {Country} from 'country-state-city';


export const PHONE_PREFIX_MOCK = [];

Country.getAllCountries().forEach((countryCode) => {

  if (countryCode.phonecode.length != 0) {


    if (countryCode.phonecode.indexOf('+') != -1) {

      countryCode.phonecode.slice(1);


    } else {
      countryCode.phonecode = '+' + countryCode.phonecode;
    }

    if (countryCode.phonecode.indexOf('+39') !== -1) {

      PHONE_PREFIX_MOCK.unshift(countryCode);
    }
    PHONE_PREFIX_MOCK.push(countryCode);
  }
});
