<div class="main-area">
  <main>
    <div class="titolo-sezione">
      <i class="las la-clipboard-list"></i> Lista buste paga
    </div>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <p-divider align="left" type="none">
    </p-divider>
    <form (ngSubmit)="doSearch()" [formGroup]="formRicerca">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-3">
          <label for="dipendenteSelezionato">Dipendente</label>
          <p-dropdown [options]="dipendenti" formControlName="dipendenteSelezionato" inputId="dipendenteSelezionato"
                      optionLabel="alias" optionValue="id" placeholder="Seleziona">
          </p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="mese">Mese</label>
          <p-dropdown [options]="mesi" formControlName="meseSelezionato" inputId="meseSelezionato" optionLabel="nome"
                      optionValue="codice" placeholder="Seleziona">
          </p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="anno">Anno</label>
          <p-dropdown [options]="anni" formControlName="annoSelezionato" inputId="annoSelezionato" optionLabel="nome"
                      optionValue="codice" placeholder="Seleziona">
          </p-dropdown>
        </div>
        <div class="p-field p-col margine-sup">
          <button class="p-button p-button-primary" label="Filtra" pButton type="submit"></button>
        </div>
        <div class="p-field p-col margine-sup">
          <button (click)="resetSearch()" class="p-button p-button-secondary" label="Annulla" pButton
                  type="button"></button>
        </div>
      </div>
      <!-- <div class="p-field p-col-12 p-md-12">
        <div class="p-field-checkbox">
          <p-checkbox formControlName="tredicesima" binary="true" inputId="binary"></p-checkbox>
          <label for="tredicesima">Tredicesima</label>
        </div>
      </div>-->
    </form>

    <p-table
      *ngIf="showTable && bustePagaFiltered.length > 0"
      [autoLayout]="true"
      [paginator]="true"
      [rowHover]="true"
      [rowsPerPageOptions]="[10,20,30]" [rows]="10" [showCurrentPageReport]="true"
      [totalRecords]="bustePagaFiltered.length"
      [value]="bustePagaFiltered"
      currentPageReportTemplate="Risultati da {first} a {last}. Totale {totalRecords} busta/e paga"
      styleClass="p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th class="p-text-center" scope="col">Dipendente</th>
          <th class="p-text-center" scope="col">Periodo</th>

          <th class="p-text-center" scope="col">Netto in busta</th>
          <th class="p-text-center" scope="col">Azioni</th>
        </tr>
      </ng-template>
      <ng-template let-bustaPaga pTemplate="body">
        <tr>
          <td class="p-text-center">{{ bustaPaga.alias }}</td>
          <td class="p-text-center">{{ bustaPaga.mese - 1 | meseBustaPaga }} {{ bustaPaga.anno }}</td>

          <td class="p-text-center">{{ bustaPaga.importo | currency:'EUR' }}</td>
          <td class="p-text-center">
            <button (click)="goToModificaBustaPaga(bustaPaga.id)" class="p-button p-button-text" icon="pi pi-eye" pButton
                    type="button"></button>
            <button (click)="confirmDelete_v2($event, bustaPaga.id)" class="p-button p-button-danger p-button-text" icon="pi pi-trash" pButton
                    type="button"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-confirmPopup></p-confirmPopup>
    <p-toast position="bottom-right"></p-toast>
    <div *ngIf="!firstLanding && (!showTable || bustePagaFiltered.length == 0)" class="p-grid">
      <div class="p-col-12">
        <div class="msgNoData">
          <span class="las la-5x la-info-circle"></span>
          <h3>Nessuna busta paga trovata corrispondente ai criteri di ricerca inseriti.</h3>
        </div>
      </div>
    </div>
    <div *ngIf="firstLanding && (!showTable || bustePagaFiltered.length == 0)" class="p-grid">
      <div class="p-col-12">
        <div class="msgNoData">
          <span class="las la-5x la-info-circle"></span>
          <h3>Non sono presenti buste paga.</h3>
        </div>
      </div>
    </div>
  </main>
</div>
