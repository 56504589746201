<div>

  <app-sidebar></app-sidebar>

  <app-header-admin></app-header-admin>

  <div>
    <router-outlet></router-outlet>
  </div>

</div>
