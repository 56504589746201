<div class="main-area">
  <header>
    <h2>
      <label for="nav-toggle" id="nav-toggle">
        <span class="las la-bars"></span>
      </label>
      <!-- Dashboard -->
    </h2>

    <div class="user-wrapper">
      <div *ngIf="currentWindowWidth > 560" class="nomeProfilo">
        <h4>{{ nome }} {{ cognome }}</h4>
        <p-tag [severity]="getServerityByRole()" [value]="getRoleName()"></p-tag>
      </div>
      <i (click)="goToNotifiche()"
         *ngIf="totaleNotificheSblocco>0 || totaleNotificheSbloccoTirocinio >0 " class="lab la-telegram p-mr-4 p-text-secondary" pBadge></i>

      <i (click)="goToNotifiche()"
         *ngIf="totaleNotificheSblocco===0 && totaleNotificheSbloccoTirocinio===0" class="lab la-telegram p-mr-4 p-text-secondary"></i>
      <button (click)="logout()" class="p-button-outlined p-button-secondary p-button-sm logout-button-margin" icon="pi pi-power-off" label="Logout"
              pButton type="button"></button>
    </div>
  </header>
</div>
