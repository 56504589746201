import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({
  name: 'importoInEuro'
})
export class ImportoInEuroPipe implements PipeTransform {
  transform(num: number) {

    return (new CurrencyPipe('it-IT')).transform(num, 'EUR', 'symbol', '1.2-2');
  }
}
