import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-dipendente',
  templateUrl: './dipendente.component.html',
  styleUrls: ['./dipendente.component.scss']
})
export class DipendenteComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
