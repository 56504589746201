import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthComponent} from './layouts/auth/auth.component';
import {DefaultComponent} from './layouts/default/default.component';
import {AdminComponent} from './pages/admin/admin.component';
import {NuovoDipendenteComponent} from './pages/admin/nuovo-dipendente/nuovo-dipendente.component';
import {LoginComponent} from './pages/login/login.component';
import {ProgettiComponent} from './pages/admin/progetti/progetti.component';
import {NuovoProgettoComponent} from './pages/admin/nuovo-progetto/nuovo-progetto.component';
import {TimesheetsComponent} from './pages/admin/timesheets/timesheets.component';
import {DipendentiComponent} from './pages/admin/dipendenti/dipendenti.component';
import {DipendenteComponent} from './layouts/dipendente/dipendente.component';
import {UserComponent} from './pages/user/user.component';
import {StoricoComponent} from './pages/user/storico/storico.component';
import {TimesheetComponent} from './pages/user/timesheet/timesheet.component';
import {ProfiloComponent} from './pages/admin/profilo/profilo.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {AuthGuard} from './services/authentication/auth.guard';
import {UserRoleEnum} from './models/enums/user-roles';
import {ProgettoComponent} from './pages/admin/progetto/progetto.component';
import {RapportinoComponent} from './pages/admin/rapportino/rapportino.component';
import {VistaRapportinoComponent} from './pages/admin/vista-rapportino/vista-rapportino.component';
import {NotificheComponent} from './pages/admin/notifiche/notifiche.component';
import {VistaTimesheetComponent} from './pages/user/vista-timesheet/vista-timesheet.component';
import {BustePagaComponent} from './pages/admin/buste-paga/buste-paga.component';
import {NuovaBustaPagaComponent} from './pages/admin/nuova-busta-paga/nuova-busta-paga.component';
import {ModificaBustaPagaComponent} from './pages/admin/modifica-busta-paga/modifica-busta-paga.component';
import {BustePagaDipendenteComponent} from './pages/user/buste-paga-dipendente/buste-paga-dipendente.component';
import {ModificaPasswordComponent} from './pages/admin/modifica-password/modifica-password.component';
import {ModificaPasswordUtenteComponent} from './pages/user/modifica-password-utente/modifica-password-utente.component';
import {NuovoTirocinanteComponent} from './pages/admin/nuovo-tirocinante/nuovo-tirocinante.component';
import {TirocinantiComponent} from './pages/admin/tirocinanti/tirocinanti.component';
import {ProgettoFormativoComponent} from './pages/admin/progetto-formativo/progetto-formativo.component';
import {RouterPaths} from './constants/RouterPaths';
import {TirocinanteComponent} from './pages/tirocinante/tirocinante.component';
import {LibrettoDiarioComponent} from './pages/tirocinante/libretto-diario/libretto-diario.component';
import {TirocinanteDefaultComponent} from './layouts/tirocinante-default/tirocinante-default.component';
import {PianoFormativoComponent} from './pages/tirocinante/piano-formativo/piano-formativo.component';
import {VistaLibrettoDiarioComponent} from './pages/admin/vista-libretto-diario/vista-libretto-diario.component';
import {ModificaPasswordTirocinanteComponent} from './pages/tirocinante/modifica-password-tirocinante/modifica-password-tirocinante.component';

const routes: Routes = [{
  path: 'admin',
  component: DefaultComponent,
  canActivate: [AuthGuard],
  data: {role: [UserRoleEnum.ADMIN]},
  children: [
    {
      path: '',
      component: AdminComponent
    },
    {
      path: 'profilo',
      component: ProfiloComponent
    },
    {
      path: 'nuovo-dipendente',
      component: NuovoDipendenteComponent
    },
    {
      path: 'nuovo-progetto',
      component: NuovoProgettoComponent
    },
    {
      path: 'dipendenti',
      component: DipendentiComponent,
    },
    {
      path: 'progetti',
      component: ProgettiComponent
    },
    {
      path: 'progetto/id/:id',
      component: ProgettoComponent
    },
    {
      path: 'timesheets',
      component: TimesheetsComponent
    },
    {
      path: 'rapportino',
      component: RapportinoComponent
    },
    {
      path: 'vista-rapportino/id/:id',
      component: VistaRapportinoComponent
    },
    {
      path: 'vista-rapportino/id/:id/:notification',
      component: VistaRapportinoComponent
    },
    {
      path: 'notifiche',
      component: NotificheComponent
    },
    {
      path: 'buste-paga',
      component: BustePagaComponent
    },
    {
      path: 'nuova-busta-paga',
      component: NuovaBustaPagaComponent
    },
    {
      path: 'modifica-busta-paga/id/:id',
      component: ModificaBustaPagaComponent
    },
    {
      path: 'modifica-password',
      component: ModificaPasswordComponent
    },
    {
      path: 'nuovo-tirocinante',
      component: NuovoTirocinanteComponent
    },
    {
      path: 'tirocinanti',
      component: TirocinantiComponent
    },
    {
      path: 'progetto-formativo',
      component: ProgettoFormativoComponent
    },

    {
      path: RouterPaths.VISTA_LIBRETTO_DIARIO + '/id/:id/:notification',
      component: VistaLibrettoDiarioComponent
    },

  ],
},
  {

    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      }
    ]

  },
  {

    path: 'reset-password',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: ResetPasswordComponent
      }
    ]

  },
  {
    path: 'dipendente',
    component: DipendenteComponent,
    canActivate: [AuthGuard],
    data: {role: [UserRoleEnum.DIPENDENTE]},
    children: [
      {
        path: 'profilo',
        component: UserComponent
      },
      {
        path: 'storico',
        component: StoricoComponent
      },
      {
        path: '',
        component: TimesheetComponent
      },
      {
        path: 'vista-rapportino/id/:id',
        component: VistaTimesheetComponent
      },
      {
        path: 'buste-paga',
        component: BustePagaDipendenteComponent
      },
      {
        path: 'modifica-password',
        component: ModificaPasswordUtenteComponent
      }
    ]
  }
  , {
    path: RouterPaths.TIROCINANTE_ROUTE,
    component: TirocinanteDefaultComponent,
    canActivate: [AuthGuard],
    data: {role: [UserRoleEnum.TIROCINANTE]},
    children: [
      {
        path: '',
        component: LibrettoDiarioComponent
      },
      {
        path: RouterPaths.PROFILO_TIROCINANTE,
        component: TirocinanteComponent
      },
      {
        path: RouterPaths.PIANO_FORMATIVO,
        component: PianoFormativoComponent
      },
      {
        path: RouterPaths.MODIFICA_PASSWORD,
        component: ModificaPasswordTirocinanteComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
