import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Endpoints} from '../constants/endpoints';
import {IMonthDetail} from '../models/tirocinio/month-detail.interface';
import {ILibrettoDiario} from '../models/tirocinio/libretto-diario.interface';
import {ServiceResponse} from '../models/response/service-response';
import {ILibrettoManagement} from '../models/tirocinio/libretto-management-request.interface';
import {ContaNotificheSblocco} from '../models/timesheet/conta-notifiche-sblocco';

@Injectable()
export class LibrettoDiarioService {

  constructor(private http: HttpClient) {
  }

  getLibrettoDiario(tirocinanteId: number): Observable<ILibrettoDiario> {
    const params = new HttpParams().set('id', '' + tirocinanteId);
    return this.http.get<ILibrettoDiario>(Endpoints.GET_LIBRETTO_FOR_TIROCINANTE, {params});
  }

  getMonthDetail(tirocinanteId: number, mese: number): Observable<IMonthDetail> {
    const params = new HttpParams().set('id', '' + tirocinanteId).set('mese', '' + mese);
    return this.http.get<IMonthDetail>(Endpoints.GET_MONTH_DETAIL_BY_USER_ID_MONTH, {params});
  }

  getAllMonthDetail(tirocinanteId: number): Observable<IMonthDetail[]> {
    return this.http.get<IMonthDetail[]>(Endpoints.FIND_ALL_MONTH_DETAIL_BY_ID + tirocinanteId);
  }

  getMonthDetailByYearAndMonth(tirocinanteId: number, mese: number, anno: number): Observable<IMonthDetail> {
    const params = new HttpParams().set('id', '' + tirocinanteId).set('mese', '' + mese).set('anno', '' + anno);
    return this.http.get<IMonthDetail>(Endpoints.GET_MONTH_DETAIL_BY_USER_ID_MONTH, {params});
  }

  newTirocinioActivity(workingDay: any): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(Endpoints.WORKING_DAY_TIROCINIO_NEW, workingDay);
  }

  deleteWorkingDay(workingDayId: string, monthDetailId: string, tirocinanteId: string): Observable<ServiceResponse> {
    const params = new HttpParams().set('workingDayId', workingDayId).set('monthDetailId', monthDetailId).set('tirocinanteId', tirocinanteId);
    return this.http.delete<ServiceResponse>(Endpoints.WORKING_DAY_TIROCINIO_DELETE, {params});
  }

  deleteOneDayDetail(dayDetailId: string, monthDetailId: string, tirocinanteId: string): Observable<ServiceResponse> {
    const params = new HttpParams().set('dayDetailId', dayDetailId).set('monthDetailId', monthDetailId).set('tirocinanteId', tirocinanteId);
    return this.http.delete<ServiceResponse>(Endpoints.WORKING_DAY_TIROCINIO_DELETE_ONE_ACTIVITY, {params});
  }

  inviaLibretto(request: ILibrettoManagement): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.SEND_LIBRETTO, request);
  }

  richiestaSblocco(request: ILibrettoManagement): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.RICHIESTA_SBLOCCO_LIBRETTO, request);
  }

  getAllLibrettiToApprove(): Observable<ILibrettoDiario[]> {
    return this.http.get<ILibrettoDiario[]>(Endpoints.FIND_ALL_LIBRETTI_BY_RICHIESTA_SBLOCCO);
  }

  contaNotifiche(): Observable<ContaNotificheSblocco> {
    return this.http.get<ContaNotificheSblocco>(Endpoints.NOTIFICHE_SBLOCCO_LIBRETTI);
  }

  sbloccaLibretto(request: ILibrettoManagement): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.SBLOCCA_LIBRETTO, request);
  }

  approvaLibretto(request: ILibrettoManagement): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.APPROVA_LIBRETTO, request);
  }

  sospendiLibretto(request: ILibrettoManagement): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.SOSPENDI_LIBRETTO, request);
  }

  exportLibrettoToPDF(tirocinanteId: string): any {
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'

    };
    return this.http.get<any>(Endpoints.ESPORTA_PDF + tirocinanteId, httpOptions);
  }

}
