import {AbstractControl, FormControl, ValidationErrors} from '@angular/forms';
import * as moment from 'moment';
import {Constants} from '../constants/constants';
import {Anno} from '../models/anno';
import {MeseBustaPaga} from '../models/mese-busta-paga';
import {TimesheetInterface} from '../models/timesheet/timesheet.interface';
import {IMonthDetail} from '../models/tirocinio/month-detail.interface';

export default class TimeUtils {

  static getYearRange(): string {
    const currentYear = new Date();
    const finalYear = currentYear.getFullYear() - 18;
    const startingYear = currentYear.getFullYear() - 90;
    return startingYear + ':' + finalYear;
  }

  static getFutureYearRange(): string {
    const currentYear = new Date();
    const finalYear = currentYear.getFullYear() + 10;
    const startingYear = 2017;
    return startingYear + ':' + finalYear;
  }


  static getDaysArray_v2(year, month) {
    var monthIndex = month;

    var date = new Date(year, monthIndex, 1);
    // console.log(date);
    var result = [];
    while (date.getMonth() == monthIndex) {
      result.push(
        {
          numeroGiorno: date.getDate(),
          giornoSettimana: date.getDay(),
          mese: date.getMonth(),
          dataCompleta: new Date(date.getFullYear(), date.getMonth(), date.getDate())
        }
      );
      date.setDate(date.getDate() + 1);
    }
    return result;
  }

  static getGiorniDaDisabilitare(daysCurrentMonth) {
    return Array.from(Array(daysCurrentMonth[0].giornoSettimana - 1), (x, i) => i);
  }

  static prepareMonthForMoment(month: number): string {
    if (month >= 1 && month <= 9) {
      return '0' + (month + 1);
    }
    return '' + (month + 1);
  }

  static mergeWorkedDaysWithCalendar_v2(timesheet: TimesheetInterface, calendarDays) {
    if (timesheet.workingDays) {
      for (let wd of timesheet.workingDays) {
        for (let day of calendarDays) {
          if (moment(day.dataCompleta).isSame(wd.dataCalendario, 'day')) {
            Object.assign(day, {
              id: wd.id,
              totaleOreGiornata: wd.totaleOreGiornata,
              activities: wd.dayDetails,
              extras: wd.dayDetailsExtraProjects,
              note: wd.note
            });
          }
        }

      }
    }
  }

  static mergeWorkedDaysTirocinioWithCalendar(monthDetail: IMonthDetail, calendarDays) {
    if (monthDetail.workingDayTirocinio) {
      for (let wd of monthDetail.workingDayTirocinio) {
        for (let day of calendarDays) {
          if (moment(day.dataCompleta).isSame(wd.dataCalendario, 'day')) {
            Object.assign(day, {
              id: wd.id,
              totaleOreGiornata: wd.totaleOreGiornata,
              activities: wd.dayDetailsTirocinio,
              descrizione: wd.descrizione
            });
          }
        }

      }
    }
  }

  static mergeRichiesteDipendente(timesheet: TimesheetInterface, calendarDays) {
    for (let day of calendarDays) {
      if (moment(day.dataCompleta).isSame('2021-04-07', 'day')) {
        // console.log('SI');
        Object.assign(day, {
          richieste: [{tipoRichiesta: 'FERIE', oreRichiesta: 8}],
        });
      }
    }
  }

  static yearsForDropdown(): Anno[] {
    let years: Anno[] = [];
    let currentYear = new Date().getFullYear();
    let earliestYear = 2020;
    while (currentYear >= earliestYear) {
      years.push({
        nome: currentYear + '',
        codice: currentYear + ''
      });
      currentYear -= 1;
    }
    return years;
  }

  static anniPerBustaPaga(annoInizioContratto: number): Anno[] {
    let years: Anno[] = [];
    let currentYear = new Date().getFullYear();
    let earliestYear = annoInizioContratto;
    while (currentYear >= earliestYear) {
      years.push({
        nome: currentYear + '',
        codice: currentYear + ''
      });
      currentYear -= 1;
    }
    return years;
  }

  static getMesiBustaPaga(): MeseBustaPaga[] {
    let mesi: MeseBustaPaga[] = [
      new MeseBustaPaga('Gennaio', 1, false),
      new MeseBustaPaga('Febbraio', 2, false),
      new MeseBustaPaga('Marzo', 3, false),
      new MeseBustaPaga('Aprile', 4, false),
      new MeseBustaPaga('Maggio', 5, false),
      new MeseBustaPaga('Giugno', 6, false),
      new MeseBustaPaga('Luglio', 7, false),
      new MeseBustaPaga('Agosto', 8, false),
      new MeseBustaPaga('Settembre', 9, false),
      new MeseBustaPaga('Ottobre', 10, false),
      new MeseBustaPaga('Novembre', 11, false),
      new MeseBustaPaga('Dicembre', 12, false),
      new MeseBustaPaga('Tredicesima', 13, false)
    ];
    return mesi;
  }

  static maxDateForBirthDay() {
    let today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    const diciottoAnniFaDaOggi = today;
    return diciottoAnniFaDaOggi;
  }

  static dataFineMaggioreDataInizio(dataInizio: Date, dataFine: Date): boolean {
    return dataFine > dataInizio;
  }

  static setMinDate(date: Date) {
    if (date != null) {
      return date;
    }
    return null;
  }

  static dataDiNascitaValidator(form: FormControl): ValidationErrors {
    let dateSixtyFiveYearsAgo = new Date();
    const currentYear = dateSixtyFiveYearsAgo.getFullYear();
    dateSixtyFiveYearsAgo.setFullYear(currentYear - 90);
    return form.value < dateSixtyFiveYearsAgo ? {dataDiNascitaValidator: true} : null;
  }

  static dateValidator(form: FormControl): ValidationErrors {
    let primoGiornoDel2017 = new Date(2017, 0, 1);
    return form.value < primoGiornoDel2017 ? {dateValidator: true} : null;
  }

  static projectDateValidator(form: FormControl): ValidationErrors {
    let primoGiornoDel2017 = new Date(2017, 0, 1);
    return form.value < primoGiornoDel2017 ? {projectDateValidator: true} : null;
  }

  static enableAndResetDateField(dateControl: AbstractControl, dateControl1: AbstractControl): boolean {


    if (dateControl != null && dateControl.value != null && dateControl.value > Constants.INITIAL_DATE_DATASOUND) {
      dateControl1.reset();
      return true;
    }
    dateControl1.disable();
    return false;
  }

  static validBeginEndDate(dateControl: AbstractControl, dateControl1: AbstractControl): boolean {
    // console.log(dateControl.value);

    if (dateControl != null && dateControl.value != null
      && dateControl.value > Constants.INITIAL_DATE_DATASOUND
      && dateControl1.value != null && dateControl1 != null) {
      if (dateControl1 > dateControl) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  static convertDayToActivityIndex(date: Date): string {
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    const s = `${ye}-${mo}-${da}`;
    // console.log(s)
    return s;
  }
}
