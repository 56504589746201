export class TipologiaRapportino {

  public static readonly MALATTIA = 'Malattia';
  public static readonly FERIE = 'Ferie';
  public static readonly ROL = 'Rol';
  public static readonly BANCAORE = 'Banca Ore';

  private nome: string;
  private codice: number;

  constructor(nome: string, codice: number) {
    this.nome = nome;
    this.codice = codice;
  }

  public getNome(): string {
    return this.nome;
  }

  public setNome(nome: string) {
    this.nome = nome;
  }

  public getCodice(): number {
    return this.codice;
  }

  public setCodice(codice: number) {
    this.codice = codice;
  }
}
