import {Pipe, PipeTransform} from '@angular/core';
import {TimesheetStatus} from '../models/enums/timesheet-status';

@Pipe({
  name: 'timesheetStatus'
})
export class TimesheetStatusPipe implements PipeTransform {
  transform(timesheetStatus: string) {
    switch (timesheetStatus) {
      case TimesheetStatus.APPROVATO:
        return 'success';
      case TimesheetStatus.DA_APPROVARE:
        return 'warning';
      case TimesheetStatus.IN_LAVORAZIONE:
        return 'danger';
      default:
        return 'danger';
    }
  }
}
