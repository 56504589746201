import {Mese} from '../models/mese';

export const MESI_MOCK: Mese[] = [
  new Mese('Gennaio', 0),
  new Mese('Febbraio', 1),
  new Mese('Marzo', 2),
  new Mese('Aprile', 3),
  new Mese('Maggio', 4),
  new Mese('Giugno', 5),
  new Mese('Luglio', 6),
  new Mese('Agosto', 7),
  new Mese('Settembre', 8),
  new Mese('Ottobre', 9),
  new Mese('Novembre', 10),
  new Mese('Dicembre', 11)
];
