<div class="main-area">
  <main>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <p-toast position="bottom-right"></p-toast>
    <div class="calendar-month">
      <section class="calendar-month-header">
        <div class="selected-month" id="selected-month">
          <i class="las la-calendar"></i> {{ meseAnno }}
        </div>
        <br>
        <p-table *ngIf="timesheet && timesheet.totaleOre > 0" [autoLayout]="true"
                 [value]="oreProgettiByTimesheetId" styleClass="p-datatable-gridlines p-datatable-sm">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col">Tipologia</th>
              <th scope="col">Progetto</th>
              <th scope="col">Ore</th>
            </tr>
          </ng-template>
          <ng-template let-activity pTemplate="body">
            <tr>
              <td>{{tipologia(activity)}}</td>
              <td>{{riepilogo(activity)}}</td>
              <td>{{ activity.ore }}</td>
            </tr>
          </ng-template>
        </p-table>
        <div class="riepilogo">

          <div class="riepilogo-single p-shadow-3">
            <div>
              <p>TOTALE</p>
              <h4 *ngIf="timesheet">{{timesheet.totaleOre}}</h4>
              <span>ore lavorate</span>
            </div>
            <div>
              <span class="las la-user-clock"></span>
            </div>
          </div>
          <div class="riepilogo-single p-shadow-3">
            <div>
              <p>STATO</p>
              <h4>
                <p-tag *ngIf="timesheet" [severity]="timesheet.statoRapportino.id | statoRapportino" [style]="{'width': '100%', 'text-transform': 'none'}"
                       [value]="timesheet.statoRapportino.nome"></p-tag>
              </h4>
              <span *ngIf="timesheet && timesheet.richiestaSblocco">Hai richiesto lo sblocco</span>
            </div>
            <div>
              <span class="las la-calendar-check"></span>
            </div>
          </div>
        </div>
        <div *ngIf="timesheet" class="p-grid p-mt-1"></div>
      </section>

      <ol *ngIf="timesheetExists" class="day-of-week" id="days-of-week">
        <li>Lun</li>
        <li>Mar</li>
        <li>Mer</li>
        <li>Gio</li>
        <li>Ven</li>
        <li class="festivita">Sab</li>
        <li class="festivita">Dom</li>
      </ol>
      <ol *ngIf="timesheetExists" class="days-grid" id="calendar-days">
        <li *ngFor="let giornoDaDisabilitare of giorniDaDisabilitare" class="calendar-day calendar-day--not-current">
          <span></span>
        </li>
        <li (click)="getData(giorno)" *ngFor="let giorno of daysCurrentMonth; let i = index" class="calendar-day">
        <span [class]="'giorno ' + (giorno.giornoSettimana == 6 || giorno.giornoSettimana == 0 || giorniFestivi.includes(giorno, 0) ? 'festivita' : '')">{{
          giorno.numeroGiorno }}</span>
          <span *ngIf="giorno.activities != undefined || giorno.extras != undefined"
                class="monteOre"> {{totHour(giorno)}}h</span>
          <span *ngFor="let act of daysActivities.activities[convertDayToActivityIndex(giorno.dataCompleta)]; let i = index" class="parzialeOre">
          {{ act.nome }} ({{act.ore}}h)
        </span>
          <span *ngFor="let act of daysActivities.extra[convertDayToActivityIndex(giorno.dataCompleta)]; let i = index" class="parzialeOreExtra">
            {{ act.nome }} &#8205;<p *ngIf="act.ore>0">({{act.ore}}h)</p>
          </span>
        </li>
      </ol>
    </div>

    <div class="p-fluid p-formgrid p-grid p-mt-4 p-jc-end">
      <div *ngIf="!timesheetExists"  (click)="creaTimesheet()"
           class="p-field p-col-12 p-md-2">
        <button class="p-button p-button-warning" label="Crea rapportino" pButton type="submit"></button>
      </div>
      <div (click)="inviaRichiestaSblocco()"
           *ngIf="timesheet && utils.renderRichiediSblocco(this.timesheet.richiestaSblocco, this.timesheet.statoRapportino.id)"
           class="p-field p-col-12 p-md-2">
        <button class="p-button p-button-warning" label="Richiedi sblocco" pButton type="submit"></button>
      </div>
      <div (click)="inviaRapportino()"
           *ngIf="timesheet && utils.renderPulsanteInvia(this.timesheet.richiestaSblocco, this.timesheet.statoRapportino.id)"
           class="p-field p-col-12 p-md-2">
        <button class="p-button p-button-success" label="Invia" pButton type="button"></button>
      </div>
    </div>


    <ng-container *ngIf="selectedDay">
      <p-dialog (onHide)="resetForm()"
                [header]="'Inserimento attività ' + (selectedDay.dataCompleta | date:'dd/MM/YYYY')"
                [(visible)]="displayModal" [draggable]="false" modal="true">

        <form (ngSubmit)="salvaDettaglioGiorno()" [formGroup]="formNuovoInserimento">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4 p-md-4">
              <label for="tipolgia" required>Tipologia</label>
              <p-dropdown (onChange)="onChange($event)" [options]="tipologiaRapportino" appendTo="body"
                          formControlName="tipologiaSelezionata" optionLabel="nome"
                          placeholder="Seleziona..."></p-dropdown>
            </div>
            <div *ngIf="showProjectDiv" class="p-field p-col-4 p-md-4">
              <label for="progetto">Progetto</label>
              <p-dropdown (click)="hoursExceeded=false"
                          [ngClass]="{'ng-invalid ng-dirty': !formNuovoInserimento.get('progettoSelezionato').valid && (formNuovoInserimento.get('progettoSelezionato').dirty || formNuovoInserimento.get('progettoSelezionato').touched)}"
                          [options]="progetti"
                          appendTo="body"
                          formControlName="progettoSelezionato"
                          optionLabel="nome"
                          placeholder="Seleziona..." required></p-dropdown>
            </div>

            <div class="p-field p-col-4 p-md-4">
              <label *ngIf="requiredHour" for="parzialeOre" required>Ore</label>
              <label *ngIf="!requiredHour" for="parzialeOreMalattia" required>Ore</label>
              <input (click)="hoursExceeded=false" (keypress)="utils.onlyPositiveNumbers($event)"
                     [(ngModel)]="parzialeOre"
                     formControlName="parzialeOre"
                     id="parzialeOre"
                     pInputText required type="number">
            </div>
            <div class="p-field p-col-12 descr">
              <label *ngIf="requiredDescrizione" for="descrizione" required>Descrizione</label>
              <label *ngIf="!requiredDescrizione" for="descrizione">Descrizione</label>
              <textarea class="descrizione" formControlName="descrizione" id="descrizione" pInputText
                        type="text"></textarea>
            </div>
            <div class="p-field p-col-3 p-md-3 p-offset-6">
              <button (click)="simulateCloseClick()" class="p-button p-button-danger" label="Annulla" pButton
                      type="button"></button>
            </div>
            <div class="p-field p-col-3 p-md-3">
              <button [disabled]="!formNuovoInserimento.valid &&requiredHour" class="p-button p-button-success" label="Aggiungi" pButton
                      type="submit"></button>
            </div>
          </div>
          <p-message *ngIf="utils.formValid(formNuovoInserimento).length > 0 " @simpleFadeAnimation
                     severity="error" text="Attenzione! Campo/i non correttamente popolati"></p-message>
        </form>
        <p-message *ngIf=" utils.formValid(formNuovoInserimento).length == 0 && hoursExceeded"
                   severity="error"
                   text="Attenzione! Per il giorno selezionato è stato raggiunto il numero massimo di ore caricabili."></p-message>

        <p-messages [enableService]="true"></p-messages>
        <div *ngIf="mostraRiepilogo">
          <div class="p-field p-col-12 p-md-12">
            <h3>Riepilogo</h3>
            <p-divider type="dashed"></p-divider>
          </div>
          <p-table [style]="{width: '80vw'}" [value]="selectedDay.activities.concat(selectedDay.extras)">
            <ng-template pTemplate="header">
              <tr>
                <th class="button" scope="col"></th>
                <th class="project" scope="col">Tipologia</th>
                <th class="project-type" scope="col">Progetto</th>
                <th class="time" scope="col">Ore</th>
                <th class="description" scope="col">Descrizione</th>
              </tr>
            </ng-template>
            <ng-template let-activity pTemplate="body">
              <tr>
                <td class="button">
                  <button (click)="deleteOneDayDetail($event, selectedDay.id, timesheet.id, activity.id, activity.tipoGiornataId)" class="p-button p-button-text p-button-danger" icon="pi pi-trash" pButton
                          type="button"></button>
                </td>
                <td *ngIf="selectedDay.activities.includes(activity)" class="project">{{tipologia(activity.progetto)}}</td>
                <td *ngIf="selectedDay.activities.includes(activity)" class="project-type">{{riepilogo(activity.progetto)}}</td>
                <td *ngIf="selectedDay.extras.includes(activity)" class="project">{{tipologia(activity.tipoGiornata)}}</td>
                <td *ngIf="selectedDay.extras.includes(activity)" class="project-type">{{riepilogo(activity.tipoGiornata)}}</td>
                <td class="time" >{{ activity.parzialeOre || '-' }}</td>
                <td (click)="showModalDialog(activity.descrizione)" class="description" >{{ activity.descrizione || '-' }}</td>
              </tr>
            </ng-template>
            <p-confirmPopup></p-confirmPopup>
          </p-table>

          <div class="p-col-12 totOre">
            Totale ore: {{totHour(selectedDay)}}
          </div>

          <p-dialog [(visible)]="displayModalInfo" [draggable]="false" [style]="{width: '50vw'}" header="Descrizione">
            <p>{{descrizione}}</p>
          </p-dialog>

        </div>
        <p-divider type="none"></p-divider>
        <div *ngIf="selectedDay.activities" class="p-grid">
          <div class="p-col-12">
            <button (click)="deleteWorkingDay(selectedDay.id, timesheet.id)" [label]="'Elimina tutte le attività del '+(selectedDay.dataCompleta | date:'dd/MM/YYYY')"
                    class="p-button p-button-outlined p-button-sm p-button-danger"
                    pButton
                    type="button"></button>
          </div>
        </div>
      </p-dialog>
    </ng-container>

    <ng-container *ngIf="selectedDay">
      <p-dialog (onHide)="resetForm()"
                [(visible)]="displayModalActivity" [draggable]="false" [header]="'Attività del ' + (selectedDay.dataCompleta | date:'dd/MM/YYYY')" modal="true">

        <p-messages [enableService]="true"></p-messages>
        <div *ngIf="mostraRiepilogo">
          <div class="p-field p-col-12 p-md-12">
            <h3>Riepilogo</h3>
            <p-divider type="dashed"></p-divider>
          </div>
          <p-table [style]="{width: '80vw'}" [value]="selectedDay.activities.concat(selectedDay.extras)">
            <ng-template pTemplate="header">
              <tr>
                <th class="button" scope="col"></th>
                <th class="project" scope="col">Tipologia</th>
                <th class="project-type" scope="col">Progetto</th>
                <th class="time" scope="col">Ore</th>
                <th class="description" scope="col">Descrizione</th>
              </tr>
            </ng-template>
            <ng-template let-activity pTemplate="body">
              <tr>
                <td class="button">
                </td>
                <td *ngIf="selectedDay.activities.includes(activity)" class="project">{{tipologia(activity.progetto)}}</td>
                <td *ngIf="selectedDay.activities.includes(activity)" class="project-type">{{riepilogo(activity.progetto)}}</td>
                <td *ngIf="selectedDay.extras.includes(activity)" class="project">{{tipologia(activity.tipoGiornata)}}</td>
                <td *ngIf="selectedDay.extras.includes(activity)" class="project-type">{{riepilogo(activity.tipoGiornata)}}</td>
                <td class="time" >{{ activity.parzialeOre || '-' }}</td>
                <td (click)="showModalDialog(activity.descrizione)" class="description" >{{ activity.descrizione || '-' }}</td>
              </tr>
            </ng-template>
          </p-table>

          <div class="p-col-12 totOre">
            Totale ore: {{totHour(selectedDay)}}
          </div>

          <p-dialog [(visible)]="displayModalInfo" [draggable]="false" [style]="{width: '50vw'}" header="Descrizione">
            <p>{{descrizione}}</p>
          </p-dialog>

        </div>
      </p-dialog>
    </ng-container>

  </main>
</div>
