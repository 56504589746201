<div class="main-body-login">
  <div class="container-box-brand">
    <div class="brand-main-content">

    </div>

    <div class="container-box">
      <ngx-spinner [fullScreen]="false" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2" size="medium"
                   type="ball-clip-rotate-multiple"><p style="color: #118ab2">Caricamento...</p></ngx-spinner>
      <div class="logo-container">
        <img [src]="fullLogoUrl" alt="" width="200px">
      </div>
      <div class="login-container">
        <h3>Reset password</h3>
      </div>
      <p-messages [enableService]="true"></p-messages>
      <div *ngIf="!resetTokenValid && showBackButton" class="login-form">
        <div class="p-fluid">
          <button [routerLink]="['/']" class="p-button-lg p-button-primary" icon="pi pi-arrow-circle-left" label="Torna indietro"
                  pButton type="button"></button>
        </div>
      </div>
      <div *ngIf="procedureCompleted" class="login-form">
        <div class="p-fluid">
          <p-divider></p-divider>
          <button [routerLink]="['/']" class="p-button-text p-button-help" icon="las la-lg la-arrow-circle-left" label="Vai alla pagina di accesso"
                  pButton type="button"></button>

        </div>
      </div>
      <form (ngSubmit)="doPasswordReset()" *ngIf="resetTokenValid && !procedureCompleted"
            [formGroup]="resetPasswordForm">
        <div class="login-form">
          <div class="p-fluid">
            <div class="p-field">
              <label for="password1">Nuova password</label>
              <input [(ngModel)]="password1" formControlName="password1" pInputText type="password"/>

            </div>

            <div class="p-field">
              <label for="float-input">Conferma password</label>
              <input [(ngModel)]="password2" formControlName="password2" pInputText type="password"/>
            </div>

            <p-message *ngIf="password1 != password2 && password2!=''" severity="error"
                       text="Le password non corrispondono"></p-message>

            <br>
            <div class="p-field">
            </div>
            <button [disabled]="!resetPasswordForm.valid || password1 != password2" class="p-button-lg p-button-success" icon="pi pi-lock" label="Conferma password"
                    pButton
                    type="submit"></button>
          </div>
        </div>
      </form>


    </div>
  </div>
</div>
