<div class="main-area">
  <main>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255,255,255,0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple"><p style="color: #118ab2"> Caricamento... </p></ngx-spinner>

    <div class="titolo-sezione">
      <i class="las la-id-card"></i> Profilo
    </div>
    <form (ngSubmit)="inviaForm()" *ngIf="dataLoaded | async" [formGroup]="formProfilo" class="p-mt-4">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-6 p-md-3">
          <label for="nome">Nome</label>
          <input (keypress)="utils.onlyCharacters($event)" [ngClass]="{'ng-invalid ng-dirty': !formProfilo.get('nome').valid && (formProfilo.get('nome').dirty || formProfilo.get('nome').touched)}"
                 formControlName="nome"
                 id="nome"
                 pInputText required type="text">
        </div>
        <div class="p-field p-col-6 p-md-3">
          <label for="cognome">Cognome</label>
          <input (keypress)="utils.onlyCharacters($event)" [ngClass]="{'ng-invalid ng-dirty': !formProfilo.get('cognome').valid && (formProfilo.get('cognome').dirty || formProfilo.get('cognome').touched)}"
                 formControlName="cognome"
                 id="cognome"
                 pInputText required type="text">
        </div>
        <div class="p-field p-col-6 p-md-3">
          <label for="codiceFiscale">Codice fiscale</label>
          <input (input)="onInputCodiceFiscale(formProfilo.get('codiceFiscale').value)" [ngClass]="{'ng-invalid ng-dirty': !formProfilo.get('codiceFiscale').valid && (formProfilo.get('codiceFiscale').dirty || formProfilo.get('codiceFiscale').touched)}"
                 formControlName="codiceFiscale"
                 id="codiceFiscale"
                 pInputText required type="text">
          <small *ngIf="!cfValid" @fadeInAnimation class="p-error" id="cf-help">Codice fiscale non valido</small>

        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="dataDiNascita">Data di nascita</label>
          <p-calendar [maxDate]="timeUtils.maxDateForBirthDay()" [monthNavigator]="true"
                      [ngClass]="{'ng-invalid ng-dirty': !formProfilo.get('dataDiNascita').valid && (formProfilo.get('dataDiNascita').dirty || formProfilo.get('dataDiNascita').touched)}"
                      [yearNavigator]="true"
                      [yearRange]="getYearRange()"
                      dateFormat="dd/mm/yy"
                      formControlName="dataDiNascita" required></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-3 parent">
          <label for="prefix">Cellulare<label class="required-asterisk">*</label></label>

          <div class="block-div">
            <div class="first">
              <input [placeholder]="this.phonePrefix" formControlName="prefix"
                     id="prefix" pInputText
                     required>
            </div>
            <div class="last">
              <p-inputMask [ngClass]="{'ng-invalid ng-dirty': !formProfilo.get('cellulare').valid && (formProfilo.get('cellulare').dirty || formProfilo.get('cellulare').touched)}"
                           formControlName="cellulare" id="cellulare"
                           mask="9999999999" required></p-inputMask>
            </div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="state">Stato</label>
          <p-dropdown [ngClass]="{'ng-invalid ng-dirty': !formProfilo.get('statusSelezionato').valid && (formProfilo.get('statusSelezionato').dirty || formProfilo.get('statusSelezionato').touched)}" [options]="statusDip"
                      formControlName="statusSelezionato"
                      inputId="statusSelezionato" optionLabel="name" placeholder="Seleziona" required></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="state">Ruolo</label>
          <p-dropdown [ngClass]="{'ng-invalid ng-dirty': !formProfilo.get('ruolo').valid && (formProfilo.get('ruolo').dirty || formProfilo.get('ruolo').touched)}" [options]="ruoliUtente"
                      formControlName="ruolo"
                      inputId="ruolo" optionLabel="name" placeholder="Seleziona" required
                      styleClass="stileRuoli"></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-3">
          <label for="state">Mansione</label>
          <p-dropdown [ngClass]="{'ng-invalid ng-dirty': !formProfilo.get('mansione').valid && (formProfilo.get('mansione').dirty || formProfilo.get('mansione').touched)}" [options]="mansioni"
                      formControlName="mansione"
                      inputId="mansione" optionLabel="nome" placeholder="Seleziona" required></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="email">Indirizzo email</label>
          <input [ngClass]="{'ng-invalid ng-dirty': !formProfilo.get('email').valid && (formProfilo.get('email').dirty || formProfilo.get('email').touched)}" formControlName="email"
                 id="email"
                 pInputText required type="email">
        </div>
        <div class="p-field p-col-6 p-md-4">
          <label for="dataDiNascita">Data inizio rapporto</label>
          <p-calendar (onSelect)="onDataInizioChange()"
                      [monthNavigator]="true"
                      [ngClass]="{'ng-invalid ng-dirty': !formProfilo.get('dataInizioRapporto').valid && (formProfilo.get('dataInizioRapporto').dirty || formProfilo.get('dataInizioRapporto').touched)}"
                      [yearNavigator]="true"
                      [yearRange]="getFutureYearRange()"
                      dateFormat="dd/mm/yy"
                      formControlName="dataInizioRapporto" required></p-calendar>
        </div>
        <div class="p-field p-col-6 p-md-4 fix-non-required-inline">
          <label for="dataFineRapporto">Data fine rapporto</label>
          <p-calendar [disabled]="dataFineDisabled"
                      [minDate]="timeUtils.setMinDate(formProfilo.get('dataInizioRapporto').value)"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      [yearRange]="getFutureYearRange()" dateFormat="dd/mm/yy" formControlName="dataFineRapporto"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="tipoContratto">Tipo contratto</label>
          <p-dropdown [options]="tipiContratto" formControlName="tipoContratto" inputId="tipoContratto"
                      optionLabel="nome" placeholder="Seleziona" required></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="oreSettimanali">Ore settimanali</label>
          <input (keypress)="utils.onlyIntegerNumber($event)" [ngClass]="{'ng-invalid': !formProfilo.get('oreSettimanali').valid && (formProfilo.get('oreSettimanali').dirty || formProfilo.get('oreSettimanali').touched)}" formControlName="oreSettimanali"
                 id="oreSettimanali" min="0"
                 pInputText
                 required type="number">
        </div>
        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <p-button label="In possesso di:" styleClass="p-button-text"></p-button>
          </p-divider>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <div class="p-field-checkbox">
            <p-checkbox binary="true" formControlName="chiaviUfficio" inputId="chiaviUfficio"></p-checkbox>
            <label for="chiaviUfficio">Chiavi ufficio</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox binary="true" formControlName="telefonoAziendale" inputId="telefonoAziendale"></p-checkbox>
            <label for="telefonoAziendale">Telefono aziendale</label>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox (onChange)="detectComodatoChange($event)" [(ngModel)]="pcAziendaleCheckBox" binary="true"
                        formControlName="pcAziendale" inputId="pcAziendale"></p-checkbox>
            <label for="pcAziendale">Pc aziendale</label>
          </div>
        </div>
        <div *ngIf="pcAziendaleCheckBox" class="p-field p-col-6 p-md-6">
          <label for="serialePc">Seriale Pc</label>
          <input [required]="pcAziendaleCheckBox || null" formControlName="serialePc" id="serialePc" pInputText
                 type="text">
        </div>
        <div *ngIf="pcAziendaleCheckBox" class="p-field p-col-6 p-md-6">
          <label for="modelloPc">Modello Pc</label>
          <input [required]="pcAziendaleCheckBox || null" formControlName="modelloPc" id="modelloPc" pInputText
                 type="text">
        </div>
        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <p-button label="Progetti" styleClass="p-button-text"></p-button>
          </p-divider>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <div *ngIf="profiloAdmin.progetti" class="p-d-flex p-ai-center">
            <p-chip *ngFor="let progetto of profiloAdmin.progetti" [label]="progetto.nome" styleClass="p-mr-2"></p-chip>
          </div>
        </div>

      </div>
    </form>
    <p-message *ngIf="utils.formValid(formProfilo).length > 0" @simpleFadeAnimation
               severity="error" text="Attenzione! Campi obbligatori non compilati."></p-message>
    <p-messages [enableService]="true"></p-messages>

  </main>
</div>
