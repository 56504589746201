import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmationService, MessageService} from 'primeng/api';
import {simpleFadeAnimation} from 'src/app/animations/simple-fade-animation';
import {Constants} from 'src/app/constants/constants';
import {FrontendMessage} from 'src/app/constants/frontend-message';
import {STATUS_PROGETTI_MOCK} from 'src/app/mock/status-progetti.mock';
import {ProgettoInterface} from 'src/app/models/progetto.interface';
import {RiepilogoOreProgetto} from 'src/app/models/response/riepilogo-ore-progetto';
import {RiepilogoConsuntivo} from 'src/app/models/riepilogo-consuntivo';
import {StatusProgetto} from 'src/app/models/status-progetto';
import {ConsuntivazioneService} from 'src/app/services/consuntivazione.service';
import {ProgettiService} from 'src/app/services/progetti.service';
import {TimesheetService} from 'src/app/services/timesheet.service';
import GenericUtils from 'src/app/utils/generic-utils';
import TimeUtils from '../../../utils/time-utils';
import {RouterPaths} from '../../../constants/RouterPaths';

@Component({
  selector: 'app-progetto',
  templateUrl: './progetto.component.html',
  styleUrls: ['./progetto.component.scss'],
  providers: [MessageService, ConfirmationService, ConsuntivazioneService],
  animations: [simpleFadeAnimation]
})
export class ProgettoComponent implements OnInit {

  progettoId: string;
  dataLoaded: Promise<boolean>;
  formProgetto: FormGroup;
  statusSelezionato: StatusProgetto;
  switchToEditMode: boolean;
  statusProgetti: StatusProgetto[] = STATUS_PROGETTI_MOCK;
  progetto: ProgettoInterface;
  timeUtils = TimeUtils;
  totaleOreDipendenti: RiepilogoOreProgetto[] = [];
  riepilogoConsuntivoProgetto: RiepilogoConsuntivo;
  coloreStatistiche: string;
  utils = GenericUtils;
  dataFineDisabled: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private progettiService: ProgettiService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private timesheetService: TimesheetService,
    private router: Router,
    private consuntivazioneService: ConsuntivazioneService) {
    this.dataFineDisabled = true;
    this.spinner.show();
    this.progettoId = this.activatedRoute.snapshot.params.id;
    this.progettiService.getProgettoById(this.progettoId).subscribe(progetto => {

      this.progetto = progetto;
      const dataInizio = progetto.dataInizio != null ? progetto.dataInizio : null;
      const dataFine = progetto.dataFine != null ? progetto.dataFine : null;
      this.statusSelezionato = new StatusProgetto(progetto.status, progetto.status);


      this.formProgetto = this.fb.group({
        code: [progetto.code, [
          Validators.required,
          Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE),
          Validators.pattern(Constants.REGEX_ALPHANUM_SPECIAL_NO_LEAD_TRAIL_SPACE),
          RxwebValidators.maxLength({value: Constants.INPUT_TEXT_MAX_LENGHT}),
          this.utils.whitespaceValidator,
          this.utils.endWithSpaceValidator]
        ],
        nome: [progetto.nome, [
          Validators.required,
          Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE),
          Validators.pattern(Constants.REGEX_ALPHANUM_SPECIAL_NO_LEAD_TRAIL_SPACE),
          RxwebValidators.maxLength({value: Constants.INPUT_TEXT_MAX_LENGHT}),
          this.utils.whitespaceValidator,
          this.utils.endWithSpaceValidator]
        ],
        cliente: [progetto.cliente, [
          Validators.required,
          Validators.pattern(Constants.REGEX_ALPHANUM_WITH_SC_NO_LEAD_TRAIL_SPACE),
          Validators.pattern(Constants.REGEX_ALPHANUM_SPECIAL_NO_LEAD_TRAIL_SPACE),
          RxwebValidators.maxLength({value: Constants.INPUT_TEXT_MAX_LENGHT}),
          this.utils.whitespaceValidator,
          this.utils.endWithSpaceValidator]
        ],
        dataInizio: [new Date(dataInizio)],
        dataFine: [new Date(dataFine)],
        status: [this.statusSelezionato],
        budgetComplessivo: [progetto.budgetComplessivo, this.utils.greaterThanOneNumberValidator],
        spesePreventivate: [progetto.spesePreventivate],
        oreUomoStimate: [progetto.oreUomoStimate, RxwebValidators.minNumber({value: 0})],
        coloreStatistiche: [progetto.coloreStatistiche]
      });

      this.coloreStatistiche = progetto.coloreStatistiche;
      this.formProgetto.disable();

      this.timesheetService.riepilogoOreSitazioneEconomicaProgetto(progetto.id + '').subscribe(oreRiepilogo => {
        this.totaleOreDipendenti = oreRiepilogo;
      }, error => {
        this.spinner.hide();
        this.messageService.add(FrontendMessage.MESSAGE_SERVICE_KO);
      });


      this.consuntivazioneService.getRiepilogoConsuntivoByProgettoId(progetto.id).subscribe(riepilogoConsuntivo => {
        this.riepilogoConsuntivoProgetto = riepilogoConsuntivo;
      }, (errorResponse: HttpErrorResponse) => {
        this.messageService.add({
          severity: FrontendMessage.SEVERITY_ERROR,
          summary: FrontendMessage.SUMMARY_ERROR,
          detail: errorResponse.error.message
        });
      });

      this.spinner.hide();
      this.dataLoaded = Promise.resolve(true);
    });


  }

  ngOnInit(): void {
  }

  switchToEdit() {
    this.switchToEditMode = true;
    this.formProgetto.enable();
    this.dataFineDisabled = true;
  }

  closeEditMode() {
    this.switchToEditMode = false;
    this.formProgetto.disable();
  }

  detectStatusChange() {
    // console.log(this.formProgetto.get('status').value);
  }

  addZeroes(num) {
    const dec = num.split('.')[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    return Number(num).toFixed(len);
  }

  modificaProgetto() {


    this.messageService.clear();
    this.spinner.show();
    const dataInizioProgetto = new Date(Date.UTC(this.formProgetto.get('dataInizio').value.getFullYear(), this.formProgetto.get('dataInizio').value.getMonth(), this.formProgetto.get('dataInizio').value.getDate()));
    const dataFineProgetto = new Date(Date.UTC(this.formProgetto.get('dataFine').value.getFullYear(), this.formProgetto.get('dataFine').value.getMonth(), this.formProgetto.get('dataFine').value.getDate()));
    let spesePreventivate = this.formProgetto.get('spesePreventivate').value;
    let budgetComplessivo = this.formProgetto.get('budgetComplessivo').value;
    if (spesePreventivate == '') {
      spesePreventivate = 0;
    }
    if (budgetComplessivo == '') {
      budgetComplessivo = 0;
    }
    if (this.coloreStatistiche == undefined) {
      this.coloreStatistiche = this.utils.random_hex_color_code();
    }

    const progetto: ProgettoInterface = {
      id: this.progetto.id,
      code: this.formProgetto.get('code').value,
      nome: this.formProgetto.get('nome').value,
      status: this.formProgetto.get('status').value.name,
      cliente: this.formProgetto.get('cliente').value,
      dataInizio: dataInizioProgetto,
      dataFine: dataFineProgetto,
      spesePreventivate: spesePreventivate != null ? parseFloat(spesePreventivate).toFixed(2) : null,
      budgetComplessivo: parseFloat(budgetComplessivo).toFixed(2),
      oreUomoStimate: this.formProgetto.get('oreUomoStimate').value,
      coloreStatistiche: this.coloreStatistiche
    };
    // console.log(progetto);

    this.progettiService.updateProgetto_v2(progetto).subscribe(response => {
      if (response.statusCode == Constants.STATUS_CODE_OK) {
        this.spinner.hide();
        this.closeEditMode();
        this.messageService.add({
          severity: FrontendMessage.SEVERITY_SUCCESS,
          summary: '',
          detail: response.message,
          life: Constants.MESSAGE_LIFE_IN_MILLISECONDS
        });
        const currRoute = this.router.url;
        setTimeout(() => {
          this.messageService.clear();
          if (currRoute === this.router.url) { this.router.navigateByUrl(RouterPaths.ADMIN_ROUTE + '/' + RouterPaths.LISTA_PROGETTI); }
        }, 2000);
      }
    }, (errorResponse: HttpErrorResponse) => {
      this.spinner.hide();
      this.messageService.add({
        severity: FrontendMessage.SEVERITY_ERROR,
        summary: '',
        detail: errorResponse.error.message,
        life: Constants.MESSAGE_LIFE_IN_MILLISECONDS
      });
    });
  }

  revertForm() {
    const statusProgetto = new StatusProgetto(this.progetto.status, this.progetto.status);
    this.formProgetto.patchValue({
      code: this.progetto.code,
      nome: this.progetto.nome,
      status: statusProgetto,
      cliente: this.progetto.cliente,
      dataInizio: new Date(this.progetto.dataInizio),
      dataFine: new Date(this.progetto.dataFine),
      spesePreventivate: this.progetto.spesePreventivate,
      budgetComplessivo: this.progetto.budgetComplessivo,
      oreUomoStimate: this.progetto.oreUomoStimate,
      coloreStatistiche: this.progetto.coloreStatistiche
    });
  }

  onDataInizioChange() {
    this.formProgetto.controls['dataFine'].reset();
    this.dataFineDisabled = false;
  }

  emptyFields() {
    const fields = [];
    const controls = this.formProgetto.controls;
    for (const name in controls) {
      if (this.utils.formValid(this.formProgetto).includes(name) && controls[name].invalid && (controls[name].value == null || controls[name].value == '')) {
        fields.push(name);
      }
    }
    return fields;
  }

}
