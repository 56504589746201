<div class="main-area">
  <main>
    <div class="titolo-sezione">
      <i class="las la-users"></i> Tirocini
    </div>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <p-divider type="dashed"></p-divider>
    <p-divider type="none"></p-divider>

    <div *ngIf="tirocinanti.length==0" class="p-grid">
      <div class="p-col-12">
        <div class="msgNoDipendenti">
          <span class="las la-5x la-info-circle"></span>
          <h3>{{ msgNessunTirocinante }}</h3>
        </div>
      </div>
    </div>


    <p-table
      *ngIf="tirocinanti.length!=0"
      [autoLayout]="true"
      [paginator]="true"
      [rowHover]="true"
      [rowsPerPageOptions]="[10,20,30]"
      [rows]="10" [showCurrentPageReport]="true" [totalRecords]="tirocinanti.length"
      [value]="tirocinanti"
      currentPageReportTemplate="Risultati da {first} a {last}. Totale {totalRecords} tirocinio\i"
      styleClass="p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th class="p-text-center" pSortableColumn="cognome" scope="col">
            Cognome
            <p-sortIcon field="cognome"></p-sortIcon>
            <p-columnFilter display="menu" field="cognome" type="text"></p-columnFilter>
          </th>
          <th class="p-text-center" pSortableColumn="nome" scope="col">
            Nome
            <p-sortIcon field="nome"></p-sortIcon>
            <p-columnFilter display="menu" field="nome" type="text"></p-columnFilter>
          </th>
          <th class="p-text-center" scope="col">
            Matricola
            <p-columnFilter display="menu" field="matricola" type="text"></p-columnFilter>
          </th>
          <th class="p-text-center" pSortableColumn="status" scope="col">
            Stato
            <p-sortIcon field="status"></p-sortIcon>
            <p-columnFilter [showMatchModes]="false" display="menu" field="stato" matchMode="equals">
              <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                <p-dropdown (onChange)="filter($event.value)" [ngModel]="value" [options]="statusTirocinante" optionLabel="name"
                            optionValue="code" placeholder="Qualsiasi">
                  <ng-template let-option pTemplate="item">
                      <span>
                        <p-tag [severity]="utils.statoLibrettoStyle(option.code)"
                               [style]="{'width': '100%'}"
                               [value]="option.name"></p-tag>
                      </span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>
          <th class="p-text-center" scope="col">
            Azioni
          </th>
        </tr>
      </ng-template>
      <ng-template let-tirocinante pTemplate="body">
        <tr>
          <td class="p-text-center">{{tirocinante.cognome}}</td>
          <td class="p-text-center">{{tirocinante.nome}}</td>
          <td class="p-text-center">{{tirocinante.matricola}}</td>
          <td class="p-text-center">
            <p-tag [severity]="utils.statoLibrettoStyle(tirocinante.stato)" [style]="{'width': '100%'}"
                   [value]="tirocinante.stato"></p-tag>
          </td>


          <td class="p-text-center">
            <button (click)="selezionaRiga(tirocinante)" class="p-button p-button-text" icon="pi pi-eye" pButton
                    type="button"></button>
            <button (click)="confirmDelete($event, tirocinante.id)" class="p-button p-button-text p-button-danger" icon="pi pi-trash" pButton
                    type="button"></button>
            <button (click)="goLibrettoDetail(tirocinante.id)" class="p-button p-button-text" icon="pi pi-external-link" pButton
                    type="button"></button>
            <p-confirmPopup></p-confirmPopup>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-dialog #dialogDetail (onHide)="onHideModal()" (onShow)="showDialogMaximized(dialogDetail)" *ngIf="tirocinanteSelezionato"
              [(visible)]="displayModal" [draggable]="false" closeOnEscape="true" header="Dettaglio Tirocinante" maximizable="true"
              modal="true" position="top" resizable="true">
      <p-tag *ngIf="switchToEditMode" [value]="'ID = '+tirocinanteSelezionato.id" icon="pi pi-user"
             severity="info"></p-tag>
      <p-divider type="dashed"></p-divider>
      <div class="p-fluid  p-jc-center">
        <p-steps [(activeIndex)]="activeIndex" [model]="stepNuovoTirocinante" [readonly]="true"></p-steps>
      </div>
      <div class="p-field p-col-10 p-md-12">
        <p-divider type="dashed"></p-divider>
      </div>
      <app-progetto-formativo (activateIndex)="childToParent($event)" (datiAnagrafici)="childToParent($event)"
                              (displayModal)="loadTirocinanteFormProgect($event)"
                              *ngIf="!datiAnagrafici"
                              [tirocinanteToStore]="tirocinanteSelezionato"></app-progetto-formativo>
      <form (ngSubmit)="modificaTirocinante()" *ngIf="datiAnagrafici" [formGroup]="formTirocinante">

        <div class="p-fluid p-formgrid p-grid p-jc-end">
          <div class="p-field p-col-12 p-md-4">
            <label for="nome">Nome</label>
            <input [ngClass]="{'ng-dirty': !formTirocinante.get('nome').valid && (formTirocinante.get('nome').dirty || formTirocinante.get('nome').touched)}" formControlName="nome"
                   id="nome"
                   pInputText required type="text"/>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="cognome">Cognome</label>
            <input [ngClass]="{'ng-dirty': !formTirocinante.get('cognome').valid && (formTirocinante.get('cognome').dirty || formTirocinante.get('cognome').touched)}" formControlName="cognome"
                   id="cognome"
                   pInputText required type="text">
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="codiceFiscale">Codice fiscale</label>
            <input (input)="onInputCodiceFiscale(formTirocinante.get('codiceFiscale').value)" [ngClass]="{'ng-dirty': !formTirocinante.get('codiceFiscale').valid && (formTirocinante.get('codiceFiscale').dirty || formTirocinante.get('codiceFiscale').touched)}"
                   formControlName="codiceFiscale"
                   id="codiceFiscale"
                   pInputText
                   required type="text">

            <!-- <small *ngIf="switchToEditMode && startCase" @fadeInAnimation
                   [ngClass]="{'p-error': !cfValid, 'success-text': cfValid }"
                   id="cf-help">{{ getCfValidationMessage() }}</small> -->

          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="luogoDiNascita">Luogo di nascita</label>
            <input [ngClass]="{'ng-dirty': !formTirocinante.get('luogoDiNascita').valid && (formTirocinante.get('luogoDiNascita').dirty || formTirocinante.get('luogoDiNascita').touched)}" formControlName="luogoDiNascita"
                   id="luogoDiNascita"
                   pInputText required type="text">

          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="dataDiNascita">Data di nascita</label>
            <p-calendar [monthNavigator]="true"

                        [ngClass]="{' ng-dirty': !formTirocinante.get('dataDiNascita').valid && (formTirocinante.get('dataDiNascita').dirty || formTirocinante.get('dataDiNascita').touched)}"
                        [yearNavigator]="true"

                        [yearRange]="getYearRange()"
                        dateFormat="dd/mm/yy"
                        formControlName="dataDiNascita" required></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-4">
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="matricola">Matricola</label>
            <input (cancel)="startMatricola=false" (input)="checkMatricola(formTirocinante.get('matricola').value)"
                   [ngClass]="{' ng-dirty': !formTirocinante.get('matricola').valid && (formTirocinante.get('matricola').dirty || formTirocinante.get('matricola').touched)}"
                   formControlName="matricola"
                   id="matricola"
                   pInputText>
            <small *ngIf="existTirocinante" @fadeInAnimation class="p-error" id="cf-help">Matricola già presente</small>
          </div>

          <div class="p-field p-col-12 p-md-4">
            <label for="annoImmatricolazione">A.A immatricolazione</label>
            <input (input)="onInputAnnoImmatricolazione(formTirocinante.get('annoImmatricolazione').value)" [ngClass]="{' ng-dirty': (!formTirocinante.get('annoImmatricolazione').valid && (formTirocinante.get('annoImmatricolazione').dirty || formTirocinante.get('annoImmatricolazione').touched))  || !immatricolazioneValid}" formControlName="annoImmatricolazione"
                   id="annoImmatricolazione"
                   pInputText
                   placeholder="AAAA/AAAA" required>
            <small *ngIf="!immatricolazioneValid && startWriting" @fadeInAnimation class="p-error" id="cf-help">Anno di
              immatricolazione non valido</small>
            <small *ngIf="immatricolazioneValid && startWriting" @fadeInAnimation class="success-text" id="cf-success">Anno
              di
              immatricolazione valido</small>
          </div>

          <div class="p-field p-col-12 p-md-4">
          </div>
          <div class="p-field p-col-12 p-md-12">
            <label for="residenza">Residente in</label>
            <input [ngClass]="{'ng-dirty': !formTirocinante.get('residenza').valid && (formTirocinante.get('residenza').dirty || formTirocinante.get('residenza').touched)}" formControlName="residenza"
                   id="residenza"
                   pInputText required>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="provincia">Prov.</label>
            <p-dropdown (onChange)="selectProvince()" [ngClass]="{'ng-dirty': !formTirocinante.get('provincia').valid && (formTirocinante.get('provincia').dirty || formTirocinante.get('provincia').touched)}"
                        [options]="province"
                        [placeholder]="selectedProvince()"
                        formControlName="provincia"
                        inputId="provincia"
                        optionLabel="name" required></p-dropdown>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="citta">Città</label>
            <p-dropdown (onChange)="showCity=true" [ngClass]="{'ng-dirty': !formTirocinante.get('citta').valid && (formTirocinante.get('citta').dirty || formTirocinante.get('citta').touched)}"
                        [options]="citta"
                        [placeholder]="selectCity()"
                        formControlName="citta"
                        inputId="citta"
                        optionLabel="Nome" required styleClass="stileRuoli"></p-dropdown>
          </div>

          <div class="p-field p-col-12 p-md-4">
            <label for="cap">Cap</label>
            <input [ngClass]="{'ng-dirty': !formTirocinante.get('cap').valid && (formTirocinante.get('cap').dirty || formTirocinante.get('cap').touched)}" formControlName="cap"
                   id="cap"
                   pInputText required>
          </div>

          <div class="p-field p-col-12 p-md-4 parent">
            <label for="prefix">Cellulare<label class="required-asterisk">*</label></label>

            <div class="block-div">
              <div class="first">
                <p-dropdown [options]="country" [placeholder]="phonePrefix"
                            formControlName="prefix"
                            id="prefix" optionLabel="phonecode" required
                            styleClass="stileRuoli"></p-dropdown>
              </div>
              <div class="last">
                <p-inputMask [ngClass]="{'ng-invalid ng-dirty': !formTirocinante.get('cellulare').valid && (formTirocinante.get('cellulare').dirty || formTirocinante.get('cellulare').touched)}" formControlName="cellulare" id="cellulare"
                             mask="9999999999"

                             required></p-inputMask>
              </div>
            </div>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label for="email">Indirizzo email</label>
            <input (cancel)="startEmail=false" (input)="checkEmail(formTirocinante.get('email').value)"
                   [ngClass]="{' ng-dirty': !formTirocinante.get('email').valid && (formTirocinante.get('email').dirty || formTirocinante.get('email').touched)}"
                   formControlName="email"
                   id="email"
                   pInputText required type="email">
            <small *ngIf="existEmail" @fadeInAnimation class="p-error" id="cf-help">Attenzione! Indirizzo email già in
              uso.</small>
          </div>
          <div class="p-field p-col-12 p-md-4">
          </div>


          <div class="p-field p-col-12 p-md-12">
            <p-divider type="dashed"></p-divider>
          </div>
          <div *ngIf="switchToEditMode" class="p-field p-col-12 p-md-2">
            <button (click)="closeEditMode()" class="p-button p-button-danger" label="Annulla" pButton
                    type="button"></button>
          </div>

          <div *ngIf="!switchToEditMode" class="p-field p-col-12 p-md-2">
            <button (click)="nextPage()" class="p-button p-button-success" label="Continua" pButton
            ></button>
          </div>
          <div *ngIf="!switchToEditMode" class="p-field p-col-12 p-md-2">
            <button (click)="switchToEdit()" class="p-button p-button-warning" label="Modifica" pButton
            ></button>
          </div>
          <div *ngIf="switchToEditMode" class="p-field p-col-12 p-md-2">
            <button [disabled]="!formTirocinante.valid || !immatricolazioneValid || existTirocinante || existEmail || !formTirocinante.get('cellulare').valid" class="p-button p-button-success" label="Continua" pButton
                    type="submit"
            ></button>
          </div>
        </div>
        <p-message *ngIf="utils.formValid(formTirocinante).length > 0 && emptyFields().length == 0" @simpleFadeAnimation
                   severity="error" text="Attenzione! Campo/i non popolati correttamente"></p-message>
        <div class="p-md-12" style="padding: 0px"></div>
        <p-message *ngIf="emptyFields().length > 0" @simpleFadeAnimation
                   severity="error" text="Attenzione! Campo/i obbligatori non popolati"></p-message>
      </form>

      <p-messages [enableService]="true" key="tableTirocinanti"></p-messages>
    </p-dialog>


    <p-messages [enableService]="true" key="tableAllTirocinanti"></p-messages>
  </main>
</div>

