import {Pipe, PipeTransform} from '@angular/core';
import {ITirocinante} from '../models/tirocinio/tirocinante-interface';

@Pipe({
  name: 'tirocinanteAlias'
})
export class TirocinanteAliasPipe implements PipeTransform {
  transform(userId: number, tirocinanti: ITirocinante[]) {
    // console.log(tirocinanti);
    // console.log(userId);
    let tirocinante: ITirocinante = tirocinanti.find((tirocinante) => {
      return tirocinante.librettoDiario.id === userId;
    });
    return tirocinante.alias;
  }

}
