import {environment} from 'src/environments/environment';

export class Endpoints {
  public static readonly SERVICE_ENDPOINT = environment.backendEndpoint;
  public static readonly GENERATE_TOKEN_URL = Endpoints.SERVICE_ENDPOINT + '/login/generate-token';
  public static readonly GET_USER_PROFILE = Endpoints.SERVICE_ENDPOINT + '/user/';
  public static readonly UPDATE_USER_PROFILE = Endpoints.SERVICE_ENDPOINT + '/updateUser/';

  /** RESET PASSWORD */
  public static readonly RESET_PWD = '/reset';
  public static readonly SEND_MAIL_PWD_RECOVERY = Endpoints.SERVICE_ENDPOINT + Endpoints.RESET_PWD + '/sendMail';
  public static readonly registration = Endpoints.SERVICE_ENDPOINT + '/registra-admin';
  public static readonly CHECK_TOKEN_PWD_RECOVERY = Endpoints.SERVICE_ENDPOINT + Endpoints.RESET_PWD + '/checkToken';
  public static readonly CHANGE_PASSWORD_PWD_RECOVERY = Endpoints.SERVICE_ENDPOINT + Endpoints.RESET_PWD + '/changePassword';
  public static readonly CHANGE_PASSWORD_NO_TOKEN = Endpoints.SERVICE_ENDPOINT + Endpoints.RESET_PWD + '/changePasswordNoToken';

  /** PROGETTI */
  public static readonly PROGETTI_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/progetti';
  public static readonly PROGETTI_ALL = Endpoints.PROGETTI_BASE_URL + '/all';
  public static readonly PROGETTI_ALL_SORTED_BY_NOME = Endpoints.PROGETTI_BASE_URL + '/allSorted';
  public static readonly NUOVO_PROGETTO = Endpoints.PROGETTI_BASE_URL + '/nuovo';
  public static readonly UPDATE_PROGETTO = Endpoints.PROGETTI_BASE_URL + '/update';
  public static readonly GET_PROGETTO_BY_ID = Endpoints.PROGETTI_BASE_URL + '/getOne/';
  public static readonly PROGETTI_ORE_BY_TIMESHEET_ID = Endpoints.PROGETTI_BASE_URL + '/riepilogoTimesheet';
  public static readonly ELIMINA_PROGETTO_BY_ID = Endpoints.PROGETTI_BASE_URL + '/delete/';

  /** Utenti */
  public static readonly GET_ALL_USERS = Endpoints.SERVICE_ENDPOINT + '/users';
  public static readonly GET_ALL_SORTED_USERS = Endpoints.SERVICE_ENDPOINT + '/sortedUsers';
  public static readonly GET_ALL_ROLES = Endpoints.SERVICE_ENDPOINT + '/getUserRoles';
  public static readonly GET_DIPENDENTI = Endpoints.SERVICE_ENDPOINT + '/getDipendenti';
  public static readonly REGISTRAZIONE = Endpoints.SERVICE_ENDPOINT + '/registrazione';
  public static readonly DELETE_USER = Endpoints.SERVICE_ENDPOINT + '/eliminaUtente/';
  public static readonly GET_ONE_USER = Endpoints.SERVICE_ENDPOINT + '/user/';

  /** MANSIONI */
  public static readonly MANSIONI_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/mansioni';
  public static readonly GET_ALL_MANSIONI = Endpoints.MANSIONI_BASE_URL + '/all';

  /** TIMESHEETS */
  public static readonly TIMESHEETS_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/timesheets';
  public static readonly TIMESHEET_EXISTS_CURRENT_MONTH_BY_UID = Endpoints.TIMESHEETS_BASE_URL + '/existsCurrentMonthForUser/';
  public static readonly NEW_TIMESHEET = Endpoints.TIMESHEETS_BASE_URL + '/new';
  public static readonly CURRENT_MONTH_TIMESHEET_BY_UID = Endpoints.TIMESHEETS_BASE_URL + '/getCurrentMonthTimesheetByUserId/';
  public static readonly GET_ALL_TIMESHEETS = Endpoints.TIMESHEETS_BASE_URL + '/all';
  public static readonly SEARCH_TIMESHEETS = Endpoints.TIMESHEETS_BASE_URL + '/search';
  public static readonly FIND_TIMESHEET_BY_ID = Endpoints.TIMESHEETS_BASE_URL + '/findById/';
  public static readonly FIND_TIMESHEET_BY_ID_v2 = Endpoints.TIMESHEETS_BASE_URL + '/find';
  public static readonly SEND_TIMESHEET = Endpoints.TIMESHEETS_BASE_URL + '/invia';
  public static readonly SEND_TIMESHEET_NEW = Endpoints.TIMESHEETS_BASE_URL + '/inviaRapportino';
  public static readonly APPROVA_TIMESHEET = Endpoints.TIMESHEETS_BASE_URL + '/approva';
  public static readonly RICHIESTA_SBLOCCO_TIMESHEET = Endpoints.TIMESHEETS_BASE_URL + '/richiestaSblocco';
  public static readonly SBLOCCA_TIMESHEET = Endpoints.TIMESHEETS_BASE_URL + '/sblocca';
  public static readonly SBLOCCA_TIMESHEET_NEW = Endpoints.TIMESHEETS_BASE_URL + '/sbloccaRapportino';
  public static readonly NOTIFICHE_SBLOCCO_TIMESHEET = Endpoints.TIMESHEETS_BASE_URL + '/findAllNotifications';
  public static readonly FIND_ALL_TIMESHEETS_BY_RICHIESTA_SBLOCCO = Endpoints.TIMESHEETS_BASE_URL + '/findAllByRichiestaSblocco';
  public static readonly FIND_ALL_TIMESHEETS_BY_IN_APPROVAZIONE = Endpoints.TIMESHEETS_BASE_URL + '/findAllByInApprovazione';
  public static readonly FIND_ALL_TIMESHEETS_BY_USERID = Endpoints.TIMESHEETS_BASE_URL + '/findAllByUserId/';
  public static readonly TIMESHEET_EXPORT_TO_PDF = Endpoints.TIMESHEETS_BASE_URL + '/exportToPdfByTimesheetId/';

  /** WORKING DAY */
  public static readonly WORKING_DAY_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/workingDay';
  public static readonly WORKING_DAY_EXISTS_URL = Endpoints.WORKING_DAY_BASE_URL + '/existsThisWorkingDay';
  public static readonly WORKING_DAY_NEW = Endpoints.WORKING_DAY_BASE_URL + '/new';
  public static readonly WORKING_DAY_DELETE = Endpoints.WORKING_DAY_BASE_URL + '/delete';
  public static readonly WORKING_DAY_DELETE_ONE_ACTIVITY = Endpoints.WORKING_DAY_BASE_URL + '/deleteOneDayDetail';
  public static readonly PROGETTI_ORE_BY_TIMESHEET_V2 = Endpoints.WORKING_DAY_BASE_URL + '/riepilogoTimesheet_v2';
  public static readonly ORE_SITUAZIONE_ECONOMICA_PROGETTO = Endpoints.WORKING_DAY_BASE_URL + '/riepilogoByProgettoId/';

  /** BUSTE PAGA */
  public static readonly BUSTE_PAGA_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/bustePaga';
  public static readonly BUSTE_PAGA_NEW = Endpoints.BUSTE_PAGA_BASE_URL + '/nuova';
  public static readonly BUSTE_PAGA_MODIFICA = Endpoints.BUSTE_PAGA_BASE_URL + '/modifica';
  public static readonly BUSTE_PAGA_GET_ALL = Endpoints.BUSTE_PAGA_BASE_URL + '/getAll';
  public static readonly BUSTE_PAGA_DELETE_BY_ID = Endpoints.BUSTE_PAGA_BASE_URL + '/delete/';
  public static readonly GET_FILE_PDF_BUSTA_PAGA_BY_ID = Endpoints.BUSTE_PAGA_BASE_URL + '/fileBustaPaga/';
  public static readonly GET_FILE_PDF_BUSTAPAGA_BY_UID_V2 = Endpoints.BUSTE_PAGA_BASE_URL + '/fileBustaPaga_v2';
  public static readonly GET_BUSTA_PAGA_BY_ID = Endpoints.BUSTE_PAGA_BASE_URL + '/findById/';
  public static readonly DELETE_FILEPDF_BY_BUSTA_PAGA_ID = Endpoints.BUSTE_PAGA_BASE_URL + '/deleteFilePdfByBustaPagaId';
  public static readonly BUSTE_PAGA_SEARCH = Endpoints.BUSTE_PAGA_BASE_URL + '/search';

  /** STATISTICHE */
  public static readonly STATISTICHE_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/statistiche';
  public static readonly STATISTICHE_MONTH_OVERVIEW = Endpoints.STATISTICHE_BASE_URL + '/monthOverview';
  public static readonly STATISTICHE_LAST_3_MONTHS = Endpoints.STATISTICHE_BASE_URL + '/lastThreeMonths';
  public static readonly STATISTICHE_LAST_6_MONTHS = Endpoints.STATISTICHE_BASE_URL + '/lastSixMonths';

  /** CONSUNTIVAZIONE */
  public static readonly CONSUNTIVAZIONE_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/consuntivazione';
  public static readonly FIND_CONSUNTIVAZIONE_BY_PROGETTOID = Endpoints.CONSUNTIVAZIONE_BASE_URL + '/findByProgettoId/';
  public static readonly CONSUNTIVAZIONE_DETAIL_OVERVIEW_BY_PROGETTOID = Endpoints.CONSUNTIVAZIONE_BASE_URL + '/findDetailOverviewByProgettoId/';

  /** STATIC ASSETS */
  public static readonly STATIC_CONTENT_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/static';
  public static readonly GET_ASSET = Endpoints.STATIC_CONTENT_BASE_URL + '/getAsset/';

  /** TIPI CONTRATTO */
  public static readonly TIPI_CONTRATTO_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/tipiContratto';
  public static readonly GET_ALL_TIPI_CONTRATTO = Endpoints.TIPI_CONTRATTO_BASE_URL + '/findAll';

  /** TIROCINANTI */
  public static readonly TIROCINANTI_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/tirocinanti';
  public static readonly NUOVO_TIROCINANTE = Endpoints.TIROCINANTI_BASE_URL + '/nuovoTirocinante';
  public static readonly GET_ALL_TIROCINANTI_SORTED = Endpoints.TIROCINANTI_BASE_URL + '/sortedTirocinanti';
  public static readonly UPDATE_TIROCINANTE_PROFILE = Endpoints.TIROCINANTI_BASE_URL + '/updateTirocinante';
  public static readonly EXIST_TIROCINANTE = Endpoints.TIROCINANTI_BASE_URL + '/existTirocinante';
  public static readonly EXIST_TIROCINANTE_OR_USER = Endpoints.TIROCINANTI_BASE_URL + '/existEmailUser';
  public static readonly DELETE_TIROCINANTE = Endpoints.TIROCINANTI_BASE_URL + '/eliminaTirocinante/';
  public static readonly GET_TIROCINANTE_PROFILE = Endpoints.TIROCINANTI_BASE_URL + '/tirocinante/';

  /** PROGETTI FORMATIVI */
  public static readonly PROGETTI_FORMATIVI_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/progettoFormativo';
  public static readonly NUOVO_PROGETTO_FORMATIVO = Endpoints.PROGETTI_FORMATIVI_BASE_URL + '/nuovoProgettoFormativo';
  public static readonly MODIFICA_PROGETTO_FORMATIVO = Endpoints.PROGETTI_FORMATIVI_BASE_URL + '/update';
  public static readonly GET_PROGETTO_FORMATIVO_TIROCINANTE = Endpoints.PROGETTI_FORMATIVI_BASE_URL + '/progettoFormativo/';
  public static readonly GET_ALL_PROGETTI_FORMATIVI = Endpoints.PROGETTI_FORMATIVI_BASE_URL + '/getProgettiFormativi/';

  /** WORKING DAY TIROCINIO */
  public static readonly WORKING_DAY_TIROCINIO_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/workingDayTirocinante';
  public static readonly WORKING_DAY_TIROCINIO_NEW = Endpoints.WORKING_DAY_TIROCINIO_BASE_URL + '/new';
  public static readonly WORKING_DAY_TIROCINIO_DELETE = Endpoints.WORKING_DAY_TIROCINIO_BASE_URL + '/delete';
  public static readonly WORKING_DAY_TIROCINIO_DELETE_ONE_ACTIVITY = Endpoints.WORKING_DAY_TIROCINIO_BASE_URL + '/deleteOneDayDetail';


  /** LIBRETTO DIARIO */
  public static readonly LIBRETTO_DIARIO_BASE_URL = Endpoints.SERVICE_ENDPOINT + '/librettoDiario';
  public static readonly GET_MONTH_DETAIL_BY_USER_ID_MONTH = Endpoints.LIBRETTO_DIARIO_BASE_URL + '/findOneMonthDetail';
  public static readonly FIND_ALL_MONTH_DETAIL_BY_ID = Endpoints.LIBRETTO_DIARIO_BASE_URL + '/findAllByTirocinanteId/';
  public static readonly GET_LIBRETTO_FOR_TIROCINANTE = Endpoints.LIBRETTO_DIARIO_BASE_URL + '/findLibrettoForTirocinante';
  public static readonly SEND_LIBRETTO = Endpoints.LIBRETTO_DIARIO_BASE_URL + '/inviaLibretto';
  public static readonly RICHIESTA_SBLOCCO_LIBRETTO = Endpoints.LIBRETTO_DIARIO_BASE_URL + '/richiestaSblocco';
  public static readonly FIND_ALL_LIBRETTI_BY_RICHIESTA_SBLOCCO = Endpoints.LIBRETTO_DIARIO_BASE_URL + '/findAllByRichiestaSblocco';
  public static readonly NOTIFICHE_SBLOCCO_LIBRETTI = Endpoints.LIBRETTO_DIARIO_BASE_URL + '/findAllNotifications';
  public static readonly SBLOCCA_LIBRETTO = Endpoints.LIBRETTO_DIARIO_BASE_URL + '/sbloccaLibretto';
  public static readonly APPROVA_LIBRETTO = Endpoints.LIBRETTO_DIARIO_BASE_URL + '/approva';
  public static readonly SOSPENDI_LIBRETTO = Endpoints.LIBRETTO_DIARIO_BASE_URL + '/sospendi';
  public static readonly ESPORTA_PDF = Endpoints.LIBRETTO_DIARIO_BASE_URL + '/exportToPdfByTirocinanteId/';

}
