import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../constants/endpoints';
import {DeleteFilePdfRequest} from '../models/request/delete-file-pdf-request';
import {BustaPagaResponse} from '../models/response/busta-paga-response';
import {ServiceResponse} from '../models/response/service-response';

@Injectable()
export class BustePagaService {

  constructor(private http: HttpClient) {
  }

  nuovaBustaPaga(bustaPagaRequest: FormData): Observable<ServiceResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data'
      })
    };
    return this.http.post<ServiceResponse>(Endpoints.BUSTE_PAGA_NEW, bustaPagaRequest, httpOptions);
  }

  getAllBustePaga(): Observable<BustaPagaResponse[]> {
    return this.http.get<BustaPagaResponse[]>(Endpoints.BUSTE_PAGA_GET_ALL);
  }

  eliminaBustaPaga(bustaPagaId: string): Observable<ServiceResponse> {
    return this.http.delete<ServiceResponse>(Endpoints.BUSTE_PAGA_DELETE_BY_ID + bustaPagaId);
  }

  getBustaPagaById(bustaPagaId: string): Observable<BustaPagaResponse> {
    return this.http.get<BustaPagaResponse>(Endpoints.GET_BUSTA_PAGA_BY_ID + bustaPagaId);
  }

  getFilePdfBustaPaga(fileId: string): any {
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'

    };
    return this.http.get<any>(Endpoints.GET_FILE_PDF_BUSTA_PAGA_BY_ID + fileId, httpOptions);
  }

  getFilePdfBustaPaga_v2(bustaPagaId: string, userId: string): any {

    let params = new HttpParams().set('userId', userId).set('bustaPagaId', bustaPagaId);
    return this.http.get<any>(Endpoints.GET_FILE_PDF_BUSTAPAGA_BY_UID_V2, {params: params, responseType: 'arraybuffer' as 'json'});
  }

  deleteFilePdfByBustaPagaId(deleteFilePdfRequest: DeleteFilePdfRequest): Observable<ServiceResponse> {
    return this.http.post<ServiceResponse>(Endpoints.DELETE_FILEPDF_BY_BUSTA_PAGA_ID, deleteFilePdfRequest);
  }

  modificaBustaPaga(modificaBustaPagaRequest: FormData): Observable<ServiceResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data'
      })
    };
    return this.http.post<ServiceResponse>(Endpoints.BUSTE_PAGA_MODIFICA, modificaBustaPagaRequest, httpOptions);
  }

  searchBustePaga(userId: string, mese: number, anno: string): Observable<BustaPagaResponse[]> {
    let params = new HttpParams().set('userId', '' + userId).set('mese', '' + mese).set('anno', '' + anno);
    return this.http.get<BustaPagaResponse[]>(Endpoints.BUSTE_PAGA_SEARCH, {params: params});
  }

  searchBustePagaYear(userId: string, anno: string): Observable<BustaPagaResponse[]> {
    let params = new HttpParams().set('userId', '' + userId).set('anno', '' + anno);
    return this.http.get<BustaPagaResponse[]>(Endpoints.BUSTE_PAGA_SEARCH, {params: params});
  }

  searchBustePagaById(userId: any): Observable<BustaPagaResponse[]> {
    let params = new HttpParams().set('userId', '' + userId).set('mese', '0').set('anno', '');
    return this.http.get<BustaPagaResponse[]>(Endpoints.BUSTE_PAGA_SEARCH, {params: params});
  }
}
