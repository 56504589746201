import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getNomeMese'
})
export class MesePipe implements PipeTransform {
  monthsArray: string[] = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

  transform(num: number) {
    return this.monthsArray[num];
  }
}
