import {NgModule} from '@angular/core';
import {ImportoInEuroPipe} from './importo-in-euro.pipe';
import {MesePipe} from './mese.pipe';
import {NumberToBytesPipe} from './number-to-bytes.pipe';
import {RuoloUtentePipe} from './ruolo-utente.pipe';
import {TimesheetStatusPipe} from './timesheet-status.pipe';
import {UserAliasPipe} from './user-alias.pipe';
import {MeseBustaPagaPipe} from './mese-busta-paga.pipe';
import {StatoRapportinoPipe} from './stato-rapportino.pipe';
import {ZeroInfinityPipe} from './zero-infinity.pipe';

@NgModule({
  imports: [],
  declarations: [
    MesePipe,
    TimesheetStatusPipe,
    RuoloUtentePipe,
    ImportoInEuroPipe,
    NumberToBytesPipe,
    UserAliasPipe,
    MeseBustaPagaPipe,
    StatoRapportinoPipe,
    ZeroInfinityPipe
  ],
  exports: [
    MesePipe,
    TimesheetStatusPipe,
    RuoloUtentePipe,
    ImportoInEuroPipe,
    NumberToBytesPipe,
    UserAliasPipe,
    MeseBustaPagaPipe,
    StatoRapportinoPipe,
    ZeroInfinityPipe
  ]
})
export class CustomPipesModule {
}
