<div class="main-area">
  <main>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <p-toast position="bottom-right"></p-toast>
    <div class="titolo-sezione">
      <i class="las la-toolbox"></i> Progetti
    </div>
    <p-divider type="dashed"></p-divider>
    <div *ngIf="!showTable" class="p-grid">
      <div class="p-col-12">
        <div class="msgNoDataFound">
          <span class="las la-5x la-info-circle"></span>
          <h3>{{ msgProgettiNonPresenti }}</h3>
        </div>
      </div>
    </div>
    <p-divider type="none"></p-divider>
    <p-table
      *ngIf="showTable"
      [paginator]="true"
      [rowHover]="true"
      [rowsPerPageOptions]="[10,20,30]" [rows]="10" [showCurrentPageReport]="true"
      [totalRecords]="progetti.length"
      [value]="progetti"
      currentPageReportTemplate="Risultati da {first} a {last}. Totale {totalRecords} progetto/i"
      styleClass="p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th class="p-text-center" pSortableColumn="nome" scope="col">
            Nome
            <p-sortIcon field="nome"></p-sortIcon>
            <p-columnFilter display="menu" field="nome" type="text"></p-columnFilter>
          </th>
          <th class="p-text-center" scope="col">
            Cliente
            <p-columnFilter display="menu" field="cliente" type="text"></p-columnFilter>
          </th>
          <th class="p-text-center" pSortableColumn="status" scope="col">
            Stato
            <p-sortIcon field="status"></p-sortIcon>
            <p-columnFilter [showMatchModes]="false" display="menu" field="status" matchMode="equals">
              <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                <p-dropdown (onChange)="filter($event.value)" [ngModel]="value" [options]="statusProgetti" optionLabel="name"
                            optionValue="code" placeholder="Qualsiasi">
                  <ng-template let-option pTemplate="item">
                      <span>
                        <p-tag [severity]="option.code == 'Attivo' ? 'success' : 'warning'"
                               [style]="{'width': '100%'}"
                               [value]="option.name"></p-tag>
                      </span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>
          <th class="p-text-center" scope="col">
            Azioni
          </th>
        </tr>
      </ng-template>
      <ng-template let-progetto pTemplate="body">
        <tr>
          <td class="p-text-center">{{progetto.nome}}</td>
          <td class="p-text-center">{{progetto.cliente}}</td>
          <td class="p-text-center">
            <p-tag [severity]="progetto.status == 'Attivo' ? 'success' : 'warning'" [style]="{'width': '60%'}"
                   [value]="progetto.status"></p-tag>
          </td>
          <td class="p-text-center">
            <button (click)="goToSingleProjectView(progetto.id)" class="p-button p-button-text" icon="pi pi-eye" pButton
                    type="button"></button>
            <button (click)="confirmDelete_v2($event, progetto.id)" class="p-button p-button-text p-button-danger" icon="pi pi-trash" pButton
                    type="button"></button>
            <p-confirmPopup></p-confirmPopup>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-dialog (onHide)="onHideModal()" *ngIf="progettoSelezionato" [(visible)]="displayModal" [draggable]="false" closeOnEscape="true"
              header="Dettaglio progetto" maximizable="true" modal="true" position="top" resizable="true">
      <p-tag [value]="'Id progetto = '+progettoSelezionato.id" icon="pi pi-briefcase" severity="info"></p-tag>
      <p-divider type="dashed"></p-divider>
      <form (ngSubmit)="modificaProgetto()" [formGroup]="formProgetto">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-6 p-md-3">
            <label for="code">Codice</label>
            <input formControlName="code" id="code" pInputText type="text">
          </div>
          <div class="p-field p-col-6 p-md-3">
            <label for="nome">Nome</label>
            <input formControlName="nome" id="nome" pInputText type="text">
          </div>
          <div class="p-field p-col-6 p-md-3">
            <label for="cliente">Cliente</label>
            <input formControlName="cliente" id="cliente" pInputText type="text">
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label for="state">Stato</label>
            <p-dropdown (onChange)="detectStatusChange()" [options]="statusProgetti" formControlName="status"
                        inputId="statusSelezionato" optionLabel="name" optionLabel="name" placeholder="Seleziona">
            </p-dropdown>
          </div>
          <div class="p-field p-col-6 p-md-3">
            <label for="dataDiNascita">Data inizio progetto</label>
            <p-calendar [monthNavigator]="true" [yearNavigator]="true" [yearRange]="timeUtils.getFutureYearRange()"
                        dateFormat="dd/mm/yy" formControlName="dataInizio"></p-calendar>
          </div>
          <div class="p-field p-col-6 p-md-3">
            <label for="dataDiNascita">Data fine progetto</label>
            <p-calendar [monthNavigator]="true" [yearNavigator]="true" [yearRange]="timeUtils.getFutureYearRange()" dateFormat="dd/mm/yy"
                        formControlName="dataFine"></p-calendar>
          </div>

          <div class="p-field p-col-6 p-md-3">
            <!-- <label for="budgetComplessivo">Budget complessivo</label>
            <input id="budgetComplessivo" formControlName="budgetComplessivo" [value]="budgetComplessivo | importoInEuro" type="number" pInputText *ngIf="switchToEditMode">
            {{ budgetComplessivo | importoInEuro}}-->

            <label for="budgetComplessivo">Budget complessivo</label>
            <div class="p-inputgroup">
              <input formControlName="budgetComplessivo" id="budgetComplessivo" pInputText placeholder="Budget complessivo"
                     type="text">
              <span class="p-inputgroup-addon">.00</span>
              <span class="p-inputgroup-addon">€</span>
            </div>
          </div>

          <div class="p-field p-col-6 p-md-3">
            <label for="coloreStatistiche">Colore statistiche</label>
            <p-colorPicker [(ngModel)]="coloreStatistiche" formControlName="coloreStatistiche"></p-colorPicker>
          </div>


          <div class="p-field p-col-12 p-md-12">
            <p-divider align="left">
              <p-button label="Situazione economica:" styleClass="p-button-text"></p-button>
            </p-divider>
          </div>
          <div class="p-field p-col-6 p-md-3">
            <label for="oreUomoStimate">Ore stimate</label>
            <input formControlName="oreUomoStimate" id="oreUomoStimate" pInputText type="number">
          </div>

          <div class="p-field p-col-6 p-md-3">
            <label for="budgetComplessivo">Spese preventivate</label>
            <div class="p-inputgroup">
              <input formControlName="spesePreventivate" pInputText placeholder="Spese preventivate" type="text">
              <span class="p-inputgroup-addon">.00</span>
              <span class="p-inputgroup-addon">€</span>
            </div>
          </div>

          <div class="p-field p-col-12 p-md-12">
            <p-divider type="dashed"></p-divider>
          </div>

          <div *ngIf="!switchToEditMode" class="p-field p-col-12 p-md-6 p-xl-offset-3 p-md-offset-3">
            <button (click)="switchToEdit()" class="p-button p-button-warning" label="Modifica" pButton
                    type="button"></button>
          </div>
          <div *ngIf="switchToEditMode" class="p-field p-col-12 p-md-6">
            <button (click)="closeEditMode()" class="p-button p-button-danger" label="Annulla" pButton
                    type="button"></button>
          </div>
          <div *ngIf="switchToEditMode" class="p-field p-col-12 p-md-6">
            <button class="p-button p-button-success" label="Salva" pButton type="submit"></button>
          </div>


        </div>
      </form>
    </p-dialog>
  </main>
</div>
