export class RouterPaths {
  /** TIROCINANTE ROUTE */
  public static readonly TIROCINANTE_ROUTE = 'tirocinante';
  public static readonly VISTA_LIBRETTO_DIARIO = 'libretto-diario';
  public static readonly PROFILO_TIROCINANTE = 'profilo';
  public static readonly PIANO_FORMATIVO = 'piano-formativo';
  public static readonly MODIFICA_PASSWORD = 'modifica-password';

  /** ADMIN ROUTE */
  public static readonly ADMIN_ROUTE = 'admin';
  public static readonly LISTA_TIROCINANTI = 'tirocinanti';
  public static readonly LISTA_DIPENDENTI = 'dipendenti';
  public static readonly LISTA_PROGETTI = 'progetti';
  public static readonly LISTA_BUSTE_PAGA = 'buste-paga';
}
