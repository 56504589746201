import {StatusTirocinante} from '../models/tirocinio/status-tirocinante';
import {StatusTirocinanteEnum} from '../models/enums/status-tirocinante';


export const STATUS_TIROCINANTI_MOCK: StatusTirocinante[] = [
  new StatusTirocinante(StatusTirocinanteEnum.ATTIVO, StatusTirocinanteEnum.ATTIVO),
  new StatusTirocinante(StatusTirocinanteEnum.SOSPESO, StatusTirocinanteEnum.SOSPESO),
  new StatusTirocinante(StatusTirocinanteEnum.CHIUSO, StatusTirocinanteEnum.CHIUSO),
  new StatusTirocinante(StatusTirocinanteEnum.IN_APPROVAZIONE, StatusTirocinanteEnum.IN_APPROVAZIONE)
];
