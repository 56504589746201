import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import TimeUtils from '../../../utils/time-utils';
import GenericUtils from '../../../utils/generic-utils';
import * as codiceFiscaleUtils from '@marketto/codice-fiscale-utils';
import {Constants} from '../../../constants/constants';
import {ProgettiService} from '../../../services/progetti.service';
import {MenuItem, MessageService} from 'primeng/api';
import {UserService} from '../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {simpleFadeAnimation} from '../../../animations/simple-fade-animation';
import {fadeInAnimation} from '../../../animations/fade-in-animation';
import {Router} from '@angular/router';
import {IProvince} from '../../../models/tirocinio/province-interface';

import italy from '../../../mock/italy.json';
import {ITirocinante} from '../../../models/tirocinio/tirocinante-interface';
import {ProgettoFormativoComponent} from '../progetto-formativo/progetto-formativo.component';
import {TirocinanteService} from '../../../services/tirocinante.service';
import {AppComponent} from '../../../app.component';
import {FrontendMessage} from '../../../constants/frontend-message';
import {PHONE_PREFIX_MOCK} from '../../../mock/phone-prefix.mock';

@Component({
  selector: 'app-nuovo-tirocinante',
  templateUrl: './nuovo-tirocinante.component.html',
  styleUrls: ['./nuovo-tirocinante.component.scss'],
  providers: [MessageService],
  animations: [simpleFadeAnimation, fadeInAnimation]
})
export class NuovoTirocinanteComponent implements OnInit {

  formNuovoTirocinante: FormGroup;
  province: IProvince[];
  city: any;
  cellulare: string;
  timeUtils = TimeUtils;
  utils = GenericUtils;
  dataFineDisabled: boolean;
  cfUtil: any;
  cfValid: boolean;
  startCase: boolean;
  initialDateValue = Constants.INITIAL_DATE_DATASOUND;

  stepNuovoTirocinante: MenuItem[];
  datiAnagrafici: boolean;
  activeIndex: number;
  immatricolazioneValid: boolean;
  startWriting: boolean;
  startMatricola: boolean;
  startEmail: boolean;
  existEmail: boolean;
  existTirocinante: boolean;
  country: any[];
  @ViewChild(ProgettoFormativoComponent) child;
  @Input('childToMaster') tirocinanteToStore: ITirocinante;

  constructor(private fb: FormBuilder,
              private progettiService: ProgettiService,
              private messageService: MessageService,
              private userService: UserService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private tirocinanteService: TirocinanteService,
              private appComponent: AppComponent) {

    this.spinner.show();
    this.immatricolazioneValid = false;
    this.existTirocinante = false;
    this.country = PHONE_PREFIX_MOCK;

    this.stepNuovoTirocinante = [{
      label: 'Dati anagrafici tirocinante',
      disabled: true,
    },
      {
        label: 'Dati piano formativo',
        disabled: true
      }];

    this.city = [];
    this.province = this.appComponent.getProvince();
    this.cfUtil = codiceFiscaleUtils;
    this.datiAnagrafici = true;
    this.activeIndex = 0;

    this.messageService.clear();
    this.dataFineDisabled = true;

    this.createForm();
    this.startMatricola = false;
    this.startEmail = false;
    this.existEmail = false;
    this.spinner.hide();

  }

  ngOnInit(): void {
  }

  onInputCodiceFiscale(cf: string) {
    this.startCase = true;
    const {Validator} = this.cfUtil;
    if (Validator.codiceFiscale(cf).valid) {
      this.cfValid = true;
    } else {
      this.cfValid = false;
    }
  }

  createForm() {


    this.formNuovoTirocinante = this.fb.group({
      nome: ['', [
        Validators.required,
        Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]
      ],
      cognome: ['', [
        Validators.required,
        Validators.minLength(Constants.INPUT_TEXT_MIN_LENGHT),
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]],
      codiceFiscale: ['', [
        Validators.required,
        Validators.maxLength(Constants.CODICE_FISCALE_MAX_LENGTH),
        Validators.pattern(Constants.CODICE_FISCALE_REGEX),
        this.utils.codiceFiscaleValidator]
      ],
      luogoDiNascita: ['', [Validators.required,
        Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT),
        this.utils.whitespaceValidator,
        this.utils.endWithSpaceValidator,
        Validators.pattern(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO)]],
      dataDiNascita: ['', [Validators.required, this.timeUtils.dataDiNascitaValidator]],
      matricola: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(Constants.TEXT_AND_NUMBERS_REGEX)]],
      annoImmatricolazione: ['', [Validators.required, Validators.pattern(Constants.YEAR_REGEX), this.utils.immatricolazioneValidator]],
      residenza: ['', [Validators.required, Validators.maxLength(Constants.INPUT_TEXT_MAX_LENGHT), Validators.pattern(Constants.REGEX_ALPHANUM_NO_ONLY_SPACE_SPECIAL)]],
      provincia: ['', Validators.required],
      citta: ['', Validators.required],
      cap: ['', [Validators.required, Validators.pattern(Constants.CAP)]],
      prefix: ['+39',],
      cellulare: ['', [Validators.required, Validators.maxLength(Constants.MAX_TELEPHONE), Validators.minLength(Constants.MIN_TELEPHONE), Validators.pattern(Constants.PHONE_REGEX)]],
      email: ['', [Validators.required, Validators.pattern(Constants.EMAIL_REGEX)]],

    });
    this.formNuovoTirocinante.reset();
  }

  checkPrefix() {
  }

  childToParent(name) {
    this.datiAnagrafici = true;
    this.activeIndex = 0;
  }

  selectProvince() {
    this.formNuovoTirocinante.get('citta').setValue('');
    this.city = [];
    const provinceCode = this.formNuovoTirocinante.get('provincia').value.name;

    italy.forEach((city) => {
      this.spinner.show();
      if (city.Provincia === provinceCode) {
        this.city.push(city);
      }

    });

    this.spinner.hide();
  }


  onInputAnnoImmatricolazione(anno: string, data: Date) {
    const annoIniziale = new Date().getFullYear();
    const annoDiNascita = this.formNuovoTirocinante.get('dataDiNascita').value.getFullYear();
    this.startWriting = true;
    anno.split('/');
    const primaParte = anno[0] + anno[1] + anno[2] + anno[3];
    const secondaParte = anno[5] + anno[6] + anno[7] + anno[8];
    if ((+secondaParte - +primaParte) === 1) {
      if ((+primaParte <= annoIniziale) && +primaParte >= annoDiNascita + 18) {

        this.immatricolazioneValid = true;
      }
    } else {
      this.immatricolazioneValid = false;
    }
  }

  inviaformNuovoTirocinante() {

    const dataDiNascita = new Date(Date.UTC(this.formNuovoTirocinante.get('dataDiNascita').value.getFullYear(), this.formNuovoTirocinante.get('dataDiNascita').value.getMonth(), this.formNuovoTirocinante.get('dataDiNascita').value.getDate()));

    let phoneCode = '';
    if (this.formNuovoTirocinante.get('prefix').touched && this.formNuovoTirocinante.value.prefix.phonecode != null) {
      phoneCode = this.formNuovoTirocinante.value.prefix.phonecode;
    } else {
      phoneCode = '+39';
    }
    this.tirocinanteToStore = {
      id: null,
      username: this.generateUsernameFromEmail(this.formNuovoTirocinante.value.email),
      nome: this.formNuovoTirocinante.value.nome.trim(),
      cognome: this.formNuovoTirocinante.value.cognome.trim(),
      email: this.formNuovoTirocinante.value.email,
      cellulare: phoneCode + ' ' + this.formNuovoTirocinante.value.cellulare,
      stato: null,
      dataDiNascita,
      codiceFiscale: this.formNuovoTirocinante.value.codiceFiscale,
      luogoDiNascita: this.formNuovoTirocinante.value.luogoDiNascita,
      matricola: this.formNuovoTirocinante.value.matricola,
      annoImmatricolazione: this.formNuovoTirocinante.value.annoImmatricolazione,
      residenza: this.formNuovoTirocinante.value.residenza,
      provincia: this.formNuovoTirocinante.value.provincia.name,
      citta: this.formNuovoTirocinante.value.citta.Nome,
      cap: this.formNuovoTirocinante.value.cap,
      progettoFormativo: null
    };


    this.tirocinanteService.existTirocinante(this.tirocinanteToStore.matricola).subscribe(response => {
      this.messageService.clear();
      this.spinner.hide();
      if (!response) {
        this.datiAnagrafici = false;
        this.activeIndex = 1;
      } else {
        this.spinner.hide();
        this.messageService.add({
          severity: FrontendMessage.SEVERITY_ERROR,
          summary: '',
          detail: 'Attenzione! Tirocinante già presente'
        });
      }
    });

  }

  checkMatricola(matricola: string) {
    this.startMatricola = true;
    this.tirocinanteService.existTirocinante(matricola).subscribe(response => {

      this.existTirocinante = response;
    });
  }

  checkEmail(email: string) {
    this.startEmail = true;
    this.tirocinanteService.existEmailUser(email).subscribe(response => {

      this.existEmail = response;
    });
  }

  resetForm() {
    this.cfValid = false;
    this.startCase = false;
    this.immatricolazioneValid = false;
    this.startMatricola = false;
    this.existTirocinante = false;
    this.startWriting = false;
    this.formNuovoTirocinante.reset();
    this.datiAnagrafici = true;
    this.startEmail = false;
    this.existEmail = false;

  }


  getYearRange(): string {
    const currentYear = new Date();
    const finalYear = currentYear.getFullYear() - 18;
    const startingYear = currentYear.getFullYear() - 90;
    return startingYear + ':' + finalYear;
  }

  generateUsername(email: string) {
    return email.split('@')[0];
  }

  generateUsernameV2(nome: string, cognome: string) {
    const nomeInput = nome.toLowerCase().trim().split(' ').join('');
    const cognomeInput = cognome.toLowerCase().trim().split(' ').join('');
    return nomeInput + '.' + cognomeInput;
  }

  generateUsernameFromEmail(email: string) {
    return email.toLowerCase().trim().split(' ').join('');
  }


  emptyFields() {
    const fields = [];
    const controls = this.formNuovoTirocinante.controls;
    for (const name in controls) {
      if (this.utils.formValid(this.formNuovoTirocinante).includes(name) && controls[name].invalid && (controls[name].value == null || controls[name].value == '')) {
        fields.push(name);
      }
    }
    return fields;
  }
}
