export class ProgettoDipendente {
  public id: number;
  public nome: string;
  public code: string;
  private status: string;

  constructor(nome: string, code: string) {
    this.nome = nome;
    this.code = code;
  }

  public get _id() {
    return this.id;
  }

  public set _id(id: number) {
    this.id = id;
  }

  public get _status() {
    return this.status;
  }

  public set _status(status: string) {
    this.status = status;
  }

  public getNome(): string {
    return this.nome;
  }

  public setNome(nome: string) {
    this.nome = nome;
  }

  public getCode(): string {
    return this.code;
  }

  public setCode(code: string) {
    this.code = code;
  }
}

