<input (click)="toggleSidebar()" [checked]="navToggleChecked" id="nav-toggle" type="checkbox">
<div class="sidebar">
  <div class="sidebar-brand">
    <a routerLink="/tirocinante"><img [alt]="companyName" [src]="datasoundImageUrl" class="logo" height="50px"
                                      width="50px"/></a>
    <a routerLink="/tirocinante"><img [alt]="companyName" [src]="fullLogoUrl" class="logo-extended" width="150px"/></a>
  </div>
  <div class="sidebar-menu">
    <ul>
      <li>
        <a #profilo="routerLinkActive"
           [routerLinkActive]="['active']" [routerLink]="'/'+routerPath.TIROCINANTE_ROUTE+'/'+routerPath.PROFILO_TIROCINANTE">
          <span class="las la-id-card"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Profilo</small>
          <span>Profilo</span>
        </a>
      </li>

      <li>
        <a #pianoFormativo="routerLinkActive"
           [routerLinkActive]="['active']" [routerLink]="'/'+routerPath.TIROCINANTE_ROUTE+'/'+routerPath.PIANO_FORMATIVO">
          <span class="las la-clipboard-list"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Piano Formativo</small>
          <span>Piano Formativo</span>
        </a>
      </li>

      <li>
        <a [ngClass]="{'active':!pianoFormativo.isActive && !profilo.isActive && !modificaPassword.isActive}"
           [routerLink]="'/'+routerPath.TIROCINANTE_ROUTE">
          <span class="las la-calendar"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Libretto Diario</small>
          <span>Libretto Diario</span>
        </a>
      </li>
      <li>
        <a #modificaPassword="routerLinkActive"
           [routerLinkActive]="['active']" [routerLink]="'/'+routerPath.TIROCINANTE_ROUTE+'/'+routerPath.MODIFICA_PASSWORD">
          <span class="las la-lock"></span>
          <small *ngIf="currentWindowWidth < 1200" @fadeSlideInOut>Modifica Password</small>
          <span>Modifica Password</span>
        </a>
      </li>
    </ul>
  </div>
</div>

