import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ServiceResponse} from '../models/response/service-response';
import {Endpoints} from '../constants/endpoints';
import {ITirocinante} from '../models/tirocinio/tirocinante-interface';
import {Observable} from 'rxjs';
import {Tirocinante} from '../models/tirocinio/tirocinante';
import {LoginResponseInterface} from '../models/login/login-response.interface';
import {StorageConstants} from '../constants/storage-constants';

@Injectable()
export class TirocinanteService {

  constructor(private http: HttpClient) {
  }

  registrazioneTirocinante(tirocinante: ITirocinante) {
    return this.http.post<ServiceResponse>(Endpoints.NUOVO_TIROCINANTE, tirocinante);
  }

  getAllTirocinantiSorted(): Observable<ITirocinante[]> {
    return this.http.get<ITirocinante[]>(Endpoints.GET_ALL_TIROCINANTI_SORTED);
  }

  existTirocinante(matricola: string): Observable<boolean> {
    const params = new HttpParams().set('matricola', matricola);
    return this.http.get<boolean>(Endpoints.EXIST_TIROCINANTE, {params});
  }

  existEmailUser(email: string): Observable<boolean> {
    const params = new HttpParams().set('email', email);
    return this.http.get<boolean>(Endpoints.EXIST_TIROCINANTE_OR_USER, {params});
  }

  updateTirocinanteProfile(tirocinante: Tirocinante): Observable<ServiceResponse> {
    return this.http.put<ServiceResponse>(Endpoints.UPDATE_TIROCINANTE_PROFILE, tirocinante);
  }

  eliminaTirocinante(tirocinanteId: number): Observable<ServiceResponse> {
    return this.http.delete<ServiceResponse>(Endpoints.DELETE_TIROCINANTE + tirocinanteId);
  }

  getTirocinanteProfile(): Observable<ITirocinante> {
    const userData: LoginResponseInterface = JSON.parse(localStorage.getItem(StorageConstants.USER_DATA));
    return this.http.get<ITirocinante>(Endpoints.GET_TIROCINANTE_PROFILE + userData.userId);
  }
}
