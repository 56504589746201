<div class="main-area">
  <main>
    <ngx-spinner [fullScreen]="false" [zIndex]="50" bdColor="rgba(255, 255, 255, 0.8)" color="#118ab2"
                 size="medium" type="ball-clip-rotate-multiple">
      <p style="color: #118ab2"> Caricamento... </p>
    </ngx-spinner>
    <div class="titolo-sezione">
      <i class="las la-id-card"></i> Piano Formativo
    </div>
    <form *ngIf="dataLoaded | async" [formGroup]="formProgettoFormativo">

      <div class="p-fluid p-formgrid p-grid p-jc-end">
        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <p-button label="Durata tirocinio:" styleClass="p-button-text"></p-button>
          </p-divider>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="dataInizioTirocinio">Data inizio tirocinio</label>
          <p-calendar dateFormat="dd/mm/yy" formControlName="dataInizioTirocinio"
                      id="dataInizioTirocinio"
          ></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="dataFineTirocinio">Data fine tirocinio</label>
          <p-calendar dateFormat="dd/mm/yy" formControlName="dataFineTirocinio"
                      id="dataFineTirocinio"
          ></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="ore">Ore </label>
          <input formControlName="ore" id="ore"
                 pInputText type="number">

        </div>

        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <p-button label="Tutor universitario:" styleClass="p-button-text"></p-button>
          </p-divider>
        </div>

        <div class="p-field p-col-12 p-md-4">
          <label for="cognomeTutorUniversitario">Cognome</label>
          <input formControlName="cognomeTutorUniversitario" id="cognomeTutorUniversitario"
                 pInputText type="text">
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="nomeTutorUniversitario">Nome</label>
          <input formControlName="nomeTutorUniversitario" id="nomeTutorUniversitario"
                 pInputText type="text"/>
        </div>
        <div class="p-field p-col-12 p-md-4"></div>

        <div class="p-field p-col-12 p-md-4">
          <label for="areaScientifica">Area Scientifica</label>
          <p-dropdown [placeholder]="progettoFormativo.tutorUniversitario.areaScientifica" formControlName="areaScientifica"
                      inputId="areaScientifica"
          ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="emailTutorUniversitario">Indirizzo email</label>
          <input formControlName="emailTutorUniversitario" id="emailTutorUniversitario"
                 pInputText type="email">
        </div>
        <div class="p-field p-col-12 p-md-4"></div>
        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <p-button label="Tutor aziendale:" styleClass="p-button-text"></p-button>
          </p-divider>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="tutorAziendale">Tutor Aziendale</label>
          <p-dropdown [placeholder]="progettoFormativo.tutorAziendale.nome + ' ' + progettoFormativo.tutorAziendale.cognome" formControlName="tutorAziendale" id="tutorAziendale"
                      inputId="tutorAziendale"
          ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-4 parent">
          <label for="cellulareTutorAziendale">Cellulare</label>

          <div class="block-div">
            <div class="first">
              <input [placeholder]="this.tutorPhonePrefix" formControlName="prefixTutorAziendale"
                     id="prefixTutorAziendale" pInputText readonly>
            </div>
            <div class="last">
              <p-inputMask formControlName="cellulareTutorAziendale" id="cellulareTutorAziendale"
                           mask="9999999999" readonly
              ></p-inputMask>
            </div>
          </div>


        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="emailTutorAziendale">Indirizzo email</label>
          <input formControlName="emailTutorAziendale" id="emailTutorAziendale"
                 pInputText readonly type="email">
        </div>

        <div class="p-field p-col-12 p-md-4"></div>
        <div class="p-field p-col-12 p-md-12">
          <p-divider align="left">
            <p-button label="Obiettivi e modalità di tirocinio:" styleClass="p-button-text"></p-button>
          </p-divider>
        </div>
        <div class="p-field p-col-12 p-md-12">
      <textarea formControlName="obiettivi" id="obiettivi"
                pInputText type="text"></textarea>
        </div>
      </div>
    </form>
  </main>
</div>
