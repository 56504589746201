import {AbstractControl, FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import * as codiceFiscaleUtils from '@marketto/codice-fiscale-utils';
import {StatusDipendenteEnum} from '../models/enums/status-dipendente';
import {Stato} from '../models/timesheet/stato.enum';
import {EStatoTirocinio} from '../models/tirocinio/stato-tirocnio.enums';
import {Constants} from '../constants/constants';

export default class GenericUtils {
  static onlyPositiveNumbers(event: any) {
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  static onlyIntegerNumber(event: KeyboardEvent) {
    return (event.key >= '0' && event.key <= '9');
  }

  static formValid(form: FormGroup) {
    const invalid = [];
    if (form != undefined) {
      const controls = form.controls;
      for (const name in controls) {
        if (controls[name].invalid && (controls[name].dirty || controls[name].touched)) {
          invalid.push(name);
        }
      }
    }
    return invalid;
  }

  static greaterThanOneNumberValidator(form: FormControl): ValidationErrors {
    return form.value < 1 ? {greaterThanOneNumberValidator: true} : null;
  }

  static maxOreSettimanaliValidator(form: FormControl): ValidationErrors {
    return form.value > 100 ? {maxOreSettimanaliValidator: true} : null;
  }

  static greaterThanZeroNumberValidator(form: FormControl): ValidationErrors {
    return form.value <= 0 ? {greaterThanZeroNumberValidator: true} : null;
  }

  static codiceFiscaleValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const {Validator} = codiceFiscaleUtils;
    const inputValue = control.value == null ? '' : control.value;
    if (!Validator.codiceFiscale(inputValue).valid) {
      return {'codiceFiscale': true};
    }
    return null;
  }

  static immatricolazioneValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const annoIniziale = new Date();
    const inputValue = control.value == null ? '' : control.value;
    inputValue.split('');
    const primaParte = inputValue[0] + inputValue[1] + inputValue[2] + inputValue[3];
    const secondaParte = inputValue[5] + inputValue[6] + inputValue[7] + inputValue[8];

    if ((+secondaParte - +primaParte) !== 1) {
      if ((+primaParte <= annoIniziale.getFullYear())) {
        return {'annoImmatricolazione': true};
      }

    }
    return null;
  }

  static initialHourValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const inputValue = control.value == null ? '' : control.value;
    inputValue.split(':');
    const primaParte = inputValue[3] + inputValue[4];

    if ((+primaParte % 5 !== 0)) {
      return {'oraInizio': true};

    }
    return null;
  }

  static lastHourValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const inputValue = control.value == null ? '' : control.value;
    inputValue.split(':');
    const primaParte = inputValue[3] + inputValue[4];

    // console.log(primaParte);
    if ((+primaParte % 5 !== 0)) {
      // console.log(primaParte);
      return {'oraFine': true};
    } else {

      return null;
    }
  }

  static whitespaceValidator(form: FormControl): ValidationErrors {
    if (form.value != null) {
      return form.value.startsWith(' ') ? {whitespace: true} : null;
    }
    return null;
  }

  static endWithSpaceValidator(form: FormControl): ValidationErrors {
    if (form.value != null) {
      return form.value.endsWith(' ') ? {endWithSpaceValidator: true} : null;
    }
    return null;
  }

  static containsPointsValidator(form: FormControl): ValidationErrors {
    return form.value.includes('.') ? {containsPointsValidator: true} : null;
  }

  static disablePointKeyPress(event: KeyboardEvent) {
    return !(event.key == '.' || event.key == ',');
  }


  static onlyCharacters(event: KeyboardEvent) {
    const regex = new RegExp(Constants.ALPHA_REGEX_LETTERE_ACCENTATE_CON_APOSTROFO);
    const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  }

  static onlyCharactersAndNumbers(event: KeyboardEvent) {
    const regex = new RegExp(Constants.TEXT_AND_NUMBERS_REGEX);
    const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  }

  static statoUtenteStyle(stato: string) {
    return stato == StatusDipendenteEnum.DIPENDENTE ? 'primary' : 'danger';
  }

  static renderPulsanteInvia(richiestaSblocco: boolean, statoId: number) {
    if (!richiestaSblocco && statoId == Stato.IN_LAVORAZIONE) {
      return true;
    }
    return false;
  }

  static renderRichiediSblocco(richiestaSblocco: boolean, statoId: number) {
    if (!richiestaSblocco && statoId == Stato.IN_APPROVAZIONE) {
      return true;
    }
    return false;
  }

  static renderSblocco(richiestaSblocco: boolean, statoId: number) {
    if (!richiestaSblocco && statoId == Stato.APPROVATO) {
      return true;
    } else if (richiestaSblocco && statoId == Stato.IN_APPROVAZIONE) {
      return true;
    }
    return false;
  }

  static renderApprova(richiestaSblocco: boolean, statoId: number) {
    if (!richiestaSblocco && statoId == Stato.IN_APPROVAZIONE) {
      return true;
    } else {
      return false;
    }
  }

  static renderEsporta(richiestaSblocco: boolean, statoId: number) {
    if (!richiestaSblocco && statoId == EStatoTirocinio.CHIUSO) {
      return true;
    } else {
      return false;
    }
  }

  static random_hex_color_code() {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  }

  static renderPulsanteInviaLibretto(richiestaSblocco: boolean, statoId: number) {
    if (!richiestaSblocco && statoId === (EStatoTirocinio.APERTO || EStatoTirocinio.SOSPESO)) {
      return true;
    }
    return false;
  }

  static renderRichiediSbloccoLibretto(richiestaSblocco: boolean, statoId: number) {
    if (!richiestaSblocco && statoId === EStatoTirocinio.IN_APPROVAZIONE) {
      return true;
    }
    return false;
  }

  static renderSbloccoLibretto(richiestaSblocco: boolean, statoId: number) {
    if (!richiestaSblocco && statoId == EStatoTirocinio.CHIUSO) {
      return true;
    } else if (richiestaSblocco && statoId == EStatoTirocinio.IN_APPROVAZIONE) {
      return true;
    }
    return false;
  }

  static renderApprovaLibretto(richiestaSblocco: boolean, statoId: number) {
    if (!richiestaSblocco && statoId == EStatoTirocinio.IN_APPROVAZIONE) {
      return true;
    } else {
      return false;
    }
  }

  static statoLibrettoStyle(stato: string) {
    if (stato === 'Attivo') {
      return 'info';
    } else if (stato === 'Sospeso') {
      return 'danger';
    } else if (stato === 'Chiuso') {
      return 'success';
    } else {
      return 'warning';
    }
  }
}
