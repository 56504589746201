import {ComodatoPc} from './comodato.pc';
import {Progetto} from './progetto';
import {Role} from './role';

export class Utente {
  public id: number;
  public username: string;
  public nome: string;
  public cognome: string;
  public email: string;
  public cellulare: string;
  public dataDiNascita: Date;
  public status: string;
  public codiceFiscale: string;
  public tipoContratto: string;
  public dataInizioRapporto: Date;
  public dataFineRapporto: Date;
  public oreSettimanali: number;
  public chiaviUfficio: boolean;
  public telefonoAziendale: string;
  public comodatoPc: ComodatoPc;
  public mansione: string;
  public roles: Role[];
  public progetti: Progetto[];


  constructor($id: number, $username: string, $nome: string, $cognome: string, $email: string, $cellulare: string, $dataDiNascita: Date, $status: string, $codiceFiscale: string, $tipoContratto: string, $dataInizioRapporto: Date, $dataFineRapporto: Date, $oreSettimanali: number, $chiaviUfficio: boolean, $telefonoAziendale: string, $comodatoPc: ComodatoPc, $mansione: string, $roles: Role[], $progetti: Progetto[]) {
    this.id = $id;
    this.username = $username;
    this.nome = $nome;
    this.cognome = $cognome;
    this.email = $email;
    this.cellulare = $cellulare;
    this.dataDiNascita = $dataDiNascita;
    this.status = $status;
    this.codiceFiscale = $codiceFiscale;
    this.tipoContratto = $tipoContratto;
    this.dataInizioRapporto = $dataInizioRapporto;
    this.dataFineRapporto = $dataFineRapporto;
    this.oreSettimanali = $oreSettimanali;
    this.chiaviUfficio = $chiaviUfficio;
    this.telefonoAziendale = $telefonoAziendale;
    this.comodatoPc = $comodatoPc;
    this.mansione = $mansione;
    this.roles = $roles;
    this.progetti = $progetti;
  }

  public get $username(): string {
    return this.username;
  }

  public set $username(value: string) {
    this.username = value;
  }

  public get $nome(): string {
    return this.nome;
  }

  public set $nome(value: string) {
    this.nome = value;
  }

  public get $cognome(): string {
    return this.cognome;
  }

  public set $cognome(value: string) {
    this.cognome = value;
  }

  public get $email(): string {
    return this.email;
  }

  public set $email(value: string) {
    this.email = value;
  }

  public get $cellulare(): string {
    return this.cellulare;
  }

  public set $cellulare(value: string) {
    this.cellulare = value;
  }

  public get $dataDiNascita(): Date {
    return this.dataDiNascita;
  }

  public set $dataDiNascita(value: Date) {
    this.dataDiNascita = value;
  }

  public get $status(): string {
    return this.status;
  }

  public set $status(value: string) {
    this.status = value;
  }

  public get $codiceFiscale(): string {
    return this.codiceFiscale;
  }

  public set $codiceFiscale(value: string) {
    this.codiceFiscale = value;
  }

  public get $tipoContratto(): string {
    return this.tipoContratto;
  }

  public set $tipoContratto(value: string) {
    this.tipoContratto = value;
  }

  public get $dataInizioRapporto(): Date {
    return this.dataInizioRapporto;
  }

  public set $dataInizioRapporto(value: Date) {
    this.dataInizioRapporto = value;
  }

  public get $dataFineRapporto(): Date {
    return this.dataFineRapporto;
  }

  public set $dataFineRapporto(value: Date) {
    this.dataFineRapporto = value;
  }

  public get $oreSettimanali(): number {
    return this.oreSettimanali;
  }

  public set $oreSettimanali(value: number) {
    this.oreSettimanali = value;
  }

  public get $chiaviUfficio(): boolean {
    return this.chiaviUfficio;
  }

  public set $chiaviUfficio(value: boolean) {
    this.chiaviUfficio = value;
  }

  public get $telefonoAziendale(): string {
    return this.telefonoAziendale;
  }

  public set $telefonoAziendale(value: string) {
    this.telefonoAziendale = value;
  }

  public get $comodatoPc(): ComodatoPc {
    return this.comodatoPc;
  }

  public set $comodatoPc(value: ComodatoPc) {
    this.comodatoPc = value;
  }

  public get $mansione(): string {
    return this.mansione;
  }

  public set $mansione(value: string) {
    this.mansione = value;
  }

  public get $roles(): Role[] {
    return this.roles;
  }

  public set $roles(value: Role[]) {
    this.roles = value;
  }

  public get $progetti(): Progetto[] {
    return this.progetti;
  }

  public set $progetti(value: Progetto[]) {
    this.progetti = value;
  }

  public getId(): number {
    return this.id;
  }

  public setId(value: number) {
    this.id = value;
  }

}
